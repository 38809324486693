import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '../Button';

const DeleteConfirmationModal = props => (
  <div
    className="modal fade show"
    id="overlayModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="deleteConfirm"
    style={{ display: 'block' }}
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content" id='deleteConfirmationModal'>
        <div className="modal-header">
          <h4 className="modal-title" id="deleteConfirm">
            <FormattedMessage
              id= {props.headingMessage}
              defaultMessage= {props.headingMessage}
            />
          </h4>
          <button
            type="button"
            className="close sprite-icon"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.handleClickCross}
          />
        </div>
        <div className="modal-body">
          <FormattedMessage
            id={props.deleteMessage}
            defaultMessage={props.deleteMessage}
          />
          <div className="cust-select-btns mr-top-24">
            <Button
              color='#282C37'
              text='Cancel'
              data-dismiss="modal"
              className='cancelModal'
              onBtnClick={props.handleClickCancel}
            />
            <Button
              color='#dc3545'
              onBtnClick={() => props.handleClickDelete(props.dataId)}
              dataDismiss='modal'
              text={props.deleteButtonText}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

DeleteConfirmationModal.propTypes = {
  handleClickDelete: PropTypes.func, // Handles delete button click
  dataId: PropTypes.string, // id sent as param in handleClickDelete
  handleClickCancel: PropTypes.func, // Handles cancel button click
  handleClickCross: PropTypes.func, // Handles Modal cross button
  deleteMessage: PropTypes.string, // delete message to be shown on modal
  deleteButtonText: PropTypes.string, // Text to be shown on delete button
  headingMessage: PropTypes.string // Modal's heading string
};

DeleteConfirmationModal.defaultProps = {
  deleteMessage: 'Are you sure you want to delete this record?',
  handleClickDelete: () => { },
  handleClickCross: () => { },
  handleClickCancel: () => { },
  dataId: null,
  deleteButtonText: 'Yes',
  headingMessage: 'CONFIRM DELETE'
};

export default DeleteConfirmationModal;
