import moment from 'moment';
import actionConstants from '../constants/ActionConstants';
import { offlineModeOptions } from '../../libs/constants/offlineEnhanced';
import convertArrayToObject, { createReportEntries } from '../../_helpers/offlineEnhanced';

const customerModalState = {
  selectedCustomer: {},
  reportCategory: '',
  reportType: '',
};

const customerListFetchStatus = {
  isFetching: false,
  isSuccess: false,
  error: '',
  total: 0,
  page: 0,
  pages: 1,
  isFirstRequest: true,
};

const jobsListFetchStatus = {
  isFetching: false,
  isSuccess: false,
  error: '',
  total: 0,
  page: 0,
  pages: 1,
  isFirstRequest: true,
};

const teamList = {
  isFetching: false,
  isSuccess: false,
  error: '',
  teamList: {},
  total: 0,
  page: 0,
  pages: 1,
  isFirstRequest: true,
};

const reportTemplateList = {
  isFetching: false,
  isSuccess: false,
  error: '',
  reportTemplateList: {},
  total: 0,
  page: 0,
  pages: 1,
  isFirstRequest: true,
};

const deviceList = {
  isFetching: false,
  isSuccess: false,
  error: '',
  deviceList: {},
  total: 0,
  page: 0,
  pages: 1,
  isFirstRequest: true,
};

const innerDeviceList = {
  isFetching: false,
  isSuccess: false,
  error: '',
  innerDeviceList: {},
  total: 0,
  page: 0,
  pages: 1,
  isFirstRequest: true,
};

const partList = {
  isFetching: false,
  isSuccess: false,
  error: '',
  partList: {},
  total: 0,
  page: 0,
  pages: 1,
  isFirstRequest: true,
};

const reportListByJobIds = {
  isFetching: false,
  isSuccess: false,
  error: '',
  reportListByJobIds: {},
  total: 0,
  page: 0,
  pages: 1,
  isFirstRequest: true,
};

const reportListByCustomer = {
  isFetching: false,
  isSuccess: false,
  error: '',
  reportListByCustomer: {},
  total: 0,
  page: 0,
  pages: 1,
  isFirstRequest: true,
};

const uploadSignature = {
  isSuccess: false,
  error: '',
  isFetching: false,
  signatureType: '',
};

const questionJsonDefaultReports = {
  isFetching: false,
  isSuccess: false,
  error: '',
  total: 0,
  page: 0,
  pages: 1,
  isFirstRequest: true,
  questionJsonDefaultReports: {}
};

// Object contains objects having keys as job id for job reports and report id for direct reports
const reportEntries = {};

const initialState = {
  isOffline: false,
  isModalVisible: false,
  isSelectCustomerModalVisible: false,
  isSelectJobsModalVisible: false,
  reportSelectedToUpload: null,
  selectedOfflineModeOption: offlineModeOptions[2],
  customersList: {},
  jobsList: {},
  clientIds: [],
  deviceIds: [],
  reportEntryIdsEditedOffline: [],
  step: 0,
  currentNewReportIndex: 0,
  isErrorWhileDownloadingData: false,
  downloadErrorMessage: '',
  isDataDownloading: false,
  shouldFetchData: true,
  customerModalState,
  customerListFetchStatus,
  jobsListFetchStatus,
  teamList,
  reportTemplateList,
  deviceList,
  innerDeviceList,
  partList,
  reportListByJobIds,
  reportListByCustomer,
  reportEntries,
  uploadSignature,
  questionJsonDefaultReports,
};

export default function offlineEnhanced(state = { ...initialState }, action) {
  let previouslyAddedLabours = [];
  let labourToBeUpdated = [];
  let previouslyAddedParts = [];
  let partToBeUpdated = [];
  let newLabour = [];
  let newParts = [];
  let reportByJobId;
  let deviceIds = [];
  let reportEntryToBeDeleted = [];
  let newDeviceListWithClientIds = {};
  let newCustomerList = {};
  let newReportDetails = {};
  const newJobList = { ...state.jobsList };
  const newReportListByCustomer = { ...state.reportListByCustomer.reportListByCustomer };
  const newReportListByJobId = { ...state.reportListByJobIds.reportListByJobIds };
  const newReportEntries = { ...state.reportEntries };
  const newClientIds = [...state.clientIds];
  const newDevicesIds = [...state.deviceIds];

  switch (action.type) {
    case actionConstants.TOGGLE_OFFLINE_MODE:
      if (!action.payload.isOffline) {
        Object.keys({
          ...(newReportListByCustomer || {}),
          ...(newReportListByJobId || {}),
          ...(newJobList || {})
        }).forEach((reportEntryId) => {
          if (!(state.reportEntryIdsEditedOffline || []).includes(reportEntryId)) {
            delete newReportEntries[reportEntryId];
            delete newReportListByCustomer[reportEntryId];
            delete newReportListByJobId[reportEntryId];
            delete newJobList[reportEntryId];
          }
        });
      }
      return {
        ...state,
        isOffline: action.payload.isOffline,
        shouldFetchData: !action.payload.isOffline || state.shouldFetchData,
        customerModalState: action.payload.isOffline
          ? {
            ...state.customerModalState,
          }
          : {
            ...initialState.customerModalState,
          },
        reportEntries: !action.payload.isOffline
          ? newReportEntries
          : state.reportEntries,
        jobsList: !action.payload.isOffline
          ? newJobList
          : state.jobsList,
        reportListByCustomer: {
          ...state.reportListByCustomer,
          reportListByCustomer: !action.payload.isOffline
            ? newReportListByCustomer
            : state.reportListByCustomer.reportListByCustomer
        },
        reportListByJobIds: {
          ...state.reportListByJobIds,
          reportListByJobIds: !action.payload.isOffline
            ? newReportListByJobId
            : state.reportListByJobIds.reportListByJobIds
        },
      };
    case actionConstants.TOGGLE_OFFLINE_MODE_MODAL_VISIBILITY:
      return {
        ...state,
        isModalVisible: action.payload.isModalVisible,
        jobsList: !action.payload.isModalVisible
          ? {}
          : { ...state.jobsList },
      };
    case actionConstants.TOGGLE_CUSTOMER_MODAL_VISIBILITY:
      return {
        ...state,
        isSelectCustomerModalVisible: action.payload.isCustomerModalVisible,
        shouldFetchData: action.payload.isCustomerModalVisible || state.shouldFetchData,
      };
    case actionConstants.TOGGLE_JOBS_MODAL_VISIBILITY:
      return {
        ...state,
        isSelectJobsModalVisible: action.payload.isSelectJobsModalVisible
      };
    case actionConstants.SELECT_OFFLINE_MODE_OPTION:
      return {
        ...state,
        selectedOfflineModeOption: action.payload.selectedOfflineMode,
      };
    case actionConstants.SET_CUSTOMER_MODAL_STATE:
      return {
        ...state,
        customerModalState: {
          ...state.customerModalState,
          ...action.payload,
        }
      };
    case actionConstants.ADD_CUSTOMER_TO_LIST:
      return {
        ...state,
        customersList: {
          ...state.customersList,
          [state.customerModalState.selectedCustomer._id]: {
            ...state.customerModalState.selectedCustomer,
            reportCategory: state.customerModalState.reportCategory,
            reportType: state.customerModalState.reportType
          }
        },
        clientIds: [...state.clientIds, state.customerModalState.selectedCustomer._id],
        shouldFetchData: true,
      };
    case actionConstants.RESET_OFFLINE_CUSTOMERS_LIST:
      return {
        ...state,
        customersList: {}
      };
    case actionConstants.SET_SELECTED_JOBS_CLIENTS:
      delete (newJobList[action.payload.jobDetails._id]);
      if (action.payload.isAddJob) {
        return {
          ...state,
          jobsList: {
            ...state.jobsList,
            [action.payload.jobDetails._id]: { ...action.payload.jobDetails },
          },
        };
      }
      return {
        ...state,
        jobsList: { ...newJobList },
      };
    case actionConstants.RESET_OFFLINE_JOBS_LIST:
      return {
        ...state,
        jobsList: {}
      };
    case actionConstants.SET_STEP:
      return {
        ...state,
        step: action.payload.step,
        shouldFetchData: action.payload.step > 12 ? false : state.shouldFetchData,
      };

    case actionConstants.FETCH_CUSTOMER_LIST_REQUEST:
      return {
        ...state,
        customerListFetchStatus: {
          ...state.customerListFetchStatus,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.FETCH_CUSTOMER_LIST_SUCCESS:
      newCustomerList = state.customerListFetchStatus.page === action.payload.page
        ? { ...state.customersList }
        : { ...state.customersList, ...convertArrayToObject(action.payload.docs) };
      return {
        ...state,
        customersList: newCustomerList,
        customerListFetchStatus: {
          ...state.customerListFetchStatus,
          isFetching: false,
          pages: action.payload.pages,
          total: action.payload.total,
          page: action.payload.page,
          isSuccess: true,
          isFirstRequest: (action.payload.page < 1),
        },
        clientIds: Object.keys(newCustomerList),
      };
    case actionConstants.FETCH_CUSTOMER_LIST_FAILURE:
      return {
        ...state,
        customerListFetchStatus: {
          ...state.customerListFetchStatus,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        },
        isErrorWhileDownloadingData: true,
        downloadErrorMessage: action.message,
      };
    case actionConstants.FETCH_CUSTOMER_LIST_CLEAR:
      return {
        ...state,
        customerListFetchStatus: {
          ...initialState.customerListFetchStatus,
        }
      };

    case actionConstants.FETCH_JOBS_LIST_REQUEST:
      return {
        ...state,
        jobsListFetchStatus: {
          ...state.jobsListFetchStatus,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.FETCH_JOBS_LIST_SUCCESS:
      return {
        ...state,
        jobsList: state.jobsListFetchStatus.page === action.payload.page
          ? { ...newJobList }
          : { ...newJobList, ...convertArrayToObject(action.payload.docs) },
        jobsListFetchStatus: {
          ...state.jobsListFetchStatus,
          isFetching: false,
          pages: action.payload.pages,
          total: action.payload.total,
          page: action.payload.page,
          isSuccess: true,
          isFirstRequest: (action.payload.page < 1),
        }
      };
    case actionConstants.FETCH_JOBS_LIST_FAILURE:
      return {
        ...state,
        jobsListFetchStatus: {
          ...state.jobsListFetchStatus,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        },
        isErrorWhileDownloadingData: true,
        downloadErrorMessage: action.message,
      };
    case actionConstants.FETCH_JOBS_LIST_CLEAR:
      return {
        ...state,
        jobsListFetchStatus: {
          ...initialState.jobsListFetchStatus,
        }
      };

    case actionConstants.FETCH_REPORTS_LIST_BY_JOB_IDS_REQUEST:
      return {
        ...state,
        reportListByJobIds: {
          ...state.reportListByJobIds,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.FETCH_REPORTS_LIST_BY_JOB_IDS_SUCCESS:
      return {
        ...state,
        reportListByJobIds: {
          ...state.reportListByJobIds,
          isFetching: false,
          pages: action.payload.pages,
          total: action.payload.total,
          page: action.payload.page,
          isSuccess: true,
          isFirstRequest: (action.payload.page < 1),
          reportListByJobIds: state.reportListByJobIds.page === action.payload.page
            ? { ...state.reportListByJobIds.reportListByJobIds }
            : {
              ...state.reportListByJobIds.reportListByJobIds,
              ...convertArrayToObject(action.payload.docs)
            },
        }
      };
    case actionConstants.FETCH_REPORTS_LIST_BY_JOB_IDS_FAILURE:
      return {
        ...state,
        reportListByJobIds: {
          ...state.reportListByJobIds,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        },
        isErrorWhileDownloadingData: true,
        downloadErrorMessage: action.message,
      };
    case actionConstants.FETCH_REPORTS_LIST_BY_JOB_IDS_CLEAR:
      return {
        ...state,
        reportListByJobIds: {
          ...initialState.reportListByJobIds,
          reportListByJobIds: state.reportListByJobIds.reportListByJobIds,
        }
      };

    case actionConstants.FETCH_REPORTS_LIST_BY_CUSTOMER_REQUEST:
      return {
        ...state,
        reportListByCustomer: {
          ...state.reportListByCustomer,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.FETCH_REPORTS_LIST_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        reportListByCustomer: {
          ...state.reportListByCustomer,
          isFetching: false,
          pages: action.payload.pages,
          total: action.payload.total,
          page: action.payload.page,
          isSuccess: true,
          isFirstRequest: (action.payload.page < 1),
          reportListByCustomer: state.reportListByCustomer.page === action.payload.page
            ? { ...state.reportListByCustomer.reportListByCustomer }
            : {
              ...state.reportListByCustomer.reportListByCustomer,
              ...convertArrayToObject(action.payload.docs)
            },
        }
      };
    case actionConstants.FETCH_REPORTS_LIST_BY_CUSTOMER_FAILURE:
      return {
        ...state,
        reportListByCustomer: {
          ...state.reportListByCustomer,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        },
        isErrorWhileDownloadingData: true,
        downloadErrorMessage: action.message,
      };
    case actionConstants.FETCH_REPORTS_LIST_BY_CUSTOMER_CLEAR:
      return {
        ...state,
        reportListByCustomer: {
          ...initialState.reportListByCustomer,
          reportListByCustomer: state.reportListByCustomer.reportListByCustomer,
        }
      };

    case actionConstants.FETCH_TEAM_LIST_REQUEST:
      return {
        ...state,
        teamList: {
          ...state.teamList,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.FETCH_TEAM_LIST_SUCCESS:
      return {
        ...state,
        teamList: {
          ...state.teamList,
          isFetching: false,
          pages: action.payload.pages,
          total: action.payload.total,
          page: action.payload.page,
          isSuccess: true,
          isFirstRequest: (action.payload.page < 1),
          teamList: state.teamList.page === action.payload.page
            ? { ...state.teamList.teamList }
            : { ...state.teamList.teamList, ...convertArrayToObject(action.payload.docs) },
        }
      };
    case actionConstants.FETCH_TEAM_LIST_FAILURE:
      return {
        ...state,
        teamList: {
          ...state.teamList,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        },
        isErrorWhileDownloadingData: true,
        downloadErrorMessage: action.message,
      };
    case actionConstants.FETCH_TEAM_LIST_CLEAR:
      return {
        ...state,
        teamList: {
          ...initialState.teamList,
          teamList: state.teamList.teamList,
        }
      };

    case actionConstants.FETCH_REPORTS_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        reportTemplateList: {
          ...state.reportTemplateList,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.FETCH_REPORTS_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        reportTemplateList: {
          ...state.reportTemplateList,
          isFetching: false,
          pages: action.payload.pages,
          total: action.payload.total,
          page: action.payload.page,
          isSuccess: true,
          isFirstRequest: (action.payload.page < 1),
          reportTemplateList: state.reportTemplateList.page === action.payload.page
            ? { ...state.reportTemplateList.reportTemplateList }
            : {
              ...state.reportTemplateList.reportTemplateList,
              ...convertArrayToObject(action.payload.docs)
            },
        }
      };
    case actionConstants.FETCH_REPORTS_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        reportTemplateList: {
          ...state.reportTemplateList,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        },
        isErrorWhileDownloadingData: true,
        downloadErrorMessage: action.message,
      };
    case actionConstants.FETCH_REPORTS_TEMPLATE_LIST_CLEAR:
      return {
        ...state,
        reportTemplateList: {
          ...initialState.reportTemplateList,
          reportTemplateList: state.reportTemplateList.reportTemplateList,
        }
      };

    case actionConstants.FETCH_DEVICE_LIST_REQUEST:
      return {
        ...state,
        deviceList: {
          ...state.deviceList,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.FETCH_DEVICE_LIST_SUCCESS:
      newDeviceListWithClientIds = state.deviceList.page === action.payload.page
        ? { ...state.deviceList.deviceList }
        : { ...state.deviceList.deviceList, ...convertArrayToObject(action.payload.docs, 'clientId') };
      Object.values(newDeviceListWithClientIds).forEach((devicesList) => {
        const newDeviceIds = devicesList && devicesList.map(device => device._id);
        deviceIds = [...deviceIds, ...newDeviceIds];
      });
      return {
        ...state,
        deviceList: {
          ...state.deviceList,
          isFetching: false,
          pages: action.payload.pages,
          total: action.payload.total,
          page: action.payload.page,
          isSuccess: true,
          isFirstRequest: (action.payload.page < 1),
          deviceList: newDeviceListWithClientIds,
        },
        deviceIds,
      };
    case actionConstants.FETCH_DEVICE_LIST_FAILURE:
      return {
        ...state,
        deviceList: {
          ...state.deviceList,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        },
        isErrorWhileDownloadingData: true,
        downloadErrorMessage: action.message,
      };
    case actionConstants.FETCH_DEVICE_LIST_CLEAR:
      return {
        ...state,
        deviceList: {
          ...initialState.deviceList,
          deviceList: state.deviceList.deviceList,
        }
      };

    case actionConstants.FETCH_INNER_DEVICE_LIST_REQUEST:
      return {
        ...state,
        innerDeviceList: {
          ...state.innerDeviceList,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.FETCH_INNER_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        innerDeviceList: {
          ...state.innerDeviceList,
          isFetching: false,
          pages: action.payload.pages,
          total: action.payload.total,
          page: action.payload.page,
          isSuccess: true,
          isFirstRequest: (action.payload.page < 1),
          innerDeviceList: state.innerDeviceList.page === action.payload.page
            ? { ...state.innerDeviceList.innerDeviceList }
            : {
              ...state.innerDeviceList.innerDeviceList,
              ...convertArrayToObject(action.payload.docs, 'clientId', 'devices', { ...state.innerDeviceList.innerDeviceList })
            },
        }
      };
    case actionConstants.FETCH_INNER_DEVICE_LIST_FAILURE:
      return {
        ...state,
        innerDeviceList: {
          ...state.innerDeviceList,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        },
        isErrorWhileDownloadingData: true,
        downloadErrorMessage: action.message,
      };
    case actionConstants.FETCH_INNER_DEVICE_LIST_CLEAR:
      return {
        ...state,
        innerDeviceList: {
          ...initialState.innerDeviceList,
          innerDeviceList: state.innerDeviceList.innerDeviceList,
        }
      };

    case actionConstants.FETCH_PART_LIST_REQUEST:
      return {
        ...state,
        partList: {
          ...state.partList,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.FETCH_PART_LIST_SUCCESS:
      return {
        ...state,
        partList: {
          ...state.partList,
          isFetching: false,
          pages: action.payload.pages,
          total: action.payload.total,
          page: action.payload.page,
          isSuccess: true,
          isFirstRequest: (action.payload.page < 1),
          partList: state.partList.page === action.payload.page
            ? { ...state.partList.partList }
            : { ...state.partList.partList, ...convertArrayToObject(action.payload.docs) },
        }
      };
    case actionConstants.FETCH_PART_LIST_FAILURE:
      return {
        ...state,
        partList: {
          ...state.partList,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        },
        isErrorWhileDownloadingData: true,
        downloadErrorMessage: action.message,
      };
    case actionConstants.FETCH_PART_LIST_CLEAR:
      return {
        ...state,
        partList: {
          ...initialState.partList,
          partList: state.partList.partList,
        }
      };

    case actionConstants.SET_CLIENT_IDS:
      newClientIds.shift();
      return {
        ...state,
        clientIds: [...newClientIds],
      };
    case actionConstants.SET_DEVICE_IDS:
      newDevicesIds.shift();
      return {
        ...state,
        deviceIds: [...newDevicesIds],
      };
    case actionConstants.DELETE_JOB:
      delete newJobList[action.payload.jobId];
      delete newReportEntries[action.payload.jobId];
      reportByJobId = Object.values(newReportListByJobId)
        .filter(report => report.jobId === action.payload.jobId);
      if (reportByJobId[0]) {
        delete newReportListByJobId[reportByJobId[0]._id];
      }
      return {
        ...state,
        jobsList: { ...newJobList },
        reportListByJobIds: {
          ...state.reportListByJobIds,
          reportListByJobIds: { ...newReportListByJobId },
        },
        reportEntries: { ...newReportEntries },
      };
    case actionConstants.DELETE_REPORT_BY_CUSTOMER:
      delete newReportListByCustomer[action.payload.reportId];
      delete newReportEntries[action.payload.reportId];
      reportEntryToBeDeleted = Object.values(newReportEntries)
        .filter(newReportEntry => newReportEntry.reportId === action.payload.reportId);
      if (reportEntryToBeDeleted[0]) {
        delete newReportEntries[reportEntryToBeDeleted[0].jobId];
      }
      return {
        ...state,
        reportListByCustomer: {
          ...state.reportListByCustomer,
          reportListByCustomer: { ...newReportListByCustomer },
        },
        reportEntries: { ...newReportEntries },
      };
    case actionConstants.DELETE_REPORT_BY_JOB_ID:
      delete newReportListByJobId[action.payload.reportId];
      delete newReportEntries[action.payload.reportId];
      return {
        ...state,
        reportListByJobIds: {
          ...state.reportListByJobIds,
          reportListByJobIds: { ...newReportListByJobId },
        },
        reportEntries: { ...newReportEntries },
      };

    case actionConstants.CREATE_REPORT_ENTRIES:
      return {
        ...state,
        reportEntries: {
          ...createReportEntries(
            { ...state.jobsList },
            { ...state.reportListByCustomer.reportListByCustomer },
            { ...state.reportListByJobIds.reportListByJobIds }
          )
        },
        shouldFetchData: false,
      };

    case actionConstants.DELETE_REPORT_ENTRY:
      delete newReportEntries[action.payload.reportEntryId];
      return {
        ...state,
        reportEntries: {
          ...newReportEntries
        },
      };

    case actionConstants.RESET_REPORT_ENTRIES:
      return {
        ...state,
        reportEntries: {}
      };

    case actionConstants.ADD_PART:
      previouslyAddedParts = [...state.reportEntries[action.payload.reportEntryId].parts];
      partToBeUpdated = previouslyAddedParts
        .findIndex(part => (action.payload.partDetails.partId
          ? (part.partId === action.payload.partDetails.partId)
          : (action.payload.partDetails.partName === part.partName)));
      if (partToBeUpdated >= 0) {
        previouslyAddedParts[partToBeUpdated] = {
          ...previouslyAddedParts[partToBeUpdated],
          ...action.payload.partDetails
        };
        if (previouslyAddedParts[partToBeUpdated].isDeleted) {
          previouslyAddedParts[partToBeUpdated].isDeleted = false;
        }
      } else {
        previouslyAddedParts = [...previouslyAddedParts, { ...action.payload.partDetails }];
      }
      return {
        ...state,
        reportEntries: {
          ...state.reportEntries,
          [action.payload.reportEntryId]: {
            ...state.reportEntries[action.payload.reportEntryId],
            parts: [...previouslyAddedParts]
          }
        },
        reportEntryIdsEditedOffline: [...(
          new Set([...(state.reportEntryIdsEditedOffline || []), action.payload.reportEntryId])
        )]
      };

    case actionConstants.DELETE_PART:
      newParts = [...state.reportEntries[action.payload.reportEntryId].parts];
      if (!newParts[action.payload.partIndex]._id) {
        newParts.splice(action.payload.partIndex, 1);
      } else {
        newParts[action.payload.partIndex].isDeleted = true;
      }
      return {
        ...state,
        reportEntries: {
          ...state.reportEntries,
          [action.payload.reportEntryId]: {
            ...state.reportEntries[action.payload.reportEntryId],
            parts: [...newParts],
          }
        },
        reportEntryIdsEditedOffline: [...(
          new Set([...(state.reportEntryIdsEditedOffline || []), action.payload.reportEntryId])
        )]
      };

    case actionConstants.DELETE_LABOUR:
      newLabour = [...state.reportEntries[action.payload.reportEntryId].labours];
      newLabour[action.payload.labourIndex].isDeleted = true;
      return {
        ...state,
        reportEntries: {
          ...state.reportEntries,
          [action.payload.reportEntryId]: {
            ...state.reportEntries[action.payload.reportEntryId],
            labours: [...newLabour],
          }
        },
        reportEntryIdsEditedOffline: [...(
          new Set([...(state.reportEntryIdsEditedOffline || []), action.payload.reportEntryId])
        )]
      };

    case actionConstants.ADD_LABOUR:
      previouslyAddedLabours = [...state.reportEntries[action.payload.reportEntryId].labours];
      labourToBeUpdated = previouslyAddedLabours
        .findIndex(labour => (action.payload.labourDetails.customerId
          && (labour.customerId === action.payload.labourDetails.customerId)));
      if (labourToBeUpdated >= 0) {
        previouslyAddedLabours[labourToBeUpdated] = {
          ...previouslyAddedLabours[labourToBeUpdated],
          ...action.payload.labourDetails
        };
        if (previouslyAddedLabours[labourToBeUpdated].isDeleted) {
          previouslyAddedLabours[labourToBeUpdated].isDeleted = false;
        }
      } else {
        previouslyAddedLabours = [...previouslyAddedLabours, { ...action.payload.labourDetails }];
      }
      return {
        ...state,
        reportEntries: {
          ...state.reportEntries,
          [action.payload.reportEntryId]: {
            ...state.reportEntries[action.payload.reportEntryId],
            labours: [...previouslyAddedLabours]
          }
        },
        reportEntryIdsEditedOffline: [...(
          new Set([...(state.reportEntryIdsEditedOffline || []), action.payload.reportEntryId])
        )]
      };

    case actionConstants.FILL_JOB_DETAILS:
      newReportDetails = {
        ...(state.reportEntries[action.payload.reportEntryId] || {}),
        jobDetails: {
          ...((state.reportEntries[action.payload.reportEntryId] || {}).jobDetails || {}),
          ...JSON.parse(JSON.stringify(action.payload.jobDetails))
        }
      };
      if (action.payload.jobDetails.answers) {
        newReportDetails.answers = action.payload.jobDetails.answers;
        delete newReportDetails.jobDetails.answers;
      }
      if (action.payload.jobDetails.reportJson) {
        newReportDetails.reportJson = action.payload.jobDetails.reportJson;
        delete newReportDetails.jobDetails.reportJson;
      }
      if (action.payload.jobDetails.parsedJson) {
        newReportDetails.parsedJson = action.payload.jobDetails.parsedJson;
        delete newReportDetails.jobDetails.parsedJson;
      }
      if (action.payload.jobDetails.missedItems) {
        newReportDetails.missedItems = action.payload.jobDetails.missedItems;
        delete newReportDetails.jobDetails.missedItems;
      }
      if (action.payload.jobDetails.templateType) {
        newReportDetails.templateType = action.payload.jobDetails.templateType;
        delete newReportDetails.jobDetails.templateType;
      }
      if (action.payload.jobDetails.reportId) {
        newReportDetails.reportId = action.payload.jobDetails.reportId;
        delete newReportDetails.jobDetails.reportId;
      }
      if (action.payload.jobDetails.isFinished) {
        newReportDetails.isFinished = action.payload.jobDetails.isFinished;
        delete newReportDetails.jobDetails.isFinished;
      }
      if (action.payload.jobDetails.frequency) {
        newReportDetails.frequency = action.payload.jobDetails.frequency;
        delete newReportDetails.jobDetails.frequency;
      }
      if (action.payload.jobDetails.questionJson) {
        newReportDetails.questionJson = action.payload.jobDetails.questionJson;
        delete newReportDetails.jobDetails.questionJson;
      }
      if (action.payload.jobDetails.noteId) {
        newReportDetails.noteId = action.payload.jobDetails.noteId;
        delete newReportDetails.jobDetails.noteId;
      }
      if (action.payload.jobDetails.startDate) {
        newReportDetails.startDate = action.payload.jobDetails.startDate;
        delete newReportDetails.jobDetails.startDate;
      }
      if (action.payload.jobDetails.finishReport) {
        newReportDetails.finishReport = {
          ...newReportDetails.finishReport,
          ...action.payload.jobDetails.finishReport
        };
        delete newReportDetails.jobDetails.finishReport;
      }
      if (action.payload.jobDetails.jobDetails) {
        newReportDetails.jobDetails = {
          ...newReportDetails.jobDetails,
          ...action.payload.jobDetails.jobDetails
        };
        delete newReportDetails.jobDetails.jobDetails;
      }
      if (action.payload.jobDetails.image) {
        const alteredImageIndex = newReportDetails.images
          .findIndex(img => img._id === action.payload.jobDetails.image._id);
        if (alteredImageIndex > -1) {
          newReportDetails.images[alteredImageIndex] = {
            ...newReportDetails.images[alteredImageIndex],
            ...action.payload.jobDetails.image,
          };
        }
      }
      if (action.payload.jobDetails.reportMetadata) {
        newReportDetails.reportMetadata = action.payload.jobDetails.reportMetadata;
        delete newReportDetails.jobDetails.reportMetadata;
      }
      return {
        ...state,
        reportEntries: {
          ...state.reportEntries,
          [action.payload.reportEntryId]: { ...newReportDetails }
        },
        reportEntryIdsEditedOffline: [...(
          new Set([...(state.reportEntryIdsEditedOffline || []), action.payload.reportEntryId])
        )]
      };

    case actionConstants.ADD_CUSTOM_QUESTION_OFFLINE:
      return {
        ...state,
        reportEntries: {
          ...state.reportEntries,
          [action.payload.reportEntryId]: {
            ...state.reportEntries[action.payload.reportEntryId],
            questionJson: {
              ...state.reportEntries[action.payload.reportEntryId].questionJson,
              [action.payload.sectionName]: {
                ...state.reportEntries[action.payload.reportEntryId]
                  .questionJson[action.payload.sectionName],
                questions: [
                  ...state.reportEntries[action.payload.reportEntryId]
                    .questionJson[action.payload.sectionName].questions,
                  { ...action.payload.questionDetails }
                ],
              },
            },
          },
        },
      };

    case actionConstants.CREATE_REPORT_ENTRY_FOR_NEW_INSPECTION_REPORT:
      if (state.selectedOfflineModeOption === offlineModeOptions[0]) {
        return {
          ...state,
          reportListByCustomer: {
            ...state.reportListByCustomer,
            reportListByCustomer: {
              ...state.reportListByCustomer.reportListByCustomer,
              [state.currentNewReportIndex + 1]: {
                createdAt: moment().valueOf(),
                quickBookDetails: {},
                ...action.payload,
                _id: state.currentNewReportIndex + 1,
              },
            },
          },
          reportEntries: {
            ...state.reportEntries,
            [state.currentNewReportIndex + 1]: {
              createdAt: moment().valueOf(),
              ...action.payload,
              isIdTemporary: true,
              _id: state.currentNewReportIndex + 1,
            }
          },
          currentNewReportIndex: state.currentNewReportIndex + 1,
        };
      }
      if (state.selectedOfflineModeOption === offlineModeOptions[1]) {
        return {
          ...state,
          jobsList: {
            ...state.jobsList,
            [state.currentNewReportIndex + 1]: {
              createdAt: moment().valueOf(),
              quickBookDetails: {},
              ...action.payload,
              _id: state.currentNewReportIndex + 1,
            },
          },
          reportEntries: {
            ...state.reportEntries,
            [state.currentNewReportIndex + 1]: {
              createdAt: moment().valueOf(),
              ...action.payload,
              isIdTemporary: true,
              _id: state.currentNewReportIndex + 1,
            }
          },
          currentNewReportIndex: state.currentNewReportIndex + 1,
        };
      }
      if (state.selectedOfflineModeOption === offlineModeOptions[2]) {
        return {
          ...state,
          reportListByJobIds: {
            ...state.reportListByJobIds,
            reportListByJobIds: {
              ...state.reportListByJobIds.reportListByJobIds,
              [state.currentNewReportIndex + 1]: {
                createdAt: moment().valueOf(),
                quickBookDetails: {},
                ...action.payload,
                _id: state.currentNewReportIndex + 1,
              },
            },
          },
          reportEntries: {
            ...state.reportEntries,
            [state.currentNewReportIndex + 1]: {
              createdAt: moment().valueOf(),
              ...action.payload,
              isIdTemporary: true,
              _id: state.currentNewReportIndex + 1,
            }
          },
          currentNewReportIndex: state.currentNewReportIndex + 1,
        };
      }
      return { ...state };

    case actionConstants.SET_REPORT_ID_TO_UPLOAD:
      return {
        ...state,
        reportSelectedToUpload: action.payload.reportId
      };

    case actionConstants.SET_SIGNATURE_TYPE:
      return {
        ...state,
        uploadSignature: {
          ...state.uploadSignature,
          signatureType: action.signatureType
        }
      };

    case actionConstants.UPLOAD_SIGNATURE_REQUEST:
      return {
        ...state,
        uploadSignature: {
          error: '',
          isSuccess: false,
          isFetching: true,
          signatureType: state.uploadSignature.signatureType,
        }
      };
    case actionConstants.UPLOAD_SIGNATURE_SUCCESS:
      newReportDetails = {
        ...state.reportEntries[state.reportSelectedToUpload]
      };
      if (newReportDetails.reportType !== 'Inspection') {
        newReportDetails = {
          ...newReportDetails,
          finishReport: {
            ...newReportDetails.finishReport,
            [state.uploadSignature.signatureType]: {
              url: action.payload.Location,
              mimeType: action.payload.file.mimetype,
              fileSize: action.payload.file.size,
              fileName: action.payload.file.originalname,
              title: action.payload.file.originalname
            }
          }
        };
      } else if (newReportDetails.templateType === 'Static') {
        newReportDetails = {
          ...newReportDetails,
          answers: {
            ...newReportDetails.answers,
            finish: {
              ...newReportDetails.answers.finish,
              [state.uploadSignature.signatureType]: {
                ...newReportDetails.answers.finish[state.uploadSignature.signatureType],
                ans: action.payload.Location,
                mimeType: action.payload.file.mimetype,
                fileSize: action.payload.file.size,
                fileName: action.payload.file.originalname,
                title: action.payload.file.originalname
              }
            }
          },
        };
      } else {
        newReportDetails = {
          ...newReportDetails,
          answers: {
            ...newReportDetails.answers,
            [state.uploadSignature.signatureType]: {
              value: action.payload.Location,
              mimeType: action.payload.file.mimetype,
              fileSize: action.payload.file.size,
              fileName: action.payload.file.originalname,
              title: action.payload.file.originalname
            }
          },
          missedItems: {
            ...newReportDetails.missedItems,
            [state.uploadSignature.signatureType]: action.payload.Location,
          }
        };
      }
      return {
        ...state,
        uploadSignature: {
          error: '',
          isSuccess: true,
          isFetching: false,
          signatureType: '',
        },
        reportEntries: {
          ...state.reportEntries,
          [state.reportSelectedToUpload]: {
            ...newReportDetails,
          }
        }
      };
    case actionConstants.UPLOAD_SIGNATURE_FAILURE:
      return {
        ...state,
        uploadSignature: {
          error: action.message,
          isSuccess: false,
          isFetching: false,
          signatureType: '',
        }
      };
    case actionConstants.UPLOAD_SIGNATURE_RESET:
      return {
        ...state,
        uploadSignature: {
          ...initialState.uploadSignature,
        }
      };

    case actionConstants.FETCH_QUESTION_JSON_DEFAULT_REQUEST:
      return {
        ...state,
        questionJsonDefaultReports: {
          ...state.questionJsonDefaultReports,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.FETCH_QUESTION_JSON_DEFAULT_SUCCESS:
      return {
        ...state,
        questionJsonDefaultReports: {
          ...state.questionJsonDefaultReports,
          isFetching: false,
          pages: action.payload.pages,
          total: action.payload.total,
          page: action.payload.page,
          isSuccess: true,
          isFirstRequest: (action.payload.page < 1),
          questionJsonDefaultReports: state.questionJsonDefaultReports.page === action.payload.page
            ? { ...state.questionJsonDefaultReports.questionJsonDefaultReports }
            : {
              ...state.questionJsonDefaultReports.questionJsonDefaultReports,
              ...convertArrayToObject(action.payload.docs, 'reportCategory')
            },
        }
      };
    case actionConstants.FETCH_QUESTION_JSON_DEFAULT_FAILURE:
      return {
        ...state,
        questionJsonDefaultReports: {
          ...state.questionJsonDefaultReports,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        },
        isErrorWhileDownloadingData: true,
        downloadErrorMessage: action.message,
      };
    case actionConstants.FETCH_QUESTION_JSON_DEFAULT_RESET:
      return {
        ...state,
        questionJsonDefaultReports: {
          ...initialState.questionJsonDefaultReports,
          questionJsonDefaultReports: state.questionJsonDefaultReports.questionJsonDefaultReports,
        }
      };

    case actionConstants.CLEAR_STATE:
      return {
        ...state,
      };
    case actionConstants.RESET_OFFLINE_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
