import actionConstants from '../constants/ActionConstants';
import { customerSignUp } from '../../api';
import ActionDispatcher from '../../api/ActionDispatcher';

const signUpAction = customerSignUpBody => (
  ActionDispatcher(customerSignUp.bind(null, customerSignUpBody),
    actionConstants.SIGN_UP_SUCCESS,
    actionConstants.SIGN_UP_FAILURE,
    actionConstants.SIGN_UP_REQUEST)
);

const toggleCoachmarksVisibility = (areCoachMarksVisible, ref = {},
  currentTarget, isCoachmarkVisibleFirstTime) => dispatch => dispatch({
  type: actionConstants.TOGGLE_COACKMARKS_VISIBILITY,
  payload: {
    areCoachMarksVisible,
    ref,
    currentTarget,
    isCoachmarkVisibleFirstTime
  }
});

const runCoachMarks = runCoachMark => dispatch => dispatch({
  type: actionConstants.RUN_COACHMARKS,
  payload: {
    runCoachMark,
  }
});

export default signUpAction;
export { toggleCoachmarksVisibility, runCoachMarks };
