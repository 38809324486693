export default {
  login: '/login',
  termsAndConditions: '/termsAndCondtions',
  privacyPolicy: '/privacyPolicy',
  default: '/',
  signUp: '/customerSignUp',
  dashboard: '/dashboard',
  customer: '/customer',
  transactionDetails: '/inventory/transactionDetails',
  uploadDevices: '/customer/uploadDevices',
  deviceDetails: '/customer/device',
  home: '/customer/home',
  customerInfo: '/customer/customerInfo',
  ownerInfo: '/customer/ownerInfo',
  buildingInfo: '/customer/buildingInfo',
  systemInfo: '/customer/buildingInfo/systemInfo',
  images: '/customer/buildingInfo/images',
  recurringInspections: '/customer/recurringInspections',
  deficiency: '/customer/deficiencies',
  customerPortal: '/customer/customerPortal',
  addJob: '/customer/addJob',
  autoEmail: '/customer/autoEmail',
  team: '/team',
  timeCard: '/team/timeCard',
  editTeam: '/team/edit',
  bugTickets: '/bugTickets',
  addBugTicket: '/bugTickets/addBugTicket',
  addTeam: '/team/add',
  timeCardUserInfo: '/team/user',
  gps: '/team/gps',
  resetPassword: '/resetPassword',
  jobLink: '/jobLink',
  reports: '/reports',
  new: '/reports/new',
  complete: '/reports/complete',
  categoryCompleted: '/category/completeReports',
  recent: '/reports/recent',
  incomplete: '/reports/incomplete',
  task: '/task',
  dueThisMonth: '/task/dueThisMonth',
  missedInspections: '/task/missedInspections',
  myJobs: '/task/myJobs',
  serviceRequests: '/serviceRequests',
  inventory: '/inventory',
  tools: '/inventory/tools',
  part: '/inventory/part',
  partInfo: '/inventory/partInfo',
  assignParts: '/inventory/assignParts',
  inventoryOnVehicles: '/inventory/inventoryOnVehicles',
  vehiclesDetails: '/inventory/vehiclesDetails',
  transactions: '/inventory/transactions',
  suppliers: '/inventory/suppliers',
  supplierInfo: '/inventory/supplierInfo',
  manufacturers: '/inventory/manufacturers',
  deficiencies: '/Deficiencies',
  settings: '/settings',
  subscriptions: '/settings/subscriptions',
  qrCode: '/settings/qrCode',
  videos: '/settings/videos',
  companyLogo: '/settings/companyLogo',
  manageReport: '/settings/manageReport',
  changePassword: '/changePassword',
  forgetPassword: '/forgetPassword',
  tokenExpired: '/tokenExpired',
  reportDetail: '/report/detail',
  reportBuildingInfo: '/reportBuildingInfo',
  reportImage: '/reportImage',
  reportSytemInfo: '/reportSytemInfo',
  reportClientDetail: '/reportClientDetail',
  teamGpsEmployee: '/team/gps',
  chat: '/chat',
  autoRouting: 'jobLink/autoRouting',
  editProfile: '/editProfile',
  addTool: 'addtool',
  assignTool: 'assigntool',
  toolList: '/inventory/tools',
  editTool: 'edittool',
  editSupplier: 'EditSupplier',
  teamGPSLocations: '/team/locations',
  offline: '/offline',
  archivedTools: 'archivedTools',
  archivedVehicle: 'archivedVehicle',
  archivedSupplier: 'archivedSupplier',
  archivedParts: 'archivedParts',
  joblinkMonth: 'jobLink/monthView',
  customReportDetails: '/customReportDetails',
};
