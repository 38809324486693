export const tableHeader = [
  {
    title: 'Frequency',
    isSortable: false
  },
  {
    title: 'Completion Date',
    isSortable: false
  },
  {
    title: 'Name',
    isSortable: false
  },
  {
    title: ' ',
    isSortable: false
  }
];

export const tableHeaderStartDate = [
  {
    title: 'Frequency',
    isSortable: false
  },
  {
    title: 'Date Started',
    isSortable: false
  },
  {
    title: 'Name',
    isSortable: false
  },
  {
    title: ' ',
    isSortable: false
  }
];

export const tableHeaderServicesDue = [
  {
    title: 'Location',
    isSortable: false
  },
  {
    title: 'Floor',
    isSortable: false,
    width: '14%',
  },
  {
    title: 'Manufact.',
    isSortable: false
  },
  {
    title: 'Serial Number',
    isSortable: false,
    width: '15%',
  },
  {
    title: 'Size',
    isSortable: false,
    width: '10%',
  },
  {
    title: 'Due For',
    isSortable: false,
    width: '19%',
  }
];
