import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  showTeamSheet: [],
  totalCount: null,
  isSuccess: false,
  totalPages: null,
  currentPage: null,
  isSuccessForTimesheetByDate: false,
};

function showTeamSheet(state = initialState, action) {
  switch (action.type) {
    case actionConstants.SHOW_SHEET_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.SHOW_SHEET_SUCCESS:
      return {
        ...state,
        showTeamSheet: action.payload.docs,
        totalCount: action.payload.total,
        isfetching: false,
        totalPages: action.payload.pages,
        currentPage: action.payload.page,
        isSuccess: true
      };
    case actionConstants.SHOW_SHEET_FAILURE:
      return {
        ...state,
        error: action.error,
        isfetching: false,
        isSuccess: false
      };
    case actionConstants.TIME_SHEET_BY_DATE_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccessForTimesheetByDate: false,
        error: ''
      };
    case actionConstants.TIME_SHEET_BY_DATE_SUCCESS:
      return {
        ...state,
        showTeamSheet: action.payload.docs,
        totalCount: action.payload.total,
        isfetching: false,
        totalPages: action.payload.pages,
        currentPage: action.payload.page,
        isSuccessForTimesheetByDate: true
      };
    case actionConstants.TIME_SHEET_BY_DATE_FAILURE:
      return {
        ...state,
        error: action.error,
        isfetching: false,
        isSuccessForTimesheetByDate: false
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isfetching: false,
        isSuccessForTimesheetByDate: false,
      };
    case actionConstants.UPDATE_TIME_SHEET_SUCCESS:
      return {
        ...state,
        isfetching: false,
        showTeamSheet: [...action.payload, ...state.showTeamSheet],
        isSuccess: false,
      };
    default:
      return state;
  }
}

export default showTeamSheet;
