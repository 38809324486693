import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button';

const PreviewImageModalBody = (props) => {
  const [width, setWidth] = useState(600);
  const {
    imageUrl
  } = props;
  return (
    <div>
      <div
        style={{ padding: '10px' }}
        className='modal-body images'>
        <div className='fixedpreview'>
          <img style={{ width: `${+width}px`, margin: 'auto', display: 'flex' }} src={imageUrl} />
        </div>
        <div className='row'>
          <div className='col-md-12 imagepreviewwidth'>
            <button onClick={() => setWidth(width - 70)} className="btn-zoom out sprite-icon-before1 buttonbottommargin"></button>
            <button onClick={() => setWidth(width + 70)} className="btn-zoom in sprite-icon-before1 buttonbottommargin"></button>
            <Button
              onBtnClick={() => setWidth(600)}
              text='RESET'
              color='#B72E28'
              className='image-reset-button'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PreviewImageModalBody.propTypes = {
  onClose: PropTypes.func,
  imageUrl: PropTypes.string,
};

export default PreviewImageModalBody;
