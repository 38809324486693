import actionConstants from '../constants/ActionConstants';

const deleteInspection = {
  isSuccessDelete: false,
  deleteErrorMsg: '',
  deleteSuccessMsg: '',
  isFetching: false,
};

const fetchedInspectionDetails = {
  inspectiondDetail: {},
  isFetching: false,
  isSuccessOfFetchDetails: false,
};

const initialState = {
  errorMsg: '',
  isFetching: false,
  successMsg: '',
  addRecurringInspectionData: [],
  isSuccess: false,
  deleteInspection,
  fetchedInspectionDetails,
};

export default function addRecurringInspection(state = initialState, action) {
  switch (action.type) {
    case actionConstants.ADD_RECURRING_INSPECTION_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isFetching: true
      };
    case actionConstants.ADD_RECURRING_INSPECTION_SUCCESS:
      return {
        ...state,
        addRecurringInspectionData: action.payload,
        isFetching: false,
        isSuccess: true,
        successMsg: action.message,
        errorMsg: '',
      };
    case actionConstants.ADD_RECURRING_INSPECTION_FAILURE:
      return {
        ...state,
        errorMsg: action.message,
        isSuccess: false,
        isFetching: false,
        successMsg: '',
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isSuccess: false,
        isFetching: false,
        successMsg: '',
        errorMsg: '',
        deleteInspection: {
          isFetching: false,
          isSuccessDelete: false,
          deleteErrorMsg: '',
          deleteSuccessMsg: ''
        },
        fetchedInspectionDetails: {
          ...state.fetchedInspectionDetails,
          isSuccessOfFetchDetails: false,
        }
      };
    case actionConstants.DELETE_RECURRING_INSPECTION_REQUEST:
      return {
        ...state,
        deleteInspection: {
          isFetching: true,
          isSuccessDelete: false,
        }
      };
    case actionConstants.DELETE_RECURRING_INSPECTION_SUCCESS:
      return {
        ...state,
        deleteInspection: {
          isFetching: false,
          isSuccessDelete: true,
          deleteSuccessMsg: action.message,
        }
      };
    case actionConstants.DELETE_RECURRING_INSPECTION_FAILURE:
      return {
        ...state,
        deleteInspection: {
          isFetching: false,
          isSuccessDelete: false,
          deleteErrorMsg: action.message,
        }
      };
    case actionConstants.FETCH_RECURRING_INSPECTION_DETAIL_REQUEST:
      return {
        ...state,
        fetchedInspectionDetails: {
          isFetching: true,
          isSuccessOfFetchDetails: false,
          inspectiondDetail: {},
        }
      };
    case actionConstants.FETCH_RECURRING_INSPECTION_DETAIL_SUCCESS:
      return {
        ...state,
        fetchedInspectionDetails: {
          isFetching: false,
          isSuccessOfFetchDetails: true,
          inspectiondDetail: action.payload,
        }
      };
    case actionConstants.FETCH_RECURRING_INSPECTION_DETAIL_FAILURE:
      return {
        ...state,
        fetchedInspectionDetails: {
          isFetching: false,
          inspectiondDetail: {},
        }
      };
    case actionConstants.CLEAR_RECURRING_INSPECTION_DETAILS:
      return {
        ...state,
        fetchedInspectionDetails: {
          ...state.fetchedInspectionDetails,
          isFetching: false,
          isSuccessOfFetchDetails: false,
        }
      };
    default:
      return state;
  }
}
