import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  isSuccess: false,
};

function updateTool(state = initialState, action) {
  switch (action.type) {
    case actionConstants.UPDATE_TOOL_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.UPDATE_TOOL_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccess: true,
        success: action.message,
      };
    case actionConstants.UPDATE_TOOL_FAILURE:
      return {
        ...state,
        isfetching: false,
        error: action.message,
        isSuccess: false,
      };
    case actionConstants.UPDATE_TOOL_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default updateTool;
