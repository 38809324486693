import Socket from './permissionsSubscriptionsSocket';
import EVENT from './permissionsSubscriptionsEvents';

function employeePermissionsUpdate(handlePermissionsUpdate) {
  const socket = new Socket().getInstance();
  socket.on(EVENT.EMPLOYEE_PERMISSIONS_UPDATE, handlePermissionsUpdate);
}

function init() {
  new Socket().getInstance();
  // socket.connect();
  // socket.emit(EVENT.CONNECT);
}

function onConnected() {
  const socket = new Socket().getInstance();
  socket.on(EVENT.CONNECTED, () => {
    socket.emit(EVENT.CONNECT);
  });
}

function notificationCount(onNotiFication) {
  const socket = new Socket().getInstance();
  socket.on(EVENT.COUNT, onNotiFication);
}

function subscriptionsUpdate(handleSubscriptionsUpdate) {
  const socket = new Socket().getInstance();
  socket.on(EVENT.SUBSCRIPTIONS_UPDATE, handleSubscriptionsUpdate);
}

function destroy() {
  const { getInstance, removeInstance } = new Socket();
  const socket = getInstance();
  socket.off();
  socket.disconnect();
  removeInstance();
}

export {
  init,
  employeePermissionsUpdate,
  subscriptionsUpdate,
  destroy,
  notificationCount,
  onConnected,
};
