import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import './DateRangePicker.css';

const MultipleDatePicker = (props) => {
  const {
    handleOnChange,
    customStyle,
    setCalenderVisibility,
    twoStepChange,
    className
  } = props;

  const node = React.createRef();

  const handleClick = (e) => {
    if (node && node.current && node.current.contains(e.target)) {
      return;
    }
    setCalenderVisibility(false);
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClick
    );
    return function removeMouseDownListener() {
      document.removeEventListener(
        'mousedown',
        handleClick
      );
    };
  }, []);

  return (
    <div className={`daterange ${className}`} ref={node} style={customStyle}>
        <DateRange
          onChange={handleOnChange}
          calendars={1}
          twoStepChange={twoStepChange}
        />
    </div>
  );
};

MultipleDatePicker.propTypes = {
  handleOnChange: PropTypes.func,
  customStyle: PropTypes.object,
  setCalenderVisibility: PropTypes.func,
  twoStepChange: PropTypes.bool,
  className: PropTypes.string,
};

export default MultipleDatePicker;
