import actionConstants from '../constants/ActionConstants';

const initialState = {
  transactionList: [],
  transactionDetail: {},
  listSuccess: '',
  detailsSuccess: '',
  deleteSuccess: '',
  listError: '',
  pages: undefined,
  detailError: '',
  deleteError: '',
  isfetching: false
};

export default function getTransactionList(state = initialState, action) {
  switch (action.type) {
    case actionConstants.GET_TRANSACTION_LIST_REQUEST:
      return {
        ...state,
        isfetching: true
      };
    case actionConstants.GET_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        transactionList: action.payload.page === 1 ? action.payload.docs
          : [...state.transactionList, ...action.payload.docs],
        isfetching: false,
        listSuccess: action.payload.message,
        pages: action.payload.pages,
      };
    case actionConstants.GET_TRANSACTION_LIST_FAILURE:
      return {
        ...state,
        listError: action.message,
        isfetching: false,
      };
    case actionConstants.GET_TRANSACTION_DETAIL_REQUEST:
      return {
        ...state,
        isfetching: true
      };
    case actionConstants.GET_TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        isfetching: false,
        transactionDetail: action.payload
      };
    case actionConstants.GET_TRANSACTION_DETAIL_FAILURE:
      return {
        ...state,
        isfetching: false,
        detailError: action.message
      };
    case actionConstants.DELETE_TRANSACTION_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        isfetching: false,
        deleteSuccess: action.message
      };
    case actionConstants.DELETE_TRANSACTION_FAILURE:
      return {
        ...state,
        isfetching: false,
        deleteError: action.message
      };
    case actionConstants.CLEAR_TRANSACTIONS:
      return {
        ...state,
        error: '',
        success: '',
        isfetching: false,
        transactionList: [],
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
