import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  showTeamListData: [],
  totalCount: null,
  totalPages: null,
  currentPage: null,
};

function showTeamList(state = initialState, action) {
  switch (action.type) {
    case actionConstants.SHOW_TEAM_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.SHOW_INITIAL_TEAM_REQUEST:
      return {
        ...state,
        isfetching: true,
        showTeamListData: [],
      };
    case actionConstants.SHOW_TEAM_SUCCESS:
      return {
        ...state,
        showTeamListData: action.payload.page === 1
          ? [...action.payload.docs]
          : [...state.showTeamListData, ...action.payload.docs],
        totalCount: action.payload.total,
        totalPages: action.payload.pages,
        currentPage: action.payload.page,
        isfetching: false,
      };
    case actionConstants.SHOW_TEAM_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.SEARCH_EMPLOYEE_REQUEST:
      return {
        ...state,
        isfetching: true,
        totalCount: 0,
      };
    case actionConstants.SEARCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        showTeamListData: action.payload.page === 1
          ? [...action.payload.docs]
          : [...state.showTeamListData, ...action.payload.docs],
        totalPages: action.payload.pages,
        currentPage: action.payload.page,
        isfetching: false,
      };
    case actionConstants.SEARCH_EMPLOYEE_FAILURE:
      return {
        ...state,
        currentPage: null,
        totalPages: null,
        error: action.error,
        isfetching: false,
      };
    case actionConstants.SORT_INITIAL_TEAM_REQUEST:
      return {
        ...state,
        isfetching: true,
        currentPage: null,
        totalPages: null,
        error: '',
        showTeamListData: [],
      };
    case actionConstants.SORT_TEAM_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
        currentPage: null,
        totalPages: null,
        error: '',
        totalCount: 0,
      };
    case actionConstants.SORT_TEAM_LIST_SUCCESS:
      return {
        ...state,
        showTeamListData: [...state.showTeamListData, ...action.payload.docs],
        totalCount: action.payload.total,
        totalPages: action.payload.pages,
        isfetching: false,
      };
    case actionConstants.SORT_TEAM_LIST_FAILURE:
      return {
        ...state,
        totalPages: null,
        error: action.error,
        isfetching: false,
      };
    default:
      return state;
  }
}

export default showTeamList;
