import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../../components/ReusableTable';

const header = [
  {
    title: 'Employee',
    isSortable: false,
  },
  {
    title: 'Modified Date',
    isSortable: false,
  },
];

export const ProfileRow = ({ row }) => (
  <tr>
    <td>
      {row.name}
    </td>
    <td>
      {moment(row.updatedAt).format('LLL')}
    </td>
  </tr>
);

const ProfileModalBody = ({ profileDetails }) => (
  <Table
    headerData={header}
    RowToRender={ProfileRow}
    bodyData={profileDetails}
    hasMoreItems={false}
    isLoaderVisible={false}
    isEndMessageVisible={false}
  />
);

ProfileModalBody.propTypes = {
  profileDetails: PropTypes.object,
};

ProfileRow.propTypes = {
  row: PropTypes.object,
};

export default ProfileModalBody;
