import actionConstants from '../constants/ActionConstants';

const initialState = {
  docs: [
  ],
  total: 0,
  limit: 10,
  page: 1,
  pages: 1,
  isFetching: false,
  isSuccess: false,
};

export default function getToolList(state = initialState, action) {
  switch (action.type) {
    case actionConstants.GET_TOOLS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
      };
    case actionConstants.GET_TOOLS_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        docs: action.payload.page === 1
          ? [...action.payload.docs]
          : [...state.docs, ...action.payload.docs],
        isFetching: false,
        isSuccess: true,
      };
    case actionConstants.GET_TOOLS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
      };
    case actionConstants.GET_TOOLS_LIST_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
