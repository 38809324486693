import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '../../components/Button';
import DropDown from '../../components/DropDown';
import JobList from '../../components/JobList';
import SearchBar from '../../components/SearchBar';
import endpoints from '../../routes';
import { getJobDetailsAction } from '../../redux/actions/jobLinkActions';
import ToolTip from '../../components/ToolTip';
import { toolTips } from '../../utils/toolTips';

const viewsList = [
  {
    name: 'Day',
    id: 0,
  },
  // {
  //   name: 'Week',
  //   id: 5,
  // }, (Week View dropdown item)
  {
    name: 'Month',
    id: 2,
  }
];

export function JobLinkHeaderComponent(props) {
  const {
    viewType,
    onChangeViewType,
    schedulerData,
    newEvent,
    taskDndSource,
    eventCategory,
    setEventCategory,
    handleClickJobListItem,
    search,
    setSearch,
    handleOnSearch,
    intl
  } = props;

  return (
    <div style={{ marginBottom: '24px' }}>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center top-search-feild">
        <h1
          className='hh'
          data-tip={intl.formatMessage({ id: 'Where you perform scheduling and dispatching' })}
          data-for='joblink-header-tooltip'
        >
          <FormattedMessage
            id='JobLink'
            defaultMessage='JobLink'
          />
        </h1>
        <ToolTip
          id='joblink-header-tooltip'
          place='right'
        />
        <div style={{ display: 'flex' }}>
          <Button
            color='#B71B1B'
            text='Auto Routing'
            onBtnClick={() => props.history.push(`/${endpoints.autoRouting}`)}
            className='mr-rt-20'
            dataTip='Routes your jobs efficiently'
            dataFor='auto-routing-tooltip'
          />
          {viewType.id !== 2 && <SearchBar
            searchValue={search}
            isCrossVisible={search}
            inputClassName='job-link-search'
            placeHolder='Search Team Member'
            onTextChange={(e) => {
              if (e === '' || (e.target && e.target.value === '')) {
                setSearch('');
                handleOnSearch(true);
                return;
              }
              setSearch(e.target.value);
            }}
            onSearchPress={() => handleOnSearch(true)}
            style={{ display: 'contents' }}
          />}
        </div>
      </div>
      <div className="filter-btn-group d-flex justify-content-between">
        <div className="d-flex calender-section">
          <DropDown
            value={viewType.name}
            name='viewtype'
            dropDownList={viewsList}
            handleOnChange={onChangeViewType}
            width='176px'
            className=''
            dataTip={toolTips.joblink.view}
            dataFor={toolTips.joblink.view}
            place={viewType.id === 2 ? 'left' : 'right'}
          />
          {viewType.id === 2
            && <DropDown
              value={eventCategory}
              name='divisions'
              dropDownList={['All', 'Work Order', 'Quote', 'Inspection']}
              handleOnChange={(name, value) => setEventCategory(value)}
              width='176px'
              className='dropdown custom-drop-option'
              dataTip={toolTips.joblink.type}
              dataFor={toolTips.joblink.type}
              place='right'
            />
          }
        </div>
        <div className='filter-row' style={{ alignSelf: 'center' }}>
          {viewType.id !== 2 && <JobList
            schedulerData={schedulerData}
            newEvent={newEvent}
            taskDndSource={taskDndSource}
            handleClickJobListItem={(event) => {
              props.getJobDetailsAction(event.id);
              handleClickJobListItem(event);
            }}
          />}
        </div>
      </div>
    </div>
  );
}

JobLinkHeaderComponent.propTypes = {
  onChangeViewType: PropTypes.func,
  viewType: PropTypes.object,
  schedulerData: PropTypes.object,
  newEvent: PropTypes.func,
  taskDndSource: PropTypes.object,
  eventCategory: PropTypes.string,
  setEventCategory: PropTypes.func,
  setResourceReportCategory: PropTypes.func,
  history: PropTypes.object,
  handleClickJobListItem: PropTypes.func,
  getJobDetailsAction: PropTypes.func,
  setSearch: PropTypes.func,
  search: PropTypes.string,
  intl: intlShape.isRequired,
  handleOnSearch: PropTypes.func,
};

export default connect(
  null, {
    getJobDetailsAction,
  })(withRouter(injectIntl(JobLinkHeaderComponent)));
