export const reportConstants = {
  jobDetails: 'Job Details',
  images: 'Images',
  notes: 'Notes',
  parts: 'Parts',
  labours: 'Labor',
  finishWork: 'Finish Work',
  imageDescription: 'default desc',
  all: 'All',
  workOrder: 'Work Order',
  quote: 'Quote',
  inspection: 'Inspection',
  reportCategory: 'Report Category',
  completed: 'Completed',
  reportNewUrl: '/reports/new',
  completeReports: 'Completed Reports',
  incompleteReports: 'Incomplete Reports',
  created: 'Created',
  inprogress: 'Inprogress',
  approvalRequired: 'Approval Required',
  rejected: 'Rejected',
  deficiency: 'Deficiency',
  recentReportUrl: '/reports/recent',
  incompleteReportUrl: '/reports/incomplete',
  recentReports: 'Recent Reports',
  missedItems: 'Missed Items/Finish',
  completeReportsUrl: '/completedReports',
  reportDetailURL: '/reportDetails',
  reportDetUrl: '/reportDetail',
  completedReports: '/reports/complete',
  isHidden: 'isHidden',
  other: 'Other',
};

export const REPORT_FALSY = [
  'fail',
  'no',
  'failed inspection'
];

export const DEVICES_FIELDS = [
  'location',
  'make',
  'model',
  'floor'
];

export const FREQUENCY = [
  {
    key: 1,
    display: 'WEEKLY',
    value: 'Weekly',
    noOfPriceFields: 1,
  },
  {
    key: 2,
    display: 'BI_WEEKLY',
    value: 'Bi Weekly',
    noOfPriceFields: 1,
  },
  {
    key: 3,
    display: 'MONTHLY',
    value: 'Monthly',
    noOfPriceFields: 1,
  },
  {
    key: 4,
    display: 'BI_MONTHLY',
    value: 'Bi Monthly',
    noOfPriceFields: 6,
  },
  {
    key: 5,
    display: 'QUARTELY',
    value: 'Quarterly',
    noOfPriceFields: 4,
  },
  {
    key: 6,
    display: 'SEMI_ANNUAL',
    value: 'Semi Annual',
    noOfPriceFields: 2,
  },
  {
    key: 7,
    display: 'ANNUAL',
    value: 'Annual',
    noOfPriceFields: 1,
  },
  {
    key: 8,
    display: 'BI_ANNUAL',
    value: 'Bi Annual',
    noOfPriceFields: 1,
  },
  {
    key: 9,
    display: 'THREE_YEAR',
    value: '3 Year',
    noOfPriceFields: 1,
  },
  {
    key: 11,
    display: 'FIVE_YEAR',
    value: '5 Year',
    noOfPriceFields: 1,
  },
];
