import call from './apiRequest';
import apiEndpoints from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call to get people list
 */
export const getPeopleListApi = () => call({
  method: 'get',
  endpoint: apiEndpoints.chatList,
  baseUrlType: 'chat',
  authKey: getToken(),
});

/**
 * Api call to get messages by chat id
 * @param {object} param0
 * @param {number} skip
 * @param {number} limit
 */
export const getMessagesbyChatIdApi = ({ chatId }, skip, limit) => call({
  method: 'get',
  query: {
    skip,
    limit,
  },
  endpoint: `${apiEndpoints.chatMessages}?channelId=${chatId}`,
  baseUrlType: 'chat',
  authKey: getToken(),
});

/**
 * Api call to get all people to chat
 * @param {number} page
 * @param {number} limit
 */
export const getAllPeopleToChatApi = (page, limit) => call({
  method: 'get',
  query: {
    page,
    limit
  },
  endpoint: `${apiEndpoints.allPeople}`,
  authKey: getToken()
});

/**
 * Api call to create individual's chat
 * @param {object} param0
 */
export const createIndividualChatApi = ({ personId }) => call({
  method: 'post',
  endpoint: `${apiEndpoints.createIndividualChat}`,
  baseUrlType: 'chat',
  authKey: getToken(),
  payload: { member2Id: personId }
});

/**
 * Api call to create group chat
 * @param {object} param0
 */
export const createGroupChatApi = ({ groupName, members = [] }) => call({
  method: 'post',
  endpoint: `${apiEndpoints.createGroupChat}`,
  baseUrlType: 'chat',
  authKey: getToken(),
  payload: { groupName, members }
});

/**
 * Api call to get group details
 * @param {object} param0
 */
export const getGroupDetailsApi = ({ chatId }) => call({
  method: 'get',
  endpoint: `${apiEndpoints.groupDetails}?channelId=${chatId}`,
  baseUrlType: 'chat',
  authKey: getToken(),
});

/**
 * Api call to update group chat
 * @param {object} param0
 */
export const updateGroupChatApi = ({
  chatId,
  newMembers,
  removedMembers,
  groupName
}) => call({
  method: 'put',
  endpoint: `${apiEndpoints.updateGroupDetails}`,
  baseUrlType: 'chat',
  authKey: getToken(),
  payload: {
    channelId: chatId,
    newMembers,
    removedMembers,
    groupName
  }
});
