import call from './apiRequest';
import apiEndpoints from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call to get supplier details
 * @param {string} supplierId
 */
const getSupplierInfo = supplierId => call({
  method: 'get',
  endpoint: `${apiEndpoints.supplier}/${supplierId}`,
  authKey: getToken(),
});

export default getSupplierInfo;
