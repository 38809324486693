import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getAuthUser } from '../../redux/actions/auth';
import { setActiveChatId } from '../../redux/actions/chat';

export default function (ComposedComponent) {
  class LoginRequired extends React.Component {
    unsubscribe = null;

    state = {
      shouldShowChatIcon: window.location.pathname !== '/chat',
    };

    componentWillMount() {
      this.authenitcateUser();
      this.unsubscribe = this.props.history.listen((location) => {
        if (location.pathname === '/chat') {
          this.setState({
            shouldShowChatIcon: false,
          });
        } else {
          this.setState({
            shouldShowChatIcon: true,
          });
          this.props.setActiveChatId({ chatId: '' });
        }
      });
    }

    componentDidUpdate() {
      this.authenitcateUser();
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    /**
     * Checks if auth token is available and valid
     */
    authenitcateUser() {
      const { auth } = this.props;
      const { login } = auth;
      if (isEmpty(login.user) && !login.isFetching) {
        this.props.getAuthUser({});
      }
    }

    render() {
      const { auth } = this.props;
      const { login } = auth;
      if (!login.isFetching && !isEmpty(login.user)) {
        return (
          <React.Fragment>
            <ComposedComponent {...this.props} />
          </React.Fragment>
        );
      }
      return (<Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />);
    }
  }

  LoginRequired.propTypes = {
    auth: PropTypes.object.isRequired, // contains user login data
    getAuthUser: PropTypes.func.isRequired, // returns auth token of user
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    setActiveChatId: PropTypes.func.isRequired, // handles setting active chat id
  };

  function mapStateToProps({ auth, chat }) {
    return { auth, chat };
  }

  return connect(
    mapStateToProps,
    { getAuthUser, setActiveChatId }
  )(withRouter(LoginRequired));
}
