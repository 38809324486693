import actionConstants from '../constants/ActionConstants';

const initialState = {
  isFetching: false,
  isSuccess: false,
  success: '',
  error: '',
  deviceToken: {},
};

export default function registerDeviceToken(state = initialState, action) {
  switch (action.type) {
    case actionConstants.REGISTER_DEVICE_TOKEN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
        deviceToken: {},
        error: '',
        success: '',
      };
    case actionConstants.REGISTER_DEVICE_TOKEN_SUCCESS:
      return {
        ...state,
        deviceToken: action.payload,
        isFetching: false,
        success: action.message,
        isSuccess: true,
      };
    case actionConstants.REGISTER_DEVICE_TOKEN_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
