import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders each row of table in service due table.
 * @param {*} props
 */
const ServiceDueTableRow = (props) => {
  const {
    location,
    floor,
    manufacturer,
    serialNumber,
    size,
    dueFor,
  } = props.row;

  return (
    <tr>
      <td>{location}</td>
      <td>{floor}</td>
      <td>{manufacturer}</td>
      <td>{serialNumber}</td>
      <td>{size}</td>
      <td>{dueFor}</td>
    </tr>
  );
};

ServiceDueTableRow.propTypes = {
  row: PropTypes.object,
};

export default ServiceDueTableRow;
