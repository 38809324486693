import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import MissedInspectionLayout from '../../layouts/MissedInspectionLayout/MissedInspectionLayout';
import getJobList, { completeJobManually, printJobList, printJobListFinished } from '../../redux/actions/getJobList';
import formReportDetails from '../../redux/actions/addReportCat';
import { endMessageForTaskTable, endMessageTaskPagination } from '../../utils/Constants';
import { reportCat } from '../../redux/actions/reportCategory';
import './MissedInspectionView.css';
import endpoints from '../../routes';
import { clearInspectionState } from '../../redux/actions/updateAnswer';
import { displayToast } from '../../_helpers/commonFunctions';
import TaskModalBody from '../../layouts/CustomerHomeLayout/taskModalBody';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/Loader';

export class MissedInspectionView extends React.Component {
  constructor(props) {
    super(props);
    this.page = 1;
    this.LIMIT = 50;
  }

  state = {
    searchText: '',
    filter: undefined,
    isCrossVisible: false,
    reportType: '',
    status: [],
    reportCategory: [],
    startDate: '',
    endDate: '',
    jobDateSort: -1,
    isClassVisible: {},
    businessNameSort: undefined,
    endMessage: endMessageTaskPagination,
    selectedTask: [],
    isTaskModal: false,
    jobsDoneManually: [],
  };

  reloadList = () => {
    this.props.getJobList(this.page, this.LIMIT,
      this.state.searchText, this.state.status,
      this.state.reportCategory, this.state.jobDateSort,
      this.state.businessNameSort, this.state.startDate, this.state.endDate, this.state.reportType);
  }
  
  printPage = () => {
     this.props.printJobList(this.page, this.LIMIT,
      this.state.searchText, this.state.status,
      this.state.reportCategory, this.state.jobDateSort,
      this.state.businessNameSort, this.state.startDate, this.state.endDate, this.state.reportType);
  }

  setReportType = (reportType) => {
    this.page = 1;
    this.setState({
      reportType
    }, () => this.reloadList());
  };

  applyFilter = (filter) => {
    this.page = 1;
    const status = this.appliedFilters(filter.statusFilters);
    const reportCategory = this.appliedFilters(filter.reportFilters);
    this.setState({
      status,
      reportCategory
    }, () => this.reloadList());
  };

  // Handle Mobile Table View
  setClassVisibility = (jobId) => {
    const isClassVisible = {};
    if (Object.keys(this.state.isClassVisible[0] === jobId)) {
      isClassVisible[`${jobId}`] = !this.state.isClassVisible[`${jobId}`];
    } else {
      isClassVisible[`${jobId}`] = true;
    }
    this.setState({ isClassVisible });
  }

  setDatePeriod = (startDate, endDate) => {
    this.page = 1;
    let yesterDay = moment().subtract(1, 'day').endOf('day').valueOf();
    if (endDate > yesterDay){
            endDate = yesterDay;
    }
    if (endDate === null) {
      this.setState({
        startDate: null,
        endDate: moment(new Date().setHours(23, 59, 59, 999)).valueOf() - 86400000
      }, () => this.reloadList());
    } else {
      this.setState({
        startDate: moment(startDate).valueOf(),
        endDate: moment(endDate).valueOf()
      }, () => this.reloadList());
    }
  };

  appliedFilters = (array) => {
    const newArray = Object.keys(array).filter(item => array[item]);
    return newArray;
  };

  setFilters = (filters) => {
    this.setState({
      filter: filters
    }, () => this.applyFilter(this.state.filter));
  }

  componentDidUpdate(prevProps) {
    const { jobsCompletedManually } = this.props;
    if (jobsCompletedManually.success
      && jobsCompletedManually.success !== prevProps.jobsCompletedManually.success) {
      displayToast(jobsCompletedManually.success, 'success');
      let _jobsDoneManually = [...this.state.jobsDoneManually];
      _jobsDoneManually.push(jobsCompletedManually.jobDetail.jobId);
      _jobsDoneManually = Array.from(new Set(_jobsDoneManually));
      this.setState({ jobsDoneManually: [..._jobsDoneManually] });
    } else if (jobsCompletedManually.error
      && jobsCompletedManually.error !== prevProps.jobsCompletedManually.error) {
      displayToast(jobsCompletedManually.error, 'error');
    }

    this.handleResponseForJobListDownload(prevProps);
  }

  componentDidMount() {
    this.page = 1;
    const startDate = moment().startOf('month').startOf('day').valueOf();
    let yesterDay = moment().subtract(1, 'day').endOf('day').valueOf();
    this.setState({
      endDate: yesterDay,
      startDate

    }, () => this.props.getJobList(this.page, this.LIMIT, null, null, null,
      this.state.jobDateSort,
      null,this.state.startDate, this.state.endDate, null));
    this.props.reportCat();
  }

  setSearchText = (text) => {
    this.setState({ searchText: text, isCrossVisible: true });
  };

  handleOnSearch = (text) => {
    this.page = 1;
    if (text === '') {
      this.setState({ searchText: '', isCrossVisible: false },
        () => this.reloadList());
    }
    this.reloadList();
  };

  loadMore = () => {
    this.page += 1;
    this.reloadList();
  }

  handleStartJob = (reportData) => {
    let formData = {};
    formData = {
      typeValue: reportData.type,
      catValue: reportData.reportCategory,
      customerValue: reportData.createdFor._id,
      frequency: reportData.frequency,
      price: reportData.price,
    };
    this.props.clearInspectionState();
    this.props.formReportDetails(formData);
    displayToast('Report started successfully', 'success');
    this.props.history.push(endpoints.reportClientDetail, { jobId: reportData.jobId });
  };

  isEndMessageVisible = () => ((this.page > 1
    || (this.props.jobList && this.props.jobList.length) === 0))

  sortData = (index) => {
    this.page = 1;
    const { businessNameSort, jobDateSort } = this.state;
    if (index === 1) {
      this.setState({
        businessNameSort: businessNameSort === undefined ? 1 : businessNameSort * -1
      }, () => this.reloadList()
      );
    } else {
      this.setState({
        jobDateSort: jobDateSort === undefined ? 1 : jobDateSort * -1
      }, () => {
        this.reloadList();
      });
    }
  }

  showTaskModdal = (tasks) => {
    this.setState({
      isTaskModal: true,
      selectedTask: tasks
    });
  }

  handleResponseForJobListDownload = (prevResponse) => {
  const {
      success, error, printData
    } = this.props;

    if (success && printData && printData !== prevResponse.printData) {
      displayToast(success, 'success');
      const pdfWindow = window.open(printData);
      this.props.printJobListFinished()
    } else if (error && prevResponse.error !== error) {
      displayToast(error, 'error');
    }

  }


  render() {
    return (
      <div >
        {this.props.jobsCompletedManually.isFetching && <Loader />}
        {this.state.isTaskModal ? <Modal
          modalId='job-link'
          modalHeading='Tasks'
          ModalBody={TaskModalBody}
          ModalBodyProps={{
            task: this.state.selectedTask,
            inspectionDetails: { isFetching: false }
          }
          }
          handleClickCross={() => this.setState({ isTaskModal: false })}
        /> : null}
        <MissedInspectionLayout
          isCrossVisible={this.state.isCrossVisible}
          searchText={this.state.searchText}
          jobList={this.props.jobList}
          printPage={this.printPage}
          setFilters={this.setFilters}
          setSearchText={this.setSearchText}
          handleSearch={this.handleOnSearch}
          loadMore={this.loadMore}
          isfetching={this.props.isfetching}
          hasMoreItems={this.page < this.props.pages}
          isClassVisible={this.state.isClassVisible}
          setClassVisibility={this.setClassVisibility}
          reportCategory={this.state.reportCategory}
          sortData={this.sortData}
          filter={this.state.filter}
          endMessage={(this.props.jobList && this.props.jobList.length > 0)
            ? this.state.endMessage
            : endMessageForTaskTable}
          setDatePeriod={this.setDatePeriod}
          setReportType={this.setReportType}
          reportType={this.state.reportType}
          isEndMessageVisible={this.isEndMessageVisible()}
          reportCategories={this.props.reportCategories}
          handleStartJob={this.handleStartJob}
          showTaskModdal={this.showTaskModdal}
          completeJobManually={this.props.completeJobManually}
          jobsDoneManually={this.state.jobsDoneManually}
          isReportNeeded={this.props.isReportNeeded}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  jobList: state.getJobsList.jobData,
  printData: state.getJobsList.printData,
  success: state.getJobsList.success,
  error: state.getJobsList.error,
  pages: state.getJobsList.pages,
  isfetching: state.getJobsList.isfetching,
  reportCategories: state.reportCategory.reportData,
  jobsCompletedManually: state.getJobsList.jobsCompletedManually || {},
  isReportNeeded: state.auth.login.user.isReportNeeded,
});

MissedInspectionView.propTypes = {
  jobList: PropTypes.array,
  isfetching: PropTypes.bool,
  pages: PropTypes.number,
  getJobList: PropTypes.any,
  reportCategories: PropTypes.object,
  reportCat: PropTypes.func,
  formReportDetails: PropTypes.func,
  clearInspectionState: PropTypes.func,
  history: PropTypes.array,
  completeJobManually: PropTypes.func,
  printJobList: PropTypes.any,
  jobsCompletedManually: PropTypes.object,
  isReportNeeded: PropTypes.any,
};

export default connect(mapStateToProps, {
  getJobList,
  clearInspectionState,
  reportCat,
  printJobList,
  formReportDetails,
  completeJobManually,
  printJobListFinished
})(MissedInspectionView);
