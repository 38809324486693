import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initDB } from 'react-indexed-db';
import { BrowserRouter } from 'react-router-dom';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Formio, Components, WebformBuilder } from 'react-formio';

import ErrorBoundary from 'components/ErrorBoundary';
import UserConfirmation from 'components/UserConfirmation';
import { persistor, store } from './redux/store/initStore';
import App from './App';
import IndexedDBconfig from './DBConfig';
import setupAriesComponents from './FormioSetup';
import { AppConfig } from './FormioAppConfig';
import ChatProvider from './components/ChatProvider';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../public/assets/styles/global.css';
import '../public/assets/styles/custom.css';
import '../public/assets/styles/customer.css';
import '../public/assets/styles/customResponsive.css';
import '../public/assets/styles/header.css';
import '../public/assets/styles/reports.css';
import '../public/assets/fonts';

Formio.setApiUrl(AppConfig.apiUrl);
Formio.setProjectUrl(AppConfig.projectUrl);
setupAriesComponents(Formio, Components, WebformBuilder);

addLocaleData(en); // added locale for english
addLocaleData(es); // added locale for spanish

initDB(IndexedDBconfig); // creating DB for offline signatures

// Service workers registration handling
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch((err) => {
      console.log('Service worker registration failed, error:', err);
    });
}

ReactDOM.render(
  // Wrapper for handling errors
  <ErrorBoundary>
    {/* Wrapper for providing state to app */}
    <Provider store={store}>
      {/* Wrapper for persisting data */}
      <PersistGate persistor={persistor}>
        {/* Wrapper for routing */}
        <BrowserRouter
          getUserConfirmation={(message, callback) => (
            UserConfirmation(message, callback, store.getState().locale.lang)
          )}
        >
          {/* Provider for chat */}
          <ChatProvider>
            {/* Main App Component */}
            <App />
          </ChatProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
