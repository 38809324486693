import actionConstants from '../constants/ActionConstants';

const innerDeviceList = {
  error: '',
  isfetching: false,
  innerDeviceList: [],
  isSuccess: true,
  totalCount: 0,
  totalPages: 0,
  currentPage: 0,
};

const initialState = {
  error: '',
  isfetching: false,
  showClientDeviceList: [],
  isSuccess: true,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  innerDeviceList,
};

function clientDeviceList(state = initialState, action) {
  switch (action.type) {
    case actionConstants.SHOW_CLIENT_DEVICE_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
        totalCount: null,
        totalPages: null,
        currentPage: null,
      };
    case actionConstants.SHOW_CLIENT_DEVICE_INITIAL_REQUEST:
      return {
        ...state,
        showClientDeviceList: [],
        isSuccess: false,
        totalCount: null,
        isfetching: true,
        totalPages: null,
        currentPage: null,
      };
    case actionConstants.SHOW_CLIENT_DEVICE_SUCCESS:
      return {
        ...state,
        showClientDeviceList: [...state.showClientDeviceList, ...action.payload.docs],
        totalCount: action.payload.total,
        totalPages: action.payload.pages,
        currentPage: action.payload.page,
        isSuccess: true,
        isfetching: false,
      };
    case actionConstants.SHOW_CLIENT_DEVICE_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
        isSuccess: true,
        showClientDeviceList: []
      };
    case actionConstants.GET_INNER_DEVICE_LIST_REQUEST:
      return {
        ...state,
        innerDeviceList: {
          ...state.innerDeviceList,
          isfetching: true,
          isSuccess: false,
          error: ''
        }
      };
    case actionConstants.GET_INNER_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        innerDeviceList: {
          ...state.innerDeviceList,
          innerDeviceList: action.payload.page === 1
            ? [...action.payload.docs]
            : [...state.innerDeviceList.innerDeviceList, ...action.payload.docs],
          totalCount: action.payload.total,
          totalPages: action.payload.pages,
          currentPage: action.payload.page,
          isSuccess: true,
          isfetching: false,
        }
      };
    case actionConstants.GET_INNER_DEVICE_LIST_FAILURE:
      return {
        ...state,
        innerDeviceList: {
          ...state.innerDeviceList,
          error: action.message,
          isfetching: false,
          isSuccess: true,
        }
      };
    case actionConstants.GET_INNER_DEVICE_LIST_CLEAR:
      return {
        ...state,
        innerDeviceList: {
          ...initialState.innerDeviceList,
        }
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isSuccess: false,
        error: '',
      };
    case actionConstants.SET_INITIAL_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default clientDeviceList;
