import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import SearchDropDown from '../../../../components/SearchDropDown';
import { reportTypes } from '../../../../libs/constants/offlineEnhanced';
import DropDown from '../../../../components/DropDown';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import { getCustomerId } from '../../../../_helpers/commonFunctions';

const SelectCustomerModalBody = (props) => {
  const {
    setCustomerModalState,
    offlineMode,
    getClientsList,
    clientData,
    reportCat,
    reportCategory,
    toggleCustomerModalVisibility,
    toggleModalVisibility,
    addCustomerToList,
    toggleOffline,
    setStep,
  } = props;

  const [customerError, setCustomerError] = useState('');
  const [reportTypeError, setReportTypeError] = useState('');
  const [reportCategoryError, setReportCategoryError] = useState('');

  const resetErrors = () => {
    setCustomerError('');
    setReportCategoryError('');
    setReportTypeError('');
  };

  const validateCustomerDetails = () => {
    const {
      selectedCustomer,
      reportCategory: selectedReportCategory,
      reportType
    } = offlineMode.customerModalState;
    let isError = false;

    if (isEmpty(selectedCustomer)) {
      setCustomerError('Required');
      isError = true;
    }
    if (!selectedReportCategory) {
      setReportCategoryError('Required');
      isError = true;
    }
    if (!reportType) {
      setReportTypeError('Required');
      isError = true;
    }
    return isError;
  };

  const handleSave = () => {
    resetErrors();

    if (!validateCustomerDetails()) {
      addCustomerToList();
      toggleCustomerModalVisibility(false);
      toggleOffline(true);
      setStep();
    }
  };

  useEffect(() => {
    reportCat();
  }, []);

  return (
    <div>
      {
        reportCategory.isfetching && <Loader />
      }
      <label>
        <FormattedMessage
          id='Customer'
          defaultMessage='Customer'
        />
      </label>
      <SearchDropDown
        fetchList={(search, page) => getClientsList(page, 10,
          search.search, true, null, null, {
            type: ['MASTER', 'SUB', 'BUILDING'],
            parentFieldName: 'customerId',
            parentFieldValue: getCustomerId(),
          }
        )}
        resetList={() => { }}
        selected={offlineMode.customerModalState.selectedCustomer}
        isFetching={clientData.isfetching}
        list={clientData.clientData}
        totalCount={clientData.total}
        currentPage={clientData.currentPage}
        onChangeList={item => setCustomerModalState({ selectedCustomer: item })}
        totalPages={clientData.pages}
        displayKey='businessName'
        styleToMainWrapper={{
          marginBottom: '8px'
        }}
      />
      <span className='errorMessage mr-top-8'>
        {customerError ? <FormattedMessage id={customerError} defaultMessage={customerError} /> : ''}
      </span>
      <DropDown
        dropDownList={Object.keys(reportCategory.reportData)}
        label='Report Category'
        name='reportCategory'
        handleOnChange={(name, value) => setCustomerModalState({ [name]: value })}
        value={offlineMode.customerModalState.reportCategory}
        error={reportCategoryError}
      />
      <DropDown
        dropDownList={reportTypes}
        label='Report Type'
        name='reportType'
        handleOnChange={(name, value) => setCustomerModalState({ [name]: value })}
        value={offlineMode.customerModalState.reportType}
        error={reportTypeError}
      />
      <div className={`select-customer-modal-buttons-container ${offlineMode.isOffline && 'is-customer'} mr-top-24 mr-bt-24`}>
        {!offlineMode.isOffline
          && <Button
            text='Previous'
            color='#6AA14E'
            className='user-confirmation-modal-buttons'
            onBtnClick={() => {
              toggleCustomerModalVisibility(false);
              toggleModalVisibility(true);
            }}
          />}
        <Button
          text='Save'
          color='#6AA14E'
          className='user-confirmation-modal-buttons'
          onBtnClick={handleSave}
        />
      </div>
    </div>
  );
};

SelectCustomerModalBody.propTypes = {
  setCustomerModalState: PropTypes.func,
  offlineMode: PropTypes.object,
  getClientsList: PropTypes.func,
  clientData: PropTypes.object,
  reportCat: PropTypes.func,
  reportCategory: PropTypes.object,
  toggleCustomerModalVisibility: PropTypes.func,
  toggleModalVisibility: PropTypes.func,
  addCustomerToList: PropTypes.func,
  toggleOffline: PropTypes.func,
  setStep: PropTypes.func,
};

export default SelectCustomerModalBody;
