import actionConstants from '../constants/ActionConstants';

const initialState = {
  jobDetailsData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.SET_JOB_DETAILS:
      return {
        ...state,
        jobDetailsData: action.payload,
      };
    case actionConstants.CLEAR_JOB_DETAILS:
      return {
        ...state,
        jobDetailsData: null
      };
    default:
      return state;
  }
};
