import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { convertFrequency } from '../../utils/Constants';

const TaskTableRow = (props) => {
  const { row } = props;
  const {
    description, frequency, months = [], year
  } = row;
  return (
    <tr>
      <td>{description}</td>
      <td>
        <FormattedMessage
          id={convertFrequency[frequency] || '-'}
          defaultMessage={convertFrequency[frequency] || '-'}
        />
      </td>
      <td>
        {months.join(', ')}
      </td>
      <td>
        {year}
      </td>
    </tr>
  );
};

TaskTableRow.propTypes = {
  row: PropTypes.object
};

export default TaskTableRow;
