import React, { Component } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import JobLinkLayout from '../../layouts/JobLinkLayout';
import getJobListAction, {
  clearCalenderViewData, getMissedJobListAction, getNextMonthJobListAction
} from '../../redux/actions/jobLinkActions';
import { reportCat } from '../../redux/actions/reportCategory';
import clearState from '../../redux/actions/clearState';
import { runCoachMarks, toggleCoachmarksVisibility } from '../../redux/actions/signUp';

const page = 1;
export class JobLinkView extends Component {
  state = {
    viewType: 'Week',
  }

  changeViewType = (viewType) => {
    this.setState({ viewType });
  }

  componentDidMount() {
    this.props.getJobListAction({ endDate: moment().endOf('month').valueOf() }, page, 100);
    this.props.getMissedJobListAction({}, page, 100);
    this.props.getNextMonthJobListAction({}, page, 100);
    this.props.reportCat();
    this.props.runCoachMarks(true);
  }

  componentWillUnmount() {
    this.props.clearCalenderViewData();
    this.props.clearState();
  }

  render() {
    const { viewType } = this.state;

    return (
      <div>
        <JobLinkLayout
          viewType={viewType}
          changeViewType={this.changeViewType}
          runCoachMarks={this.props.runCoachMarks}
          toggleCoachmarksVisibility={this.props.toggleCoachmarksVisibility}
          currentCoachmarkTarget={this.props.currentCoachmarkTarget}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ signUpStatus }) => ({
  currentCoachmarkTarget: signUpStatus.currentTarget,
});

JobLinkView.propTypes = {
  getJobListAction: PropTypes.func,
  reportCat: PropTypes.func,
  clearCalenderViewData: PropTypes.func,
  clearState: PropTypes.func,
  getMissedJobListAction: PropTypes.func,
  getNextMonthJobListAction: PropTypes.func,
  runCoachMarks: PropTypes.func,
  toggleCoachmarksVisibility: PropTypes.func,
  currentCoachmarkTarget: PropTypes.string,
};

export default connect(mapStateToProps, {
  getJobListAction,
  reportCat,
  clearCalenderViewData,
  clearState,
  getMissedJobListAction,
  getNextMonthJobListAction,
  runCoachMarks,
  toggleCoachmarksVisibility,
})(DragDropContext(HTML5Backend)(JobLinkView));
