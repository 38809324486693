import actionConstants from '../constants/ActionConstants';
import { getTransactionListApiCall, TransactionDetailApiCall, deleteTransactionApicall } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

export const getTransactionlist = (page, limit, queryParams) => (
  ActionDispatcher(getTransactionListApiCall.bind(null, page, limit, queryParams),
    actionConstants.GET_TRANSACTION_LIST_SUCCESS,
    actionConstants.GET_TRANSACTION_LIST_FAILURE,
    actionConstants.GET_TRANSACTION_LIST_REQUEST
  )
);

export const getTransactionDetail = id => (
  ActionDispatcher(TransactionDetailApiCall.bind(null, id),
    actionConstants.GET_TRANSACTION_DETAIL_SUCCESS,
    actionConstants.GET_TRANSACTION_DETAIL_FAILURE,
    actionConstants.GET_TRANSACTION_DETAIL_REQUEST)
);

export const deleteTransaction = params => (
  ActionDispatcher(deleteTransactionApicall.bind(null, params),
    actionConstants.DELETE_TRANSACTION_SUCCESS,
    actionConstants.DELETE_TRANSACTION_FAILURE,
    actionConstants.DELETE_TRANSACTION_REQUEST)
);

export const clearTransactionList = () => (dispatch) => {
  dispatch({ type: actionConstants.CLEAR_TRANSACTIONS });
};
