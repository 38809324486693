import call from './apiRequest';
import apiEndpoints from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call to create auto routing
 * @param {object} data
 */
const createAutoRouting = data => call({
  method: 'post',
  endpoint: apiEndpoints.autoRouting,
  payload: data,
  authKey: getToken(),
});

export default createAutoRouting;
