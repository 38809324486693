import apiEndpoints from './apiEndpoints';
import call from './apiRequest';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call for customer's login
 * @param {string} email
 * @param {string} password
 */
const login = (email, password) => call({
  method: 'post',
  endpoint: `${apiEndpoints.customer}/${apiEndpoints.login}`,
  payload: { email, password }
});

/**
 * Handles customer's logout
 */
function logoutApi() {
  localStorage.removeItem('user');
}

/**
 * Api call for customer's logout
 */
const logout = () => {
  const payload = {
    method: 'post',
    authKey: getToken(),
    endpoint: apiEndpoints.logout
  };
  const fcmToken = localStorage.getItem('fcm_token');
  if (fcmToken) {
    payload.payload = {
      fcmToken
    };
  }
  return call(payload);
};

/**
 * Api call to reset password
 * @param {string} email
 */
function resetPasswordApi(email) {
  return new Promise((resolve, reject) => {
    if (email.length) {
      resolve({
        body: {
          data: {
            status: 200,
            message: 'Please check your email',
          },
        },
      });
    } else {
      reject(
        new Error({
          status: 404,
          message: "Error! Email doesn't exits",
        })
      );
    }
  });
}

/**
 * Api call to set if the user agrees to terms and conditions
 * @param {boolean} isTermAndCondition
 */
function setTermsAndCondition(isTermAndCondition) {
  return call({
    method: 'put',
    endpoint: apiEndpoints.privacyPolicy,
    payload: {
      termsAndCondition: isTermAndCondition,
    },
    authKey: getToken()
  });
}

/**
 * Api call to change password
 * @param {string} oldPassword
 * @param {string} newPassword
 */
function changePasswordApi(oldPassword, newPassword) {
  return call({
    method: 'put',
    endpoint: apiEndpoints.changePassword,
    payload: {
      previousPassword: oldPassword,
      password: newPassword
    },
    authKey: getToken()
  });
}

/**
 * Api call to edit user's profile details
 * @param {object} profileDetails
 * @param {string} clientId
 * @param {string} homeAddress
 */
function editProfile(profileDetails, clientId, homeAddress) {
  return call({
    method: 'put',
    endpoint: `${apiEndpoints.customer}/${clientId}`,
    payload: { ...profileDetails, homeAddress },
    authKey: getToken(),
  });
}

/**
 * Api call to edit profile's signature
 * @param {object} profileDetails
 * @param {string} clientId
 */
function editProfileSignature(profileDetails, clientId) {
  return call({
    method: 'put',
    endpoint: `${apiEndpoints.customer}/${clientId}`,
    payload: { ...profileDetails },
    authKey: getToken(),
  });
}

/**
 * Api call to update employee's permissions
 */
function permissionsUpdate() {
  return call({
    method: 'get',
    endpoint: apiEndpoints.permissionsUpdate,
    authKey: getToken(),
  });
}

/**
 * Api call to update subscription plans
 */
function subscriptionsUpdate() {
  return call({
    method: 'get',
    endpoint: apiEndpoints.subscriptionsUpdate,
    authKey: getToken(),
  });
}

export default {
  login,
  logoutApi,
  logout,
  changePasswordApi,
  resetPasswordApi,
  setTermsAndCondition,
  editProfile,
  permissionsUpdate,
  subscriptionsUpdate,
  editProfileSignature
};
