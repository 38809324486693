import call from './apiRequest';
import endpoint from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call to get team listing
 * @param {number} page
 * @param {number} limit
 */
export const teamListing = (page, limit = 10, payload) => call({
  method: 'post',
  endpoint: endpoint.employee,
  query: { pageNo: page, limit },
  payload,
  authKey: getToken()
});

/**
 * Api call to get team list by soting by name
 * @param {number} page
 * @param {number} limit
 * @param {number} sortOrder
 */
export const teamSortByName = (page, limit, sortOrder, search) => call({
  method: 'post',
  endpoint: endpoint.employee,
  query: { pageNo: page, limit },
  payload: {
    sortOrder,
    search
  },
  authKey: getToken()
});

/**
 * Api call to edit team member's details
 * @param {string} memberId
 */
export const editTeamMember = memberId => call({
  method: 'get',
  endpoint: `${endpoint.customer}/${memberId}`,
  authKey: getToken()
});

/**
 * Api call to update user's timecard
 * @param {string} employeeId
 * @param {number} page
 * @param {number} limit
 * @param {string} startDate
 * @param {string} endDate
 */
export const timeCardUser = (employeeId, page, limit, startDate, endDate) => call({
  method: 'post',
  endpoint: `${endpoint.employeeTimeCard}/${employeeId}`,
  payload: {
    startDate,
    endDate
  },
  query: { page },
  authKey: getToken()
});

/**
 * Api call to get time sheet by date
 * @param {string} employeeId
 * @param {object} dateObject
 */
export const timeSheetByDate = (employeeId, dateObject) => call({
  method: 'post',
  endpoint: `${endpoint.employeeTimeCard}/${employeeId}`,
  payload: dateObject,
  authKey: getToken()
});

/**
 * Api call to update hours
 * @param {string} updatedHours
 * @param {string} empId
 */
export const updateHours = (updatedHours, empId) => call({
  method: 'put',
  endpoint: `${endpoint.updateStatus}/${empId}`,
  payload: updatedHours,
  authKey: getToken(),
});

/**
 * Api call to update team member's status
 * @param {object} data
 */
export const updateStatus = data => call({
  method: 'put',
  endpoint: endpoint.updateStatus,
  payload: data,
  authKey: getToken(),
});

/**
 * Api call to update team member's details
 * @param {string} memberId
 * @param {object} data
 */
export const updateTeamMember = (memberId, data) => call({
  method: 'put',
  endpoint: `${endpoint.customer}/${memberId}`,
  payload: data,
  authKey: getToken()
});

/**
 * Api call to search for an employee
 * @param {object} data
 * @param {number} skip
 */
export const searchEmployee = (data, skip) => call({
  method: 'post',
  endpoint: endpoint.employee,
  query: { pageNo: skip, limit: 50 },
  payload: data,
  authKey: getToken()
});

/**
 * Api call to get employee's gps list
 * @param {string} customerId
 * @param {number} page
 * @param {number} limit
 */
export const gpsEmployeeList = (customerId, page, limit) => call({
  method: 'get',
  endpoint: `${endpoint.gpsEmployeeList}/${customerId}`,
  query: { pageNo: page, limit },
  authKey: getToken()
});

/**
 * Api call to search for employee gps
 * @param {string} customerId
 * @param {number} page
 * @param {number} limit
 * @param {string} search
 */
export const searchGPSEmployee = (customerId, page, limit, search) => call({
  method: 'get',
  endpoint: `${endpoint.gpsEmployeeList}/${customerId}`,
  query: { page, limit, search },
  payload: search,
  authKey: getToken()
});

/**
 * Api call to get employee's gps details
 * @param {string} empId
 * @param {object} params
 */
export const gpsEmployeeDetail = (empId, params) => call({
  method: 'get',
  endpoint: `${endpoint.gpsEmployeeDetail}/${empId}`,
  query: params,
  authKey: getToken()
});
