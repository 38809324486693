import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import IconButton from '../../components/IconButton';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import AddNoteModalBodys from './AddNoteModal';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import { displayToast, checkPermission } from '../../_helpers/commonFunctions';
import { QUESTION_IS_PRESENT, DELETE_NOTES } from '../../utils/Constants';
import { subPermissions } from '../../libs/constants/permissions';

const yup = require('yup');

export class NotesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: [],
      isAddNoteModalVisible: false,
      isConfirmDeleteModalVisible: false,
      selectedNoteQue: '',
      selectedNoteId: '',
    };
  }

  /**
   * reset the selected note value in the state
   */
  resetSelectedNote = () => {
    this.setState({
      selectedNoteQue: '',
      selectedNoteId: '',
    });
  }

  /**
   * handles the input change value
   * @param {object} e - event
   * @param {string} id -id of the note
   * @param {object} question - notes question
   */
  handleInputChange = (e, id, question) => {
    const { notes } = this.state;
    notes.map((note, index) => {
      if (id && note._id === id) {
        notes[index].answer = e.target.value;
      } else if (note.question === question) {
        notes[index].answer = e.target.value;
      }
      return notes;
    });
    this.setState({ notes });
  }

  /**
   * handles the notes in edit mode
   * @param {string} question - notes value
   */
  handleSaveEditedNotes = (question) => {
    const { notes, selectedNoteQue, selectedNoteId } = { ...this.state };
    notes.map((note, index) => {
      if ((note._id && note._id === selectedNoteId)
        || (selectedNoteQue && note.question === selectedNoteQue)
      ) {
        notes[index].question = question;
      }
      return notes;
    });
    return notes;
  }

  handleSaveNoteLocally = (e, question, isSaveButton, cb) => {
    let { notes } = { ...this.state };
    const { selectedNoteQue, selectedNoteId } = this.state;
    const newNote = {
      question,
      answer: '',
      error: '',
    };
    if (selectedNoteId || selectedNoteQue) {
      const isPresent = notes.some(item => item.question === newNote.question);
      if (isPresent) {
        displayToast(QUESTION_IS_PRESENT.questionPresent, 'error');
      } else {
        notes = this.handleSaveEditedNotes(question);
      }
    } else {
      const isPresent = notes.some(item => item.question === newNote.question
      );
      if (isPresent) {
        displayToast(QUESTION_IS_PRESENT.questionPresent, 'error');
      } else {
        notes = notes.concat([newNote]);
      }
    }
    this.resetSelectedNote();
    this.setState({
      notes,
      isAddNoteModalVisible: !isSaveButton,
    }, () => cb && cb()
    );
  }

  /**
   * changes the notes state
   * @param {string} selectedNoteQue - note question
   * @param {string} selectedNoteId - note id
   */
  handleEditNote = (selectedNoteQue, selectedNoteId) => {
    this.setState({
      isAddNoteModalVisible: true,
      selectedNoteQue,
      selectedNoteId,
    });
  }

  /**
   * handles the delete note functionality
   */
  handleDeleteNote = () => {
    const { notes } = { ...this.state };
    const { selectedNoteQue, selectedNoteId } = this.state;
    notes.map((note, index) => {
      if ((selectedNoteId && note._id && note._id === selectedNoteId)
        || (selectedNoteQue && selectedNoteQue === note.question)
      ) {
        notes.splice(index, 1);
      }
      return notes;
    });
    this.resetSelectedNote();
    this.setState({
      notes,
      isConfirmDeleteModalVisible: false,
    });
    displayToast(DELETE_NOTES.notesDelete, 'success');
  }

  /**
   * validate inputs of notes whether it is required or not
   */
  validateInputs = () => {
    const { notes } = { ...this.state };
    const validationSchema = yup.object({
      answer: yup.string().required('Required.')
    });
    let isDataValid = true;
    if (notes.length) {
      notes.forEach((note, index) => {
        validationSchema.validate({ answer: note.answer }, { abortEarly: false })
          .then(() => {
            notes[index].error = '';
            if (isDataValid && (index === notes.length - 1)) {
              this.handleClickSaveBtn();
            }
          })
          .catch((error) => {
            const inputError = error.errors[0];
            isDataValid = false;
            notes[index].error = inputError;
          });
      });
    } else {
      this.handleClickSaveBtn();
    }
    this.setState({ notes });
  }

  /**
   * handle click the save button
   */
  handleClickSaveBtn = () => {
    const { handleClickSave } = this.props;
    const { notes } = { ...this.state };
    notes.map((note, index) => {
      if (note._id) {
        delete notes[index]._id;
      }
      if (note.createdAt) {
        delete notes[index].createdAt;
      }
      if (note.updatedAt) {
        delete notes[index].updatedAt;
      }
      delete notes[index].error;
      return notes;
    });
    handleClickSave(notes);
  }

  /**
   * get new notes
   */
  getNewNotes = () => {
    const { notes } = { ...this.props };
    if (notes) {
      notes.map((note, index) => {
        notes[index].error = '';
        return notes;
      });
    }
    this.setState({ notes });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notes.length !== this.props.notes.length) {
      this.getNewNotes();
    }
  }

  componentDidMount() {
    this.getNewNotes();
  }

  render() {
    const {
      notes,
      isAddNoteModalVisible,
      selectedNoteQue,
      isConfirmDeleteModalVisible,
    } = this.state;

    const {
      intl,
      SetIsPromptAction,
    } = this.props;

    return (
      <div className="building-info-wrap">
        {isAddNoteModalVisible && <Modal
          modalHeading='Add Note'
          handleClickCross={() => this.setState({
            isAddNoteModalVisible: false,
          }, () => this.resetSelectedNote())}
          modalId='add-note'
          ModalBody={AddNoteModalBodys}
          ModalBodyProps={{
            handleClickSave: this.handleSaveNoteLocally,
            initialValue: selectedNoteQue,
            SetIsPromptAction,
          }}
        />}
        {isConfirmDeleteModalVisible && <DeleteConfirmationModal
          handleClickCross={() => this.setState({
            isConfirmDeleteModalVisible: false,
          }, () => this.resetSelectedNote())}
          handleClickCancel={() => this.setState({
            isConfirmDeleteModalVisible: false,
          }, this.resetSelectedNote)}
          handleClickDelete={() => this.handleDeleteNote()}
          deleteMessage='Are you sure you want to delete this note?'
        />}
        <div className="row">
          {notes.map((note, index) => (
            <div className="col-md-12" key={note._id || index}>
              <div className="form-group dis-flex f-dir-column">
                <span className='errorMessage'>
                  {note.error}
                </span>
                <textarea
                  row={8}
                  value={note.answer}
                  name='username'
                  placeholder={intl.formatMessage({ id: 'Write your answer here' })}
                  className='form-control'
                  onChange={(e) => {
                    this.handleInputChange(e, (note._id ? note._id : null), note.question);
                    SetIsPromptAction(true);
                  }}
                  disabled={!checkPermission(subPermissions.editDeleteExistingCustomers)}
                />
                <label
                  className="editable-text"
                  htmlFor="usr"
                >
                  {note.question}
                  {checkPermission(subPermissions.editDeleteExistingCustomers) && <>
                    <span
                      className="edit-label sprite-icon1 material-icons-outlined edit-vr-2"
                      id = 'test-span'
                      onClick={() => (
                        this.handleEditNote(note.question, (note._id ? note._id : ''))
                      )}
                    >create</span>
                    <span
                      className="del-label sprite-icon1 material-icons-outlined del-vr-2"
                      onClick={() => this.setState({
                        isConfirmDeleteModalVisible: true,
                        selectedNoteQue: note.question,
                        selectedNoteId: note._id || '',
                      })}
                    >delete_outline</span>
                  </>}
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between mr-bt-24">
          <div>
            {checkPermission(subPermissions.addCustomer) && <IconButton
              text='Add Note'
              handleOnClick={() => this.setState({ isAddNoteModalVisible: true })}
            />}
          </div>
          <Button
            text='Save'
            color='#6AA14E'
            onBtnClick={this.validateInputs}
          />
        </div>
      </div>
    );
  }
}

NotesSection.propTypes = {
  notes: PropTypes.array,
  handleClickSave: PropTypes.func,
  intl: intlShape.isRequired,
  SetIsPromptAction: PropTypes.func,
  isPrompt: PropTypes.bool,
};

export default injectIntl(NotesSection);
