import call from './apiRequest';
import apiEndpoints from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call for uploading part
 * @param {object} payload
 */
const uploadParts = payload => call({
  method: 'post',
  endpoint: apiEndpoints.uploadParts,
  payload,
  authKey: getToken(),
  type: 'multipart/form-data',
});

export default uploadParts;
