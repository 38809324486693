export default [
  'A Level Pricing',
  'B Level Pricing',
  'C Level Pricing',
  'V Level Pricing',
];

export const pricingItems = [
  'A',
  'B',
  'C',
  'V',
];

export const accountTypes = [
  'MASTER',
  'SUB',
  'BUILDING',
];

export const tableHeaders = [
  {
    title: 'Category',
    isSortable: false
  },
  {
    title: 'Frequency',
    isSortable: false
  },
  {
    title: 'Month',
    isSortable: false
  },
];

export const taskTableHeaders = [
  {
    title: 'Task Description',
    isSortable: false
  },
  {
    title: 'Frequency',
    isSortable: false
  },
  {
    title: 'Month',
    isSortable: false
  },
  {
    title: 'Year',
    isSortable: false
  },
];
