import actionConstants from '../constants/ActionConstants';
import { clientOrOwnerDetails } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const getClientOrOwnerDetails = id => (
  ActionDispatcher(clientOrOwnerDetails.bind(null, id),
    actionConstants.GET_CLIENT_OR_OWNER_DETAILS_SUCCESS,
    actionConstants.GET_CLIENT_OR_OWNER_DETAILS_FAILURE,
    actionConstants.GET_CLIENT_OR_OWNER_DETAILS_REQUEST,
  )
);

export default getClientOrOwnerDetails;
