import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  isSuccess: false,
  success: '',
};

export default function setEmployeeLocation(state = initialState, action) {
  switch (action.type) {
    case actionConstants.SET_EMPLOYEE_LOCATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
      };
    case actionConstants.SET_EMPLOYEE_LOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: '',
        isSuccess: true,
      };
    case actionConstants.SET_EMPLOYEE_LOCATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: '',
        isSuccess: true,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isSuccess: false,
        error: ''
      };
    default:
      return state;
  }
}
