import moment from 'moment';
import { MESSAGE_STATUS } from '../views/ChatView/components/MessageBox';

/**
 * Schema - People List
 *  -isGroup: Boolean
 *  -chatId: String
 *  -lastMsgContent: String
 *  -lastMsgTime: String
 *  -senderName: String
 *  -senderId: String
 *  -senderImg: String
 *  -unreadCount: Number
 * Schema - Message List
 *  -content: String
 *  -type: String
 *  -msgStatus: String
 *  -msgTime: String
 *  -senderImg: String
 *  -senderName: String
 *  -senderId: String
 *  -isSentMsg: Boolean
 *  -msgId: String
 *  -clientMsgId: String
 *  -isSystem: Boolean
 */

export function formatMsgTime(time) {
  return moment(time).format('hh:mm A');
}

export function genMsgId() {
  return `${Math.random()}${Date.now()}`;
}

export function getStatusForMessage(isRead, isDelivered, isSent, isGroup) {
  if (isRead && !isGroup) {
    return MESSAGE_STATUS.seen;
  }
  if (isDelivered && !isGroup) {
    return MESSAGE_STATUS.delivered;
  }
  if (isSent) {
    return MESSAGE_STATUS.sent;
  }
  return MESSAGE_STATUS.pending;
}

export function normalizeMessage(message, currentUserId) {
  let senderName = '';
  let isGroup = false;
  if (message.groupName) {
    senderName = message.groupName;
    isGroup = true;
  } else {
    const senderInfo = (message.senderDetails && message.senderDetails[0]) || {};
    senderName = `${senderInfo.firstName} ${senderInfo.lastName}`;
  }
  return {
    content: message.message,
    type: message.type,
    msgStatus: getStatusForMessage(message.isRead, message.isDelivered, true, isGroup),
    msgTime: formatMsgTime(message.createdAt),
    senderId: message.senderId,
    isSentMsg: message.senderId === currentUserId,
    msgId: message._id,
    clientMsgId: message._id || message.clientMsgId,
    senderName,
    senderImg: '../../../public/assets/img/chat2.png',
    isSystem: message.isSystemMessage
  };
}

export function normalizePerson(payload = {}) {
  return {
    isGroup: payload.isGroup,
    chatId: payload.channelId,
    lastMsgContent: '',
    lastMsgTime: formatMsgTime(payload.createdAt),
    senderName: payload.senderName || payload.groupName,
    senderId: payload.senderId,
    senderImg: '../../../public/assets/img/chat2.png',
    unreadCount: 0,
    clientMsgId: 'DUMMY_CLIENT_MESSAGE_ID'
  };
}

export function normalizePeopleList(payload = []) {
  return payload.map((item) => {
    let senderName = '';
    if (item.isGroup) {
      senderName = item.groupName;
    } else if (item.customer && item.customer.length > 0) {
      const index = item.customer[0] === '' ? 1 : 0;
      const nameDetails = item.customer[index];
      if (item.lastMessagePerson.firstName) {
        senderName = senderName.concat(nameDetails.firstName);
      }
      if (item.lastMessagePerson.lastName) {
        senderName = senderName.concat(` ${nameDetails.lastName}`);
      }
    }

    return {
      isGroup: item.isGroup,
      chatId: item.channelId,
      lastMsgContent: item.lastMessage,
      lastMsgTime: formatMsgTime(item.messageDate),
      senderName,
      senderId: item.senderId,
      senderImg: '../../../public/assets/img/chat2.png',
      unreadCount: item.unreadCount,
      clientMsgId: 'DUMMY_CLIENT_MESSAGE_ID'
    };
  });
}


export function normalizeMessagesList(payload, currentUserId) {
  return payload.reverse().map((item) => {
    const {
      messages: {
        message,
        messageType,
        isRead,
        isDelivered,
        createdAt,
        senderId,
        clientMsgId,
        isSystemMessage,
        _id
      } = {},
      isGroup,
      sender
    } = item;
    let senderName = '';
    if (Array.isArray(sender) && sender.length > 0) {
      if (sender[0].firstName) {
        senderName = senderName.concat(sender[0].firstName);
      }
      if (sender[0].lastName) {
        senderName = senderName.concat(` ${sender[0].lastName}`);
      }
    }
    return {
      content: message,
      type: messageType,
      msgStatus: getStatusForMessage(isRead, isDelivered, true, isGroup),
      msgTime: formatMsgTime(createdAt),
      senderImg: '../../../public/assets/img/chat1.png',
      senderName,
      senderId,
      isSentMsg: senderId === currentUserId,
      msgId: _id,
      clientMsgId,
      isSystem: isSystemMessage
    };
  });
}

export function appendMessageToChatList(messageList, message) {
  return messageList.concat(message);
}

export function updatePeopleList(
  peopleList, updateInfo,
  { moveToTop = true, incUnreadCount = false } = {}
) {
  const _peopleList = peopleList;
  const indexToUpdate = _peopleList.findIndex(item => item.chatId === updateInfo.chatId);
  if (indexToUpdate === -1) {
    // case: chat does not exist, create and append to top
    _peopleList.unshift(updateInfo);
  } else {
    // case: chat is pre-existing, update
    _peopleList[indexToUpdate] = { ...peopleList[indexToUpdate], ...updateInfo };
    if (incUnreadCount) {
      _peopleList[indexToUpdate].unreadCount += 1;
    }
    if (moveToTop) {
      // append to top
      const objToMove = _peopleList[indexToUpdate];
      _peopleList.splice(indexToUpdate, 1);
      _peopleList.unshift(objToMove);
    }
  }
  return _peopleList;
}

export function updateMsgById(messageList, updatedInfo, idToUpdate, appendIfNew, message) {
  const _messageList = messageList;
  const msgIndex = _messageList.findIndex(
    msg => msg.clientMsgId === idToUpdate || msg.msgId === idToUpdate
  );
  if (msgIndex !== -1) {
    _messageList[msgIndex] = { ..._messageList[msgIndex], ...updatedInfo };
  }
  if (msgIndex === -1 && appendIfNew) {
    _messageList.push(message);
  }
  return _messageList;
}

/**
 * stopOn - if during iteration, returns true, iteration is stopped.
 * fromBack - if true, messages are traversed in reverse order.
 */
export function updateMessageList(messageList, updatedInfo, { stopOn = () => { }, fromBack }) {
  const _messageList = messageList;
  let startIndex;
  let endIndex;
  let comparator; // stop iteration if returns false
  let getNextIndex; // get index for next iteration
  if (fromBack) {
    startIndex = _messageList.length - 1;
    endIndex = 0;
    comparator = (a, b) => a >= b;
    getNextIndex = a => a - 1;
  } else {
    startIndex = 0;
    endIndex = _messageList.length - 1;
    comparator = (a, b) => a <= b;
    getNextIndex = a => a + 1;
  }
  let index = startIndex;
  while (comparator(index, endIndex)) {
    if (stopOn(_messageList[index])) {
      break;
    }
    _messageList[index] = { ..._messageList[index], ...updatedInfo };
    index = getNextIndex(index);
  }
  return _messageList;
}
