import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  success: '',
  clientReportList: [],
  totalPages: null,
  currentPage: null
};

export default function reportTemplateState(state = initialState, action) {
  switch (action.type) {
    case actionConstants.FETCH_REPORT_TEMPLATES_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actionConstants.FETCH_REPORT_TEMPLATES_SUCCESS:
      if (action.payload.page === 1) {
        return {
          ...state,
          clientReportList: action.payload.docs,
          isFetching: false,
          success: action.message,
          totalPages: action.payload.pages,
          currentPage: action.payload.page
        };
      }
      return {
        ...state,
        clientReportList: [...state.clientReportList, ...action.payload.docs],
        isFetching: false,
        success: action.payload.message,
        totalPages: action.payload.pages,
        currentPage: action.payload.page,
      };
    case actionConstants.FETCH_REPORT_TEMPLATES_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...initialState,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
