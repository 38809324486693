/* eslint-disable import/prefer-default-export */
import actionConstants from '../constants/ActionConstants';
import ActionDispatcher from '../../api/ActionDispatcher';
import {
  getPeopleListApi,
  getMessagesbyChatIdApi,
  getAllPeopleToChatApi,
  createIndividualChatApi,
  createGroupChatApi,
  getGroupDetailsApi,
  updateGroupChatApi
} from '../../api/chat';
import { chatSocketHandlers } from '../../socket';
import { MESSAGE_STATUS } from '../../views/ChatView/components/MessageBox';
import { formatMsgTime, genMsgId } from '../../_helpers/chat';
import { displayToast } from '../../_helpers/commonFunctions';

export const getPeopleList = () => (
  ActionDispatcher(getPeopleListApi.bind(null),
    actionConstants.CHAT_FETCH_PEOPLE_LIST_SUCCESS,
    actionConstants.CHAT_FETCH_PEOPLE_LIST_FAILURE,
    actionConstants.CHAT_FETCH_PEOPLE_LIST_INIT)
);

export const getAllChatMessages = ({ chatId, currentUserId }, skip, limit, isPaginatedRequest) => (
  ActionDispatcher(getMessagesbyChatIdApi.bind(null, { chatId }, skip, limit),
    actionConstants.CHAT_FETCH_MESSAGES_BY_CHATID_SUCCESS,
    actionConstants.CHAT_FETCH_MESSAGES_BY_CHATID_FAILURE,
    actionConstants.CHAT_FETCH_MESSAGES_BY_CHATID_INIT,
    { currentUserId, chatId, isPaginatedRequest }
  )
);

export const getAllPeopleToChat = (isPaginatedRequest, page, limit) => (
  ActionDispatcher(getAllPeopleToChatApi.bind(null, page, limit),
    actionConstants.CHAT_GET_ALL_PEOPLE_SUCCESS,
    actionConstants.CHAT_GET_ALL_PEOPLE_FAILURE,
    actionConstants.CHAT_GET_ALL_PEOPLE_INIT,
    {
      isPaginatedRequest
    }
  )
);

export const getGroupDetails = ({ chatId }) => (
  ActionDispatcher(getGroupDetailsApi.bind(null, { chatId }),
    actionConstants.CHAT_GET_ROOM_DETAILS_SUCCESS,
    actionConstants.CHAT_GET_ROOM_DETAILS_FAILURE,
    actionConstants.CHAT_GET_ROOM_DETAILS_INIT
  )
);

export const createIndividualChat = ({ personId, personName }) => (
  ActionDispatcher(createIndividualChatApi.bind(null, { personId }),
    actionConstants.CHAT_CREATE_INDIVIDUAL_CHAT_SUCCESS,
    actionConstants.CHAT_CREATE_INDIVIDUAL_CHAT_FAILURE,
    actionConstants.CHAT_CREATE_INDIVIDUAL_CHAT_INIT,
    { personName, personId }
  )
);

export const createGroupChat = ({ groupName, members }) => (
  ActionDispatcher(createGroupChatApi.bind(null, { groupName, members }),
    actionConstants.CHAT_CREATE_GROUP_CHAT_SUCCESS,
    actionConstants.CHAT_CREATE_GROUP_CHAT_FAILURE,
    actionConstants.CHAT_CREATE_GROUP_CHAT_INIT,
    { groupName }
  )
);

export const updateGroupChat = ({
  chatId,
  newMembers,
  removedMembers,
  groupName
}, isSelfLeave) => (
  ActionDispatcher(updateGroupChatApi.bind(null, {
    chatId,
    newMembers,
    removedMembers,
    groupName
  }),
  actionConstants.CHAT_UPDATE_ROOM_DETAILS_SUCCESS,
  actionConstants.CHAT_UPDATE_ROOM_DETAILS_FAILURE,
  actionConstants.CHAT_UPDATE_ROOM_DETAILS_INIT,
  { isSelfLeave })
);

export const setActiveChatId = ({ chatId }) => ({
  type: actionConstants.CHAT_SET_ACTIVE_CHAT_ID,
  chatId
});

export const sendTextMessage = ({
  content,
  currentUser,
  activeChatId
}) => (dispatch) => {
  // a client-end msg id is needed to keep track of message before it is sent to server.
  const clientMsgId = genMsgId();
  dispatch({
    type: actionConstants.CHAT_SEND_MESSAGE,
    payload: {
      msgId: clientMsgId, // will be updated by actual msgId after server receives msg.
      isSentMsg: false,
      type: 'text',
      msgStatus: MESSAGE_STATUS.pending,
      content,
      msgTime: formatMsgTime(),
      senderImg: '../../../public/assets/img/chat1.png',
      senderName: currentUser.userName,
      senderId: currentUser.customerId,
      clientMsgId
    }
  });
  chatSocketHandlers.sendMessage({
    channelId: activeChatId,
    senderId: currentUser.customerId,
    message: content,
    clientMsgId
  });
};

export const deleteOldMessage = (msg, currentUser, activeChatId) => (dispatch) => {
  dispatch({
    type: actionConstants.CHAT_DELETE_MESSAGE,
    payload: {
      msgId: msg.msgId,
      currentUser,
      activeChatId,
    }
  });
};

export const onMessageReceived = ({
  message,
  senderDetails,
  channelId
}) => (dispatch, getState) => {
  if (window.location.pathname !== '/chat') {
    displayToast(message.message);
  }
  const { auth: { login } = {}, chat: { activeChatId } } = getState();
  const { user: { customerId } = {} } = login;
  if (message.channelId === activeChatId) {
    chatSocketHandlers.emitChatIdAsSeen({
      chatId: activeChatId,
      currentUserId: customerId
    });
  } else {
    chatSocketHandlers.emitReceivedMsgAsDelivered({
      chatId: message.channelId,
      currentUserId: customerId
    });
  }
  dispatch(({
    type: actionConstants.CHAT_MESSAGE_RECEIVED,
    payload: {
      receivedMessage: message,
      senderDetails,
      chatInfo: channelId,
      extra: {
        currentUserId: customerId
      }
    }
  }));
};

export const handleOnMessageSent = msg => (dispatch, getState) => {
  const { customerId = '' } = getState().auth.login && getState().auth.login.user;
  dispatch({
    type: actionConstants.CHAT_UPDATE_MESSAGE_BY_ID,
    payload: {
      chatId: msg.channelId,
      idToUpdate: msg.clientMsgId,
      updateInfo: {
        msgStatus: MESSAGE_STATUS.sent,
        clientMsgId: msg._id,
        msgId: msg._id,
      },
      message: msg,
      currentUserId: customerId
    }
  });
};

export const handleGroupUpdate = data => ({
  type: actionConstants.CHAT_UPDATE_ROOM_NAME,
  payload: {
    channelId: data.channelId,
    groupName: data.groupName,
  }
});

export const handleOnMessageSeen = msg => ({
  type: actionConstants.CHAT_UPDATE_MESSAGES_BY_CHAT_ID,
  payload: {
    chatId: msg.channelId,
    updateInfo: {
      msgStatus: MESSAGE_STATUS.seen
    },
    stopOn: message => message.msgStatus === MESSAGE_STATUS.seen
  }
});

export const handleOnMessageDelivered = msg => ({
  type: actionConstants.CHAT_UPDATE_MESSAGES_BY_CHAT_ID,
  payload: {
    chatId: msg.channelId,
    updateInfo: {
      msgStatus: MESSAGE_STATUS.delivered
    },
    stopOn: message => message.msgStatus === MESSAGE_STATUS.seen
      || message.msgStatus === MESSAGE_STATUS.delivered
  }
});

export const markAllUndeliveredMessages = () => ({
  type: actionConstants.CHAT_MARK_AS_UNDELIVERED,
});
