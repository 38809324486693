import call from './apiRequest';
import { API } from '../config';

const baseURLGetIPLocation = API.IPIFY;
const baseURLGetIPStack = API.IP_STACK;

/**
 * Api call to get IP of location
 */
export const getIPOfLocation = () => call({
  url: baseURLGetIPLocation,
});

/**
 * Api call to get IP stack
 * @param {string} systemIP
 */
export const getIPStack = systemIP => call({
  url: `${baseURLGetIPStack}/${systemIP}`,
  query: {
    access_key: API.IPSTACK_KEY
  }
});
