import actionConstants from '../constants/ActionConstants';

const initialState = {
  inspectionsDue: {
    isFetching: false,
    isSuccess: false,
    inspectionsDueList: [],
    error: '',
    currentPage: null,
    totalPages: null,
  },
  inspectionsDone: {
    isFetching: false,
    isSuccess: false,
    inspectionsDoneList: [],
    error: '',
    currentPage: null,
    totalPages: null,
  },
  pricingLevel: {
    isFetching: false,
    error: '',
    isSuccess: false,
    pricingLevelData: [],
  },
  clientListForTypeChange: {
    isFetching: false,
    isSuccess: false,
    clientList: [],
    error: '',
    currentPage: null,
    totalPages: null,
  },
  clientAccountTypeChangeStatus: {
    isFetching: false,
    error: '',
    isSuccess: false,
    success: '',
  }
};

export default function customerHome(state = initialState, action) {
  const {
    inspectionsDue,
    inspectionsDone,
    pricingLevel,
    clientListForTypeChange,
  } = state;
  switch (action.type) {
    case actionConstants.GET_INSPECTIONS_DUE_LIST_REQUEST:
      return {
        ...state,
        inspectionsDue: {
          ...inspectionsDue,
          isFetching: true,
          isSuccess: false,
          error: '',
        }
      };
    case actionConstants.GET_INSPECTIONS_DUE_LIST_SUCCESS:
      return {
        ...state,
        inspectionsDue: {
          ...inspectionsDue,
          isFetching: false,
          isSuccess: true,
          error: '',
          inspectionsDueList: action.payload.page === 1
            ? action.payload.docs
            : [...inspectionsDue.inspectionsDueList, ...action.payload.docs],
          currentPage: action.payload.page,
          totalPages: action.payload.pages,
        },
      };
    case actionConstants.GET_INSPECTIONS_DUE_LIST_FAILURE:
      return {
        ...state,
        inspectionsDue: {
          ...inspectionsDue,
          isFetching: false,
          error: action.message,
          isSuccess: false,
        }
      };
    case actionConstants.GET_INSPECTIONS_DONE_LIST_REQUEST:
      return {
        ...state,
        inspectionsDone: {
          ...inspectionsDone,
          isFetching: true,
          isSuccess: false,
          error: '',
        }
      };
    case actionConstants.GET_INSPECTIONS_DONE_LIST_SUCCESS:
      return {
        ...state,
        inspectionsDone: {
          ...inspectionsDone,
          isFetching: false,
          isSuccess: true,
          error: '',
          inspectionsDoneList: action.payload.page === 1
            ? action.payload.docs
            : [...inspectionsDone.inspectionsDoneList, ...action.payload.docs],
          currentPage: action.payload.page,
          totalPages: action.payload.pages,
        },
      };
    case actionConstants.GET_INSPECTIONS_DONE_LIST_FAILURE:
      return {
        ...state,
        inspectionsDone: {
          ...inspectionsDone,
          isFetching: false,
          error: action.message,
          isSuccess: false,
        }
      };
    case actionConstants.GET_PRICING_LEVEL_REQUEST:
      return {
        ...state,
        pricingLevel: {
          ...pricingLevel,
          isFetching: true,
          isSuccess: false,
          error: '',
        }
      };
    case actionConstants.GET_PRICING_LEVEL_SUCCESS:
      return {
        ...state,
        pricingLevel: {
          ...pricingLevel,
          isFetching: false,
          isSuccess: true,
          error: '',
          pricingLevelData: action.payload,
        },
      };
    case actionConstants.GET_PRICING_LEVEL_FAILURE:
      return {
        ...state,
        pricingLevel: {
          ...pricingLevel,
          isFetching: false,
          error: action.message,
          isSuccess: false,
        }
      };
    case actionConstants.GET_CLIENT_LIST_FOR_TYPE_CHANGE_REQUEST:
      return {
        ...state,
        clientListForTypeChange: {
          ...clientListForTypeChange,
          isFetching: true,
          isSuccess: false,
          error: '',
        }
      };
    case actionConstants.GET_CLIENT_LIST_FOR_TYPE_CHANGE_SUCCESS:
      return {
        ...state,
        clientListForTypeChange: {
          ...clientListForTypeChange,
          isFetching: false,
          isSuccess: true,
          error: '',
          clientList: action.payload.page === 1
            ? action.payload.docs
            : [...clientListForTypeChange.clientList, ...action.payload.docs],
          currentPage: action.payload.page,
          totalPages: action.payload.pages,
        },
      };
    case actionConstants.GET_CLIENT_LIST_FOR_TYPE_CHANGE_FAILURE:
      return {
        ...state,
        clientListForTypeChange: {
          ...clientListForTypeChange,
          isFetching: false,
          error: action.message,
          isSuccess: false,
        }
      };
    case actionConstants.CHANGE_CLIENT_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        clientAccountTypeChangeStatus: {
          isFetching: true,
          isSuccess: false,
          error: '',
          success: '',
        }
      };
    case actionConstants.CHANGE_CLIENT_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        clientAccountTypeChangeStatus: {
          isFetching: false,
          isSuccess: true,
          error: '',
          success: action.message,
        },
      };
    case actionConstants.CHANGE_CLIENT_ACCOUNT_TYPE_FAILURE:
      return {
        ...state,
        clientAccountTypeChangeStatus: {
          isFetching: false,
          error: action.message,
          isSuccess: false,
        }
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
