import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../../components/ReusableTable';
import TaskHeaders from '../../components/TaskHeader/TaskHeader';
import MyJobsRow from './MyJobsRow';

const headerFields = [
  {
    title: 'Name',
    isSortable: true,
    width: '16%',
  },
  {
    title: 'Report Category',
    isSortable: false,
    width: '16%',
  },
  {
    title: 'Frequency',
    isSortable: false,
    width: '16%',
  },
  {
    title: 'Date And Time',
    isSortable: true,
    width: '16%',
  },
  {
    title: 'Type',
    isSortable: false,
    width: '16%',
  },
  {
    title: 'Action',
    isSortable: false,
    width: '16%',
  },
];

const _TableHeadings = JSON.parse(JSON.stringify(headerFields));
_TableHeadings[headerFields.length - 1].title = ' ';

const MyJobsLayout = ({
  setSearchText, setFilters, isCrossVisible,
  handleSearch, status, reportType, filter, hasMoreItems, runCoachMarks,
  setDatePeriod, setReportType, sortData, jobList, isfetching, endMessage, handleStartJob,
  isClassVisible, setClassVisibility, reportCategories, reportCategory, loadMore, showTaskModdal,
  jobsDoneManually, completeJobManually, isReportNeeded, toggleCoachmarksVisibility,
  currentCoachmarkTarget, printPage
}) => (
    <React.Fragment>
      <TaskHeaders name="My Jobs" setSearchText={setSearchText}
        isCrossVisible={isCrossVisible}
        setFilters={setFilters}
        handleSearch={handleSearch}
        reportCategory={reportCategory}
        status={status}
        minDate = {moment(new Date())}
        reportType={reportType}
        hideFilter = {true}
        filter={filter}
        printPage={printPage}
        setDatePeriod={setDatePeriod}
        setReportType={setReportType}
        reportCategories={reportCategories}
      />

      <Table
        sortData={sortData}
        headerData={(isReportNeeded === false)
          ? _TableHeadings
          : headerFields}
        bodyData={jobList}
        hasMoreItems = {hasMoreItems}
        RowToRender={MyJobsRow}
        loadMore = {loadMore}
        isLoaderVisible={isfetching}
        endMessage={endMessage}
        isEndMessageVisible={jobList.length === 0}
        RowToRenderProps={{
          isClassVisible,
          setClassVisibility,
          showTaskModdal,
          handleStartJob,
          runCoachMarks,
          jobsDoneManually,
          completeJobManually,
          isReportNeeded,
          toggleCoachmarksVisibility,
          currentCoachmarkTarget,
        }}
        rowClassName={'myjobUniqueTable'}
      />
    </React.Fragment>);

MyJobsLayout.propTypes = {
  setSearchText: PropTypes.func,
  setFilters: PropTypes.func,
  handleSearch: PropTypes.func,
  status: PropTypes.array,
  reportType: PropTypes.string,
  filter: PropTypes.array,
  setDatePeriod: PropTypes.func,
  setReportType: PropTypes.func,
  sortData: PropTypes.func,
  jobList: PropTypes.array,
  loadMore: PropTypes.func,
  isfetching: PropTypes.bool,
  hasMoreItems: PropTypes.bool,
  endMessage: PropTypes.string,
  isEndMessageVisible: PropTypes.bool,
  isCrossVisible: PropTypes.bool,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func,
  reportCategories: PropTypes.object,
  reportCategory: PropTypes.any,
  showTaskModdal: PropTypes.func,
  handleStartJob: PropTypes.object,
  runCoachMarks: PropTypes.func,
  jobsDoneManually: PropTypes.array,
  completeJobManually: PropTypes.func,
  isReportNeeded: PropTypes.any,
  toggleCoachmarksVisibility: PropTypes.func,
  currentCoachmarkTarget: PropTypes.string,
};
export default MyJobsLayout;
