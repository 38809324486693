import isEqual from 'lodash/isEqual';

import actionConstants from '../constants/ActionConstants';
import {
  appendMessageToChatList,
  normalizeMessagesList,
  normalizePeopleList,
  normalizeMessage,
  updateMessageList,
  updatePeopleList,
  updateMsgById,
  normalizePerson
} from '../../_helpers/chat';
import { MESSAGE_STATUS } from '../../views/ChatView/components/MessageBox';

const initialState = {
  // data states
  activeChatId: '',
  chats: {},
  totalChatMessagesCount: 0,
  peopleList: [],
  allPeople: [],
  page: 0,
  totalAllPeopleCount: 0,
  chatRoomDetails: {},
  // flag states
  isChatLoading: false,
  isGetChatByIdSuccess: false,
  chatError: '',
  isChatRoomDetailsLoading: false,
  isAllPeopleApiLoading: false,
  isAllPeopleApiError: false,
  allPeopleApiError: '',
  isUpdateChatLoading: false,
  isUpdateChatSuccess: false,
  isUpdateChatError: false,
  updateChatError: '',
  undeliveredMessages: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.CHAT_FETCH_PEOPLE_LIST_INIT:
      return {
        ...state,
        isChatLoading: true,
        chatError: ''
      };
    case actionConstants.CHAT_FETCH_PEOPLE_LIST_FAILURE:
      return {
        ...state,
        isChatLoading: false,
        chatError: action.message
      };
    case actionConstants.CHAT_FETCH_PEOPLE_LIST_SUCCESS:
      return {
        ...state,
        isChatLoading: false,
        peopleList: normalizePeopleList(action.payload),
        chatError: ''
      };
    case actionConstants.CHAT_FETCH_MESSAGES_BY_CHATID_INIT:
      return {
        ...state,
        isChatLoading: true,
        isGetChatByIdSuccess: false,
        chatError: ''
      };
    case actionConstants.CHAT_FETCH_MESSAGES_BY_CHATID_FAILURE:
      return {
        ...state,
        isChatLoading: false,
        isGetChatByIdSuccess: false,
        chatError: action.message
      };
    case actionConstants.CHAT_FETCH_MESSAGES_BY_CHATID_SUCCESS: {
      // TODO: handle case: unsent messages are present and user switches active chat.
      const { chatId, currentUserId } = action.extra;
      const { chats } = state;
      const payload = action.payload && action.payload.length > 0
        && action.payload[0].pipelineResults;
      const newChatList = normalizeMessagesList(payload, currentUserId);
      const peopleList = [...state.peopleList];
      const peopleInfoToUpdate = {
        chatId: payload.length && payload.length > 0 && payload[0]._id,
        lastMsgContent: payload.length && payload.length > 0 && payload[0].messages.message,
      };
      if (action.extra.isPaginatedRequest) {
        return {
          ...state,
          isChatLoading: false,
          isGetChatByIdSuccess: true,
          chats: {
            ...chats,
            [chatId]: [...newChatList, ...chats[chatId]],
            peopleList: updatePeopleList(peopleList, peopleInfoToUpdate)
          }
        };
      }
      return {
        ...state,
        isChatLoading: false,
        isGetChatByIdSuccess: true,
        totalChatMessagesCount: action.payload.length
          && action.payload.length > 0
          && action.payload[0].totalCount
          && action.payload[0].totalCount.length
          && action.payload[0].totalCount[0].count,
        chats: {
          ...chats,
          [chatId]: newChatList,
          peopleList: updatePeopleList(peopleList, peopleInfoToUpdate)
        }
      };
    }
    case actionConstants.CHAT_SET_ACTIVE_CHAT_ID: {
      if (action.chatId) {
        const infoToUpdate = { unreadCount: 0, chatId: action.chatId }; // reset unread count
        const peopleList = updatePeopleList(
          [...state.peopleList], infoToUpdate, { moveToTop: false }
        );
        return {
          ...state, activeChatId: action.chatId, peopleList
        };
      }
      return {
        ...state, activeChatId: action.chatId,
      };
    }
    case actionConstants.CHAT_DELETE_MESSAGE: {
      const chat = [...state.chats[action.payload.activeChatId]];
      const index = chat.findIndex(message => message.msgId === action.payload.msgId);
      if (index > -1) {
        chat.splice(index, 1);
      }
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.activeChatId]: chat,
        }
      };
    }
    case actionConstants.CHAT_SEND_MESSAGE: {
      const message = action.payload;
      const peopleList = [...state.peopleList];
      const messageList = state.chats[state.activeChatId] || [];
      const chats = {
        ...state.chats,
        [state.activeChatId]: [
          ...messageList,
          message
        ]
      };
      const undeliveredMessagesList = state.undeliveredMessages[state.activeChatId] || [];
      const undeliveredMessages = {
        ...state.undeliveredMessages,
        [state.activeChatId]: [
          ...undeliveredMessagesList,
          message
        ]
      };
      const peopleInfoToUpdate = {
        chatId: state.activeChatId,
        lastMsgContent: message.content,
        lastMsgTime: message.msgTime
      };
      return {
        ...state,
        chats,
        undeliveredMessages,
        peopleList: updatePeopleList(peopleList, peopleInfoToUpdate),
      };
    }
    case actionConstants.CHAT_MESSAGE_RECEIVED: {
      const {
        receivedMessage, extra, senderDetails = [], chatInfo = {}
      } = action.payload;
      const chatId = receivedMessage.channelId;
      const messageList = [...(state.chats[chatId] || [])];
      const peopleList = [...state.peopleList];
      const message = { ...receivedMessage, senderDetails, groupName: chatInfo.groupName };
      const normalizedMessage = normalizeMessage(message, extra.currentUserId);
      const chats = {
        ...state.chats,
        [chatId]: appendMessageToChatList(messageList, normalizedMessage)
      };
      const index = peopleList.findIndex(people => people.chatId === chatId);
      let peopleInfoToUpdate;
      if (index > -1) {
        peopleInfoToUpdate = {
          isGroup: peopleList[index].isGroup,
          senderName: peopleList[index].senderName,
          chatId,
          lastMsgContent: normalizedMessage.content,
          lastMsgTime: normalizedMessage.msgTime
        };
      } else {
        peopleInfoToUpdate = {
          isGroup: chatInfo.isGroup,
          senderName: normalizedMessage.senderName,
          chatId,
          lastMsgContent: normalizedMessage.content,
          lastMsgTime: normalizedMessage.msgTime,
          unreadCount: 1,
        };
      }
      const updatePeopleListOptions = {
        moveToTop: true,
        incUnreadCount: state.activeChatId !== chatId,
        appendIfNew: true
      };
      return {
        ...state,
        chats,
        peopleList: updatePeopleList(peopleList, peopleInfoToUpdate, updatePeopleListOptions)
      };
    }
    case actionConstants.CHAT_UPDATE_MESSAGE_BY_ID: {
      const {
        updateInfo,
        chatId,
        idToUpdate,
        message,
        currentUserId
      } = action.payload;
      let normalizedMessage = {};
      let peopleList = [...state.peopleList];
      const messageList = [...(state.chats[chatId] || [])];
      const appendIfNew = updateInfo.msgStatus === MESSAGE_STATUS.sent;
      const messageToNormalize = { ...message.message, senderDetails: message.senderDetails };
      const undeliveredMessagesList = [...state.undeliveredMessages[chatId]];
      const index = undeliveredMessagesList.findIndex(
        msg => msg.clientMsgId === action.payload.idToUpdate
      );
      if (index > -1) {
        undeliveredMessagesList.splice(index, 1);
      }
      if (appendIfNew) {
        // case: message does not pre-exist in chat.
        // append message to both chat and people list.
        normalizedMessage = normalizeMessage(messageToNormalize, currentUserId);
        const peopleInfoToUpdate = {
          chatId,
          lastMsgContent: normalizedMessage.content,
          lastMsgTime: normalizedMessage.msgTime,
        };
        const incUnreadCount = state.activeChatId !== chatId
          && normalizedMessage.senderId !== currentUserId;
        const updatePeopleListOptions = {
          moveToTop: true,
          incUnreadCount
        };
        peopleList = updatePeopleList(peopleList, peopleInfoToUpdate, updatePeopleListOptions);
      }
      const chats = {
        ...state.chats,
        [chatId]: updateMsgById(messageList, updateInfo, idToUpdate, appendIfNew, normalizedMessage)
      };
      return {
        ...state,
        chats,
        peopleList,
        undeliveredMessages: {
          ...state.undeliveredMessages,
          [chatId]: undeliveredMessagesList,
        },
      };
    }
    case actionConstants.CHAT_MARK_AS_UNDELIVERED: {
      const { undeliveredMessages, chats } = state;

      const nextState = {
        ...state,
        chats: Object.keys(undeliveredMessages)
          .reduce((acum, chatID) => ({
            ...acum,
            [chatID]: chats[chatID].map(
              msg => (undeliveredMessages[chatID]
                .findIndex(m => m.msgId === msg.clientMsgId) > -1 ? {
                  ...msg,
                  msgStatus: 'undelivered',
                } : msg)),
          }), chats),
      };

      return !isEqual(state, nextState) ? nextState : state;
    }
    case actionConstants.CHAT_UPDATE_ROOM_NAME: {
      const { peopleList, chatRoomDetails } = state;
      const index = peopleList.findIndex(item => item.chatId === action.payload.channelId);
      const newPeopleList = [...peopleList];
      if (index > -1) {
        newPeopleList[index].senderName = action.payload.groupName;
      }
      return {
        ...state,
        chatRoomDetails: chatRoomDetails.channelId === action.payload.channelId ? {
          ...state.chatRoomDetails,
          groupName: action.payload.groupName,
        } : chatRoomDetails,
        peopleList: newPeopleList,
      };
    }
    case actionConstants.CHAT_UPDATE_MESSAGES_BY_CHAT_ID: {
      const { chatId, updateInfo, stopOn } = action.payload;
      const messageList = [...(state.chats[chatId] || [])];
      const updateMsgListOptions = { stopOn, fromBack: true };
      return {
        ...state,
        chats: {
          ...state.chats,
          [chatId]: updateMessageList(messageList, updateInfo, updateMsgListOptions)
        }
      };
    }
    case actionConstants.CHAT_GET_ALL_PEOPLE_INIT:
      return {
        ...state,
        isAllPeopleApiLoading: true,
        isAllPeopleApiError: false,
        allPeopleApiError: ''
      };
    case actionConstants.CHAT_GET_ALL_PEOPLE_FAILURE:
      return {
        ...state,
        isAllPeopleApiLoading: false,
        isAllPeopleApiError: true,
        allPeopleApiError: action.message
      };
    case actionConstants.CHAT_GET_ALL_PEOPLE_SUCCESS:
      if (action.extra.isPaginatedRequest) {
        return {
          ...state,
          isAllPeopleApiLoading: false,
          isAllPeopleApiError: false,
          allPeople: [...state.allPeople, ...action.payload.docs],
          page: action.payload.page,
        };
      }
      return {
        ...state,
        isAllPeopleApiLoading: false,
        isAllPeopleApiError: false,
        allPeople: action.payload.docs,
        page: action.payload.page,
        totalAllPeopleCount: action.payload.totalDocs,
      };
    case actionConstants.CHAT_CREATE_GROUP_CHAT_INIT:
    case actionConstants.CHAT_CREATE_INDIVIDUAL_CHAT_INIT:
      return {
        ...state,
        isUpdateChatSuccess: false,
        isUpdateChatError: false,
        isUpdateChatLoading: true,
        updateChatError: ''
      };
    case actionConstants.CHAT_CREATE_GROUP_CHAT_FAILURE:
    case actionConstants.CHAT_CREATE_INDIVIDUAL_CHAT_FAILURE:
      return {
        ...state,
        isUpdateChatSuccess: false,
        isUpdateChatError: true,
        isUpdateChatLoading: false,
        updateChatError: action.message
      };
    case actionConstants.CHAT_CREATE_INDIVIDUAL_CHAT_SUCCESS: {
      const { payload = [], extra = {} } = action;
      let newPeopleList = [];
      const newPersonInfo = {
        ...payload[0],
        senderName: extra.personName,
        senderId: extra.senderId,
        createdAt: new Date().toISOString()
      };
      const normalizedNewPerson = normalizePerson(newPersonInfo);
      const index = state.peopleList
        .findIndex(item => item.chatId === normalizedNewPerson.chatId);
      if (index === -1) {
        // case: chat is not pre-existing
        newPeopleList = [normalizedNewPerson].concat(state.peopleList);
      } else {
        newPeopleList = state.peopleList;
      }
      return {
        ...state,
        isUpdateChatSuccess: true,
        isUpdateChatError: false,
        isUpdateChatLoading: false,
        updateChatError: '',
        peopleList: newPeopleList,
        activeChatId: newPersonInfo.channelId
      };
    }
    case actionConstants.CHAT_CREATE_GROUP_CHAT_SUCCESS: {
      const { payload: { conversationData } } = action;
      const peopleList = [...state.peopleList];
      const normalizedNewPerson = normalizePerson(conversationData);
      const newPeopleList = updatePeopleList(peopleList, normalizedNewPerson);
      return {
        ...state,
        isUpdateChatSuccess: true,
        isUpdateChatError: false,
        isUpdateChatLoading: false,
        updateChatError: '',
        peopleList: newPeopleList,
        activeChatId: conversationData.channelId
      };
    }
    case actionConstants.CHAT_GET_ROOM_DETAILS_INIT:
      return {
        ...state,
        isChatRoomDetailsLoading: true
      };
    case actionConstants.CHAT_GET_ROOM_DETAILS_SUCCESS: {
      const chatRoomDetails = (action.payload && action.payload[0]) || {};
      return {
        ...state,
        chatRoomDetails,
        isChatRoomDetailsLoading: false
      };
    }
    case actionConstants.CHAT_UPDATE_ROOM_DETAILS_INIT:
      return {
        ...state,
        isUpdateChatSuccess: false,
        isUpdateChatError: false,
        isUpdateChatLoading: true,
        updateChatError: ''
      };
    case actionConstants.CHAT_UPDATE_ROOM_DETAILS_FAILURE:
      return {
        ...state,
        isUpdateChatSuccess: false,
        isUpdateChatError: true,
        isUpdateChatLoading: false,
        updateChatError: action.message
      };
    case actionConstants.CHAT_UPDATE_ROOM_DETAILS_SUCCESS: {
      // getting updated group details in 'membersRemoved' key from server
      if (action.extra.isSelfLeave) {
        const { chats, peopleList, activeChatId } = { ...state };
        chats[state.activeChatId] = undefined;
        const index = peopleList.findIndex(people => people.chatId === activeChatId);
        if (index > -1) {
          peopleList.splice(index, 1);
        }
        return {
          ...state,
          chats,
          peopleList,
          activeChatId: '',
          chatRoomDetails: {},
          isUpdateChatSuccess: true,
          isUpdateChatError: false,
          isUpdateChatLoading: false,
          updateChatError: '',
        };
      }
      const { membersRemoved: { groupName, channelId } = {} } = action.payload;
      const peopleList = [...state.peopleList];
      const updateInfo = { senderName: groupName, chatId: channelId };
      const newPeopleList = updatePeopleList(peopleList, updateInfo, { moveToTop: false });
      return {
        ...state,
        isUpdateChatSuccess: true,
        isUpdateChatError: false,
        isUpdateChatLoading: false,
        updateChatError: '',
        peopleList: newPeopleList
      };
    }
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
