import call from './apiRequest';
import apiEndpoints from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call for getting report categories
 */
const reportCategory = () => call({
  endpoint: apiEndpoints.reportCategory,
  authKey: getToken()
});

export default reportCategory;
