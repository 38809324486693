import actionConstants from '../constants/ActionConstants';
import { uploadImage } from '../../api';
import ActionDispatcher from '../../api/ActionDispatcher';

const uploadImageAction = imageDetails => (
  ActionDispatcher(
    uploadImage.bind(null, imageDetails),
    actionConstants.UPLOAD_IMAGE_SUCCESS,
    actionConstants.UPLOAD_IMAGE_FAILURE,
    actionConstants.UPLOAD_IMAGE_REQUEST, null)
);

export default uploadImageAction;
