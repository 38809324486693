import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';

/* eslint-disable */
class QrCodeScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facingMode: 'environment'
    }
  }

  flip = () => {
    if (this.state.facingMode === 'environment') {
      this.setState({
        facingMode: 'user'
      })
    } else {
      this.setState({
        facingMode: 'environment'
      });
    }
  }
  render() {
    return (
      <div>
        <div className='dis-flex mr-bt-16 flip-icon qrcode-flip-icon-sprite-icon'>
          <span className='material-icons md-36' onClick={this.flip}>
            flip
          </span>
        </div>
        <QrReader
          delay={3000}
          onError={this.props.handleError}
          onScan={(data) => {
            if (data) {
              this.props.handleSuccess(data)
            }
          }}
          facingMode={this.state.facingMode}
          style={{ width: '100%' }}
        />
        <p>{this.state.result}</p>
      </div>
    );
  }
}

QrCodeScanner.propTypes = {
  handleError: PropTypes.func,
  handleSuccess: PropTypes.func
};
export default QrCodeScanner;
