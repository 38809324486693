import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  isSuccess: false,
  success: '',
  notes: [],
  images: [],
  systemInfoList: [],
  systemInfoId: '', // This id is building info id.
  clientId: '',
};

export default function getSystemInfo(state = initialState, action) {
  switch (action.type) {
    case actionConstants.GET_SYSTEM_INFO_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actionConstants.GET_SYSTEM_INFO_SUCCESS:
      return {
        ...state,
        systemInfoList: action.payload.systemInfo,
        systemInfoId: action.payload._id,
        isSuccess: true,
        isFetching: false,
        success: action.payload.message,
        notes: action.payload.notes,
        images: action.payload.images,
        clientId: action.payload.clientId,
      };
    case actionConstants.GET_SYSTEM_INFO_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        isFetching: false,
        isSuccess: false,
        systemInfoList: [],
        success: '',
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
