import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  bugTicketData: [],
  isSuccess: false,
  totalCount: 0,
  Currentpage: 1,
  totalPages: 1
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.FETCH_TICKETS_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false
      };
    case actionConstants.FETCH_TICKETS_SUCCESS:
      return {
        ...state,
        bugTicketData: [...state.bugTicketData, ...action.payload.docs],
        isfetching: false,
        totalCount: action.payload.total,
        Currentpage: action.payload.page,
        totalPages: action.payload.pages,
        isSuccess: true
      };
    case actionConstants.FETCH_TICKETS_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false
      };
    case actionConstants.FETCH_TICKETS_CLEAR:
      return {
        ...state,
        bugTicketData: [],
        total: 0,
        error: ''
      };
    default:
      return state;
  }
};
