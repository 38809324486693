import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import '../../../../components/DnDTable/DnDTable.scss';
import Checkbox from '../../../../components/Checkbox';

function JobListRow(props) {
  const {
    customerName,
    frequency,
    id,
    jobId,
    name,
    reportCategory,
    type,
    allDetails,
  } = props.row;

  const { jobsList } = props.offlineMode;

  const typeId = (type === 'Work Order'
    ? 'WO'
    : type[0].toUpperCase());


  return (
    <tr
      className='job-details-hover offline-job-details'
    >
      <td className='offline-job-row'>
        <div data-tip data-for='happyFace' className='dnd-row offline-dnd-row'>
          <div className='c-checkboc'>
            <label className="cust-checkbox">
              {name}
              <Checkbox
                name={id}
                onChange={(checkBoxName, checked) => (
                  props.setSelectedJobsClients(allDetails, checked)
                )}
                checked={Object.keys(jobsList).includes(id)}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div><span className='popover-quote offline-popover-quote' id={typeId}>{typeId}</span></div>
        </div>
        <ReactTooltip id='happyFace' type='none' place="left" effect="solid">
          <span className='job-details-hover-text'>
            <ul>
              <li>Name:- {customerName}</li>
              <li>Id:- {jobId}</li>
              <li>Category:- {reportCategory}</li>
              <li>Type:- {type}</li>
              <li>Frequency:- {frequency}</li>
            </ul>
          </span>
        </ReactTooltip>
      </td>
    </tr>
  );
}

JobListRow.propTypes = {
  row: PropTypes.object,
  task: PropTypes.object,
  setSelectedJobsClients: PropTypes.func,
  offlineMode: PropTypes.object,
};

export default JobListRow;
