import actionConstants from '../constants/ActionConstants';

const setNotificationCount = count => dispatch => (
  dispatch({
    type: actionConstants.SET_NOTIFICATION_COUNT,
    payload: count
  })
);

export default setNotificationCount;
