import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';

const ReportSelectionModalBody = () => (
    <div>
      <div className="dis-flex f-dir-column mr-top-24">

        <div>
            <label htmlFor="usr">
              <FormattedMessage
                id='Do you want to start default or Dynamic reports?'
                defaultMessage='Do you want to start default or Dynamic reports?'
              />
            </label>
          </div>
        </div>
    </div>
);

const ReportSelectionModalFooter = ({ handleClickDefaultReport, handleClickDynamicReport }) => (
  <div className="cust-select-btns mr-top-24">
    <Button
      text='Default'
      color='#6AA14E'
      onBtnClick={handleClickDefaultReport}
    />
     <Button
      text='Dynamic'
      color='#6AA14E'
      onBtnClick={handleClickDynamicReport}
    />
    </div>

);

ReportSelectionModalFooter.propTypes = {
  handleClickDynamicReport: PropTypes.func,
  handleClickDefaultReport: PropTypes.func
};

export { ReportSelectionModalBody, ReportSelectionModalFooter };
