import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { date, checkPermission } from '../../_helpers/commonFunctions';
import { reportConstants } from '../../libs/constants/reportConstants';
import { subPermissions } from '../../libs/constants/permissions';

/**
 * Returns answers for downloading reports
 * @param {object} answersData
 */
const getAnswers = (answersData) => {
  let answers = {};
  if (answersData) {
    const { Devices } = answersData;
    if (Devices && Devices.length) {
      Devices.forEach((device) => {
        answers = {
          ...answers,
          ...device,
        };
      });
    } else {
      answers = {
        ...answersData
      };
    }
  }
  delete answers.id;
  delete answers.child;
  return answers;
};

const disabledLinkStyle = {
  cursor: 'not-allowed',
  pointerEvents: 'none',
  opacity: 0.5,
  left: '-27px'
};

/**
 * Renders each row of table in filters report list table.
 * @param {*} props
 */
const ReportFilterListTableBody = (props) => {
  const {
    row,
    handleDelete,
    handleShowProfile,
    handleEditReport,
    downloadReportPDF,
    emailReportPDF,
    isReportNeeded,
  } = props;
  const {
    createdForName,
    createdAt,
    firstCompletionDate,
    completionDate,
    _id,
    status,
    reportJson,
    answers,
    updateHistory,
    isLegacyReport,
    pdfUrl
  } = row;

  const downloadNewReport = () => {
    downloadReportPDF({
      htmlJson: reportJson,
      answers: getAnswers(answers),
      projectName: _id
    });
  };

  const downloadButton = pdfUrl ? (
    <a href={pdfUrl}>
      <span
        className="download sprite-icon"
        style={pdfUrl === 'error' ? disabledLinkStyle : { left: '-27px' }}
      ></span>
    </a>
  ) : (
    <span
      className="download sprite-icon"
      style={{ left: '-27px' }}
      onClick={downloadNewReport}
    ></span>
  );

  return (
    <tr>
      <td>
        <FormattedMessage
          id={row.frequency || 'NA'}
          defaultMessage={row.frequency || 'NA'}
        />
      </td>
      <td>{status !== reportConstants.completed
        ? date(createdAt)
        : firstCompletionDate ? date(firstCompletionDate) : date(completionDate)}</td>
      <td>{createdForName}</td>
      <td>
        {isReportNeeded === false
          ? <span className={isReportNeeded !== false ? 'Subscibe' : 'Subscibe report-not-needed'}>
            <FormattedMessage
              id='Completed'
              defaultMessage='Completed' />
            {isReportNeeded === false && <span
              className="material-icons material-icon-checkmark-report"
            >
              check_box
          </span>}
          </span>
          : <>
            <span className="ellipses-icon"></span>
            <span className="open-icons">
              <span
                className="user sprite-icon"
                style={(status !== reportConstants.completed) ? { left: '-55px' } : { left: '-44px' }}
                onClick={() => handleShowProfile(updateHistory)}></span>
              {status === reportConstants.completed && <span
                className="email sprite-icon"
                style={{ left: '-35px' }}
                onClick={() => emailReportPDF(_id)}
              ></span>}
              {status === reportConstants.completed && downloadButton }
              {(status !== reportConstants.completed
                || checkPermission(subPermissions.editDeleteReports))
                && !isLegacyReport && <span
                  className="delete-icon sprite-icon"
                  style={(status !== reportConstants.completed) ? { right: '26px', top: '35%' } : { right: '0px', top: '35%' }}
                  onClick={() => handleDelete(_id, createdForName)}></span>}
              {(status !== reportConstants.completed
                || checkPermission(subPermissions.editDeleteReports))
                && !isLegacyReport && <span
                  className="edit-icon sprite-icon"
                  style={(status !== reportConstants.completed) ? { left: '-1px', top: '35%' } : { right: '-24px', top: '35%' }}
                  onClick={() => handleEditReport(row)}></span>}
            </span>
          </>}
      </td>
    </tr>
  );
};

ReportFilterListTableBody.propTypes = {
  row: PropTypes.object,
  handleDelete: PropTypes.func,
  handleShowProfile: PropTypes.func,
  handleEditReport: PropTypes.func,
  downloadReportPDF: PropTypes.func,
  clearReportPdf: PropTypes.func,
  emailReportPDF: PropTypes.func,
  isReportNeeded: PropTypes.any,
};

export default ReportFilterListTableBody;
