import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  isSuccess: false,
  isEditMode: false,
  isEdit: false,
  partData: [],
  isfetchingDelete: false,
  isSuccessDelete: false,
  successDelete: '',
  successMsg: '',
  errorDelete: '',
};

export default function addPartsDetailsStore(state = initialState, action) {
  switch (action.type) {
    case actionConstants.INVENTORY_ADD_PART_INFO_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false
      };
    case actionConstants.INVENTORY_ADD_PART_INFO_SUCCESS:
      return {
        ...state,
        isfetching: false,
        success: action.payload,
        successMsg: action.message,
        isSuccess: true,
      };
    case actionConstants.INVENTORY_ADD_PART_INFO_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.INVENTORY_ADD_PART_INFO_RESET:
      return {
        ...initialState
      };
    /** EDIT Part Reducer */
    case actionConstants.INVENTORY_EDIT_PART_INFO_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false
      };
    case actionConstants.INVENTORY_EDIT_PART_INFO_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.INVENTORY_EDIT_PART_INFO_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        partData: action.payload,
        successMsg: action.message,
        success: action.message
      };

    case actionConstants.INVENTORY_EDIT_PART_INFO_RESET:
      return {
        ...initialState
      };

    /** Get parts Details */
    case actionConstants.INVENTORY_PARTS_EDIT_MODE:
      return {
        ...state,
        isEditMode: true
      };
    case actionConstants.INVENTORY_GET_PART_INFO_REQUEST:
      return {
        ...state,
        isfetching: true,
        isEdit: false,
        isEditMode: true
      };
    case actionConstants.INVENTORY_GET_PART_INFO_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.INVENTORY_GET_PART_INFO_SUCCESS:
      return {
        ...state,
        isfetching: false,
        partData: action.payload,
        success: action.message,
        isEdit: true,
      };

    case actionConstants.INVENTORY_GET_PART_INFO_RESET:
      return {
        ...state,
        isEdit: false,
      };

      /** Delete Part Info */

    case actionConstants.INVENTORY_DELETE_PART_INFO_REQUEST:
      return {
        ...state,
        isfetchingDelete: true,
        isSuccessDelete: false
      };
    case actionConstants.INVENTORY_DELETE_PART_INFO_FAILURE:
      return {
        ...state,
        errorDelete: action.message,
        isfetchingDelete: false,
      };
    case actionConstants.INVENTORY_DELETE_PART_INFO_SUCCESS:
      return {
        ...state,
        isSuccessDelete: true,
        successDelete: action.message
      };

    case actionConstants.INVENTORY_DELETE_PART_INFO_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
