import actionConstants from '../constants/ActionConstants';

const deleteReportPdf = {
  error: '',
  isFetching: false,
  isSuccess: false,
  success: '',
};

const emailReportPdf = {
  error: '',
  isFetching: false,
  isSuccess: false,
  success: '',
};

const initialState = {
  error: '',
  isFetching: false,
  reportPdf: [],
  isSuccess: false,
  success: '',
  deleteReportPdf,
  emailReportPdf,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.DOWNLOAD_REPORT_PDF_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
        error: '',
        success: '',
      };
    case actionConstants.DOWNLOAD_REPORT_PDF_SUCCESS:
      return {
        ...state,
        reportPdf: action.payload,
        isFetching: false,
        isSuccess: true,
        success: action.message,
      };
    case actionConstants.DOWNLOAD_REPORT_PDF_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false
      };
    case actionConstants.DOWNLOAD_REPORT_PDF_CLEAR:
      return {
        ...initialState
      };
    case actionConstants.DELETE_REPORT_PDF_REQUEST:
      return {
        ...state,
        deleteReportPdf: {
          isFetching: true,
          isSuccess: false,
          error: '',
          success: '',
        },
      };
    case actionConstants.DELETE_REPORT_PDF_SUCCESS:
      return {
        ...state,
        deleteReportPdf: {
          isFetching: false,
          isSuccess: true,
          success: action.message,
        },
      };
    case actionConstants.DELETE_REPORT_PDF_FAILURE:
      return {
        ...state,
        deleteReportPdf: {
          error: action.message,
          isFetching: false
        },
      };
    case actionConstants.EMAIL_REPORT_PDF_REQUEST:
      return {
        ...state,
        emailReportPdf: {
          isFetching: true,
          isSuccess: false,
          error: '',
          success: '',
        },
      };
    case actionConstants.EMAIL_REPORT_PDF_SUCCESS:
      return {
        ...state,
        emailReportPdf: {
          isFetching: false,
          isSuccess: true,
          success: action.message,
        },
      };
    case actionConstants.EMAIL_REPORT_PDF_FAILURE:
      return {
        ...state,
        emailReportPdf: {
          error: action.message,
          isFetching: false
        },
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...initialState,
        reportPdf: state.reportPdf,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
