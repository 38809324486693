import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PageLoader from '../PageLoader';
import ToolTip from '../ToolTip';

/**
 * Returns table style
 * @param {number} index
 * @param {object} dat
 * @param {object} firstChildStyle
 */
const getStyle = (index, data, firstChildStyle) => {
  let style = {};
  if (index === 0) {
    style = { ...firstChildStyle };
  }
  if (data.width) {
    style.width = data.width;
  }
  return style;
};

export const Table = ({
  headerData,
  bodyData,
  RowToRender,
  loadMore,
  hasMoreItems,
  Footer,
  sortData,
  height,
  serviceRequestTable,
  isEndMessageVisible,
  isLoaderVisible,
  endMessage,
  columnSpan,
  RowToRenderProps,
  firstChildStyle,
  mainTableClass,
  mainTableSubClass,
  contentBoxClass,
  showHeader,
  StaticRowToRender,
  staticRowProps,
  shouldRenderStaticRow,
  showFullTableLoader,
  rowClassName,
  className,
  intl,
}) => (
    <div className={`table-responsive ${mainTableClass} ${serviceRequestTable} global-table ${contentBoxClass} ${mainTableSubClass}`}>
      <table className={`table table-striped table-sm ${rowClassName}`}>
        {/* ------------------------------------TABLE HEADER-------------------------------- */}
        <thead>
          <tr>
            <th style={{ padding: '0 20px 0 20px', background: 'transparent' }}>
              <table width="100%">
                <tbody>
                  {(window.innerWidth > 767 || showHeader) && <tr>
                    {headerData.map((header, index) => (
                      <th
                        colSpan={columnSpan}
                        className={header.isSortable ? 'arrow' : null}
                        key={header.title + index} // key can be made unique
                        style={getStyle(index, header, firstChildStyle)}
                      >
                        <ToolTip
                          id={header.dataFor}
                          place='left'
                        />
                        {header.isSortable
                          ? <span
                            id='test-span'
                            onClick={() => sortData(index)} style={{ cursor: 'pointer' }}
                            data-tip={header.dataTip
                              && intl.formatMessage({ id: header.dataTip })}
                            data-for={header.dataFor}
                          >
                            <FormattedMessage
                              id={header.title}
                              defaultMessage={header.title}
                              data-tip={header.dataTip
                                && intl.formatMessage({ id: header.dataTip })}
                              data-for={header.dataFor}
                            />
                          </span>
                          : <span
                            data-tip={header.dataTip
                              && intl.formatMessage({ id: header.dataTip })}
                            data-for={header.dataFor}><FormattedMessage
                              id={header.title}
                              defaultMessage={header.title}
                            />
                          </span>
                        }
                      </th>
                    ))}
                  </tr>}
                </tbody>
              </table>
            </th>
          </tr>
        </thead>
        {/* ---------------------------------- TABLE BODY ----------------------------------- */}
        <tbody>
          <tr>
            <td colSpan={columnSpan} style={{ padding: '20px', background: '#ffffff' }}>
              <table width="100%" className="accordion-table">
                <tbody>
                  {shouldRenderStaticRow
                    && <StaticRowToRender
                      {...staticRowProps}
                    />}
                </tbody>
              </table>
              <InfiniteScroll
                dataLength={bodyData.length}
                next={loadMore}
                hasMore={hasMoreItems}
                height={height}
                className={className}
                endMessage={isEndMessageVisible
                  ? <div className="end-message">
                    <FormattedMessage
                      id={endMessage}
                      defaultMessage={endMessage}
                    />
                  </div>
                  : ''
                }
              >
                {showFullTableLoader
                  ? <PageLoader />
                  : (
                    <table width="100%" className="accordion-table">
                      <tbody>
                        {bodyData && bodyData.map((row, index) => (
                          row && <RowToRender
                            key={row._id || row + index}
                            row={row} index={index}
                            {...RowToRenderProps}
                          />
                        ))}
                      </tbody>
                    </table>
                  )}
                {
                  !showFullTableLoader && isLoaderVisible ? <PageLoader /> : null
                }
              </InfiniteScroll>
              {Footer ? <Footer /> : null}
            </td>
          </tr>
        </tbody>
      </table>
    </div>);

Table.propTypes = {
  headerData: PropTypes.array, // array containing table header items
  bodyData: PropTypes.array, // array containing data to be rendered in rows
  RowToRender: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]), // component injected as row in table
  loadMore: PropTypes.func, // function to handle pagination
  hasMoreItems: PropTypes.bool, // is true if the items can be fetched further
  Footer: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]), // footer component to be injected in table
  sortData: PropTypes.func, // function to handle data sorting
  height: PropTypes.number, // height of table
  isEndMessageVisible: PropTypes.bool, // is true if end message in the table is visible
  isLoaderVisible: PropTypes.bool, // is true if the loader is visible
  endMessage: PropTypes.string, // end message to be shown
  columnSpan: PropTypes.number, // column span
  RowToRenderProps: PropTypes.object, // props to be sent to RowToRender component
  firstChildStyle: PropTypes.object, // style to be applied on first child of he table
  padding: PropTypes.string,
  mainTableClass: PropTypes.string,
  contentBoxClass: PropTypes.string,
  mainTableSubClass: PropTypes.string,
  StaticRowToRender: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]), // Component used as static row for table
  staticRowProps: PropTypes.object, // props to be sent to StaticRowToRender
  shouldRenderStaticRow: PropTypes.bool, // is true if static row is to be rendered
  showFullTableLoader: PropTypes.bool, // is true if full table loader is to be shown
  className: PropTypes.string,
  serviceRequestTable: PropTypes.string,
  rowClassName: PropTypes.string,
  intl: intlShape.isRequired,
  showHeader: PropTypes.bool
};

Table.defaultProps = {
  headerData: [],
  bodyData: [],
  RowToRender: undefined,
  loadMore: () => { },
  hasMoreItems: false,
  Footer: undefined,
  sortData: () => { },
  height: (window.innerHeight - 390),
  isEndMessageVisible: false,
  isLoaderVisible: true,
  endMessage: 'NO_MORE_DATA',
  columnSpan: 7,
  RowToRenderProps: {},
  firstChildStyle: {},
  mainTableClass: 'customer-table',
  contentBoxClass: ' content-box',
  mainTableSubClass: 'bind-info-table',
  StaticRowToRender: undefined,
  staticRowProps: {},
  shouldRenderStaticRow: false,
  showFullTableLoader: false,
  serviceRequestTable: '',
  showHeader: false
};

export default injectIntl(Table);
