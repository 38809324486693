import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import fileDownload from 'react-file-download';
import ReportFilterList from '../../layouts/ReportFilterList';
import getReportListAction, { getServiceDueList, getServiceDueForCount } from '../../redux/actions/getReportList';
import { deleteReportAction } from '../../redux/actions/deleteReport';
import clearState from '../../redux/actions/clearState';
import { displayToast, reportRouter } from '../../_helpers/commonFunctions';
import { setSubMenuVisibility } from '../../redux/actions/setSelectedCustomer';
import formReportDetails from '../../redux/actions/addReportCat';
import { clearHardCodeInspectionState } from '../../redux/actions/customReports';
import { setAnswer, clearAnswers, clearInspectionState } from '../../redux/actions/updateAnswer';
import endpoints from '../../routes';
import {
  downloadReportPDF,
  clearReportPdf,
  deleteReportPDF,
  emailReportPDF,
} from '../../redux/actions/downloadReportPDF';
import Loader from '../../components/Loader';
import { reportFilters } from '../../utils/Constants';
import { fetchServiceDueList } from '../../api';
import SetIsPromptAction from '../../redux/actions/isPromptAction';

let page = 1;
const LIMIT = 10;
const FIRSTLIMIT = 50;

const reportFilter = {
  Quotes: 'Quote',
  'Work Orders': 'Work Order',
  Inspections: 'Inspection',
};

export class CustomerReportFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecordDelete: false,
      selectedFilterName: 'Inspections',
      isDeleteModalVisible: false,
      isShowProfile: false,
      profileDetails: null,
      isExportingServiceDueList: false,
    };
    this.childRef = React.createRef();
  }

  componentDidMount() {
    page = 1;
    const { name } = this.props.match.params;
    const id = reportRouter(name);
    const clientId = this.props.setSelectedCustomer.selectedCustomerId;
    const initialFilterToFetchList = {
      status: 'Completed',
      reportCategory: id,
      reportType: 'Inspection',
      createdFor: clientId,
      page,
      limit: FIRSTLIMIT,
      reportId: null,
      isLegacyReport: true,
    };
    this.props.getReportListAction(initialFilterToFetchList);
    this.props.setSubMenuVisibility(true);
    this.props.getServiceDueForCount({ createdFor: clientId });
  }

  /**
   * Handles toast display for response of report pdf download
   *
   * @memberof CustomerReportFilter
   */
  handleResponseForReportPdfDownload = (prevResponse) => {
    const {
      success, error, reportPdf, emailReportPdf
    } = this.props.reportPdf;
    if (success && prevResponse.success !== success) {
      displayToast(success, 'success');
      const _reportPdf = typeof reportPdf === 'object' ? reportPdf.path : reportPdf;
      const pdfWindow = window.open(_reportPdf);
      const timer = setInterval(() => {
        const windowUrl = _reportPdf.split('/');
        const reportId = windowUrl[windowUrl.length - 1].split('.')[0];
        if (pdfWindow.closed) {
          this.props.deleteReportPDF(reportId);
          clearInterval(timer);
        }
      }, 60000);
    } else if (error && prevResponse.error !== error) {
      displayToast(error, 'error');
    }
    if (emailReportPdf.isSuccess
      && emailReportPdf.isSuccess !== prevResponse.emailReportPdf.isSuccess) {
      displayToast(emailReportPdf.success, 'success');
      if (this.childRef.current) {
        this.childRef.current.toggleRecipientsModalVisibility(false);
        this.childRef.current.setReportId(null);
      }
    } else if (emailReportPdf.error
      && emailReportPdf.error !== prevResponse.emailReportPdf.error) {
      displayToast(emailReportPdf.error, 'error');
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { isSuccessDelete, successMsg, error } = this.props.deleteReport;
    if (isSuccessDelete && prevProps.deleteReport.isSuccessDelete !== isSuccessDelete) {
      displayToast(successMsg, 'success');
      const { name } = this.props.match.params;
      const id = reportRouter(name);
      const clientId = this.props.setSelectedCustomer.selectedCustomerId;
      const filterToFetchList = {
        status: 'Completed',
        reportCategory: id,
        reportType: reportFilter[this.state.selectedFilterName] || this.state.selectedFilterName,
        createdFor: clientId,
        page,
        limit: FIRSTLIMIT,
        reportId: null,
        isLegacyReport: true
      };
      if (this.state.selectedFilterName === reportFilters.serviceDue) {
        this.props.getServiceDueList({ page, limit: FIRSTLIMIT, createdFor: clientId });
      } else {
        this.props.getReportListAction(filterToFetchList);
      }
      this.props.clearState();
    } else if (error && prevProps.deleteReport.error !== error) {
      displayToast(error, 'error');
      this.props.clearState();
    }
    if (location.pathname !== prevProps.location.pathname) {
      this.setState({ selectedFilterName: 'Inspections' });
    }
    this.handleResponseForReportPdfDownload(prevProps.reportPdf);
  }

  showFilterList = (filterName) => {
    this.setState({ selectedFilterName: filterName }, () => {
      page = 1;
      const { name } = this.props.match.params;
      const id = reportRouter(name);
      const clientId = this.props.setSelectedCustomer.selectedCustomerId;
      const selectedFilter = this.state.selectedFilterName;
      const initialFilterToFetchList = {
        status: 'Completed',
        reportCategory: id,
        reportType: selectedFilter === 'Incomplete Reports'
          ? selectedFilter
          : selectedFilter.substring(0,
            selectedFilter.length - 1),
        createdFor: clientId,
        page,
        limit: FIRSTLIMIT,
        isLegacyReport: true
      };
      if (this.state.selectedFilterName === reportFilters.serviceDue) {
        this.props.getServiceDueList({ page, limit: FIRSTLIMIT, createdFor: clientId });
      } else {
        this.props.getReportListAction(initialFilterToFetchList);
      }
    });
  };

  handleDelete = (reportId) => {
    this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible, reportId });
  }

  handleConfirmDelete = () => {
    const { reportId } = this.state;
    this.props.deleteReportAction(reportId);
    this.setState({ isDeleteModalVisible: false, reportId: null });
  }

  handleShowProfile = (profileDetails) => {
    this.setState({ isShowProfile: !this.state.isShowProfile, profileDetails });
  }

  handleClickSave = () => {
    const { name } = this.props.match.params;
    const id = reportRouter(name);
    const clientId = this.props.setSelectedCustomer.selectedCustomerId;
    if (this.state.selectedFilterName !== 'Incomplete Reports' && this.state.selectedFilterName !== 'Service Due') {
      let formData = {};
      formData = {
        typeValue: this.state.selectedFilterName.substring(0,
          this.state.selectedFilterName.length - 1),
        catValue: id,
        customerValue: clientId
      };
      this.props.clearAnswers();
      this.props.clearInspectionState();
      this.props.clearHardCodeInspectionState();
      this.props.formReportDetails(formData);
      this.props.history.push(endpoints.reportClientDetail);
    }
  }

  handleEditReport = (row) => {
    let formData = {};
    if (row.reportType !== 'Inspection') {
      formData = {
        typeValue: row.reportType,
        catValue: row.reportCategory,
        customerValue: row.createdFor
      };
    }
    if (row.reportType === 'Inspection') {
      formData = {
        typeValue: row.reportType,
        catValue: row.reportCategory,
        customerValue: row.createdFor,
        frequency: row.frequency,
        reportTemplate: {
          reportJson: row.reportJson
        }
      };
      this.props.clearInspectionState();
      this.props.clearAnswers();
      if (row.answers) {
        this.props.setAnswer({ answers: row.answers });
      }
    }
    this.props.formReportDetails(formData);
    this.props.history.push(`/reportDetail/${row._id}`, { selectedReport: 'Default' });
  }

  loadMore = (isExportList) => {
    page += 1;
    const { name } = this.props.match.params;
    const id = reportRouter(name);
    const clientId = this.props.setSelectedCustomer.selectedCustomerId;
    const filterToFetchList = {
      status: 'Completed',
      reportCategory: id,
      reportType: reportFilter[this.state.selectedFilterName] || this.state.selectedFilterName,
      createdFor: clientId,
      page,
      limit: LIMIT,
      reportId: null,
      isLegacyReport: true
    };
    if (isExportList) {
      page -= 1;
      this.exportServiceDueList();
    } else if (this.state.selectedFilterName === reportFilters.serviceDue) {
      this.props.getServiceDueList({ page, limit: LIMIT, createdFor: clientId });
    } else {
      this.props.getReportListAction(filterToFetchList);
    }
  }

  exportServiceDueList = () => {
    this.setState({
      isExportingServiceDueList: true
    });
    fetchServiceDueList({ download: true })
      .then((data) => {
        if (data) {
          this.setState({
            isExportingServiceDueList: false
          });
        }
        fileDownload(data.body, 'ServicesDue.xlsx');
      }, (err) => {
        displayToast(err.message, 'error');
        this.setState({
          isExportingServiceDueList: false
        });
      });
  }

  render() {
    const {
      isRecordDelete,
      selectedFilterName,
      isDeleteModalVisible,
      isShowProfile,
      profileDetails,
      isExportingServiceDueList
    } = this.state;
    const {
      getReportList,
      reportPdf,
      deleteReport,
      servicesDueDetails,
    } = this.props;
    const {
      serviceDueList
    } = servicesDueDetails;
    return (
      <>
        {(reportPdf.isFetching
          || deleteReport.isfetching
          || isExportingServiceDueList
          || reportPdf.emailReportPdf.isFetching)
          && <Loader />}
        <ReportFilterList
          ref={this.childRef}
          isRecordDelete={isRecordDelete}
          isShowProfile={isShowProfile}
          showFilterList={this.showFilterList}
          selectedFilterName={selectedFilterName}
          profileDetails={profileDetails}
          tableBodyData={selectedFilterName === reportFilters.serviceDue
            ? serviceDueList.serviceDueList
            : getReportList.reportData}
          hasMoreItems={selectedFilterName === reportFilters.serviceDue
            ? !serviceDueList.isfetching && (serviceDueList.page < serviceDueList.pages)
            : page < getReportList.pages}
          isFetchingList={selectedFilterName === reportFilters.serviceDue
            ? serviceDueList.isfetching
            : getReportList.isfetching}
          isDeleteModalVisible={isDeleteModalVisible}
          handleDelete={this.handleDelete}
          handleConfirmDelete={this.handleConfirmDelete}
          handleClickSave={this.handleClickSave}
          handleShowProfile={this.handleShowProfile}
          downloadReportPDF={this.props.downloadReportPDF}
          clearReportPdf={this.props.clearReportPdf}
          handleEditReport={this.handleEditReport}
          servicesDueDetails={servicesDueDetails}
          loadMore={this.loadMore}
          emailReportPDF={this.props.emailReportPDF}
          clientDetails={this.props.clientDetails}
          SetIsPromptAction={this.props.SetIsPromptAction}
          isReportNeeded={this.props.isReportNeeded}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    setSelectedCustomer: state.setSelectedCustomer,
    getReportList: state.getReportList,
    deleteReport: state.deleteReport,
    reportPdf: state.reportPdf,
    servicesDueDetails: state.servicesDueDetails,
    clientDetails: state.getClientOrOwnerDetails.clientOrOwnerData,
    isReportNeeded: (state.auth.login.user || {}).isReportNeeded,
  };
}

CustomerReportFilter.propTypes = {
  setSelectedCustomer: PropTypes.object,
  getReportListAction: PropTypes.func,
  deleteReportAction: PropTypes.func,
  clearState: PropTypes.func,
  getReportList: PropTypes.object,
  match: PropTypes.object,
  formReportDetails: PropTypes.func,
  deleteReport: PropTypes.object,
  location: PropTypes.object,
  setSubMenuVisibility: PropTypes.func,
  history: PropTypes.array,
  downloadReportPDF: PropTypes.func,
  clearReportPdf: PropTypes.func,
  reportPdf: PropTypes.object,
  setAnswer: PropTypes.func,
  clearAnswers: PropTypes.func,
  clearInspectionState: PropTypes.func,
  servicesDueDetails: PropTypes.object,
  getServiceDueList: PropTypes.func,
  getServiceDueForCount: PropTypes.func,
  deleteReportPDF: PropTypes.func,
  emailReportPDF: PropTypes.func,
  clearHardCodeInspectionState: PropTypes.func,
  clientDetails: PropTypes.object,
  SetIsPromptAction: PropTypes.func,
  isReportNeeded: PropTypes.any,
};

export default connect(mapStateToProps, {
  getReportListAction,
  deleteReportAction,
  clearState,
  formReportDetails,
  setSubMenuVisibility,
  downloadReportPDF,
  clearReportPdf,
  setAnswer,
  clearAnswers,
  clearInspectionState,
  getServiceDueList,
  getServiceDueForCount,
  deleteReportPDF,
  emailReportPDF,
  clearHardCodeInspectionState,
  SetIsPromptAction,
})(CustomerReportFilter);
