import actionConstants from '../constants/ActionConstants';

const serviceDueList = {
  error: '',
  isfetching: false,
  success: false,
  pages: 0,
  page: 0,
  total: 0,
  serviceDueList: [],
};

const serviceDueForCount = {
  error: '',
  isFetching: false,
  isSuccess: false,
  serviceDueForCount: [],
};

const initialState = {
  serviceDueList,
  serviceDueForCount,
};

export default function servicesDueDetails(state = initialState, action) {
  switch (action.type) {
    case actionConstants.GET_SERVICE_DUE_LIST_REQUEST:
      return {
        ...state,
        serviceDueList: {
          ...state.serviceDueList,
          isfetching: true,
          error: '',
          success: false,
        }
      };
    case actionConstants.GET_SERVICE_DUE_LIST_SUCCESS:
      return {
        ...state,
        serviceDueList: {
          ...state.serviceDueList,
          serviceDueList: action.payload.page === 1
            ? [...action.payload.docs]
            : [...state.serviceDueList.serviceDueList, ...action.payload.docs],
          isfetching: false,
          success: true,
          pages: action.payload.pages,
          page: action.payload.page,
          total: action.payload.total,
        }
      };
    case actionConstants.GET_SERVICE_DUE_LIST_FAILURE:
      return {
        ...state,
        serviceDueList: {
          ...state.serviceDueList,
          error: action.message,
          isfetching: false,
          success: false,
        }
      };

    case actionConstants.GET_SERVICE_DUE_FOR_COUNT_REQUEST:
      return {
        ...state,
        serviceDueForCount: {
          ...state.serviceDueForCount,
          isFetching: true,
          error: '',
          isSuccess: false,
        }
      };
    case actionConstants.GET_SERVICE_DUE_FOR_COUNT_SUCCESS:
      return {
        ...state,
        serviceDueForCount: {
          ...state.serviceDueForCount,
          serviceDueForCount: [...action.payload],
          isFetching: false,
          isSuccess: true,
        }
      };
    case actionConstants.GET_SERVICE_DUE_FOR_COUNT_FAILURE:
      return {
        ...state,
        serviceDueForCount: {
          ...state.serviceDueForCount,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        }
      };

    case actionConstants.CLEAR_STATE:
      return {
        ...initialState,
        serviceDueList: {
          ...initialState.serviceDueList,
          serviceDueList: [...state.serviceDueList.serviceDueList],
        },
        serviceDueForCount: {
          ...initialState.serviceDueForCount,
          serviceDueForCount: state.serviceDueForCount.serviceDueForCount,
        },
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
