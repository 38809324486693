import ActionConstants from '../constants/ActionConstants';

export default function locale(state = { lang: 'en' }, action = {}) {
  switch (action.type) {
    case ActionConstants.SET_LOCALE_EN:
      return {
        ...state,
        lang: 'en'
      };
    case ActionConstants.SET_LOCALE_ES:
      return {
        ...state,
        lang: 'es'
      };
    default:
      return state;
  }
}
