import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import '../TaskListLayout/TaskLayout.css';
import PropTypes from 'prop-types';

export class IncompleteReportLayoutRow extends React.Component {
  /**
   * check if particular report is already openend or not
   * @param {*} id - report id
   */
  checkReport(id) {
    const IdsArray = [...this.props.reports];
    const statusOfId = IdsArray.some(el => (el._id === id));
    return statusOfId;
  }

  render() {
    const {
      row, setSelectedReportEditId, setClassVisibility,
      country,
      isClassVisible,
    } = this.props;
    const idToExpand = Object.keys(isClassVisible);
    const isIdToExpand = isClassVisible[idToExpand[0]];
    const isIdExist = this.checkReport(row._id);
    return (
      <tr
        id='test-tr'
        className={
          row._id === idToExpand[0] && isIdToExpand ? 'accordion-opened' : ''
        }
        style={
          row._id === idToExpand[0] && isIdToExpand
            ? !isIdExist ? { maxHeight: '1000px', cursor: 'pointer' } : { maxHeight: '1000px', pointerEvents: 'none', cursor: 'none' }
            : !isIdExist ? { maxHeight: '60px', cursor: 'pointer' } : { maxHeight: '60px', pointerEvents: 'none' }
        }
        onClick={() => setSelectedReportEditId(row)}
      >
        <td id="">
          <h3>
            <FormattedMessage
              id='Category'
              defaultMessage='Category' />
          </h3>
          <FormattedMessage
            id={row.reportCategory}
            defaultMessage={row.reportCategory}
          />
        </td>

        <td id="">
          <h3>
            <FormattedMessage
              id='Start Date'
              defaultMessage='Start Date' />
          </h3>
          {moment(row.createdAt).format(country ==="united states"? 'MM-DD-YYYY': 'DD-MM-YYYY')}
        </td>
        <td id="">
          <h3>
            <FormattedMessage
              id='Modified Date'
              defaultMessage='Modified Date' />
          </h3>
          {moment(row.updatedAt).format(country ==="united states"? 'MM-DD-YYYY': 'DD-MM-YYYY')}</td>
        <span className="icon-accordion" onClick={() => setClassVisibility(row._id)}>
          {
            row._id === idToExpand[0] && isIdToExpand ? '-' : '+'
          }
        </span>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  return {
    reports: state.updateAnswer.reportData,
    country: state.auth.login.user.businessAddress.country.toLowerCase()
  };
}


IncompleteReportLayoutRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  reportHeading: PropTypes.string,
  setSelectedReportInfoId: PropTypes.func,
  setSelectedReportEditId: PropTypes.func,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func,
  reports: PropTypes.array,
  country: PropTypes.string
};


export default connect(mapStateToProps, {})(IncompleteReportLayoutRow);
