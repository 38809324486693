import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import ProfilePhoto from './ProfilePhoto';

const GROUP_NAME_MAX_LEN = 25;
const LIMIT = 100;

const PersonRow = ({
  firstName,
  lastName,
  id,
  onClick
}) => {
  const fullName = `${firstName}${lastName ? ` ${lastName}` : ''}`;
  return (
    <li className="clearfix" onClick={() => onClick(id, fullName)}>
      <ProfilePhoto label={fullName} />
      <div className="about">
        <div className="name">{fullName}</div>
      </div>
    </li>
  );
};

PersonRow.propTypes = {
  firstName: PropTypes.any,
  id: PropTypes.any,
  lastName: PropTypes.any,
  onClick: PropTypes.any
};

const PersonTag = ({
  firstName,
  lastName,
  id,
  onClick
}) => {
  const fullName = `${firstName}${lastName ? ` ${lastName}` : ''}`;
  return (
    <div className="search-chip" onClick={() => onClick(id)}>
      {fullName}
      <span className="sprite-icon" />
    </div>
  );
};

PersonTag.propTypes = {
  firstName: PropTypes.any,
  id: PropTypes.any,
  lastName: PropTypes.any,
  onClick: PropTypes.any
};

class StartChatModal extends Component {
  initialState = {
    selectedPeople: new Set(),
    groupName: ''
  }

  state = { ...this.initialState };

  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  componentDidMount() {
    this.props.getAllPeopleToChat(false, 1, LIMIT);
    document.addEventListener(
      'mousedown',
      this.handleClick
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mousedown',
      this.handleClick
    );
  }

  handleClick = (e) => {
    if (this.modal.current.contains(e.target)) {
      return;
    }
    this.props.hideChatModal();
  };

  handlePersonRowClick = (personId, personName) => {
    const { isGroupChatEnabled, createIndividualChat, hideChatModal } = this.props;
    if (isGroupChatEnabled) {
      this.selectPerson(personId);
    } else {
      createIndividualChat(({ personId, personName }));
      hideChatModal();
    }
  }

  selectPerson = (personId) => {
    this.setState(prevState => ({
      selectedPeople: new Set(prevState.selectedPeople).add(personId)
    }));
  }

  deselectPerson = (personId) => {
    this.setState((prevState) => {
      const selectedPeople = new Set(prevState.selectedPeople);
      selectedPeople.delete(personId);
      return {
        selectedPeople
      };
    });
  }

  onGroupNameChange = (e) => {
    const value = e.target.value.trimLeft().slice(0, GROUP_NAME_MAX_LEN);
    this.setState({ groupName: value });
  }

  createGroupChat = () => {
    const { groupName, selectedPeople } = this.state;
    const { createGroupChat, currentUser = {}, hideChatModal } = this.props;
    createGroupChat({
      groupName,
      members: [...selectedPeople, currentUser.customerId]
    });
    hideChatModal();
    this.resetState();
  }

  loadMore = () => {
    this.props.getAllPeopleToChat(true, this.props.page + 1, LIMIT);
  }

  resetState = () => {
    this.setState({
      ...this.initialState
    });
  }

  render() {
    const {
      hideChatModal,
      isGroupChatEnabled,
      allPeople,
      isVisible,
      totalAllPeopleCount,
      intl,
    } = this.props;
    const { selectedPeople, groupName } = this.state;
    return (
      <div className={`modal fade ${isVisible ? 'show' : ''}`} id="newchat" style={{ display: isVisible ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" ref={this.modal}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                <FormattedMessage
                  id={'Start New Chat'}
                  defaultMessage={'Start New Chat'}
                />
              </h4>
              <button type="button" className="close sprite-icon" data-dismiss="modal" onClick={hideChatModal}></button>
            </div>
            <div className="modal-body">
              {isGroupChatEnabled && <div className="create-group-chat">
                <div className="create-group-head">
                  <h6>
                    <FormattedMessage
                      id={'Create New Group'}
                      defaultMessage={'Create New Group'}
                    />
                  </h6>
                  <span className="icon sprite-icon1 crate-group-icon ">
                  </span>
                </div>
                <hr className="seperator" />
                <div className="form-group dis-flex f-dir-row">
                  <input spellCheck='true' type="text" value={groupName} onChange={this.onGroupNameChange} placeholder={intl.formatMessage({ id: 'Enter group name' })} className="form-control" id="usr" name="username" />
                  {groupName && selectedPeople.size >= 1 && (
                    <button onClick={this.createGroupChat}>
                      <FormattedMessage
                        id={'Create'}
                        defaultMessage={'Create'}
                      />
                    </button>
                  )}
                </div>
                <div className="chat-person-tag-container">
                  {allPeople.map(item => selectedPeople.has(item._id) && <PersonTag
                    key={item._id}
                    firstName={item.firstName}
                    lastName={item.lastName}
                    id={item._id}
                    onClick={this.deselectPerson}
                  />
                  )}
                </div>
              </div>}
              <div className="people-list open-new-chat-list">
                <ul className="list">
                  <InfiniteScroll
                    dataLength={allPeople.length}
                    next={this.loadMore}
                    hasMore={totalAllPeopleCount > allPeople.length}
                    height={700}
                  >
                    {allPeople.map(item => !selectedPeople.has(item._id) && <PersonRow
                      key={item._id}
                      firstName={item.firstName}
                      lastName={item.lastName}
                      id={item._id}
                      onClick={this.handlePersonRowClick}
                    />
                    )}
                  </InfiniteScroll>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StartChatModal.propTypes = {
  allPeople: PropTypes.array.isRequired,
  createGroupChat: PropTypes.func.isRequired,
  createIndividualChat: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  getAllPeopleToChat: PropTypes.func.isRequired,
  hideChatModal: PropTypes.func.isRequired,
  isGroupChatEnabled: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  totalAllPeopleCount: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(StartChatModal);
