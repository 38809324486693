import React from 'react';
import PropTypes from 'prop-types';
import './JobDetailPopOver.scss';

function JobDetailPopOver(props) {
  const {
    title,
    start,
    end,
    eventItem,
  } = props;

  return (
    <div className="calender-date-popover">
      <div className="popover-title">{title}</div>
      <div className="calender-pop-date">
        <div className="start-date">
          <span className="popover-hour">{start.format('HH:mm')}</span>
          <span className="popover-day">{start.format('DD MMM')}</span>
        </div>
        -
        <div className="end-date">
          <span className="popover-hour">{end.format('HH:mm')}</span>
          <span className="popover-day">{end.format('DD MMM')}</span>
        </div>
      </div>
      <span className="popover-quote" id={`${eventItem.state.type[0].toUpperCase()}`}>{
        eventItem.state.type === 'Work Order'
          ? 'WO'
          : eventItem.state.type[0].toUpperCase()
      }</span>
      <span className="popover-type">{eventItem.state.reportCategory}</span>
    </div>
  );
}

JobDetailPopOver.propTypes = {
  title: PropTypes.string,
  start: PropTypes.object,
  end: PropTypes.object,
  statusColor: PropTypes.string,
  eventItem: PropTypes.object,
};

export default JobDetailPopOver;
