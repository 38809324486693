import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: false,
  pages: 0,
  reportTemplateData: []
};

export default function getReportTemplateList(state = initialState, action) {
  switch (action.type) {
    case actionConstants.GET_REPORT_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
        error: '',
        success: '',
      };
    case actionConstants.GET_REPORT_TEMPLATE_LIST_SUCCESS:

      return {
        ...state,
        reportTemplateData: action.payload.page === 1 ? action.payload.docs
          : [...state.reportTemplateData, ...action.payload.docs],
        isfetching: false,
        success: true,
        pages: action.payload.pages,
      };
    case actionConstants.GET_REPORT_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
        success: false,
      };
    case actionConstants.CLEAR_REPORT_TEMPLATE_SUCCESS_STATE:
      return {
        ...state,
        error: '',
        success: false,
        isfetching: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        success: '',
        isfetching: false,
        reportTemplateData: [],
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
