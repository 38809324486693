import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import ToolTip from '../ToolTip/index';
// import { prototype } from 'react-tooltip';

export const ServiceRequestListFilterDropDownContainer = (props) => {
  const {
    dataFor, dataTip, place, children, isOpened, toggleOpen,
  } = props;
  return (
    <div className="dropdown filter-dropdown">
      <button className="btn btn-default dropdown-toggle" type="button" onClick={toggleOpen}
        data-for={dataFor && props.intl.formatMessage({ id: dataFor })}
        data-tip={dataTip && props.intl.formatMessage({ id: dataTip })}>
        <FormattedMessage
          id='Filter'
          defaultMessage='Filter'
        />
        <span className="caret"></span>
      </button>
      <ToolTip id={dataFor && props.intl.formatMessage({ id: dataFor })} place={place} />
      {isOpened ? children : null}
    </div>
  );
};

ServiceRequestListFilterDropDownContainer.propTypes = {
  children: PropTypes.object,
  isOpened: PropTypes.bool,
  toggleOpen: PropTypes.func,
  dataFor: PropTypes.string,
  dataTip: PropTypes.string,
  place: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(ServiceRequestListFilterDropDownContainer);
