const toasterMessages = {
  partExistsError: 'Part already exists',
  labourExistsError: 'Labor already exists',
  finishReport: 'Report submitted successfully',
  success: ' Report updated successfully',
  signatureAdded: 'Signature added successfully',
  validationMessage: 'All questions/fields must be filled',

};

export default toasterMessages;
