import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  payload: {},
  customerDetails: null,
  companyAddressImageURL: {},
  companyLogoImageURL: {},
  isSuccess: false,
};

export default (state = initialState, action) => {
  let userSubscriptionDetails = {};
  let subscriptions = {};
  switch (action.type) {
    case actionConstants.FETCH_EMPLOYESS_DETAIL_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
        error: '',
      };
    case actionConstants.FETCH_SUBSCRIPTIONS:
      subscriptions = action.payload.newSubscriptions
        ? action.payload.newSubscriptions
        : action.payload;
      userSubscriptionDetails = { ...subscriptions };
      if (Object.prototype.hasOwnProperty.call(userSubscriptionDetails, 'balanceAmount')) {
        userSubscriptionDetails = {
          _id: subscriptions._id,
          balanceAmount: subscriptions.balanceAmount,
          stripeUserId: subscriptions.stripeUserId,
          subscriptions: subscriptions.subscriptions,
        };
      }
      return {
        ...state,
        customerDetails: {
          userSubscriptionId: userSubscriptionDetails,
        },
        isfetching: false,
        isSuccess: true
      };
    case actionConstants.FETCH_EMPLOYESS_DETAIL_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        customerDetails: {
          userSubscriptionId: action.payload.userSubscriptionId
        },
        companyAddressImageURL: action.payload.companyAddressImageURL || {},
        companyLogoImageURL: action.payload.companyLogoImageURL || {},
        isfetching: false,
        isSuccess: true
      };
    case actionConstants.FETCH_EMPLOYESS_DETAIL_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.FETCH_EMPLOYESS_DETAIL_RESET:
      return {
        ...state,
        isSuccess: false,
        error: '',
      };
    default:
      return state;
  }
};
