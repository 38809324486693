import ValidationMessage from './validationMessage';

const yup = require('yup');

const addSystemOrDeviceSchema = yup.object().shape({
  reportType: yup
    .string()
    .required(ValidationMessage.Required),
  systemDeviceType: yup
    .string()
    .required(ValidationMessage.Required),
  type: yup
    .string()
    .required(ValidationMessage.Required),
  otherDeviceType: yup.string().when('type', {
    is: 'Other',
    then: fieldSchema => fieldSchema.required(ValidationMessage.Required)
  }),
  quantity: yup
    .number()
    .nullable()
    .test('len', ValidationMessage.Required, val => !(val === 0))
    .test('len', ValidationMessage.validQuantity, (val) => {
      const value = val && val.toString();
      if (typeof value === 'string' && (value.includes('-') || value.includes('e'))) {
        return false;
      }
      return true;
    })
    .test('len', ValidationMessage.quantityLength, val => (val <= 100000000000000000))
    .required(ValidationMessage.Required),
});

const importCustomerSchema = yup.object().shape({
  clientSheet: yup
    .string()
    .required(ValidationMessage.Required),
});

export default addSystemOrDeviceSchema;
export { importCustomerSchema };
