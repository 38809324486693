import superagent from 'superagent';
import config from '../config';

const { API } = config;
const BASE_URL_FOR_TYPE = {
  default: API.BASE_URL,
  chat: API.CHAT_SERVER_URL
};

/*
 * @function "call" common method that makes api requests
 * @param {object} "request" stores the request 'method','endpoint', 'payload', 'query',
 * 'token' as keys...'
 */
export default function call({
  method = 'get',
  url,
  endpoint,
  authKey,
  payload,
  query,
  type = 'application/json',
  responseType = '',
  baseUrlType = 'default',
  timeout
}) {
  const _url = `${BASE_URL_FOR_TYPE[baseUrlType]}/${endpoint}`;
  const request = superagent(method, endpoint ? _url : url)
    .set('Authorization', authKey);
  if (timeout) {
    request.timeout(timeout);
  }
  if (type !== 'multipart/form-data') {
    request.set('Content-Type', type);
  }
  if (responseType === 'blob') {
    request.responseType(responseType);
  }
  return request.send(payload).query(query);
}
