import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import ToolTip from '../ToolTip/index';

const checkIsCustomOptionVisible = (option, createdFor) => (
  option.isCustom
    ? (option.createdFor === undefined // This one time
      ? true
      : (option.createdFor === '' // On all reports for all customers
        ? true
        : option.createdFor === createdFor)) // Every time for this customer
    : false
);

export class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectFieldValue: this.props.value || '',
      showDropDown: false
    };
  }

  node = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleShowDropDown = () => {
    this.setState(prevstate => ({ showDropDown: !prevstate.showDropDown }));
  }

  handleClick = (e) => {
    if (this.node.current.contains(e.target)) {
      return;
    }
    this.setState({
      showDropDown: false
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ selectFieldValue: this.props.value });
    }
  }

  render() {
    const { selectFieldValue, showDropDown } = this.state;
    const {
      dataFor,
      dataTip,
      place,
      dropDownList,
      label,
      width,
      handleOnChange,
      name,
      error,
      className,
      // height,
      optionClassName,
      placeholder,
      style,
      disabledField,
      labelClass,
      showDropDownItemObjectKey,
      errorClassName,
      extras,
      createdFor,
    } = this.props;
    return (
      <Fragment>
        <div
          className={className}
          data-for={dataFor}
          data-tip={dataTip && this.props.intl.formatMessage({ id: dataTip })}
        >
          <span className={`${errorClassName} errorMessage mr-top-8`} style={style}>
            {error ? <FormattedMessage id={error} defaultMessage={error} /> : ''}
          </span>
          <div
            className='custom-select'
            style={{ width: `${width}` }}
            ref={this.node}
          >
            <div
              className={`select-selected ${
                showDropDown ? 'select-arrow-active' : ''}`}
              onClick={!this.props.isDisabled ? this.handleShowDropDown : undefined}
              placeholder={placeholder}
            >
              <FormattedMessage
                id={selectFieldValue || placeholder || ' '}
                defaultMessage={selectFieldValue || placeholder || ' '}
              />
            </div>
            <div
              style={{ overflow: 'auto', maxHeight: window.outerWidth > 800 ? 0.2 * window.outerWidth : 0.6 * window.outerHeight }}
              className={`select-items ${showDropDown ? '' : 'select-hide'}`}
              data-mcs-theme='minimal-dark'
            >
              {
                Object.keys(dropDownList).length === 0
                && <div
                  className={`${optionClassName} no-option-dropValue`}
                >
                  <FormattedMessage
                    id={'No data avilable'}
                    defaultMessage={'No data available'} />
                </div>
              }
              {dropDownList.map((dropDownItem, index) => (
                ((dropDownItem.isCustom
                  && checkIsCustomOptionVisible(dropDownItem, createdFor))
                  || !dropDownItem.isCustom)
                  && <div
                  id={dropDownItem + index}
                  style={dropDownItem === disabledField ? { pointerEvents: 'none' } : {}}
                  className={`${optionClassName} selecthover`}
                  key={JSON.stringify(dropDownItem) + index}
                  onClick={(e) => {
                    this.setState(
                      {
                        showDropDown: !showDropDown
                      },
                    );
                    handleOnChange(
                      name,
                      (typeof (dropDownItem) === 'object'
                        ? dropDownItem
                        : e.target.textContent),
                      index
                    );
                  }
                  }
                >
                  {typeof (dropDownItem) === 'object'
                    ? dropDownItem[showDropDownItemObjectKey]
                    : dropDownItem
                  }
                </div>
              ))}
            </div>
          </div>
          <label htmlFor='usr' className={labelClass}>
            {label
              ? <>
              <FormattedMessage id={label} defaultMessage={label} />
              {extras && extras.additionalQuestionText}
              </>
              : ''}
          </label>
        </div>
        <ToolTip id={dataFor} place={place} />
      </Fragment>
    );
  }
}

DropDown.propTypes = {
  dropDownList: PropTypes.array,
  label: PropTypes.string,
  width: PropTypes.string,
  name: PropTypes.string,
  handleOnChange: PropTypes.func,
  error: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.string,
  optionClassName: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  disabledField: PropTypes.string,
  labelClass: PropTypes.string,
  showDropDownItemObjectKey: PropTypes.string,
  errorClassName: PropTypes.string,
  dataFor: PropTypes.string,
  dataTip: PropTypes.string,
  place: PropTypes.string,
  intl: intlShape.isRequired,
  createdFor: PropTypes.string,
  extras: PropTypes.any,
};

DropDown.defaultProps = {
  width: '100%',
  error: '',
  className: 'dis-flex f-dir-column mr-top-24',
  isDisabled: false,
  label: ' ',
  disabledField: undefined,
  labelClass: '',
  showDropDownItemObjectKey: 'name',
  errorClassName: '',
};

export default injectIntl(DropDown);
