const API = {
  BASE_URL: 'https://staging.api4.firelabinc.com/firelab/api/v1',
  CLIENT_URL: 'https://staging.client4.firelabinc.com/login',
  DOWNLOAd_FILE_URL: 'https://staging.api4.firelabinc.com',
  IPIFY: 'https://api.ipify.org/?format=json',
  IP_STACK: 'https://api.ipstack.com',
  GEO_CODE_API: 'https://maps.googleapis.com/maps/api/geocode/json',
  WAY_POINT_API: 'https://maps.googleapis.com/maps/api/directions/json',
  DISTANCE_MATRIX_API: 'https://maps.googleapis.com/maps/api/distancematrix/json',
  MAP_KEY: 'AIzaSyBvnzovCy-dw338jC6I-tnT_4jKOX54ePg',
  GEO_LOCATION_KEY: 'AIzaSyAzBUrghYgZZBXT0ibg9JGKTW05PzSq5Bs',
  IPSTACK_KEY: 'eb09c597a778f259af2515ca6989dfdd',
  CHAT_SERVER_URL: 'https://staging.chat4.firelabinc.com/api/v1',
  CHAT_SOCKET_URL: 'https://staging.chat4.firelabinc.com',
  BASE_SOCKET_URL: 'https://staging.api4.firelabinc.com:8752',
  INTERCOM_APP_ID: 'i6qvv3ht'
};

const DEV_TOOLS = {
  logError: true
};

module.exports = {
  API,
  DEV_TOOLS
};
