import actionConstants from '../constants/ActionConstants';
import { addNotesApiCall } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const addNotes = (buildingInfoId, notes) => (
  ActionDispatcher(addNotesApiCall.bind(null, buildingInfoId, notes),
    actionConstants.ADD_NOTES_SUCCESS,
    actionConstants.ADD_NOTES_FAILURE,
    actionConstants.ADD_NOTES_REQUEST,
  )
);

export default addNotes;
