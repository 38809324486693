import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import endpoints from '../../routes';


export const TransactionListRow = (props) => {
  const {
    transactionId, userName, asset,
    transactionType, createdAt, _id,
  } = props.row;
  const { country } = props;
  const idToExpand = Object.keys(props.isClassVisible);
  const isIdToExpand = props.isClassVisible[idToExpand[0]];
  return (
    <tr className={
      transactionId === idToExpand[0] && isIdToExpand ? 'accordion-opened' : ''
    }
      style={
        transactionId === idToExpand[0] && isIdToExpand
          ? { maxHeight: '1000px', cursor: 'pointer' }
          : { maxHeight: '60px', cursor: 'pointer' }
      }>
      <td onClick={() => props.history.push({
        pathname: `${endpoints.transactionDetails}`,
        state: {
          _id
        }
      })}><h3><FormattedMessage id='Transaction id' defaultMessage='Transaction id' /></h3><span >{transactionId || '-'}</span></td>
      <td onClick={() => props.history.push({
        pathname: `${endpoints.transactionDetails}`,
        state: {
          _id
        }
      })}><h3><FormattedMessage id='User Name' defaultMessage='User Name' /></h3>{userName || '-'}</td>
      <td onClick={() => props.history.push({
        pathname: `${endpoints.transactionDetails}`,
        state: {
          _id
        }
      })}><h3><FormattedMessage id='Date' defaultMessage='Date' /></h3>{moment(createdAt).format(country ==="united states"? 'MM-DD-YYYY': 'DD-MM-YYYY')}</td>
      <td onClick={() => props.history.push({
        pathname: `${endpoints.transactionDetails}`,
        state: {
          _id
        }
      })}><h3><FormattedMessage id='Asset' defaultMessage='Asset' /></h3>
        <FormattedMessage
          id={asset || '-'}
          defaultMessage={asset || '-'}
        />
      </td>
      <td onClick={() => props.history.push({
        pathname: `${endpoints.transactionDetails}`,
        state: {
          _id
        }
      })}><h3><FormattedMessage id='Transaction Type' defaultMessage='Transaction type' /></h3>{
          <FormattedMessage
            id={transactionType || ' '}
            defaultMessage={transactionType || ' '}
          />
          || '-'}</td>
      <span className="icon-accordion" onClick={() => props.setClassVisibility(transactionId)}>
        {
          transactionId === idToExpand[0] && isIdToExpand ? '-' : '+'
        }
      </span>
    </tr>
  );
};
TransactionListRow.propTypes = {
  row: PropTypes.object,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func,
  detail: PropTypes.func,
  history: PropTypes.object,
  country: PropTypes.string,
};

const mapStateToProps = ({ auth })=> {
return {
    country: auth.login.user.businessAddress.country.toLowerCase()
  }
}

export default withRouter(connect(mapStateToProps)(TransactionListRow));
