/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import AriesMultipleChoice from 'aries-formio-components/dist/components/AriesMultipleChoice/AriesMultipleChoice';
import AriesTextInputMixin from 'aries-formio-components/dist/components/AriesTextInput/AriesTextInput';
import AriesFormHeader from 'aries-formio-components/dist/components/AriesFormHeader/AriesFormHeader';
import AriesSignature from 'aries-formio-components/dist/components/AriesSignature/AriesSignature';
import EditGrid from 'aries-formio-components/dist/components/EditGrid/EditGrid';

const components = {
  arieseditgrid: EditGrid,
  ariesformheader: AriesFormHeader,
  ariesmultiplechoice: AriesMultipleChoice,
  ariesnumberinput: AriesTextInputMixin({
    title: 'Aries Number Input', icon: 'calculator', componentType: 'ariesnumberinput', type: 'number'
  }),
  ariestextinput: AriesTextInputMixin({
    title: 'Aries Text Input', icon: 'font', componentType: 'ariestextinput', type: 'text'
  }),
  ariesdateinput: AriesTextInputMixin({
    title: 'Aries Date Input', icon: 'calendar', componentType: 'ariesdateinput', type: 'date'
  }),
  ariestimeinput: AriesTextInputMixin({
    title: 'Aries Time Input', icon: 'hourglass', componentType: 'ariestimeinput', type: 'time'
  }),
  ariessignature: AriesSignature,
};

export default (Formio, Components) => {
  console.log('Aries components setup');
  Components.setComponents(components);

  const originalDownloadFile = Formio.prototype.downloadFile;
  Formio.prototype.downloadFile = async function (file, options) {
    const ret = await originalDownloadFile.call(this, file, options);
    return {
      ...ret,
      url: `https://aries-report-attachments.s3.amazonaws.com/${ret.url}`,
    };
  };
};
