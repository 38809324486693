import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  success: '',
  isSuccess: false,
};

export default function toolDelete(state = initialState, action) {
  switch (action.type) {
    case actionConstants.DELETE_INVENTORY_TOOL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        success: action.message,
      };
    case actionConstants.DELETE_INVENTORY_TOOL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      };
    case actionConstants.DELETE_INVENTORY_TOOL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actionConstants.DELETE_INVENTORY_TOOL_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
