import React, { useState } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import './SingleDatePicker.scss';
import SetIsPromptAction from '../../redux/actions/isPromptAction';

export const DatePicker = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    date, onChange, openDirection, disabled, isOutsideRange, country
  } = props;
  if (isOutsideRange) {
    return (
      <SingleDatePicker
        date={date}
        readOnly
        openDirection={openDirection}
        onDateChange={(e) => {
          onChange(e);
          props.SetIsPromptAction(true);
        }}
        focused={isFocused}
        displayFormat={country ==="united states"? 'MM-DD-YYYY': 'DD-MM-YYYY'}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel={true}
        onFocusChange={({ focused }) => setIsFocused(focused)}
        id="currentDate"
        disabled={disabled}
        isOutsideRange={isOutsideRange}
        isDayHighlighted={currentDate => currentDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')}
      />
    );
  }
  return (
    <SingleDatePicker
      date={date}
      openDirection={openDirection}
      onDateChange={(e) => {
        onChange(e);
        props.SetIsPromptAction(true);
      }}
      displayFormat={currentValue => moment(currentValue).format(country ==="united states"? 'MM-DD-YYYY': 'DD-MM-YYYY')}
      focused={isFocused}
      numberOfMonths={1}
      hideKeyboardShortcutsPanel={true}
      onFocusChange={({ focused }) => setIsFocused(focused)}
      id="currentDate"
      disabled={disabled}
      isDayHighlighted={currentDate => currentDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')}
    />
  );
};

DatePicker.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func,
  openDirection: PropTypes.string,
  isOutsideRange: PropTypes.func,
  disabled: PropTypes.bool,
  SetIsPromptAction: PropTypes.func,
};

DatePicker.defaultProps = {
  onChange: () => { },
  openDirection: 'up',
  disabled: false
};
const mapStateToProps = ({ auth })=> {
return {
    country: auth.login.user.businessAddress.country.toLowerCase()
  }
}

export default connect(mapStateToProps, {
  SetIsPromptAction
})(DatePicker);
