import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../components/ReusableTable';
import CompleteReportLayoutRow from './CompleteReportLayoutRow';
import CompleteReportHeader from '../../components/CompleteReportHeader/CompleteReportHeader';

const incompleteHeaderFields = [
  {
    title: 'Customer Name',
    isSortable: true,
  },
  {
    title: 'Category',
    isSortable: false,
  },
  {
    title: 'Start Date',
    isSortable: false,
  },
  {
    title: 'Modified Date',
    isSortable: false,
  }
];

/**
 * complete report layout
 */
const CompleteReportLayout = ({
  setSearchText, setFilters,
  handleSearch, status, reportType, filter, setReportType,
  sortData, reportList, loadMore, isfetching, hasMoreItems, endMessage,
  isEndMessageVisible, searchText, isCrossVisible, reportHeading,
  setSelectedReportInfoId, setSelectedReportEditId, isClassVisible,
  setClassVisibility
}) => (
    <React.Fragment>
      <CompleteReportHeader name={reportHeading} setSearchText={setSearchText}
        isCrossVisible={isCrossVisible}
        searchText={searchText}
        setFilters={setFilters}
        setReportType={setReportType}
        handleSearch={handleSearch}
        status={status}
        reportType={reportType}
        filter={filter}
        />

      <Table
        sortData={sortData}
        headerData={incompleteHeaderFields}
        bodyData={reportList}
        RowToRender={CompleteReportLayoutRow}
        RowToRenderProps={{
          reportHeading,
          setSelectedReportInfoId,
          setSelectedReportEditId,
          isClassVisible,
          setClassVisibility,
        }}
        loadMore={loadMore}
        endMessage={endMessage}
        isLoaderVisible={isfetching}
        mainTableClass={'customer-table report-listing-table completed-report-table-coachmark'}
        hasMoreItems={hasMoreItems}
        isEndMessageVisible={isEndMessageVisible}
      />
    </React.Fragment>

);
CompleteReportLayout.propTypes = {
  setSearchText: PropTypes.func,
  setFilters: PropTypes.func,
  isCrossVisible: PropTypes.bool,
  handleSearch: PropTypes.func,
  status: PropTypes.array,
  reportType: PropTypes.string,
  filter: PropTypes.array,
  setDatePeriod: PropTypes.func,
  setReportType: PropTypes.func,
  sortData: PropTypes.func,
  reportList: PropTypes.array,
  loadMore: PropTypes.func,
  isfetching: PropTypes.bool,
  hasMoreItems: PropTypes.bool,
  endMessage: PropTypes.string,
  isEndMessageVisible: PropTypes.bool,
  searchText: PropTypes.string,
  reportHeading: PropTypes.string,
  setSelectedReportInfoId: PropTypes.func,
  setSelectedReportEditId: PropTypes.func,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func
};

export default CompleteReportLayout;
