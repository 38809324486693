import ActionTypes from '../redux/constants/ActionConstants';
import { store } from '../redux/store/initStore';

const logout = () => {
  const { auth } = store.getState();
  const user = auth.login && auth.login.user;
  if (user && user.token) {
    store.dispatch({
      type: ActionTypes.RESET_ALL_REDUCERS,
    });
  }
};

export default logout;
