import Socket from './permissionsSubscriptionsSocket';
import EVENT from './reportSocketEvents';

function updateAnswerEvent(updatedAnswers) {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.UPDATE_ANSWER, updatedAnswers);
}

function reportStartEvent(message) {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.REPORT_START, message);
}

function reportUpdated(handleReportUpdated) {
  const socket = new Socket().getInstance();
  socket.on(EVENT.REPORT_UPDATED, handleReportUpdated);
}

function reportEndEvent(message) {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.REPORT_END, message);
}
export {
  reportUpdated,
  updateAnswerEvent,
  reportStartEvent,
  reportEndEvent
};
