import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  reportData: {},
  isSuccess: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.FETCH_REPORT_CATEGORY_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false
      };
    case actionConstants.FETCH_REPORT_CATEGORY_SUCCESS:
      return {
        ...state,
        reportData: action.payload,
        isfetching: false,
        isSuccess: true
      };
    case actionConstants.FETCH_REPORT_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false
      };
    case actionConstants.FETCH_REPORT_CATEGORY_CLEAR:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
