import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  isSuccess: false,
  employeeDetailData: [],
  totalCount: null,
  totalPages: null,
  currentPage: null,
};

function fetchGPSEmployeeDetail(state = initialState, action) {
  switch (action.type) {
    case actionConstants.FETCH_GPS_EMPLOYEE_DETAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actionConstants.FETCH_GPS_EMPLOYEE_DETAIL_INITIAL_REQUEST:
      return {
        ...state,
        employeeDetailData: [],
      };
    case actionConstants.FETCH_GPS_EMPLOYEE_DETAIL_SUCCESS:
      return {
        ...state,
        employeeDetailData: [...state.employeeDetailData, ...action.payload.docs],
        totalCount: action.payload.total,
        totalPages: action.payload.pages,
        currentPage: action.payload.page,
        isFetching: false,
        isSuccess: true,
      };
    case actionConstants.FETCH_GPS_EMPLOYEE_DETAIL_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false,
        isSuccess: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isSuccess: false,
      };
    default:
      return state;
  }
}

export default fetchGPSEmployeeDetail;
