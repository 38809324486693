import io from 'socket.io-client';
import config from '../config';
import { getToken } from '../_helpers/commonFunctions';

class Socket {
  static instance = null;

  constructor() {
    if (Socket.instance === null) {
      const { API } = config;
      Socket.instance = io(`${API.CHAT_SOCKET_URL}?token=${getToken()}`);
    }
  }

  removeInstance = () => { Socket.instance = null; };

  getInstance = () => Socket.instance;
}

export default Socket;
