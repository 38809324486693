import call from './apiRequest';
import { getToken } from '../_helpers/commonFunctions';
import apiEndpoints from './apiEndpoints';

/**
 * Api call for getting part list
 * @param {number} page
 * @param {number} limit
 * @param {string} searchText
 * @param {string} supplierId
 * @param {string} reportType
 */
const partListDropDown = (page, limit = 10, searchText, supplierId = '', reportType = '') => {
  /** In case supplierId and report Type is empty not required remove these fields */
  let endpoint = `${apiEndpoints.partListDropDown}?page=${page}&limit=${limit}&search=${searchText}`;
  if (supplierId) {
    endpoint += `&supplierId=${supplierId}`;
  }
  if (reportType) {
    endpoint += `&reportType=${reportType}`;
  }
  return call({
    method: 'get',
    endpoint,
    authKey: getToken(),
  });
};

/**
 * Api call for getting part list with search text
 * @param {number} page
 * @param {string} searchText
 */
const partDropDown = (page, searchText) => {
  const payload = {};
  return call({
    method: 'post',
    endpoint: `${apiEndpoints.partListDropDown}?page=${page}&search=${searchText}`,
    payload,
    authKey: getToken(),
  });
};

export {
  partListDropDown, partDropDown
};
