import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  createReportData: {},
  reportMessage: '',
  isSuccess: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.CREATE_REPORT_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false
      };
    case actionConstants.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        createReportData: action.payload,
        reportMessage: action.message,
        isfetching: false,
        isSuccess: true
      };
    case actionConstants.CREATE_REPORT_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false
      };
    case actionConstants.CREATE_REPORT_CLEAR:
      return {
        ...state,
        error: '',
        isSuccess: false
      };
    default:
      return state;
  }
};
