import actionConstants from '../constants/ActionConstants';

const initialState = {
  isSuccess: false,
  isFetching: false,
  error: '',
  success: '',
  rowCount: null,
};

export default function getXLXSCount(state = initialState, action) {
  switch (action.type) {
    case actionConstants.GET_XLXS_COUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
        rowCount: 0,
        isSuccess: false,
      };
    case actionConstants.GET_XLXS_COUNT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: action.message,
        rowCount: action.payload.rowCount,
        isSuccess: true,
      };
    case actionConstants.GET_XLXS_COUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSuccess: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        success: '',
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
}
