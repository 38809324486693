import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import '../TaskListLayout/TaskLayout.css';
import PropTypes from 'prop-types';

/**
 * Complete Report Layout Row component
 */
const CompleteReportLayoutRow = (props) => {
  const {
    row, setSelectedReportEditId, setClassVisibility,
    isClassVisible,
    country
  } = props;

  const idToExpand = Object.keys(isClassVisible);
  const isIdToExpand = isClassVisible[idToExpand[0]];

  return (
    <tr
      className={
        row._id === idToExpand[0] && isIdToExpand ? 'accordion-opened test-class' : 'test-class'
      }
      style={
        row._id === idToExpand[0] && isIdToExpand
          ? { maxHeight: '1000px', cursor: 'pointer' }
          : { maxHeight: '60px', cursor: 'pointer' }
      }
      onClick={() => setSelectedReportEditId(row)
      }
    >
      <td id="">
        <h3>
          <FormattedMessage
            id='Customer Name'
            defaultMessage='Customer Name' />
        </h3>
        {row.createdForName}
      </td>

      <td id="">
        <h3>
          <FormattedMessage
            id='Category'
            defaultMessage='Category' />
        </h3>
        {row.reportCategory}
      </td>

      <td id="">
        <h3>
          <FormattedMessage
            id='Start Date'
            defaultMessage='Start Date' />
        </h3>
        {moment(row.createdAt).format(country ==="united states"? 'MM-DD-YYYY': 'DD-MM-YYYY')}
      </td>
      <td id="">
        <h3>
          <FormattedMessage
            id='Modified Date'
            defaultMessage='Modified Date' />
        </h3>
        {moment(row.updatedAt).format(country ==="united states"? 'MM-DD-YYYY': 'DD-MM-YYYY')}</td>
      <span className="icon-accordion" onClick={() => setClassVisibility(row._id)}>
        {
          row._id === idToExpand[0] && isIdToExpand ? '-' : '+'
        }
      </span>
    </tr>
  );
};
CompleteReportLayoutRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  reportHeading: PropTypes.string,
  setSelectedReportInfoId: PropTypes.func,
  setSelectedReportEditId: PropTypes.func,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func,
  country: PropTypes.string,
};

const mapStateToProps = ({ auth })=> {
return {
    country: auth.login.user.businessAddress.country.toLowerCase()
  }
}
export default connect(mapStateToProps)(CompleteReportLayoutRow);
