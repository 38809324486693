import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../../components/ReusableTable';
import TaskHeaders from '../../components/TaskHeader/TaskHeader';
import '../TaskListLayout/TaskLayout.css';
import TableHeadings from '../../utils/tableHeadings';
import TransactionListRows from './TransactionListRow';

const TransactionLayout = ({
  setSearchText, setFilters,
  handleSearch, assets, filter, detail,
  setDatePeriod, sortData, transactionList,
  loadMore, isfetching, hasMoreItems, endMessage,
  isEndMessageVisible, searchText, isCrossVisible,
  isClassVisible, setClassVisibility, reportCategories, transactions
}) => (
    <React.Fragment>
      <TaskHeaders name="Transactions"
      setSearchText={setSearchText}
        isCrossVisible={isCrossVisible}
        reportCategory={transactions}
        searchText={searchText}
        setFilters={setFilters}
        for = 'transactions'
        hideFilter = {false}
        maxDate={moment(new Date())}
        handleSearch={handleSearch}
        status={assets}
        filter={filter}
        setDatePeriod={setDatePeriod}
        reportCategories={reportCategories}
        />

      <Table
        sortData={sortData}
        headerData={TableHeadings.transactionTableHeading}
        bodyData={transactionList}
        RowToRender={TransactionListRows}
        loadMore={loadMore}
        endMessage={endMessage}
        isLoaderVisible={isfetching}
        mainTableSubClass= ' '
        hasMoreItems={hasMoreItems}
        isEndMessageVisible={isEndMessageVisible}
        RowToRenderProps ={{ isClassVisible, setClassVisibility, detail }}
      />
    </React.Fragment>

);
TransactionLayout.propTypes = {
  setSearchText: PropTypes.func,
  setFilters: PropTypes.func,
  isCrossVisible: PropTypes.bool,
  handleSearch: PropTypes.func,
  assets: PropTypes.array,
  reportType: PropTypes.string,
  filter: PropTypes.array,
  setDatePeriod: PropTypes.func,
  setReportType: PropTypes.func,
  sortData: PropTypes.func,
  transactionList: PropTypes.array,
  loadMore: PropTypes.func,
  isfetching: PropTypes.bool,
  hasMoreItems: PropTypes.bool,
  endMessage: PropTypes.string,
  isEndMessageVisible: PropTypes.bool,
  searchText: PropTypes.string,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func,
  reportCategories: PropTypes.object,
  transactions: PropTypes.any,
  detail: PropTypes.func
};

export default TransactionLayout;
