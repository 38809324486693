import React from 'react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  toast
} from 'react-toastify';

import {
  store
} from '../redux/store/initStore';
import ChatView from '../views/ChatView';
import validationMessage from './validator/validationMessage';
import {
  VALID_ZIP_CODE_REGEX_EXPRESSIONS, VALID_PHONE_NUMBERS, MONTH_LIST,
  RECURRING_INSPECTION_VALID_FREQUENCIES, RECURRING_INSPECTION_PARITY, Quarterly,
  resourceClasses, STATUS, roles, userStatuses, Administrative_Privileges
} from '../utils/Constants';
import CustomerReportFilters from '../views/CustomerReportFilter';
import endPoint from '../routes/endpoints';
import {
  subPermissions, permissions, planPermissions, subscriptionGrandParents
} from '../libs/constants/permissions';
import RedirectToCustomer from '../components/RedirectToCustomer';
import ActionConstants from '../redux/constants/ActionConstants';
import QrCodeScanner from '../views/QrCodescanner/QrCodeScanner';
import TaskListViews from '../views/TaskListView/index';
import MyJobsView from '../views/MyJobsView/MyJobsView';
import MissedInspectionViews from '../views/MissedInspectionView/MissedInspectionView';
import JobLink from '../views/JobLink/JobLink';
import ReportListDetail from '../views/Report/ReportListDetails';
import CompletedReport from '../views/Report/CompletedReports';
import InventoryTransactions from '../views/InventoryTransactionView/InventoryTransactionView';
import UserConfirmation from '../components/UserConfirmation/UserConfirmation';
import TransactionDetails from '../layouts/InventoryViewLayout/TransactionDetail';
import OfflineEnhanced from '../views/OfflineEnhanced/OfflineEnhanced';
import ClientDetailsReports from '../views/Report/ClientDetailsReport';

const Team = React.lazy(() => import('../views/Team'));
const Video = React.lazy(() => import('../views/Video'));
const QRcode = React.lazy(() => import('../views/QRcode/QRcode'));
const TimeCard = React.lazy(() => import('../views/TimeCard'));
const EditTeam = React.lazy(() => import('../views/EditTeam/EditTeam'));
const BugTicket = React.lazy(() => import('../views/BugTickets/BugTicket'));
const AddBugTicket = React.lazy(() => import('../views/BugTickets/AddBugTickets'));
const CustomerListView = React.lazy(() => import('../views/CustomerListView'));
const TeamGPS = React.lazy(() => import('../views/TeamGPS'));
const AddJob = React.lazy(() => import('../views/AddJob'));
const ServiceRequestView = React.lazy(() => import('../views/ServiceRequest'));
const CustomerOrOwnerView = React.lazy(() => import('../views/CustomerOrOwnerView'));
const AutoEmail = React.lazy(() => import('../views/AutoEmail/AutoEmail'));
const CustomerPortal = React.lazy(() => import('../views/CustomerPortal'));
const SystemInfo = React.lazy(() => import('../views/SystemInfo'));
const ImageUploadBuildingInfo = React.lazy(() => import('../views/ImageUploadBuildingInfo/ImageUploadBuildingInfo'));
const DeviceDetailView = React.lazy(() => import('../views/DeviceDetailView'));
const UploadDevice = React.lazy(() => import('../views/ClientDeviceListView'));
const BuildingInfo = React.lazy(() => import('../views/BuildingInfo'));
const UnderDevelopment = React.lazy(() => import('../components/UnderDevelopment'));
const Subscriptions = React.lazy(() => import('../views/Subscriptions'));
const ManageReports = React.lazy(() => import('../views/ManageReports/ManageReports'));
const CompanyLogo = React.lazy(() => import('../views/CompanyLogo/CompanyLogo'));
const ReportDetail = React.lazy(() => import('../views/Reports/ReportDetail'));
const NewReport = React.lazy(() => import('../views/Report/NewReport'));
// const ClientDetailsReports = React.lazy(() => import('../views/Report/ClientDetailsReport'));
const Setting = React.lazy(() => import('../views/Setting/Setting'));
const DeficiencyQuotes = React.lazy(() => import('../views/DeficiencyQuotes/DeficiencyQuotes'));
const DeficiencyWorkOrder = React.lazy(() => import('../views/DeficiencyWorkOrder/DeficiencyWorkOrder'));
const UserTimeSheet = React.lazy(() => import('../views/UserTimeSheet'));
const DeficiencyViews = React.lazy(() => import('../views/DeficiencyView/index '));
const TeamGPSEmployeeDetail = React.lazy(() => import('../views/TeamGPSEmployeeDetail'));
const InventoryVehicle = React.lazy(() => import('../views/InventoryVehicle'));
const AssignParts = React.lazy(() => import('../layouts/InventoryParts/AssignPart'));
const InventoryPartsView = React.lazy(() => import('../views/InventoryParts'));
const VehicleDetails = React.lazy(() => import('../layouts/InventoryVehicle/InventoryVehicleDetails'));
const InventoryTools = React.lazy(() => import('../views/InventoryTools'));
const RecurringInspection = React.lazy(() => import('../views/RecurringInspections/RecurringInspections'));
const ViewReports = React.lazy(() => import('../views/Reports/viewsReport'));
const CustomerHome = React.lazy(() => import('../views/CustomerHome'));
const PageNotFound = React.lazy(() => import('../components/PageNotFound'));
const AddSupplier = React.lazy(() => import('../views/AddSupplier/AddSupplier'));
const SupplierView = React.lazy(() => import('../views/InventorySupplier'));
const AddToolForm = React.lazy(() => import('../views/AddToolForm/AddToolForm'));
const InventoryToolAssign = React.lazy(() => import('../views/InventoryToolAssign/InventoryToolAssign'));
const TeamGPSEmployeeLocations = React.lazy(() => import('../views/TeamGPSEmployeeLocations/TeamGPSEmployeeLocations'));
const AutoRouting = React.lazy(() => import('../views/AutoRouting/AutoRouting'));
const EditProfile = React.lazy(() => import('../views/EditProfile'));
const PartInfo = React.lazy(() => import('../layouts/InventoryParts/PartInfo'));
const InventoryFlowChartView = React.lazy(() => import('../views/InventoryFlowChart'));

/**
 * Provides an array of sorted permissions
 * @param {object} allSubPermissions
 */
const sortSubPermissions = (allSubPermissions) => {
  let sortedPermissions = [...allSubPermissions];
  sortedPermissions = Object.values(sortedPermissions).sort();
  if (sortedPermissions.includes(Administrative_Privileges.Full_Administrative_Privileges)) {
    const index = sortedPermissions.findIndex(permission => (
      permission === Administrative_Privileges.Full_Administrative_Privileges
    ));
    sortedPermissions.splice(index, 1);
    sortedPermissions.unshift(Administrative_Privileges.Full_Administrative_Privileges);
  }
  return sortedPermissions;
};

export const getStatusForPlans = () => {
  const { auth } = store.getState();
  const { userSubscriptionDetails } = auth.login.user;
  const statusForPlans = {};
  if (userSubscriptionDetails.subscriptions) {
    userSubscriptionDetails.subscriptions.forEach((subscription) => {
      statusForPlans[subscription.planValue] = subscription.status;
    });
  }
  return statusForPlans;
};

/**
 * Provides an object for all sub permissions.
 * @param {object} allParentPermissions
 * @param {boolean} isSort
 * @param {boolean} isSetValue
 */
export const getSubPermissions = (
  allParentPermissions,
  isSort = false,
  isSetValue = false,
  getPermissionsForSubscribedPlans = false,
) => {
  const {
    auth,
  } = store.getState();
  let allSubPermissions = {};
  const { userSubscriptionDetails } = auth.login.user;
  Object.keys(allParentPermissions).forEach((permission) => {
    if (getPermissionsForSubscribedPlans) {
      if (userSubscriptionDetails
        && userSubscriptionDetails.subscriptions
        && userSubscriptionDetails.subscriptions[0]
        && userSubscriptionDetails.subscriptions[0].status === 'Active') {
        const activePlan = userSubscriptionDetails
          && userSubscriptionDetails.subscriptions
          && userSubscriptionDetails.subscriptions[0].planValue;
        allSubPermissions = {
          ...planPermissions[activePlan],
        };
      }
    } else {
      allSubPermissions = {
        ...allSubPermissions,
        ...allParentPermissions[permission].subPermissions
      };
    }
    if (!isSetValue) {
      allSubPermissions[permission] = allParentPermissions[permission].value;
    }
  });
  if (isSort) {
    allSubPermissions = sortSubPermissions(Object.values(allSubPermissions));
  }
  return allSubPermissions;
};

const checkIfPermissionIsSubscription = permission => (Object.values(permissions)
  .includes(permission)
);

const checkIfPlanIsSubscribed = (plan, parentPlan, subscribedPlans) => {
  let isPlanSubscribed = {};
  if (subscribedPlans && subscribedPlans.subscriptions) {
    isPlanSubscribed = subscribedPlans.subscriptions.find(planItem => (planItem.planValue === parentPlan) && planItem.status === 'Active');
  }
  if (isPlanSubscribed) {
    isPlanSubscribed = subscriptionGrandParents[parentPlan]
      && !(Object.values(subscriptionGrandParents[parentPlan]).includes(plan));
    return isPlanSubscribed;
  }
  return isPlanSubscribed && !isEmpty(isPlanSubscribed);
};

const isTeamMemberFullyAuthorized = (permission, authPermissions) => (
  authPermissions && authPermissions.includes(subPermissions.fullAdministrativePrivileges)
  && (permission !== subPermissions.viewDashboardFinancialAnalytics)
);

const isTeamMemberAuthorized = (permission, authPermissions) => (
  authPermissions && authPermissions.includes(permission)
);

/**
 * Returns a boolean to indicate whether the user has permission or not.
 * @param {string} permission
 * @param {string} parent
 */
export const checkPermission = (permission, parent = permissions.standard) => {
  const {
    auth
  } = store.getState();
  const {
    status, endDate, userSubscriptionDetails, role
  } = auth.login.user;

  let grandParent = userSubscriptionDetails
    && userSubscriptionDetails.subscriptions
    && userSubscriptionDetails.subscriptions.find(plan => (plan.status === 'Active'
      && Object.keys(subscriptionGrandParents) // to be removed after extra stripe plans get deleted
        .includes(plan.planValue)));

  if (grandParent && !isEmpty(grandParent)) {
    grandParent = grandParent.planValue;
  }

  const [customer, employee] = roles;
  const [trial, freeTrial, subscribed, cancelled] = userStatuses;

  const isStatusValid = moment(endDate).valueOf() >= moment().valueOf();

  const isSubscriptionPermission = checkIfPermissionIsSubscription(permission);
  const isParentSubscriptionPermission = checkIfPermissionIsSubscription(parent);

  const isPermissionSubscribed = checkIfPlanIsSubscribed(permission,
    grandParent, userSubscriptionDetails);
  const isParentsubscribed = checkIfPlanIsSubscribed(parent, grandParent, userSubscriptionDetails);

  const isTeamMemberAuthorised = isTeamMemberAuthorized(permission, auth.login.user.permissions);
  const hasFullAdminPrivileges = isTeamMemberFullyAuthorized(permission,
    auth.login.user.permissions);

  const isTrialUser = (status === trial || status === freeTrial) && isStatusValid;
  const isSubscribedUser = status === subscribed && isStatusValid;
  const isCancelledUser = status === cancelled;

  let isPermitted = false;

  if (role === customer) {
    if (isTrialUser) isPermitted = true;
    if (isSubscribedUser) {
      if (!isSubscriptionPermission) isPermitted = true;
      if (isSubscriptionPermission) {
        isPermitted = isPermissionSubscribed;
      }
    }
    if (isCancelledUser || !isStatusValid) {
      if (permission === subPermissions.addRemoveAriesFeature
        || permission === subPermissions.manageReports) {
        isPermitted = true;
      } else isPermitted = false;
    }
  } else if (role === employee) {
    if (isTrialUser) {
      if (isSubscriptionPermission) isPermitted = true;
      if (!isSubscriptionPermission) {
        if (hasFullAdminPrivileges) isPermitted = true;
        if (!hasFullAdminPrivileges) isPermitted = isTeamMemberAuthorised;
      }
    }
    if (isSubscribedUser) {
      if (isSubscriptionPermission) {
        isPermitted = isPermissionSubscribed;
      }
      if (!isSubscriptionPermission && isParentSubscriptionPermission) {
        if (isParentsubscribed) {
          if (hasFullAdminPrivileges) isPermitted = true;
          if (!hasFullAdminPrivileges) isPermitted = isTeamMemberAuthorised;
        }
        if (!isParentsubscribed) isPermitted = false;
        if (!isParentsubscribed
          && isTeamMemberAuthorised
          && Object.values(subPermissions).includes(permission)
        ) isPermitted = true;
      }
    }
    if (isCancelledUser || !isStatusValid) {
      if ((isTeamMemberAuthorised || hasFullAdminPrivileges)
        && (permission === subPermissions.addRemoveAriesFeature
          || permission === subPermissions.manageReports)) {
        isPermitted = true;
      } else isPermitted = false;
    }
  }
  return isPermitted;
};

/**
 * Returns the component to be rendered considering the id as route.
 * @param {string} id
 */
export function componentToRender(id) {
  const { offlineMode } = store.getState();
  if (offlineMode.isOffline) {
    switch (id) {
      case 'reportClientDetail':
        return ClientDetailsReports;
      case 'completedReports':
        return CompletedReport;
      case 'reportListing':
        return ReportListDetail;
      case 'offline':
      default:
        return OfflineEnhanced;
    }
  }
  if (!checkPermission(permissions.standard)) {
    switch (id) {
      case 'bugTickets':
        return BugTicket;
      case 'addBugTicket':
        return AddBugTicket;
      case 'subscriptions':
        return Subscriptions;
      case 'videos':
        return Video;
      case 'settings':
        return Setting;
      case 'editProfile':
        return EditProfile;
      case 'companyLogo':
        if (checkPermission(subPermissions.addRemoveCompanyLogo)) {
          return CompanyLogo;
        }
        return () => RedirectToCustomer({ endpoint: endPoint.subscriptions });
      case 'manageReport':
        if (checkPermission(subPermissions.manageReports)) {
          return ManageReports;
        }
        return () => RedirectToCustomer({ endpoint: endPoint.subscriptions });
      case 'qrCode':
        if (checkPermission(permissions.qrCode, permissions.qrCode)) {
          return QRcode;
        }
        return () => RedirectToCustomer({ endpoint: endPoint.subscriptions });
      default:
        return () => RedirectToCustomer({ endpoint: endPoint.subscriptions });
    }
  }

  switch (id) {
    case 'team':
      return Team;
    case 'customer':
      return CustomerListView;
    case 'completedReports':
      return CompletedReport;
    case 'uploadDevices':
      return UploadDevice;
    case 'codeScanner':
      return QrCodeScanner;
    case 'serviceRequests':
      if (checkPermission(subPermissions.viewServiceRequests)) {
        return ServiceRequestView;
      }
      return RedirectToCustomer;
    case 'timeCard':
      if (checkPermission(permissions.timeCard)) {
        return TimeCard;
      }
      return RedirectToCustomer;
    case 'edit':
      return EditTeam;
    case 'bugTickets':
      return BugTicket;
    case 'addBugTicket':
      return AddBugTicket;
    case 'user':
      return UserTimeSheet;
    case 'gps':
      if (checkPermission(permissions.gps, permissions.gps)) {
        return TeamGPS;
      }
      return RedirectToCustomer;
    case 'addJob':
      if (checkPermission(subPermissions.addCustomer)) {
        return AddJob;
      }
      return RedirectToCustomer;
    case 'add':
      return EditTeam;
    case 'autoEmail':
      return AutoEmail;
    case 'customerPortal':
      if (checkPermission(permissions.customerPortal, permissions.customerPortal)) {
        return CustomerPortal;
      }
      return RedirectToCustomer;
    case 'subscriptions':
      return Subscriptions;
    case 'manageReport':
      if (checkPermission(subPermissions.manageReports)) {
        return ManageReports;
      }
      return RedirectToCustomer;
    case 'qrCode':
      if (checkPermission(permissions.qrCode, permissions.qrCode)) {
        return QRcode;
      }
      return RedirectToCustomer;
    case 'videos':
      return Video;
    case 'ownerInfo':
      return CustomerOrOwnerView;
    case 'customerInfo':
      return CustomerOrOwnerView;
    case 'companyLogo':
      if (checkPermission(subPermissions.addRemoveCompanyLogo)) {
        return CompanyLogo;
      }
      return RedirectToCustomer;
    case 'transactions':
      return InventoryTransactions;
    case 'systemInfo':
      return SystemInfo;
    case 'images':
      return ImageUploadBuildingInfo;
    case 'buildingInfo':
      return BuildingInfo;
    case 'monthView':
    case 'jobLink':
      if (checkPermission(permissions.jobLink)) {
        return JobLink;
      }
      return RedirectToCustomer;
    case 'detail':
      return ReportDetail;
    case 'new':
      return NewReport;
    case 'reportBuildingInfo':
      return BuildingInfo;
    case 'reportImage':
      return ImageUploadBuildingInfo;
    case 'reportSytemInfo':
      return SystemInfo;
    case 'reportClientDetail':
      return ClientDetailsReports;
    case 'reportListing':
      return ReportListDetail;
    case 'task':
      return '';
    case 'myJobs':
      return MyJobsView;
    case 'dueThisMonth':
      if (checkPermission(subPermissions.viewJobsDueMissed)) {
        return TaskListViews;
      }
      return RedirectToCustomer;
    case 'missedInspections':
      if (checkPermission(subPermissions.viewJobsDueMissed)) {
        return MissedInspectionViews;
      }
      return RedirectToCustomer;
    case 'archivedVehicle':
    case 'inventoryOnVehicles':
      if (checkPermission(permissions.inventory)) {
        return InventoryVehicle;
      }
      return RedirectToCustomer;
    case 'archivedParts':
    case 'part':
      if (checkPermission(permissions.inventory)) {
        return InventoryPartsView;
      }
      return RedirectToCustomer;
    case 'partInfo':
      if (checkPermission(permissions.inventory)) {
        return PartInfo;
      }
      return RedirectToCustomer;
    case 'transactionDetails':
      return TransactionDetails;
    case 'assignParts':
      if (checkPermission(permissions.inventory)) {
        return AssignParts;
      }
      return RedirectToCustomer;
    case 'vehiclesDetails':
      if (checkPermission(permissions.inventory)) {
        return VehicleDetails;
      }
      return RedirectToCustomer;
    case 'chat':
      return ChatView;
    case 'settings':
      return Setting;
    case 'archivedTools':
    case 'tools':
      if (checkPermission(permissions.inventory)) {
        return InventoryTools;
      }
      return RedirectToCustomer;
    case 'deficiencies':
    case 'Deficiencies':
      if (checkPermission(subPermissions.viewDeficiencies, permissions.deficiency)) {
        return DeficiencyViews;
      }
      return RedirectToCustomer;
    case 'quotes':
      return DeficiencyQuotes;
    case 'recurringInspections':
      if (checkPermission(permissions.recurringInspection, permissions.recurringInspection)) {
        return RecurringInspection;
      }
      return RedirectToCustomer;
    case 'workOrder':
      return DeficiencyWorkOrder;
    case 'Sprinkler':
    case 'GreaseCleaning':
    case 'Security':
    case 'Alarm':
    case 'Other':
    case 'Extinguisher':
    case 'HoodSystem':
    case 'Hydrant':
    case 'Sensitivity':
    case 'SpecialHazard':
    case 'Pump':
    case 'Monitoring':
    case 'Suppression':
    case 'FirePump':
    case 'FireDoor':
    case 'Backflow':
    case 'EmergencyExit':
    case 'Standpipe':
    case 'WaterTank':
      return CustomerReportFilters;
    case 'home':
      return CustomerHome;
    case 'complete':
    case 'recent':
    case 'incomplete':
      return ViewReports;
    case endPoint.addTool:
      if (checkPermission(permissions.inventory)) {
        return AddToolForm;
      }
      return RedirectToCustomer;
    case endPoint.assignTool:
      if (checkPermission(permissions.inventory)) {
        return InventoryToolAssign;
      }
      return RedirectToCustomer;
    case 'autoRouting':
      return AutoRouting;
    case 'editProfile':
      return EditProfile;
    case 'offline':
      return OfflineEnhanced;
    case 'archivedSupplier':
    case 'suppliers':
      if (checkPermission(permissions.inventory)) {
        return SupplierView;
      }
      return RedirectToCustomer;
    case 'supplierInfo':
      if (checkPermission(permissions.inventory)) {
        return AddSupplier;
      }
      return RedirectToCustomer;
    case 'reports':
      return '';
    case 'inventory':
      if (checkPermission(permissions.inventory)) {
        return InventoryFlowChartView;
      }
      return RedirectToCustomer;
    default:
      return PageNotFound;
  }
}

// Customer Routes
export function routesForCustomer(subParts) {
  switch (subParts) {
    case 'device':
      return DeviceDetailView;
    case 'timeCard':
      return UserTimeSheet;
    case 'locations':
      return TeamGPSEmployeeLocations;
    case 'gps':
      return TeamGPSEmployeeDetail;
    case 'edit':
      return EditTeam;
    case 'EmergencyExit':
    case 'Standpipe':
    case 'Sprinkler':
    case 'Security':
    case 'GreaseCleaning':
      return CustomerReportFilters;
    case 'autoRouting':
      return AutoRouting;
    default:
      return PageNotFound;
  }
}

/**
 * It creates route based unique id.
 *
 * @param {string} text
 * @public
 */
export function componentToRenderOnId(handler) {
  const { id, name } = handler;
  switch (id) {
    case 'customer':
      return routesForCustomer(name);
    case 'team':
      return routesForCustomer(name);
    default:
      return UnderDevelopment;
  }
}

/**
 * It removes extra spaces from start and end of the string.
 *
 * @param {string} value
 * @public
 */
export const removeSpaces = (value) => {
  if (value) {
    return value.trim();
  }
  return value;
};

/**
 * It validate input field to contain only characters.
 *
 * @param {string} value
 * @public
 */
export const validateInputFieldCharacters = (inputText) => {
  const inputTxt = inputText && removeSpaces(inputText);
  let count = 0;
  const letters = /^[0-9a-zA-Z]+|\s.$/;
  if (inputTxt) {
    for (let i = 0; i < inputTxt.length; i += 1) {
      if (inputTxt.match(letters)) {
        count += 1;
      }
    }
  }
  return !(count === 0 || inputTxt.length > 50);
};

/**
 * It provides the auth token of customer or membered logged in.
 *
 * @public
 */
export const getToken = () => {
  const {
    auth
  } = store.getState();
  const {
    user
  } = auth.login;
  if (!isEmpty(user)) {
    return user.token;
  }
  if (auth.loginData) {
    return auth.loginData.token;
  }
  return '';
};

/**
 * It provides the id of customer selected.
 *
 * @public
 */
export const getCustomerId = () => {
  const {
    auth
  } = store.getState();
  const {
    user
  } = auth.login;
  if (!isEmpty(user)) {
    return user.customerId;
  }
  return '';
};

/**
 * It displays toaster on success or error case.
 *
 * @param {string} message
 * @param {string} status ,i.e, 'success' or 'failure'
 * @public
 */
export const displayToast = (message, status) => {
  switch (status) {
    case 'success':
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;
    case 'error':
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;
    default:
      toast(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
  }
};


export const checkIfTruthy = value => (Array.isArray(value)
  ? value.filter(item => item).length : value);

/**
 * It validate email provided.
 *
 * @param {string} email
 * @public
 */
export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const rejex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const trimmedEmail = removeSpaces(email);
  return rejex.test(String(trimmedEmail).toLowerCase());
};

/**
 * It helps to sort aan array on the basis of name field in asendiing or descending orde.
 *
 * @public
 */
export const sortByName = (array, string) => {
  if (string === 'asc') {
    return (
      array.sort((ob1, ob2) => {
        const a = new Date(ob1.name);
        const b = new Date(ob2.name);
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      })
    );
  }
  return (
    array.sort((ob1, ob2) => {
      const a = new Date(ob1.name);
      const b = new Date(ob2.name);
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    })
  );
};


/**
 * Puts the value of source object keys in the corresponding keys of target object.
 * @param {object} target
 * @param {object} source
 */
export const compareObjects = (target, source) => {
  const targetObj = {
    ...target
  };
  Object.keys(source).map((key) => {
    if (Object.prototype.hasOwnProperty.call(target, key)) {
      targetObj[key] = source[key];
    }
    return targetObj;
  });
  return targetObj;
};

/**
 * Returns an array of objects where each object
 * contains all info needed for draggable and droppable jobs.
 * @param {array} jobs
 */
export const fetchJobDetails = (jobs) => {
  let requiredJobDetails = [];
  if (jobs.length) {
    const jobsToMap = [...jobs];
    requiredJobDetails = jobsToMap.map((job) => {
      const jobDetail = {};
      jobDetail.id = job._id;
      jobDetail.clientId = job.createdFor._id;
      jobDetail.address = job.createdFor
        && job.createdFor.formattedAddress;
      jobDetail.name = job.createdFor && `${job.createdFor.businessName} - ${job.jobId}`;
      jobDetail.jobDate = job.jobDate;
      jobDetail.customerName = job.createdFor.businessName;
      jobDetail.jobId = job.jobId;
      jobDetail.reportCategory = job.reportCategory;
      jobDetail.frequency = job.recurringInspectionDetails.frequency
        ? job.recurringInspectionDetails.frequency : 'NA';
      jobDetail.type = job.type;
      jobDetail.allDetails = { ...job };
      return jobDetail;
    });
  }
  return requiredJobDetails;
};

// minutes converter
/**
 * It converts mili sec to minutes.
 *
 * @param {number} miliSec
 * @public
 */
export const minutesConverter = (miliSec) => {
  let min = 0;
  if (miliSec) {
    const tempTime = moment.duration(miliSec);
    min = tempTime.minutes();
  }
  return min;
};

/**
 * It converts mili sec to hours.
 *
 * @param {number} miliSec
 * @public
 */
export const hoursConverter = (miliSec) => {
  let hrs = 0;
  if (miliSec) {
    hrs = parseInt(miliSec / (1000 * 60 * 60), 10);
  }
  return hrs;
};

/**
 * It finds total hours.
 *
 * @public
 */
export const findTotalHours = (BREAK,
  CHECKOUT,
  DRIVING,
  MISCELLANEOUS,
  WORKING
) => hoursConverter(BREAK)
+ hoursConverter(CHECKOUT)
+ hoursConverter(DRIVING)
+ hoursConverter(MISCELLANEOUS)
  + hoursConverter(WORKING);

/**
 * It finds total minutes.
 *
 * @public
 */
export const findtotalMinutes = (BREAK,
  CHECKOUT,
  DRIVING,
  MISCELLANEOUS,
  WORKING
) => minutesConverter(BREAK)
+ minutesConverter(CHECKOUT)
+ minutesConverter(DRIVING)
+ minutesConverter(MISCELLANEOUS)
  + minutesConverter(WORKING);

/**
 * It updates total hours and minutes.
 *
 * @param {number} totalHrs
 * @param {number} totalMins
 * @public
 */
export const updateTotalHoursAndMinutes = (totalhrs, totalMins) => {
  let totaMinutes = totalMins;
  let totalhours = totalhrs;
  while (totaMinutes > 59) {
    totaMinutes -= 60;
    totalhours += 1;
  }
  return {
    totaMinutes,
    totalhours
  };
};

/**
 * It validates for contact number.
 *
 * @param {string} phoneNumber
 * @public
 */
export const validatePhoneNumber = (value) => {
  // eslint-disable-next-line no-useless-escape
  if (value === '0' || !value) {
    return true;
  }
  for (let key = 0; key < VALID_PHONE_NUMBERS.length; key += 1) {
    if (VALID_PHONE_NUMBERS[key].test(value)) {
      return (value && value.length >= 6 && value.length <= 15);
    }
  }
  return false;
};

export const validateFaxNumber = (value) => {
  if (!value) {
    return true;
  }
  // eslint-disable-next-line no-useless-escape
  const rejex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  const isSymbolsCorrect = rejex.test(value);
  if (!isSymbolsCorrect) {
    return isSymbolsCorrect;
  }
  return (value.length >= 6 && value.length <= 15);
};

/**
 * Returns the class name for the status of team member.
 * @param {string} status
 */
const getResourceClasses = (status) => {
  const [
    working,
    breakstatus,
    checkout,
    miscellaneous,
    driving,
  ] = STATUS;

  switch (status.toLowerCase()) {
    case working:
      return resourceClasses.working;
    case breakstatus:
      return resourceClasses.break;
    case checkout:
      return resourceClasses.checkout;
    case miscellaneous:
      return resourceClasses.miscellaneous;
    case driving:
      return resourceClasses.driving;
    default:
      return {};
  }
};

export const populateInitialValues = (filtersLabels) => {
  const filters = {};
  const values = Object.keys(filtersLabels);
  for (let i = 0; i < values.length; i += 1) {
    Object.assign(filters, { [values[i]]: false });
  }
  return filters;
};

/**
 * Returns an array of objects of details teams visible in joblink.
 * @param {array} teams
 * @param {boolean} isObjectFormat
 */
export const getTeams = (teams, isObjectFormat = true) => {
  let teamList = [];
  let teamsVisibleInJobLink = [...teams];
  teamsVisibleInJobLink = teamsVisibleInJobLink.filter(team => team.showInJobLink);
  teamList = teamsVisibleInJobLink.map((team) => {
    const resourceClass = team.timeCardId
      ? getResourceClasses(team.timeCardId.lastStatus)
      : resourceClasses.checkout;
    const { grandParentClass, childClass, parentClass } = resourceClass;
    if (isObjectFormat) {
      const teamDetail = {};
      teamDetail.id = team._id;
      teamDetail.homeAddress = team.homeAddress ? team.homeAddress.formattedAddress : '';
      teamDetail.name = `${team.firstName} ${team.lastName}`;
      teamDetail.isDefault = false;
      teamDetail.grandParentClass = grandParentClass;
      teamDetail.parentClass = childClass;
      teamDetail.childClass = parentClass;
      return teamDetail;
    }
    return `${team.firstName} ${team.lastName}`;
  });
  return teamList;
};

/**
 * Returns the status of the team member.
 * @param {array} teams
 * @param {string} selectedTeamId
 */
export const getTeamStatus = (teams, selectedTeamId) => {
  const selectedTeam = teams.filter(team => (team._id === selectedTeamId));
  const status = selectedTeam[0].timeCardId ? selectedTeam[0].timeCardId.lastStatus : 'Checkout';
  return status;
};


/**
 * Returns an array of hours with the maximum hour given in parameter with gaps of hourGap
 * @param {number} max
 * @param {number} hourGap
 */
export const getHours = (max, hourGap = 1) => {
  const hours = [];
  for (let index = hourGap; index <= max; index += hourGap) {
    hours.push(index.toString());
  }
  return hours;
};


/**
 * Converts a time string with colon separation to
 * an object containing hours, minutes and time format.
 * @param {string} timeStringWithColonSeparation
 */
export function convertTimeTo12HrFormat(timeStringWithColonSeparation) {
  const timeArray = timeStringWithColonSeparation.split(':');
  let hours = timeArray[0] > 12 ? timeArray[0] - 12 : timeArray[0];
  const am_pm = timeArray[0] >= 12 ? 'PM' : 'AM';
  let minutes = '00';
  if (Number(timeArray[1]) === 30) {
    minutes = '30';
  } else if (Number(timeArray[1]) > 30) {
    hours = Number(hours) + 1;
  }
  if (hours === 13) {
    hours = 1;
  }
  return {
    hours: hours.toString(),
    minutes,
    am_pm,
  };
}
/**
 * It validates zip code.
 *
 * @param {string} zipCode
 * @public
 */
export const validateZipCode = (zipCode) => {
  for (let key = 0; key < VALID_ZIP_CODE_REGEX_EXPRESSIONS.length; key += 1) {
    if (VALID_ZIP_CODE_REGEX_EXPRESSIONS[key].test(zipCode)) {
      return !(zipCode.length > 10);
    }
  }
  return false;
};

/**
 * It checks no of days between start date and end date.
 *
 * @param {string} startDate
 * @param {string} endDate
 * @public
 */
export const findTotalNoOfDaysBtwnStartDateEndDate = (startDate, endDate) => {
  const momentStart = moment(startDate);
  const momentEnd = moment(endDate);

  const count = momentEnd.diff(momentStart, 'days');
  return count;
};

/**
 * It checks whether string only contains spaces.
 *
 * @param {string} string
 * @public
 */
export const checkStringWithOnlySpaces = (string) => {
  if (!string.replace(/\s/g, '').length) {
    return true;
  }
  return false;
};

/**
 * Convert iso format date to particular date format.
 * @param {date} isoDate
 * @public
 */
export const date = isoDate => moment(isoDate).format('DD MMM YYYY');

/**
 * Convert iso format date to particular time format.
 * @param {date} isoTime
 * @public
 */
export const time = isoTime => moment(isoTime).format('hh:mm A');

export function filterJobs(jobs) {
  const currentTime = new Date().setHours(0, 0, 0, 0);
  const missedJobs = [];
  const dueThisMonth = [];
  jobs.forEach((job) => {
    if (new Date(job.jobDate).getTime() < currentTime) {
      missedJobs.push(job);
    } else {
      dueThisMonth.push(job);
    }
  });
  return {
    missedJobs,
    dueThisMonth,
  };
}
/**
 * @function returns array of report categories having devices as sub key.
 * @param {Array} reportList
 */
export const reportCategoriesHavingDevices = (reportList) => {
  const deviceReportcategory = [];
  const reportCategories = Object.keys(reportList);
  for (let index = 0; index < reportCategories.length; index += 1) {
    const subCategory = reportList[reportCategories[index]];
    if (subCategory.Device) {
      deviceReportcategory.push(reportCategories[index]);
    }
  }
  return deviceReportcategory;
};

/**
 * @function returns array of devices for selected report category.
 * @param {*} selectedReportCategory
 * @param {*} reportList
 */
export const deviceList = (selectedReportCategory, reportList) => {
  const list = reportList[selectedReportCategory];
  return list.Device;
};

/**
 * Returns array of annual months.
 * @param {array} monthList
 * @param {string} month
 * @param {string} frequency
 */
export const getAnnualMonths = (monthList, month, frequency) => {
  let count = 7;
  if (frequency === 'QUARTELY') {
    count = 4;
  }
  const startingIndex = monthList.findIndex(item => item.value === month);
  let iterator = startingIndex;
  const annualMonths = [];
  while (iterator < startingIndex + 11) {
    annualMonths.push(monthList[iterator % 12]);
    iterator += count - 1;
  }
  return annualMonths.map(({ shortLabel }) => shortLabel);
};

/**
 * Returns frequency list for task.
 * @param {array} frequencyList
 * @param {string} frequency
 */
export const getFrequencyListForTask = (frequencyList, frequency) => {
  const startingIndex = frequencyList.findIndex(
    item => item.value === frequency
  );
  const frequencyListForTask = [];
  for (let index = startingIndex; index < frequencyList.length; index += 1) {
    frequencyListForTask.push(frequencyList[index].label);
  }
  return frequencyListForTask;
};

/**
 * Returns year list.
 * @param {string} currentYear
 */
export const getYearList = (currentYear) => {
  const arrayOfYears = [];
  arrayOfYears.push(currentYear);
  let year = currentYear;
  for (let index = 0; index < 20; index += 1) {
    year += 1;
    arrayOfYears.push(year);
  }
  return arrayOfYears;
};

/**
 * Returns an array of months.
 */
export const getMonths = (monthList, month, frequency) => {
  let count = 7;
  if (frequency === Quarterly) {
    count = 4;
  }
  const startingIndex = monthList.findIndex(item => item.value === month);
  let iterator = startingIndex;
  const annualMonths = [];
  while (iterator < startingIndex + 11) {
    annualMonths.push(monthList[iterator % 12]);
    iterator += count - 1;
  }
  return annualMonths.map(({ value }) => value);
};


/**
 * Returns an array of valid months for task.
 * @param {array} monthList
 * @param {string} month
 */
export const validMonthsForTask = (monthList, month) => {
  let startingIndex = monthList.findIndex(item => item.value === month);
  const validTasksMonths = [];
  while (startingIndex < monthList.length) {
    validTasksMonths.push(monthList[startingIndex].label);
    startingIndex += 1;
  }
  return validTasksMonths;
};

/**
 * Fetching Month list for new task under recurring inspection.
 */
export const getValidMonthListForTask = (monthList, months) => {
  let validMonthListForTask = [];
  if (months.length === 1) {
    validMonthListForTask = validMonthsForTask(
      monthList,
      months[0]
    );
  } else {
    let index = 0;
    while (index < months.length) {
      validMonthListForTask.push(
        monthList[monthList.findIndex(
          // eslint-disable-next-line no-loop-func
          item => item.value === months[index]
        )].label
      );
      index += 1;
    }
  }
  return validMonthListForTask;
};

/**
 * Fetch annual months list.
 */
export const getAnnualMonthList = (addRecurrInspection, monthsArray) => {
  const markAsAnnualMonthsList = ((addRecurrInspection.frequency === 'QUARTELY'
    || addRecurrInspection.frequency === 'SEMI_ANNUAL'))
    && (monthsArray)
    && getAnnualMonths(
      MONTH_LIST,
      monthsArray[0],
      addRecurrInspection.frequency);
  return markAsAnnualMonthsList;
};

/**
 * Checks if form field is visible or not.
 */
export const checkFormFieldsVisibility = (inspectionDataFrequency) => {
  const isParity = inspectionDataFrequency === 'BI_WEEKLY'
    || inspectionDataFrequency === 'Bi_MONTHLY'
    || inspectionDataFrequency === 'BI_ANNUAL'
    || inspectionDataFrequency === 'BI_MONTHLY';

  const isYear = inspectionDataFrequency === 'THREE_YEAR'
    || inspectionDataFrequency === 'FIVE_YEAR';
  return { isParity, isYear };
};

/**
 * Returns valid months list.
 * @param {string} frequency
 * @param {string} value
 */
export const getValidMonthList = (frequency, value) => {
  const validMonthList = ((frequency === 'QUARTELY'
    || frequency === 'SEMI_ANNUAL'))
    && (value)
    && getMonths(
      MONTH_LIST,
      MONTH_LIST[
        MONTH_LIST.findIndex(item => item.label === value)
      ].value,
      frequency);
  return validMonthList;
};

/**
 * Returns frequency for particular value.
 * @param {string} value
 */
export const getFrequency = (value) => {
  const frequency = RECURRING_INSPECTION_VALID_FREQUENCIES[
    RECURRING_INSPECTION_VALID_FREQUENCIES.findIndex(item => item.label === value)
  ].value;
  return frequency;
};


/**
 * Returns frequency for edit mode in recurring inspections.
 * @param {string} value
 */
export const getFrequencyForEditMode = (value) => {
  const frequency = RECURRING_INSPECTION_VALID_FREQUENCIES[
    RECURRING_INSPECTION_VALID_FREQUENCIES.findIndex(item => item.value === value)
  ].label;
  return frequency;
};

/**
 * Return month for value.
 * @param {string} value
 */
export const getMonth = (value) => {
  const months = [MONTH_LIST[
    MONTH_LIST.findIndex(item => item.label === value)
  ].value];
  return months;
};

/**
 * Return month for edit mode for value.
 * @param {string} value
 */
export const getMonthForEditMode = (value) => {
  const months = [MONTH_LIST[
    MONTH_LIST.findIndex(item => item.value === value)
  ].label];
  return months;
};

/**
 * Month list on basis of current month.
 */
export const getMonthList = (months) => {
  let index = 0;
  const monthList = [];
  while (index < months.length) {
    monthList.push(months[index].label);
    index += 1;
  }
  return monthList;
};

/**
 * Returns parity for value.
 * @param {string} value
 */
export const getParity = (value) => {
  const parity = RECURRING_INSPECTION_PARITY[
    RECURRING_INSPECTION_PARITY.findIndex(item => item.label === value)
  ].value;
  return parity;
};

/**
 * Sets form errors for recurring inspections feature.
 * @param {object} errors
 * @param {string} frequency
 * @param {string} parity
 * @param {string} year
 * @param {string} months
 * @param {string} description
 * @param {boolean} isParity
 * @param {string} index
 */
export const setFormErrors = (
  errors, frequency, parity, year, months, description, isParity, index
) => {
  const formErrors = errors;
  if (!frequency) {
    formErrors[`frequency-${index}`] = validationMessage.frequency;
  }
  if (isParity && !parity) {
    formErrors[`parity-${index}`] = validationMessage.parity;
  }
  if (!year) {
    formErrors[`year-${index}`] = validationMessage.year;
  }
  if (!months) {
    formErrors[`month-${index}`] = validationMessage.months;
  }
  if (!description) {
    formErrors[`description-${index}`] = validationMessage.Description;
  }
  return formErrors;
};


/**
 * Returns an object of report categories.
 * @param {string} reportCategory
 */
export const getReportCategory = (reportCategory) => {
  const reportObject = {};
  const list = Object.keys(reportCategory);
  let index = 0;
  while (index < list.length) {
    reportObject[`${list[index]}`] = `/customer/${list[index].replace(/\s/g, '')}`;
    index += 1;
  }
  return reportObject;
};


/**
 * Returns an array of technician names.
 * @param {array} techs
 */
export const getTechniciansName = (techs, isObject = false) => {
  const techNames = techs.map(tech => (isObject ? { name: tech.name, id: tech.id } : tech.name));
  return techNames;
};

/**
 * Returns the end location address.
 * @param {*} value Job name of starting location.
 * @param {*} missedInspectionsJobs Selected missed inspection jobs array of object.
 * @param {*} dueThisMonthJobs Selected due this month jobs.
 */
export const getEndLocation = (value, missedInspectionsJobs, dueThisMonthJobs) => {
  const missedJobs = missedInspectionsJobs ? [...missedInspectionsJobs] : null;
  const duejobs = dueThisMonthJobs ? [...dueThisMonthJobs] : null;
  let missedIndex = -1;
  let dueIndex = -1;
  if (duejobs) {
    dueIndex = dueThisMonthJobs.findIndex(item => item.jobName === value);
    duejobs.splice(dueIndex, 1);
    if (dueThisMonthJobs.length > 0) {
      return dueThisMonthJobs[0].address;
    }
    return missedInspectionsJobs[0].address;
  }
  missedIndex = missedJobs.findIndex(item => item.jobName === value);
  missedJobs.splice(missedIndex, 1);
  return missedJobs[0].address;
};

/**
 * Returns start job list.
 * @param {string} homeAddress
 */
export const getStartJobList = (homeAddress) => {
  const selectStartJobList = [];
  if (homeAddress) {
    selectStartJobList.push('Home Address');
  }
  selectStartJobList.push('Office Address');
  selectStartJobList.push('Other');
  return selectStartJobList;
};

/**
 * Returns the starting address for auto routing.
 * @param {string} homeAddress
 * @param {string} selectedJobName
 * @param {object} employeesDetail
 */
export const getStartingAddress = (
  selectedJobName, employeesDetail, homeAddress) => {
  let addressValue = '';
  if (selectedJobName === 'Home Address' && employeesDetail) {
    addressValue = homeAddress;
  }
  if (selectedJobName === 'Office Address' && employeesDetail) {
    addressValue = employeesDetail.formattedAddress;
  }
  if (selectedJobName === 'Other') {
    addressValue = '';
  }
  return addressValue;
};

/**
 * Returns way points list.
 * @param {array} dueJobs
 * @param {array} missedJobs
 */
export const getWayPointsList = (dueJobs, missedJobs) => {
  const wayPoint = [];
  let index = 0;
  if (dueJobs) {
    while (index < dueJobs.length) {
      wayPoint.push({
        address: dueJobs[index].address,
        customerName: dueJobs[index].customerName
      });
      index += 1;
    }
  }
  index = 0;
  if (missedJobs) {
    while (index < missedJobs.length) {
      wayPoint.push({
        address: missedJobs[index].address,
        customerName: missedJobs[index].customerName
      });
      index += 1;
    }
  }
  return wayPoint;
};

/**
 * Returns an array of jobIds.
 * @param {array} missedJobs
 * @param {array} dueJobs
 */
export const getJobIds = (missedJobs, dueJobs) => {
  let index = 0;
  const jobList = [];
  if (missedJobs) {
    while (index < missedJobs.length) {
      const object = {
        jobId: missedJobs[index].jobId
      };
      jobList.push(object);
      index += 1;
    }
  }
  if (dueJobs) {
    while (index < dueJobs.length) {
      const object = {
        jobId: dueJobs[index].jobId
      };
      jobList.push(object);
      index += 1;
    }
  }
  return jobList;
};

/**
 * Gets epoch of time for scheduling auto routing jobs.
 * @param {moment object} s_date
 */
export const getEpochDateTime = (s_date) => {
  let dateTime = s_date;
  const minutes = moment().minutes();
  if (moment(s_date).startOf('day').valueOf() === moment().startOf('day').valueOf()) {
    if (minutes === 0 || minutes === 30) {
      return moment().set('second', 0).set('millisecond', 0).valueOf();
    }
    if (minutes < 30) {
      return moment().set('minute', 30).set('second', 0).set('millisecond', 0)
        .valueOf();
    }
    if (minutes > 30) {
      return moment().set('hour', moment().hours() + 1).set('minute', 0).set('second', 0)
        .set('millisecond', 0)
        .valueOf();
    }
  }
  dateTime = moment(s_date).set('hour', 9).set('minute', 0).set('second', 0);
  return dateTime.valueOf();
};

/**
 * Update form errors for schduling auto routing.
 */
export const setAutoRouteFormErrors = (
  isDateVisible,
  isTeamSelectionVisible,
  isStarttLocationSelectionVisible,
  isSelectJobs,
  sheduleJobData,
  formErr,
  formattedAddress
) => {
  const formErrors = { ...formErr };
  if (isDateVisible && !sheduleJobData.date) {
    formErrors.date = validationMessage.sheduleDate;
  } else if (isTeamSelectionVisible && !sheduleJobData.teamMember) {
    formErrors.teamMember = validationMessage.sheduleMember;
  } else if (isSelectJobs) {
    if (!sheduleJobData.missedInspectionsJobs && !sheduleJobData.dueThisMonthJobs) {
      formErrors.setJobs = validationMessage.sheduleJobs;
    } else if (sheduleJobData.missedInspectionsJobs || sheduleJobData.dueThisMonthJobs) {
      const missedLength = sheduleJobData.missedInspectionsJobs
        ? sheduleJobData.missedInspectionsJobs.length
        : 0;
      const dueLength = sheduleJobData.dueThisMonthJobs
        ? sheduleJobData.dueThisMonthJobs.length
        : 0;
      if (missedLength + dueLength < 2) {
        formErrors.setJobs = validationMessage.noOfSheduleJobs;
      }
    }
  } else if ((isStarttLocationSelectionVisible && !sheduleJobData['startingLocation-address']) && formattedAddress === '') {
    formErrors.startingLocation = validationMessage.sheduleStartLocation;
  }
  return formErrors;
};

export const NoImageUrl = './img/No-Image-Available.png';


/**
 * Returns a profile details object.
 * @param {object} userInfo
 */
export const setProfileInfo = (userInfo) => {
  const profileDetails = {};
  profileDetails.firstName = userInfo.firstName;
  profileDetails.lastName = userInfo.lastName;
  profileDetails.businessName = userInfo.businessName;
  profileDetails.city = userInfo.city;
  profileDetails.profilePicture = userInfo.profilePicture;
  profileDetails.contactNo = userInfo.contactNo;
  profileDetails.email = userInfo.email;
  profileDetails.role = userInfo.role;
  profileDetails.state = userInfo.state;
  profileDetails.zipCode = `${userInfo.zipCode}`;
  profileDetails.businessAddress = userInfo.businessAddress;
  profileDetails.formattedAddress = userInfo.formattedAddress;
  return profileDetails;
};


/**
 * Returns a count that indicates number of jobs having jobId in selectedJobs.
 * @param {array} selectedJobs
 * @param {string} jobId
 */
export const findJobIndexInSelectedJobs = (selectedJobs, jobId) => {
  let index = 0;
  let count = 0;
  while (index < selectedJobs.length) {
    if (selectedJobs[index]._id === jobId) {
      count += 1;
      break;
    }
    index += 1;
  }
  return count;
};


/**
 * Returns current date and time string.
 */
export const getCurrentDateTimeString = () => {
  const dateTime = `${moment.utc(new Date())
    .format('ddd, DD MMM')} ${new Date().getHours()} Hrs ${new Date().getMinutes()} Mins`;
  return dateTime;
};


/**
 * Returns route names for right side menu items click.
 * @param {string} routeProps
 */
export const reportRouter = (routeProps) => {
  switch (routeProps) {
    case 'Alarm':
      return 'Alarm';
    case 'Sprinkler':
      return 'Sprinkler';
    case 'Security':
      return 'Security';
    case 'Other':
      return 'Other';
    case 'Extinguisher':
      return 'Extinguisher';
    case 'Hydrant':
      return 'Hydrant';
    case 'Sensitivity':
      return 'Sensitivity';
    case 'Pump':
      return 'Pump';
    case 'Monitoring':
      return 'Monitoring';
    case 'Suppression':
      return 'Suppression';
    case 'EmergencyExit':
    case 'Light':
      return 'Emergency Exit/Light';
    case 'FireDoor':
      return 'Fire Door';
    case 'GreaseCleaning':
      return 'Grease Cleaning';
    case 'HoodSystem':
      return 'Hood System';
    case 'Standpipe':
      return 'Standpipe/Hose';
    case 'Backflow':
      return 'Backflow';
    case 'SpecialHazard':
      return 'Special Hazard';
    case 'WaterTank':
      return 'Water Tank';
    default:
      return '';
  }
};

/**
 * @method returns array of technician ids.
 */
export const getListOfTechnicianIds = (technicianList) => {
  const technicianIds = [];
  let index = 0;
  while (index < technicianList.length) {
    const technician = technicianList[index];
    technicianIds.push(technician.customerId);
    index += 1;
  }
  return technicianIds;
};

/**
 * @method returns object of customer and technician array of ids.
 * @param {*} selectedJobs Jobs selected for offline.
 */
export const getListOfIds = (selectedJobs) => {
  const setOfCustomerEmployeeIds = {};
  const listOfCustomerIds = [];
  let listOfTechnicianIds = [];
  let index = 0;
  while (index < selectedJobs.length) {
    const job = selectedJobs[index];
    if (!job.isDownloaded) {
      listOfCustomerIds.push(job.createdFor._id);
      if (job.technicians) { // If technician list exist, get list of techician ids.
        listOfTechnicianIds = [
          ...listOfTechnicianIds,
          ...getListOfTechnicianIds(job.technicians)
        ];
      }
    }
    index += 1;
  }
  setOfCustomerEmployeeIds.customerIds = listOfCustomerIds;
  setOfCustomerEmployeeIds.technicianIds = listOfTechnicianIds;
  return setOfCustomerEmployeeIds;
};

/**
 * Returns payload for offline mode customer success action dispatch
 * @param {object} dataSetOfCustomer
 * @param {string} selectedCustmerId
 */
export const getPayloadCustomer = (dataSetOfCustomer, selectedCustmerId) => {
  let index = 0;
  const customerSet = Object.values(dataSetOfCustomer);
  const payload = {
    docs: [],
    images: [],
    notes: [],
    systemInfo: [],
    page: 1,
    pages: 1,
    total: customerSet.length
  };
  while (index < customerSet.length) {
    const {
      clientOwnerDetail,
      images,
      notes,
      systemInfoList,
    } = customerSet[index];
    payload.docs.push(clientOwnerDetail);
    if (clientOwnerDetail._id === selectedCustmerId) {
      payload.images = images;
      payload.notes = notes;
      payload.systemInfo = systemInfoList;
    }
    index += 1;
  }
  return payload;
};


/**
 * Returns payload for offline mode inspection success action dispatch
 * @param {object} dataSetOfCustomer
 * @param {string} selectedCustmerId
 */
export const getPayloadInspection = (dataSetOfCustomer, selectedCustmerId) => {
  let index = 0;
  const customerSet = Object.values(dataSetOfCustomer);
  const payload = {
    docs: [],
    page: 1,
    pages: 1,
    total: customerSet.length
  };
  while (index < customerSet.length) {
    const {
      clientOwnerDetail,
      recurringInsList,
    } = customerSet[index];
    if (clientOwnerDetail._id === selectedCustmerId) {
      payload.docs = recurringInsList;
    }
    index += 1;
  }
  return payload;
};


/**
 * dispatches success actions for offline mode.
 * @param {string} actionType
 * @param {object} globalDatabase
 * @param {function} dispatch
 * @param {object} extra
 * @param {string} selectedCustomerId
 */
export const offlineModeDispatcher = (
  actionType,
  globalDatabase,
  dispatch,
  extra,
  selectedCustomerId
) => {
  switch (actionType) {
    case ActionConstants.GET_SYSTEM_INFO_SUCCESS:
    case ActionConstants.GET_CLIENT_LIST_SUCCESS:
      return dispatch({
        type: actionType,
        payload: getPayloadCustomer(globalDatabase.customer, selectedCustomerId),
        message: 'Success',
        extra,
      });
    case ActionConstants.FETCH_RECURRING_INSPECTION_SUCCESS:
      return dispatch({
        type: actionType,
        payload: getPayloadInspection(globalDatabase.customer, selectedCustomerId),
        message: 'Success',
        extra,
      });
    case ActionConstants.SHOW_TEAM_SUCCESS: {
      const payload = {
        docs: globalDatabase.teamList,
        page: 1,
        pages: 1,
        total: globalDatabase.teamList.length
      };
      return dispatch({
        type: actionType,
        payload,
        message: 'Success',
        extra,
      });
    }
    case ActionConstants.INVENTORY_GET_PARTS_LIST_SUCCESS: {
      const payload = {
        docs: globalDatabase.inventoryParts,
        page: 1,
        pages: 1,
        total: globalDatabase.inventoryParts.length
      };
      return dispatch({
        type: actionType,
        payload,
        message: 'Success',
        extra,
      });
    }
    case ActionConstants.GET_JOB_LIST_SUCCESS: {
      const payload = {
        docs: globalDatabase,
        page: 1,
        pages: 1,
        total: globalDatabase.length
      };
      return dispatch({
        type: actionType,
        payload,
        message: 'Success',
        extra,
      });
    }
    default:
      return '';
  }
};


/**
 * Returns an object with customer id as key and customer details as value.
 * @param {array} clientList
 */
export const setClientDataList = (clientList) => {
  const clientListObject = {};
  let index = 0;
  while (index < clientList.length) {
    const client = clientList[index];
    clientListObject[`${client._id}`] = {};
    clientListObject[`${client._id}`].clientOwnerDetail = client;
    index += 1;
  }
  return clientListObject;
};

export const fetchDetailsForSingleClient = (
  getSystemInfoAction,
  getClientDeviceList,
  fetchRecurringInspectionsAction,
  clientId,
  page,
  LIMIT
) => {
  // Fetching client device list.
  getClientDeviceList(true, page, LIMIT, clientId);
  // API calling for fetching recurring inspection.
  fetchRecurringInspectionsAction(
    true,
    clientId,
    page,
    LIMIT
  );
  // Fetching Building Info.
  getSystemInfoAction(clientId);
};

export const handleErrorCase = (globalDb, clearStateAction) => {
  if (globalDb.ErrorMsgForFetchedDatabase !== '') {
    displayToast(globalDb.ErrorMsgForFetchedDatabase, 'error');
    clearStateAction();
  }
};

/**
* Calling failure action of global database. */
export const callGlobalFailureAction = (
  globalDbAction,
  clearLocalState,
  clearStateAction
) => {
  globalDbAction(-1);
  clearLocalState();
  clearStateAction();
};

/**
 * Returns an array of missed and due jobs.
 * @param {array} missedJobs
 * @param {array} dueJobs
 * @param {object} selectedTimeCategory
 */
export const getConbinedDueMissedJobs = (missedJobs, dueJobs, selectedTimeCategory) => {
  const missJob = [...missedJobs];
  const dueJob = [...dueJobs];
  let jobs = [];
  let index = 0;
  while (index < missJob.length) {
    missJob[index].jobName = 'Missed';
    index += 1;
  }
  index = 0;
  while (index < dueJob.length) {
    dueJob[index].jobName = 'Due';
    index += 1;
  }
  if (selectedTimeCategory['Missed Inspections']
    && selectedTimeCategory['Due Jobs']) {
    jobs = [...dueJob, ...missJob];
  } else if (selectedTimeCategory['Due Jobs']) {
    jobs = [...missJob];
  } else if (selectedTimeCategory['Missed Inspections']) {
    jobs = [...dueJob];
  } else {
    jobs = [];
  }
  return jobs;
};

export const handleUserConfirmationPopupForModals = (hideModal) => {
  const { isPromptReducer, locale } = store.getState();
  if (isPromptReducer.isPrompt) {
    UserConfirmation('', (isContinue) => {
      if (!isContinue) return;
      store.dispatch({
        type: ActionConstants.SET_IS_PROMPT,
        payload: false
      });
      if (typeof (hideModal) === 'function') hideModal();
    }, locale.lang, isPromptReducer);
    return;
  }
  if (typeof (hideModal) === 'function') hideModal();
};

export const getInitialInspectorNotes = (iNotes) => {
  const inspectorNotes = iNotes && iNotes.map((iNote, index) => {
    const noteObj = {};
    noteObj[index] = iNote;
    return noteObj;
  });
  return inspectorNotes;
};

export const getInitialCustomerNotes = (cNotes) => {
  const customerNotes = cNotes && cNotes.map((cNote, index) => {
    const noteObj = {};
    noteObj[index] = cNote;
    return noteObj;
  });
  return customerNotes;
};

export const getNotes = (notes) => {
  const notesArr = notes.map(note => note[Object.keys(note)[0]]);
  return notesArr;
};
