import ActionConstants from '../constants/ActionConstants';

const initialState = {
  selectedCustomerId: '',
  selectedCustomerType: '',
  selectedCustomerBusinessName: '',
  showMenu: false,
  reportLogoList: false,
  reportName: null,
};

export default function setSelectedCustomer(state = initialState, action) {
  if (action.type === ActionConstants.SET_SELECTED_CUSTOMER) {
    return {
      ...state,
      selectedCustomerId: action.data.customerId,
      selectedCustomerBusinessName: action.data.buisnessName,
      selectedCustomerType: action.data.type,
      showMenu: action.data.showMenu,
    };
  }
  if (action.type === ActionConstants.SET_SUBMENU_VISIBILITY) {
    return {
      ...state,
      showMenu: action.data.showMenu,
    };
  }
  if (action.type === ActionConstants.RESET_SELECTED_CUSTOMER) {
    return {
      ...state,
      selectedCustomerId: '',
      selectedCustomerType: '',
      selectedCustomerBusinessName: '',
      showMenu: false,
      reportLogoList: false
    };
  }
  if (action.type === ActionConstants.RESET_REPORT_LOGO_LIST) {
    return {
      ...state,
      reportLogoList: true
    };
  }
  if (action.type === ActionConstants.RESET_GRID_LIST_REPORT) {
    return {
      ...state,
      reportLogoList: false
    };
  }
  if (action.type === ActionConstants.SET_REPORT_NAME_BREADCRUMB) {
    return {
      ...state,
      reportName: action.payload,
    };
  }
  if (action.type === ActionConstants.RESET_ALL_REDUCERS) {
    return { ...initialState };
  }
  return state;
}
