import React from 'react';
import PropTypes from 'prop-types';

/**
 * It renders each row of table for device listing.
 * @param {*} row Details of each row in table.
 */
const TransactionDetailsPartsRow = props => (
  <tr
    key={props.row._id}>
    <td>{props.row.partName || 'NO PART'}</td>
    <td>{props.row.sku || 'No SKU'}</td>
    <td>{props.row.transactionQuantity !== undefined ? props.row.transactionQuantity : '-'}</td>
    <td>{props.row.quantityWareHouse !== undefined ? props.row.quantityWareHouse : '-'}</td>
    <td>{props.row.reportType || '-'}</td>
  </tr>
);

TransactionDetailsPartsRow.propTypes = {
  row: PropTypes.object.isRequired,
  unassignBtn: PropTypes.func
};

const TransactionDetailsToolsRow = props => (
  <React.Fragment>
    <tr
      key={props.row._id}>
      <td>{props.row.toolName}</td>
      <td>{props.row.idNumber}</td>
      <td>{props.row.transactionQuantity !== undefined ? props.row.transactionQuantity : '-'}</td>
      <td>{props.row.quantityWareHouse !== undefined ? props.row.quantityWareHouse : '-'}</td>
    </tr>
  </React.Fragment>
);

TransactionDetailsToolsRow.propTypes = {
  row: PropTypes.object.isRequired,
  unassignBtn: PropTypes.func
};

export { TransactionDetailsPartsRow, TransactionDetailsToolsRow };
