import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button from '../../components/Button';

const CommentModalBody = (props) => {
  const {
    handleChange,
    commentDescrip,
    onSave,
    validationErrs,
    intl,
  } = props;
  return (
    <div className="modal-body">
      <div
        className="form-group dis-flex f-dir-column cmnt-textarea">
        {
          validationErrs && validationErrs.description
          && <span className='errorMessage'>
            <FormattedMessage
              id={validationErrs.description}
              defaultMessage={validationErrs.description}
            />
          </span>
        }
        <textarea
          type="textarea"
          spellCheck="true"
          className="form-control"
          rows="3"
          id="comment"
          name="commente"
          onChange={handleChange}
          value={commentDescrip}
          placeholder={intl.formatMessage({ id: 'Type Here' })}
        />
        <label htmlFor="comment">
          <FormattedMessage
            id='Comment'
            defaultMessage='Comment' />
        </label>
      </div>
      <div className="text-center mr-top-24" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Button
          type="button"
          className="btn btn_success"
          color='#6AA14E'
          text='Save'
          onBtnClick={onSave} />
      </div>
    </div>
  );
};

CommentModalBody.propTypes = {
  handleChange: PropTypes.func,
  commentDescrip: PropTypes.string,
  onSave: PropTypes.func,
  validationErrs: PropTypes.object,
  intl: intlShape.isRequired,
};

export default injectIntl(CommentModalBody);
