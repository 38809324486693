import actionConstants from '../constants/ActionConstants';

const initialState = {
  jobList: {
    error: '',
    isFetching: false,
    jobList: [],
    isSuccess: false,
    pages: 0,
    totalListCount: 0,
    currentPage: 1,
  },
  missedJobList: {
    error: '',
    isFetching: false,
    jobList: [],
    isSuccess: false,
    pages: 0,
    totalListCount: 0,
    currentPage: 1,
  },
  nextMonthJobList: {
    error: '',
    isFetching: false,
    jobList: [],
    isSuccess: false,
    pages: 0,
    totalListCount: 0,
    currentPage: 1,
  },
  jobDetails: {
    error: '',
    isFetching: false,
    jobDetail: {},
    isSuccess: false,
  },
  teamListWithFilters: {
    error: '',
    isfetching: false,
    teamList: [],
    isSuccess: false,
    pages: 1,
    totalListCount: 0,
    currentPage: 1,
  },
  calenderViewData: {
    startDate: '',
    endDate: '',
  },
  assignedJob: {
    isFetching: false,
    error: '',
    isSuccess: false,
    jobDetail: {},
    success: ''
  },
  unassignedJob: {
    isFetching: false,
    error: '',
    isSuccess: false,
    jobDetail: {},
  },
  deletedJob: {
    isFetching: false,
    error: '',
    isSuccess: false,
    jobDetail: {},
  },
  latLong: {
    isFetching: false,
    error: '',
    isSuccess: false,
    location: {},
    customerName: null,
    uniqueId: '',
  },
  updateJob: {
    isFetching: false,
    error: '',
    isSuccess: false,
    success: '',
  }
};

export default (state = initialState, action) => {
  const {
    jobList, teamListWithFilters, latLong, missedJobList, nextMonthJobList,
  } = state;
  let newTeamList = [];
  let newJobList = [];
  let newMissedJobList = [];
  let newNextMonthJobList = [];

  switch (action.type) {
    case actionConstants.GET_JOB_LINK_REQUEST:
      return {
        ...state,
        jobList: {
          ...jobList,
          isFetching: true,
          isSuccess: false
        }
      };
    case actionConstants.GET_JOB_LINK_SUCCESS:
      if (action.payload.page === 1) {
        newJobList = action.payload.docs;
      } else if (action.payload.page !== jobList.currentPage) {
        newJobList = [...jobList.jobList, ...action.payload.docs];
      } else {
        newJobList = [...jobList.jobList];
      }
      return {
        ...state,
        jobList: {
          ...jobList,
          jobList: newJobList,
          isFetching: false,
          isSuccess: true,
          pages: action.payload.pages,
          totalListCount: action.payload.total,
          currentPage: action.payload.page,
          error: '',
        }
      };
    case actionConstants.GET_JOB_LINK_FAILURE:
      return {
        ...state,
        jobList: {
          ...jobList,
          error: action.message,
          isFetching: false,
          isSuccess: false,
          jobList: action.message === 'Operation not allowed'
            ? []
            : state.jobList.jobList,
        }
      };
    case actionConstants.GET_MISSED_JOB_LIST_REQUEST:
      return {
        ...state,
        missedJobList: {
          ...missedJobList,
          isFetching: true,
          isSuccess: false,
          error: '',
        }
      };
    case actionConstants.GET_MISSED_JOB_LIST_SUCCESS:
      if (action.payload.page === 1) {
        newMissedJobList = action.payload.docs;
      } else if (action.payload.page !== missedJobList.currentPage) {
        newMissedJobList = [...missedJobList.jobList, ...action.payload.docs];
      } else {
        newMissedJobList = [...missedJobList.jobList];
      }
      return {
        ...state,
        missedJobList: {
          ...missedJobList,
          jobList: newMissedJobList,
          isFetching: false,
          isSuccess: true,
          pages: action.payload.pages,
          totalListCount: action.payload.total,
          currentPage: action.payload.page,
          error: '',
        }
      };
    case actionConstants.GET_MISSED_JOB_LIST_FAILURE:
      return {
        ...state,
        missedJobList: {
          ...missedJobList,
          error: action.message,
          isFetching: false,
          isSuccess: false,
          jobList: action.message === 'Operation not allowed'
            ? []
            : state.missedJobList.jobList,
        }
      };
    case actionConstants.GET_NEXT_MONTH_JOBS_REQUEST:
      return {
        ...state,
        nextMonthJobList: {
          ...nextMonthJobList,
          isFetching: true,
          isSuccess: false,
          error: '',
        }
      };
    case actionConstants.GET_NEXT_MONTH_JOBS_SUCCESS:
      if (action.payload.page === 1) {
        newNextMonthJobList = action.payload.docs;
      } else if (action.payload.page !== nextMonthJobList.currentPage) {
        newNextMonthJobList = [...nextMonthJobList.jobList, ...action.payload.docs];
      } else {
        newNextMonthJobList = [...nextMonthJobList.jobList];
      }
      return {
        ...state,
        nextMonthJobList: {
          ...nextMonthJobList,
          jobList: newNextMonthJobList,
          isFetching: false,
          isSuccess: true,
          pages: action.payload.pages,
          totalListCount: action.payload.total,
          currentPage: action.payload.page,
          error: '',
        }
      };
    case actionConstants.GET_NEXT_MONTH_JOBS_FAILURE:
      return {
        ...state,
        nextMonthJobList: {
          ...nextMonthJobList,
          error: action.message,
          isFetching: false,
          isSuccess: false,
          jobList: action.message === 'Operation not allowed'
            ? []
            : state.nextMonthJobList.jobList,
        }
      };
    case actionConstants.GET_JOB_DETAILS_REQUEST:
      return {
        ...state,
        jobDetails: {
          ...state.jobDetails,
          isSuccess: false,
          error: '',
          isFetching: true,
        }
      };
    case actionConstants.GET_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        jobDetails: {
          ...state.jobDetails,
          error: '',
          isFetching: false,
          jobDetail: action.payload,
          isSuccess: true,
        }
      };
    case actionConstants.GET_JOB_DETAILS_FAILURE:
      return {
        ...state,
        jobDetails: {
          ...state.jobDetails,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        }
      };
    case actionConstants.SET_CALENDER_DATA:
      return {
        ...state,
        calenderViewData: {
          ...state.calenderViewData,
          ...action.payload,
        },
      };
    case actionConstants.CLEAR_CALENDER_DATA:
      return {
        ...state,
        calenderViewData: {},
      };
    case actionConstants.GET_TEAM_LIST_WITH_FILTERS_REQUEST:
      return {
        ...state,
        teamListWithFilters: {
          ...teamListWithFilters,
          isfetching: true,
          isSuccess: false
        }
      };
    case actionConstants.GET_TEAM_LIST_WITH_FILTERS_SUCCESS:
      if (action.payload.page === 1) {
        newTeamList = action.payload.docs;
      } else if (action.payload.page === teamListWithFilters.currentPage) {
        newTeamList = [...teamListWithFilters.teamList];
      } else {
        newTeamList = [...teamListWithFilters.teamList, ...action.payload.docs];
      }

      return {
        ...state,
        teamListWithFilters: {
          ...teamListWithFilters,
          teamList: newTeamList,
          isfetching: false,
          isSuccess: true,
          pages: action.payload.pages,
          totalListCount: action.payload.total,
          currentPage: action.payload.page,
          error: '',
        }
      };
    case actionConstants.GET_TEAM_LIST_WITH_FILTERS_FAILURE:
      return {
        ...state,
        teamListWithFilters: {
          ...teamListWithFilters,
          error: action.message,
          isfetching: false
        }
      };
    case actionConstants.RESET_TEAM_LIST_WITH_FILTERS:
      return {
        ...state,
        teamListWithFilters: {
          ...initialState.teamListWithFilters,
        }
      };
    case actionConstants.ASSIGN_JOB_REQUEST:
      return {
        ...state,
        assignedJob: {
          ...state.assignedJob,
          isSuccess: false,
          error: '',
          isFetching: true,
          success: '',
        }
      };
    case actionConstants.ASSIGN_JOB_SUCCESS:
      return {
        ...state,
        assignedJob: {
          ...state.assignedJob,
          error: '',
          isFetching: false,
          jobDetail: action.payload,
          isSuccess: true,
          success: action.message,
        }
      };
    case actionConstants.ASSIGN_JOB_FAILURE:
      return {
        ...state,
        assignedJob: {
          ...state.assignedJob,
          error: action.message,
          isFetching: false,
          isSuccess: false,
          success: '',
        }
      };
    case actionConstants.UNASSIGN_JOB_REQUEST:
      return {
        ...state,
        unassignedJob: {
          ...state.unassignedJob,
          isSuccess: false,
          error: '',
          isFetching: true,
        }
      };
    case actionConstants.UNASSIGN_JOB_SUCCESS:
      return {
        ...state,
        unassignedJob: {
          ...state.unassignedJob,
          error: '',
          isFetching: false,
          jobDetail: action.payload,
          isSuccess: true,
        }
      };
    case actionConstants.UNASSIGN_JOB_FAILURE:
      return {
        ...state,
        unassignedJob: {
          ...state.unassignedJob,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        }
      };
    case actionConstants.DELETE_JOB_REQUEST:
      return {
        ...state,
        deletedJob: {
          ...state.deletedJob,
          isSuccess: false,
          error: '',
          isFetching: true,
        }
      };
    case actionConstants.DELETE_JOB_SUCCESS:
      return {
        ...state,
        deletedJob: {
          ...state.deletedJob,
          error: '',
          isFetching: false,
          jobDetail: action.payload,
          isSuccess: true,
        }
      };
    case actionConstants.DELETE_JOB_FAILURE:
      return {
        ...state,
        deletedJob: {
          ...state.deletedJob,
          error: action.message,
          isFetching: false,
          isSuccess: false,
        }
      };
    case actionConstants.GET_LAT_LONG_WITH_ADDRESS_REQUEST:
      return {
        ...state,
        latLong: {
          ...latLong,
          isFetching: true,
          error: '',
          isSuccess: false
        }
      };
    case actionConstants.GET_LAT_LONG_WITH_ADDRESS_SUCCESS:
      return {
        ...state,
        latLong: {
          ...state.latLong,
          location: action.payload.results[0].geometry.location,
          uniqueId: action.uniqueId,
          address: action.payload.results[0].formatted_address,
          customerName: action.customerName,
          isFetching: false,
          isSuccess: true,
          error: '',
        }
      };
    case actionConstants.GET_LAT_LONG_WITH_ADDRESS_FAILURE:
      return {
        ...state,
        latLong: {
          ...latLong,
          error: action.message,
          isFetching: false
        }
      };
    case actionConstants.UPDATE_JOB_REQUEST:
      return {
        ...state,
        updateJob: {
          isSuccess: false,
          error: '',
          isFetching: true,
          success: '',
        }
      };
    case actionConstants.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        updateJob: {
          error: '',
          isFetching: false,
          isSuccess: true,
          success: action.message,
        }
      };
    case actionConstants.UPDATE_JOB_FAILURE:
      return {
        ...state,
        updateJob: {
          error: action.message,
          isFetching: false,
          isSuccess: false,
          success: '',
        }
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        jobList: {
          ...jobList,
          isSuccess: false,
          error: '',
        },
        jobDetails: {
          ...initialState.jobDetails,
        },
        latLong: {
          ...initialState.latLong,
        },
        teamListWithFilters: {
          ...teamListWithFilters,
          error: '',
          isfetching: false,
          isSuccess: false,
          pages: 1,
          totalListCount: 0,
          currentPage: 1,
        },
      };
    case actionConstants.RESET_FETCH_JOB_LIST:
      return {
        ...state,
        jobList: {
          error: '',
          isFetching: false,
          jobList: [],
          isSuccess: false,
          pages: 0,
          totalListCount: 0,
          currentPage: 1,
        }
      };
    default:
      return state;
  }
};
