import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  clientInfo: {},
  ownerInfo: {},
  isSuccess: false,
  success: ''
};

export default function setClientOrOwnerDetails(state = initialState, action) {
  switch (action.type) {
    case actionConstants.SET_CLIENT_OR_OWNER_DETAILS_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.SET_OWNER_DETAILS_SUCCESS:
      return {
        ...state,
        ownerInfo: action.payload.ownerInfo,
        isfetching: false,
        error: '',
        isSuccess: true,
        success: action.message
      };
    case actionConstants.SET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        clientInfo: action.payload,
        isfetching: false,
        error: '',
        success: action.message,
        isSuccess: true,
      };
    case actionConstants.SET_CLIENT_OR_OWNER_DETAILS_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
        isSuccess: false,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        isfetching: false,
        isSuccess: false,
        success: ''
      };
    default:
      return state;
  }
}
