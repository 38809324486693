import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  supplierData: null,
};

export default function getSupplierInfo(state = initialState, action) {
  switch (action.type) {
    case actionConstants.GET_SUPPLIER_DETAILS_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.GET_REPORT_PART_LIST_SUCCESS:
      return {
        ...state,
        supplierData: action.payload,
        isfetching: false,
        success: action.payload.message,
      };
    case actionConstants.GET_SUPPLIER_DETAILS_SUCCESS:
      return {
        ...state,
        supplierData: action.payload,
        isfetching: false,
        success: action.payload.message,
      };

    case actionConstants.INVENTORY_GET_SUPPLIER_LIST_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.GET_SUPPLIER_DETAILS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
