import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import './TermsAndCondition.css';
import Button from '../../components/Button';
import endpoints from '../../routes';
import { updateTermsAndCondition } from '../../redux/actions/auth';
import { displayToast } from '../../_helpers/commonFunctions';
import messages from '../../i18n/messages';

export class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTermsConditonChecked: false,
    };
  }

  handleChange = () => {
    this.setState({ isTermsConditonChecked: !this.state.isTermsConditonChecked });
  }

  handleProcced = () => {
    if (this.state.isTermsConditonChecked) {
      // const { login } = this.props.auth;
      // this.props.updateTermsAndCondition(
      //   this.state.isTermsConditonChecked,
      //   login.user.customerId,
      //   login.user.role
      // );
      this.props.history.push(endpoints.privacyPolicy);
    } else {
      displayToast('Please accept terms and conditions to login', 'error');
    }
  }

  render() {
    if (!this.props.auth.loginData.token) {
      this.props.history.push(endpoints.login);
    }
    return (
      <div className='container-fluid pageBackground'>
        <div className='card termsConditionCard'>
          <div className='headerOfTermsCondition h3 pt-4 pb-4'>
            <span className='terms-condition-heading'>
              <FormattedMessage
                id='TERMS OF USE'
                defaultMessage='TERMS OF USE' />
            </span>
          </div>
          <div className='terms-condtions-content-area'>
            <ol className='terms-condition-points'>
              <li>
                <span className='tc-main-point'>
                  <FormattedMessage
                    id='Your Agreement and Acceptance of Terms:'
                    defaultMessage='Your Agreement and Acceptance of Terms:' />
                </span>
                <ol type='a' className='terms-condition-sub-points'>
                  <li>
                    <FormattedMessage
                      id='TermsCondition1a'
                      defaultMessage='This document sets forth your legal agreement with FireLab.
                        Your use of theFireLab website, web application, mobile application
                        or service (collectively Service or Services) is subject
                        to these Terms of Use (the General Terms).' />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition1b'
                      defaultMessage='In the event that the General Terms change
                        (FireLab may do so at any time, at its sole discretion),
                        then a new copy will be available at http://www.firelabinc.com.
                        Your use of the Services is subject to the most current version
                        of the General Terms at the time of such use.' />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition1c'
                      defaultMessage='You may not use the Services if you do not
                        agree to these General Terms. You may accept these General
                        Terms by:' />
                    <ol type='i' className='temrs-condition-sub-subtype'>
                      <li>
                        <FormattedMessage
                          id='TermsCondition1ca'
                          defaultMessage='selecting "I agree" to these General Terms below, or' />
                      </li>
                      <li>
                        <FormattedMessage
                          id='TermsCondition1cb'
                          defaultMessage='using the Services in any way, such as downloading
                            or uploading any materials provided by FireLab, including
                            without limitation, any information, data, documents, images,
                            designs, graphics, audio, videos, products, applications
                            and software (collectively "FireLab Materials").' />
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <span className='tc-main-point'>
                  <FormattedMessage
                    id='Ownership:'
                    defaultMessage='Ownership:' />
                </span>
                <ol type='a' className='terms-condition-sub-points'>
                  <li>
                    <FormattedMessage
                      id='TermsCondition2a'
                      defaultMessage='All of the Services and FireLab Materials are subject
                        to, and protected by, copyright, moral rights, trademark,
                        trade dress, patent, trade secret, unfair competition,
                        and any other applicable intellectual and proprietary rights
                        (collectively, the "FireLab Intellectual Property Rights").' />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition2b'
                      defaultMessage='Except as expressly provided in these General Terms,
                        FireLab and its licensors do not grant any express or implied rights
                        to use the Services and FireLab Materials.
                        All rights, title, and interest in the Service
                        and FireLab Materials, in all languages, formats, and media
                        throughout the world, are and will continue to be the exclusive
                        property of FireLab and/or its licensors and nothing in these
                        General Terms shall be construed to confer any license or right,
                        by implication, estoppel or otherwise, under copyright or other
                        intellectual property rights, to you or any third party.' />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition2c'
                      defaultMessage='All trademarks, logos and service marks displayed
                        on the Services (collectively, the "Marks") are the property
                        of FireLab or other rights holders. You are not permitted to use
                        the Marks without the prior consent of FireLab or the rights holder.
                        "FireLab" and the associated logo "Aries" are trademarks of FireLab.' />
                  </li>
                </ol>
              </li>
              <li>
                <span className='tc-main-point'>
                  <FormattedMessage
                    id='Use of Services, FireLab Materials and FireLab Software.'
                    defaultMessage='Use of Services, FireLab Materials and FireLab Software.' />
                </span>
                <ol type='a' className='terms-condition-sub-points'>
                  <li>
                    <FormattedMessage
                      id='TermsCondition3a'
                      defaultMessage='Providing you comply at all times with these
                        General Terms, FireLab grants to you a non-exclusive, non-transferable,
                        revocable and limited right to access and use the Services,
                        to email, post, transmit, upload, or otherwise make available
                        through your use of the Services your own data (collectively,
                        "User Data"), and to use the FireLab Materials in connection
                        with the Services, subject to the restrictions stated in this Section.' />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition3b'
                      defaultMessage='You hereby agree:' />
                    <ol type='i' className='temrs-condition-sub-subtype'>
                      <li>
                        <FormattedMessage
                          id='TermsCondition3ba'
                          defaultMessage='Not to license, rent, or sell the FireLab Materials
                            or the right to use and access the Services;' />
                      </li>
                      <li>
                        <FormattedMessage
                          id='TermsCondition3bb'
                          defaultMessage='Not to copy or imitate part or all of the design,
                            layout, or look-and-feel of the Services, which are protected
                            by the FireLab Intellectual Property Rights;' />
                      </li>
                      <li>
                        <FormattedMessage
                          id='TermsCondition3bc'
                          defaultMessage='To use the Services and the FireLab Materials
                            only as permitted by these General Terms and any applicable law;' />
                      </li>
                      <li>
                        <FormattedMessage
                          id='TermsCondition3bd'
                          defaultMessage='Not to defraud, defame, or otherwise violate the
                            legal rights (such as rights of privacy and publicity) of third
                            parties;' />
                      </li>
                      <li>
                        <FormattedMessage
                          id='TermsCondition3be'
                          defaultMessage='Not to remove, obscure, or alter any text or
                            proprietary notices contained in the FireLab Materials;' />
                      </li>
                      <li>
                        <FormattedMessage
                          id='TermsCondition3bf'
                          defaultMessage='Not to access or attempt to access the Services
                            by any means other than the interfaces provided by FireLab
                            or circumvent any access or use restrictions put into place
                            to prevent certain uses of the Services; and' />
                      </li>
                      <li>
                        <FormattedMessage
                          id='TermsCondition3bg'
                          defaultMessage='You are solely responsible for obtaining and maintaining any equipment or ancillary services needed to access mobile and tablet applications in relation to the Services and you are responsible for all applicable taxes and fees incurred while accessing such applications (such as fees from your mobile carrier, overage charges, etc.)' />
                      </li>
                      <li>
                        <FormattedMessage
                          id='TermsCondition3bh'
                          defaultMessage='FireLab may modify or discontinue, temporarily
                            or permanently, the Services or FireLab Materials, or any portion
                            thereof, with or without notice. You agree that FireLab shall
                            not be liable to you or anyone else in the event of such modification
                            and/or discontinuance.' />
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <span className='tc-main-point'>
                  <FormattedMessage
                    id='User Material:'
                    defaultMessage='User Material:' />
                </span>
                <ol type='a' className='terms-condition-sub-points'>
                  <li>
                    <FormattedMessage
                      id='TermsCondition4a'
                      defaultMessage='As a component of the Services, FireLab shall,
                        subject to these General Terms and any additional terms or
                        conditions that FireLab may apply, provide online storage
                        for certain User Material,. Unless otherwise stated in
                        these General Terms or a separate written agreement
                        between you and FireLab, FireLab has:' />
                    <ol type='i'className='temrs-condition-sub-subtype'>
                      <li>
                        <FormattedMessage
                          id='TermsCondition4aa'
                          defaultMessage={messages.en.TermsCondition4aa} />
                      </li>
                      <li>
                        <FormattedMessage
                          id='TermsCondition4ab'
                          defaultMessage={messages.en.TermsCondition4ab} />
                      </li>
                    </ol>
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition4b'
                      defaultMessage={messages.en.TermsCondition4b} />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition4c'
                      defaultMessage={messages.en.TermsCondition4c} />
                  </li>
                </ol>
              </li>
              <li>
                <span className='tc-main-point'>
                  <FormattedMessage
                    id='Warranty, Indemnification Obligation, and Waiver:'
                    defaultMessage='Warranty, Indemnification Obligation, and Waiver:' />
                </span>
                <ol type='a' className='terms-condition-sub-points'>
                  <li>
                    <FormattedMessage
                      id='TermsCondition5a'
                      defaultMessage={messages.en.TermsCondition5a} />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition5b'
                      defaultMessage={messages.en.TermsCondition5b} />
                  </li>
                </ol>
              </li>
              <li>
                <span className='tc-main-point'>
                  <FormattedMessage
                    id='Disclaimer of Warranties:'
                    defaultMessage='Disclaimer of Warranties:' />
                </span>
                <ul style={{ marginTop: '8px' }}>
                  <li>
                    <FormattedMessage
                      id='You expressly understand and agree that, to the maximum extent permitted by applicable law:'
                      defaultMessage='You expressly understand and agree that, to the maximum extent permitted by applicable law:' />
                  </li>
                </ul>
                <ol type='a' className='terms-condition-sub-points'>
                  <li>
                    <FormattedMessage
                      id='TermsCondition6a'
                      defaultMessage={messages.en.TermsCondition6a} />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition6b'
                      defaultMessage={messages.en.TermsCondition6b} />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition6c'
                      defaultMessage={messages.en.TermsCondition6c} />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition6d'
                      defaultMessage={messages.en.TermsCondition6d} />
                  </li>
                </ol>
              </li>
              <li>
                <span className='tc-main-point'>
                  <FormattedMessage
                    id='Limitation of Liability:'
                    defaultMessage='Limitation of Liability:' />
                </span>
                <ol type='a' className='terms-condition-sub-points'>
                  <li>
                    <FormattedMessage
                      id='TermsCondition7a'
                      defaultMessage={messages.en.TermsCondition7a} />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition7b'
                      defaultMessage={messages.en.TermsCondition7b} />
                  </li>
                </ol>
              </li>
              <li>
                <span className='tc-main-point'>
                  <FormattedMessage
                    id='Resolution of Disputes and Injunctive Remedies:'
                    defaultMessage='Resolution of Disputes and Injunctive Remedies:' />
                </span>
                <ol type='a' className='terms-condition-sub-points'>
                  <li>
                    <FormattedMessage
                      id='TermsCondition8a'
                      defaultMessage={messages.en.TermsCondition8a} />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition8b'
                      defaultMessage={messages.en.TermsCondition8b} />
                  </li>
                </ol>
              </li>
              <li>
                <span className='tc-main-point'>
                  <FormattedMessage
                    id='Miscellaneous:'
                    defaultMessage='Miscellaneous:' />
                </span>
                <ol type='a' className='terms-condition-sub-points'>
                  <li>
                    <FormattedMessage
                      id='TermsCondition9a'
                      defaultMessage={messages.en.TermsCondition9a} />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition9b'
                      defaultMessage={messages.en.TermsCondition9b} />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition9c'
                      defaultMessage={messages.en.TermsCondition9c} />
                  </li>
                  <li>
                    <FormattedMessage
                      id='TermsCondition9d'
                      defaultMessage={messages.en.TermsCondition9d} />
                  </li>

                  <li>
                    <FormattedMessage
                      id='TermsCondition9e'
                      defaultMessage={messages.en.TermsCondition9e} />
                  </li>
                </ol>
              </li>
            </ol>
            <div className="value">
              <div
                className="row d-flex align-items-center checkbox-for-terms-and-condn"
                style={{ fontSize: '16px' }}>
                <label className='cust-checkbox' style={{ color: '#B71B1B' }}>
                  <FormattedMessage
                    id='I accept Terms and Conditions'
                    defaultMessage='I accept Terms and Conditions' />
                  <input
                    name='terms-conditions'
                    style={{ width: '14px', marginRight: '8px' }}
                    checked={this.state.isTermsConditonChecked}
                    onClick={this.handleChange}
                    type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div className='Proceed-btn'>
            <Button
              className='cloneButton'
              text = {<FormattedMessage
                    id='Proceed'
                    defaultMessage='Proceed' />}
              onBtnClick={this.handleProcced} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

TermsAndConditions.propTypes = {
  history: PropTypes.object,
  auth: PropTypes.object,
  updateTermsAndCondition: PropTypes.func,
};

export default connect(mapStateToProps, {
  updateTermsAndCondition
})(TermsAndConditions);
