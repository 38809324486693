import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import ToolTip from '../ToolTip/index';

const Input = (props) => {
  const {
    dataFor,
    dataTip,
    place,
    value,
    onChange,
    name,
    className,
    type,
    max,
    disabled,
    placeholder,
    onClick,
    noMax,
    step
  } = props;
  return (
    <Fragment>
      <input
        data-for={dataFor}
        data-tip={dataTip && props.intl.formatMessage({ id: dataTip })}
        type={type}
        value={value}
        disabled={disabled}
        onChange={onChange}
        name={name}
        {...(step && {step})}
        onClick={onClick}
        className={className}
        placeholder={props.intl.formatMessage({ id: placeholder })}
        {...(!noMax && { maxLength: max || 50})}
        autoComplete="nope"
        spellCheck='true'
      />
      <ToolTip id={dataFor} place={place} />
    </Fragment>

  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]), // Value of input field
  onChange: PropTypes.func, // handles input field on change
  name: PropTypes.string, // name of input field
  className: PropTypes.string, // class name of input field
  type: PropTypes.string, // type of input field
  max: PropTypes.number, // max attribute of input field
  disabled: PropTypes.bool, // disabled attribute of input field
  placeholder: PropTypes.string, // placeholder of input field
  onClick: PropTypes.func, // handles input field on click
  intl: intlShape.isRequired,
  place: PropTypes.string,
  dataFor: PropTypes.string,
  dataTip: PropTypes.string
};

Input.defaultProps = {
  placeholder: 'Input Here',
  place: 'bottom'
};

export default injectIntl(Input);
