import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Checkbox from '../Checkbox';
import ToolTip from '../ToolTip';

export class CustomMultiSelectDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectFieldValue: this.props.selectedValues || [],
      showDropDown: false
    };
  }

  node = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  /**
   * Handles dropdown menu close when user clicks elsewhere on the screen
   *
   * @memberof CustomMultiSelectDropDown
   */
  handleClick = (e) => {
    if (this.node.current.contains(e.target)) {
      return;
    }
    this.setState({
      showDropDown: false
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value === '') {
      this.setState({ selectFieldValue: this.props.value });
    }
  }

  /**
   * Handles dropdown items selection
   *
   * @memberof CustomMultiSelectDropDown
   */
  handleOnClick = (textContent) => {
    const { selectFieldValue } = this.state;
    const selectedValue = [...this.state.selectFieldValue];
    if (selectFieldValue.includes(textContent)) {
      const indexOfEle = selectFieldValue.indexOf(
        textContent
      );
      selectFieldValue.splice(indexOfEle, 1);
    } else {
      selectedValue.push(textContent);
    }
    this.setState({
      selectFieldValue: selectedValue
    });
  }

  render() {
    const { showDropDown } = this.state;
    const {
      dropDownList,
      dropItemSelected,
      label,
      width,
      handleOnChange,
      error,
      className,
      height,
      optionClassName,
      placeholder,
      style,
      customStyle,
      dropDownWidth,
      dropDownListBaseLineWidth,
      dataFor,
      dataTip,
      namesWithId,
    } = this.props;

    let dropItemsArray = [];
    if (dropItemSelected) {
      dropItemsArray = Object.keys(dropItemSelected);
    }

    return (
      <React.Fragment>
        <div
          className={className}
          style={customStyle}
          data-for={dataFor}
          data-tip={dataTip && this.props.intl.formatMessage({ id: dataTip })}
        >
          <span className='errorMessage' style={style}>
            {error ? <FormattedMessage id={error} defaultMessage={error} /> : ''}
          </span>
          <div
            className='custom-select'
            style={{ width: `${width}` }}
            ref={this.node}
          >
            <div
              className={`select-selected ${
                showDropDown ? 'select-arrow-active' : ''}`
              }
              onClick={() => this.setState({ showDropDown: !showDropDown })}
              placeholder={placeholder}
            >
              {
                dropItemSelected && dropItemsArray.length > 0
                  ? dropItemsArray.length > 1
                    ? namesWithId ? `${namesWithId[dropItemsArray[0]]}, ${namesWithId[dropItemsArray[1]]}, ${namesWithId[dropItemsArray[2]] || ''}, ${namesWithId[dropItemsArray[3]] || ''} `
                      : `${dropItemsArray[0]}, ${dropItemsArray[1]}, ${dropItemsArray[2] || ''}, ${dropItemsArray[3] || ''}, `
                    : namesWithId ? `${namesWithId[dropItemsArray]}` : `${dropItemsArray}`
                  : <FormattedMessage
                    id={placeholder}
                    defaultMessage={placeholder}
                  />
              }
            </div>
            <div
              style={{ height: `${height}`, width: `${dropDownWidth}`, overflow: 'auto' }}
              className={`select-items ${showDropDown ? '' : 'select-hide'}`}
              data-mcs-theme='minimal-dark'
            >
              {dropDownList.map((dropDownItem, index) => (
                <div
                  className={`${optionClassName} selecthover`}
                  style={{ width: `${dropDownListBaseLineWidth}` }}
                  key={dropDownItem.id || dropDownItem + index}
                >
                  <label className="cust-checkbox">
                    <FormattedMessage
                      id={dropDownItem.name || dropDownItem}
                      defaultMessage={dropDownItem.name || dropDownItem} />
                    <Checkbox
                      type='checkbox'
                      name={dropDownItem.id || dropDownItem}
                      onClick={this.handleOnClick}
                      checked={dropItemSelected
                        && dropItemSelected[dropDownItem.id || dropDownItem]}
                      onChange={handleOnChange} />
                    <span className="checkmark"></span>
                  </label>
                </div>
              ))}
              {!dropDownList.length
                && <div
                  className={`${optionClassName} selecthover`}
                  style={{ width: `${dropDownListBaseLineWidth}` }}>
                  <FormattedMessage
                    id='No data available'
                    defaultMessage='No data available'
                  />
                </div>}
            </div>
          </div>
          <label htmlFor='usr'>
            {label ? <FormattedMessage id={label} defaultMessage={label} /> : ''}
          </label>
        </div>
        <ToolTip
          id={dataFor}
          place='top'
        />
      </React.Fragment>
    );
  }
}

CustomMultiSelectDropDown.propTypes = {
  dropDownList: PropTypes.array, // drop down list menu items
  label: PropTypes.string, // label of dropdown
  width: PropTypes.string, // width of dropdown
  name: PropTypes.string, // name of dropdown element
  handleOnChange: PropTypes.func, // handles dropdown items selection
  error: PropTypes.string,
  value: PropTypes.string, // value of dropdown
  className: PropTypes.string, // class name of dropdown
  height: PropTypes.string, // height of dropdown
  optionClassName: PropTypes.string, // options class name
  placeholder: PropTypes.string, // placeholder for dropdown
  dropItemSelected: PropTypes.object, // selected dropdown items
  style: PropTypes.object, // style object for dropdown
  selectedList: PropTypes.object, // selected drop menu list
  selectedValues: PropTypes.array, // selected dropmenu values
  customStyle: PropTypes.object, // style object for container div
  dropDownWidth: PropTypes.string, // dropdown menu items width
  dropDownListBaseLineWidth: PropTypes.string,
  dataFor: PropTypes.string,
  dataTip: PropTypes.string,
  intl: intlShape.isRequired,
  namesWithId: PropTypes.object,
};

CustomMultiSelectDropDown.defaultProps = {
  width: '100%',
  error: '',
  className: 'dis-flex f-dir-column mr-top-24',
  placeholder: ' ',
};

export default injectIntl(CustomMultiSelectDropDown);
