import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ServiceRequestListFilterDropDownComponent = (props) => {
  const {
    headLabels,
    childLabels,
    expandedIndex,
    onApply,
    onHeadPress,
    onChildPress,
    onReset,
  } = props;
  return (
    <ul
      className='dropdown-menu show'
      x-placement='bottom-start'
      style={{
        position: 'absolute',
        willChange: 'transform',
        top: '0px',
        left: '0px',
        transform: 'translate3d(-131px, 40px, 0px)'
      }}
    >
      <div className='apply-filter'>
        <span className='reset' onClick={onReset}>
          <FormattedMessage
            id='Reset'
            defaultMessage='Reset'
          />
        </span>
        <span className='apply' onClick={onApply}>
        <FormattedMessage
            id='Apply Filter'
            defaultMessage='Apply Filter'
          />
        </span>
      </div>
      {
        headLabels.map((headItem, headIndex) => (
            <li className='dropdown-submenu' key={headIndex}>
              <a
                className={`dropdown-head ${headIndex === expandedIndex ? 'menu-drop' : ''}`}
                tabIndex='-1'
                style={{ cursor: 'pointer' }}
                onClick={() => onHeadPress(headIndex)}
              >
                <FormattedMessage
                  id={headItem}
                  defaultMessage={headItem}
                />
                <span className='caret'></span>
              </a>
              <ul
                className='dropdown-menu'
                style={{ display: headIndex === expandedIndex ? 'block' : 'none', maxHeight: '300px', overflowY: 'auto' }}
              >
              {
                Object.keys(childLabels[headIndex]).map(childItem => (
                  <li key={childItem}>
                    <label className='cust-checkbox'>
                        <FormattedMessage
                          id={childItem}
                          defaultMessage={childItem}
                        />
                        <input type='checkbox' checked={childLabels[headIndex][childItem]} onChange={() => onChildPress(headLabels[headIndex], childItem) }/>
                        <span className='checkmark'></span>
                    </label>
                </li>
                ))
              }
              </ul>
            </li>
        ))
      }
      </ul>
  );
};

ServiceRequestListFilterDropDownComponent.propTypes = {
  headLabels: PropTypes.array.isRequired,
  childLabels: PropTypes.array.isRequired,
  expandedIndex: PropTypes.number,
  onApply: PropTypes.func,
  onHeadPress: PropTypes.func,
  onChildPress: PropTypes.func,
  onReset: PropTypes.func,
};

export default ServiceRequestListFilterDropDownComponent;
