import React from 'react';
import { OfflineModeContext, fetch, offlineModeOptions } from '../../../../libs/constants/offlineEnhanced';
import Modal from '../../../../components/Modal';
import OfflineModeOptionsModalBody from './OfflineModeOptionsModalBody';
import SelectCustomerModalBody from './SelectCustomerModalBody';
import SelectJobsModalBody from './SelectJobsModalBody';

const OfflineModeModals = () => (
  <OfflineModeContext.Consumer>
    {value => (
      <div>
        {value.offlineMode.isModalVisible && <Modal
          modalHeading='Download Reports/ Jobs for the day'
          ModalBody={OfflineModeOptionsModalBody}
          ModalBodyProps={{
            setInitialModalOption: () => value.selectOfflineModalOption(offlineModeOptions[0])
          }}
          handleClickCross={() => {
            value.toggleModalVisibility(false);
            value.setCustomerModalState({
              selectedCustomer: {},
              reportCategory: '',
              reportType: '',
            });
          }}
        />}
        {value.offlineMode.isSelectCustomerModalVisible && <Modal
          modalHeading='Download Reports/ Jobs for the day'
          ModalBody={SelectCustomerModalBody}
          ModalBodyProps={{
            setCustomerModalState: value.setCustomerModalState,
            getClientsList: value.getClientsList,
            offlineMode: value.offlineMode,
            clientData: value.clientData,
            reportCat: value.reportCat,
            reportCategory: value.reportCategory,
            toggleCustomerModalVisibility: value.toggleCustomerModalVisibility,
            toggleModalVisibility: value.toggleModalVisibility,
            addCustomerToList: value.addCustomerToList,
            toggleOffline: value.toggleOffline,
            setStep: () => value.setStep(fetch.JOB_LIST),
          }}
          handleClickCross={value.toggleCustomerModalVisibility}
          isCrossVisible={value.offlineMode.isOffline}
        />}
        {value.offlineMode.isSelectJobsModalVisible && <Modal
          modalHeading='Download Reports/ Jobs for the day'
          ModalBody={SelectJobsModalBody}
          ModalBodyProps={{
            handleClickPrevious: () => {
              value.toggleModalVisibility(true);
              value.toggleJobsModalVisibility(false);
            },
            handleClickSave: () => {
              value.toggleJobsModalVisibility(false);
              value.toggleOffline(true);
              value.setStep(fetch.CUSTOMER_LIST);
            },
            reportCategories: Object.keys(value.reportCategory.reportData),
            jobList: value.jobListDetails.jobList,
            missedJobs: value.jobListDetails.missedJobList,
            getMissedJobListAction: value.getMissedJobListAction,
            getJobListAction: value.getJobListAction,
            setSelectedJobsClients: value.setSelectedJobsClients,
            offlineMode: value.offlineMode,
            resetOfflineJobsList: value.resetOfflineJobsList,
          }}
          isCrossVisible={false}
        />}
      </div>)}
  </OfflineModeContext.Consumer>
);

export default OfflineModeModals;
