import actionConstants from '../constants/ActionConstants';
import { reportsListApiCall } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const getReportList = (page, limit, searchText, reportType,
  ReportCategory, CustomerNameSort, startDate, endDate, status,
  createdFor, isDefault, isLegacy, createdBy, hideMigrated) => (
  ActionDispatcher(reportsListApiCall.bind(null, page, limit, searchText, reportType,
    ReportCategory, CustomerNameSort, startDate, endDate, status, createdFor,
    isDefault, isLegacy, createdBy, hideMigrated),
  actionConstants.GET_REPORT_LIST_SUCCESS,
  actionConstants.GET_REPORT_LIST_FAILURE,
  actionConstants.GET_REPORT_LIST_REQUEST
  )
);

export const clearReports = () => dispatch => dispatch({
  type: actionConstants.CLEAR_REPORT_LIST
});

export default getReportList;
