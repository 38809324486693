import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  isSuccess: false,
};

function updateHours(state = initialState, action) {
  switch (action.type) {
    case actionConstants.UPDATE_HOURS_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
        success: '',
        error: '',
      };
    case actionConstants.UPDATE_HOURS_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccess: true,
        success: action.message
      };
    case actionConstants.UPDATE_HOURS_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
        isSuccess: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isfetching: false,
        isSuccess: false,
        success: ''
      };
    default:
      return state;
  }
}

export default updateHours;
