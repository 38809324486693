import call from './apiRequest';
import apiEndpoints from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call for updating insection report answers
 * @param {object} payload
 */
const reportInspectionUpdatedAnswer = payload => call({
  method: 'put',
  payload,
  endpoint: apiEndpoints.reportInspectionAnswer,
  authKey: getToken()
});

export const getDevicesApi = (page, limit, listId) => call({
  method: 'get',
  endpoint: `${apiEndpoints.clientDevice}/${apiEndpoints.clientDeviceList}/${listId}`,
  query: {
    page,
    limit
  },
  authKey: getToken()
});

export const updateDefaultReportRealtimeApi = payload => call({
  method: 'post',
  endpoint: `${apiEndpoints.realTime}`,
  authKey: getToken(),
  payload: { ...payload, eventNo: getToken().slice(-10) },
});

export const getClientDetailsApi = id => call({
  method: 'get',
  endpoint: `${apiEndpoints.clientDetails}`,
  query: {
    id
  },
  authKey: getToken()
});

export const updateClientDetails = payload => call({
  method: 'put',
  payload,
  endpoint: `${apiEndpoints.clientDetailsSignature}`,
  authKey: getToken()
});

export default reportInspectionUpdatedAnswer;
