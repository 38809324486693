import call from './apiRequest';
import { getToken } from '../_helpers/commonFunctions';
import apiEndpoints from './apiEndpoints';

/**
 * Api call for getting clients list
 */
export default (page, limit = 10, ids) => call({
  method: 'get',
  endpoint: `${apiEndpoints.clientList}?page=${page}&limit=${limit}`,
  query: { ids },
  authKey: getToken(),
});

/**
 * Api call for getting reports list by job id
*/
export const reportListByJobIdsApiCall = ids => call({
  method: 'post',
  endpoint: apiEndpoints.reportListByJobIds,
  payload: { ids },
  authKey: getToken(),
});

/**
 * Api call for getting questionJson for all report categories
 * @param {array} reportCategory
 */
export const questionJsonDefaultReports = reportCategory => call({
  method: 'post',
  endpoint: apiEndpoints.questionJsonDefaultReports,
  payload: { reportCategory },
  authKey: getToken(),
});
