import actionConstants from '../constants/ActionConstants';
import { myJobListApiCall } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const getMyJobList = (page, limit, queryParam) => (
  ActionDispatcher(myJobListApiCall.bind(null, page, limit, queryParam),
    actionConstants.GET_MY_JOB_LIST_SUCCESS,
    actionConstants.GET_MY_JOB_LIST_FAILURE,
    actionConstants.GET_MY_JOB_LIST_REQUEST
  )
);

export default getMyJobList;
