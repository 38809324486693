import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  reportDetails: null,
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.FETCH_REPORT_DETAIL_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false
      };
    case actionConstants.FETCH_REPORT_DETAIL_SUCCESS:
      return {
        ...state,
        reportDetails: {
          ...(state.reportDetails || {}),
          ...action.payload
        },
        isfetching: false,
        isSuccess: true
      };
    case actionConstants.FETCH_REPORT_DETAIL_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false
      };
    case actionConstants.FETCH_REPORT_DETAIL_RESET:
      return {
        ...state,
        reportDetails: null,
        isSuccess: false,
        error: '',
      };
    case actionConstants.FETCH_REPORT_DETAIL_SUCCESS_RESET:
      return {
        ...state,
        isSuccess: false,
        error: '',
      };
    default:
      return state;
  }
};
