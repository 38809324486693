const spanClass = 'joblink-icon1 sprite-icon';

export default [
  {
    wrapperClass: 'driving',
    name: 'Driving',
    spanClass,
  },
  {
    wrapperClass: 'working',
    name: 'Working',
    spanClass,
  },
  {
    wrapperClass: 'break',
    name: 'Break',
    spanClass,
  },
  {
    wrapperClass: 'checkout',
    name: 'Clocked Out',
    spanClass,
  },
  {
    wrapperClass: 'miscellaneous',
    name: 'Miscellaneous',
    spanClass,
  }
];
