import { ReportAddNew, savePartApi } from '../../api/reports';
import { reportCreateInspection } from '../../api/apiCallReport';

import actionConstants from '../constants/ActionConstants';
import actionDispatcher from '../../api/ActionDispatcher';

const updateReport = data => (
  actionDispatcher(ReportAddNew.bind(null, data),
    actionConstants.UPDATE_REPORT_SUCCESS,
    actionConstants.UPDATE_REPORT_FAILURE,
    actionConstants.UPDATE_REPORT_REQUEST)
);

const savePartAction = (name, quantity, price, reportType) => (
  actionDispatcher(savePartApi.bind(null, name, quantity, price, reportType),
    actionConstants.SAVE_PART_SUCCESS,
    actionConstants.SAVE_PART_FAILURE,
    actionConstants.SAVE_PART_REQUEST
  )
);
const createInspection = data => (
  actionDispatcher(reportCreateInspection.bind(null, data),
    actionConstants.UPDATE_REPORT_SUCCESS,
    actionConstants.UPDATE_REPORT_FAILURE,
    actionConstants.UPDATE_REPORT_REQUEST)
);

const setFinishedKey = () => (dispatch) => {
  dispatch({ type: actionConstants.UPDATE_REPORT_FINISH_REPORT });
};

const updateReportClear = () => async (dispatch) => {
  dispatch({ type: actionConstants.UPDATE_REPORT_CLEAR });
};

const clearPartReducer = () => (dispatch) => {
  dispatch({ type: actionConstants.CLEAR_PART_REDUCER });
};

const clearSuccess = () => (dispatch) => {
  dispatch({ type: actionConstants.CLEAR_SUCCESS_REPORT });
};

const clearImageDetails = () => (dispatch) => {
  dispatch({ type: actionConstants.CLEAR_IMAGE_DETAILS_FOR_SOCKET });
};

const setImageSocketDetails = payload => (dispatch) => {
  dispatch({
    type: actionConstants.UPDATE_IMAGE_DETAILS_FOR_SOCKET,
    payload
  });
};

export {
  updateReport, updateReportClear, clearSuccess, setFinishedKey, createInspection,
  savePartAction, clearPartReducer, setImageSocketDetails, clearImageDetails
};
