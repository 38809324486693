import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  isDeleteFetching: false,
  isEditFetching: false,
  isAddFetching: false,
  success: '',
  deviceData: null,
  isSuccess: false,
  totalCount: null,
  totalPages: null,
  currentPage: null,
};

export default function addClientDevice(state = initialState, action) {
  switch (action.type) {
    case actionConstants.ADD_DEVICE_REQUEST:
      return {
        ...state,
        isfetching: true,
        isAddFetching: true,
        isSuccess: false
      };
    case actionConstants.ADD_DEVICE_SUCCESS:
      return {
        ...state,
        deviceData: action.payload.devices,
        isfetching: false,
        isAddFetching: false,
        success: action.message,
        isSuccess: true,
        totalCount: action.payload.total,
        totalPages: action.payload.pages,
        currentPage: action.payload.page,
      };
    case actionConstants.ADD_DEVICE_FAILURE:
      return {
        ...state,
        error: action.error,
        isAddFetching: false,
        isfetching: false,
      };
    case actionConstants.EDIT_DEVICE_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isEditFetching: true,
      };
    case actionConstants.EDIT_DEVICE_SUCCESS:
      return {
        ...state,
        deviceData: action.payload.devices,
        success: action.message,
        isEditFetching: false,
        isSuccess: true,
      };
    case actionConstants.EDIT_DEVICE_FAILURE:
      return {
        ...state,
        error: action.message,
        isEditFetching: false,
        isfetching: false,
      };
    case actionConstants.DELETE_DEVICE_REQUEST:
      return {
        ...state,
        error: '',
        isSuccessDelete: false,
        isDeleteFetching: true,
        success: ''
      };
    case actionConstants.DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        deviceData: action.payload.devices,
        isSuccessDelete: true,
        isDeleteFetching: false,
        success: action.message
      };
    case actionConstants.DELETE_DEVICE_FAILURE:
      return {
        ...state,
        error: action.error,
        isDeleteFetching: false,
        isSuccessDelete: false,
      };
    case actionConstants.DELETE_CLIENT_DEVICE_REQUEST:
      return {
        ...state,
        error: '',
        isfetching: true,
        isSuccessDelete: false,
        success: ''
      };
    case actionConstants.DELETE_CLIENT_DEVICE_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccessDelete: true,
        isDeleteFetching: false,
        success: action.message
      };
    case actionConstants.DELETE_CLIENT_DEVICE_FAILURE:
      return {
        ...state,
        error: action.error,
        isfetching: false,
        isDeleteFetching: false,
        isSuccessDelete: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        isSuccess: false,
        isSuccessDelete: false,
        isAddFetching: false,
        isEditFetching: false,
      };
    case actionConstants.CLEAR_DEVICE_DETAILS_DATA:
      return {
        ...state,
        deviceData: null,
      };
    default:
      return state;
  }
}
