import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button from '../../components/Button';
import { checkStringWithOnlySpaces } from '../../_helpers/commonFunctions';
import validationMessage from '../../_helpers/validator/validationMessage';

export class AddNoteModalBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.initialValue,
      error: '',
    };
  }

  /**
   * check for the errors in notes modal
   */
  checkForErrors = () => {
    const { inputValue } = this.state;
    if (!inputValue) {
      this.setState({ error: validationMessage.note });
      return true;
    }
    if (checkStringWithOnlySpaces(inputValue)) {
      this.setState({ error: validationMessage.validNote });
      return true;
    }
    this.setState({ error: '' });
    return false;
  }

  /**
   * handles the save button handling
   * @param {object} e - event
   * @param {bool} isSaveBtn - is save pressed
   */
  handleSaveData = (e, isSaveBtn) => {
    const { handleClickSave } = this.props;
    const { inputValue } = this.state;
    if (!this.checkForErrors()) {
      if (isSaveBtn) {
        handleClickSave(e, inputValue, isSaveBtn);
      } else {
        handleClickSave(e, inputValue, isSaveBtn, () => this.setState({ inputValue: '' }));
      }
    }
  }

  render() {
    const { inputValue, error } = this.state;
    const { intl } = this.props;

    return (
      <div>
        <div className="form-group dis-flex f-dir-column">
          {error && <span className='errorMessage'>
            <FormattedMessage
              id={error}
              defaultMessage={error}
            />
          </span>}
          <textarea
            value={inputValue}
            name='username'
            placeholder={intl.formatMessage({ id: 'Write your question here' })}
            className='form-control'
            max={1000000}
            type='text'
            onChange={(e) => {
              this.setState({ inputValue: e.target.value });
              this.props.SetIsPromptAction(true);
            }}
          />
          <label htmlFor="usr">
            <FormattedMessage
              id='Note'
              defaultMessage='Note'
            />
          </label>
        </div>
        <div className="cust-select-btns">
          <Button
            text='Save and New'
            color='#6AA14E'
            onBtnClick={e => this.handleSaveData(e, false)}
          />
          <Button
            text='Save'
            color='#6AA14E'
            onBtnClick={e => this.handleSaveData(e, true)}
          />
        </div>
      </div>
    );
  }
}

AddNoteModalBody.propTypes = {
  handleClickSave: PropTypes.func,
  initialValue: PropTypes.string,
  intl: intlShape.isRequired,
  SetIsPromptAction: PropTypes.func,
};

export default injectIntl(AddNoteModalBody);
