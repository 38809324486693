import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';

const ConfirmNewOrCopyBody = () => (
    <div>
      <div className="dis-flex f-dir-column mr-top-24">

        <div>
            <label htmlFor="usr">
              <FormattedMessage
                id='Do you want to start new or copy?'
                defaultMessage='Do you want to start new or copy?'
              />
            </label>
          </div>
        </div>
    </div>
);

const ConfirmNewOrCopyFooter = ({ handleClickNewReport, handleClickCopyReport }) => (
  <div className="cust-select-btns mr-top-24">
    <Button
      text='New'
      color='#6AA14E'
      onBtnClick={handleClickNewReport}
    />
     <Button
      text='Copy'
      color='#6AA14E'
      onBtnClick={handleClickCopyReport}
    />
    </div>

);

ConfirmNewOrCopyFooter.propTypes = {
  handleClickNewReport: PropTypes.func,
  handleClickCopyReport: PropTypes.func
};

export { ConfirmNewOrCopyBody, ConfirmNewOrCopyFooter };
