import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { Button } from '../../components/Button/Button';
import { IconButton } from '../../components/IconButton/IconButton';
import '../../views/EmailListForm/EmailListForm.scss';

class Recipients extends React.Component {
  state = {
    recipients: [],
  };

  getRecipients = () => (this.state.recipients.map(recipient => recipient.email));

  renderEmailFields = (emailData, isDisabled = false) => (
    emailData.map((userData, index) => (
      <div className='col-md-6' key={isDisabled ? userData.email : index}>
        <div className='form-group dis-flex f-dir-column'>
          {
            !isDisabled && userData.error
              ? <span className='errorMessage'>
                <FormattedMessage
                  id={userData.error}
                  defaultMessage={userData.error}
                />
              </span>
              : null
          }
          <input
            type='text'
            spellCheck="true"
            className='form-control padding-class'
            value={userData.email}
            disabled={isDisabled}
            onChange={(e) => {
              this.updateRecipient(e.target.value, index);
              this.props.SetIsPromptAction(true);
            }}
          />
          <span
            className="delete-icon sprite-icon delete-icon-position"
            onClick={() => this.deleteRecipient(index, isDisabled)}></span>
        </div>
      </div>
    ))
  );

  deleteRecipient = (index) => {
    const _recipients = JSON.parse(JSON.stringify(this.state.recipients));
    _recipients.splice(index, 1);
    this.setState({ recipients: _recipients });
  }

  updateRecipient = (value, index) => {
    const _recipients = JSON.parse(JSON.stringify(this.state.recipients));
    if (_recipients[index]) {
      _recipients[index].email = value;
    }
    this.setState({ recipients: _recipients });
  }

  addNewEmailField = () => {
    this.setState({
      recipients: [
        ...this.state.recipients,
        {
          email: '',
          error: '',
        }
      ]
    });
  }

  setError = (errorText, index) => {
    const _recipients = JSON.parse(JSON.stringify(this.state.recipients));
    if (_recipients[index]) {
      _recipients[index].error = errorText;
    }
    this.setState({ recipients: _recipients });
  }

  getDuplicateIndices = (emailSet) => {
    const emailStrings = emailSet.map(emailObj => emailObj.email);
    const duplicateIndices = emailStrings
      .map((email, index) => (emailStrings.indexOf(email) !== index))
      .reduce((array, item, index) => (item ? [...array, index] : array), []);
    return duplicateIndices;
  };

  onSubmit = () => {
    const _recipients = JSON.parse(JSON.stringify(this.state.recipients));
    _recipients.forEach((emailObject, index) => {
      this.setError('', index);
    });
    const validationSchema = yup.object({
      email: yup.string().email('Enter a valid email address.').required('Required.')
    });
    let isDataValid = true;
    _recipients.forEach((emailObj, index) => {
      validationSchema.validate(emailObj).then(() => {
        if (isDataValid && index === _recipients.length - 1) {
          const duplicateIndices = this.getDuplicateIndices(_recipients);
          if (duplicateIndices.length < 1) {
            this.props.emailReportPDF(this.props.reportId, this.getRecipients());
          } else {
            duplicateIndices.forEach((duplicateIndex) => {
              this.setError('Duplicate', duplicateIndex);
            });
          }
        }
      }).catch((error) => {
        isDataValid = false;
        this.setError(error.errors[0], index);
      });
    });
  };

  componentDidMount() {
    const { clientDetails } = this.props;
    if (clientDetails && clientDetails.email) {
      this.setState({
        recipients: [
          {
            email: clientDetails.email,
            error: '',
          },
        ],
      });
    }
  }

  render() {
    return (
      <div className='building-info-wrap'>
        <div className='row'>
          {this.renderEmailFields(this.state.recipients)}
        </div>
        <div className='d-flex justify-content-between mr-bt-24'>
          <div>
            <IconButton
              text='Add Recipient'
              handleOnClick={this.addNewEmailField}
            />
          </div>
          <Button
            text='Save'
            onBtnClick={this.onSubmit}
            color='#6AA14E'
          />
        </div>
      </div>
    );
  }
}

Recipients.propTypes = {
  emailReportPDF: PropTypes.func,
  reportId: PropTypes.string,
  clientDetails: PropTypes.object,
  SetIsPromptAction: PropTypes.func,
};

export default Recipients;
