import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconButton from '../../components/IconButton';
import Table from '../../components/ReusableTable';
import SystemInfoTableRow from './SystemInfoTableRow';
import { AddSystemModalBody, AddSystemModalFooter } from '../../components/AddSystemModal';
import Modal from '../../components/Modal';
import endpoints from '../../routes';
import messages from '../../i18n/messages';
import { subPermissions } from '../../libs/constants/permissions';
import { checkPermission } from '../../_helpers/commonFunctions';

const header = [
  {
    title: 'Report Type',
    isSortable: false,
  },
  {
    title: 'System/Device',
    isSortable: false,
  },
  {
    title: 'Type',
    isSortable: false,
  },
  {
    title: 'Quantity',
    isSortable: false,
  },
];

const SystemInfoLayout = ({
  systemInfoList, isFetching,
  hasMoreItems, setSystemInfo,
  handleClickSave, systemInfoErrors,
  resetState, setSelectedSystemInfoId,
  resetErrors, systemInfo, reportGridShow, callBack,
  reportCategoryList, isClassVisible, setClassVisibility, isRenderedInReports
}) => {
  const [isModalVisible, makeModalVisible] = useState(false);
  const [isToolTipVisible, setToolTipVisibility] = useState(false);

  /**
   * handles the save button click
   */
  function handleSave() {
    handleClickSave(() => makeModalVisible(false));
  }

  /**
   * handles the save and new button click
   */
  function handleSaveAndNew() {
    handleClickSave(() => resetState());
  }

  return (
    <div>
      {isModalVisible
        && <Modal
          modalHeading='Add System Information'
          handleClickCross={() => {
            makeModalVisible(false);
            resetErrors();
            resetState();
          }}
          ModalBody={AddSystemModalBody}
          ModalFooter={AddSystemModalFooter}
          modalId='add-system'
          ModalBodyProps={{
            setSystemInfo,
            systemInfoErrors,
            systemInfo,
            reportCategoryList,
          }}
          ModalFooterProps={{
            handleClickSave: handleSave,
            handleClickSaveAndNew: handleSaveAndNew
          }}
        />
      }
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center top-search-feild">
        <h1 className={isRenderedInReports && 'mr-lt-20-px'}>
          {reportGridShow
            && <Link to={endpoints.reportClientDetail} onChange={callBack} >
              <span className='back-arrow sprite-icon' />{' '}
            </Link>}
          <FormattedMessage
            id='System Information'
            defaultMessage='System Information'
          />
          <span
            className='sprite-icon info-system-info'
            onMouseLeave={() => setToolTipVisibility(false)}
            onMouseOver={() => setToolTipVisibility(true)}>
            {isToolTipVisible && <div
              className='info-system-info-show-descr' >
              <FormattedMessage
                id='aboutSystemInfo'
                defaultMessage={messages.en.aboutSystemInfo} />
            </div>
            }
          </span>
        </h1>
      </div>
      {checkPermission(subPermissions.addCustomer) && <IconButton
        text='Add System/Device'
        handleOnClick={() => makeModalVisible(true)}
      />}
      <Table
        rowClassName='systemInfoTableClass'
        headerData={checkPermission(subPermissions.editDeleteExistingCustomers)
          ? [...header, {
            title: ' ',
            isSortable: false,
          }]
          : header}
        bodyData={systemInfoList}
        RowToRender={SystemInfoTableRow}
        isFetching={isFetching}
        hasMoreItems={hasMoreItems}
        isLoaderVisible={isFetching}
        isEndMessageVisible={!systemInfoList.length && !isFetching}
        RowToRenderProps={{
          setSelectedSystemInfoId,
          setClassVisibility,
          isClassVisible
        }}
      />
    </div>
  );
};

SystemInfoLayout.propTypes = {
  systemInfoList: PropTypes.array,
  isFetching: PropTypes.bool,
  hasMoreItems: PropTypes.bool,
  setSystemInfo: PropTypes.func,
  handleClickSave: PropTypes.func,
  handleClickSaveAndNew: PropTypes.func,
  systemInfoErrors: PropTypes.object,
  resetState: PropTypes.func,
  setSelectedSystemInfoId: PropTypes.func,
  resetErrors: PropTypes.func,
  systemInfo: PropTypes.object,
  reportGridShow: PropTypes.bool,
  callBack: PropTypes.func,
  infoSystemInfo: PropTypes.func,
  showSystemInfo: PropTypes.bool,
  reportCategoryList: PropTypes.object,
  isClassVisible: PropTypes.bool,
  setClassVisibility: PropTypes.func,
  isRenderedInReports: PropTypes.bool,
};

export default SystemInfoLayout;
