import actionConstants from '../constants/ActionConstants';

const initialState = {
  showModal: false,
  reports: [],
  isFetching: false,
  isSuccess: false,
  error: '',
  isDevice: false
};

export default function ViewReports(state = initialState, action) {
  switch (action.type) {
    case actionConstants.SET_SHOW_MODAL:
      return { ...state, showModal: action.payload };
    case actionConstants.GET_VIEW_REPORT_LIST_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actionConstants.GET_VIEW_REPORT_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        reports: action.payload.docs.map(item => ({
          reportCategory: item.reportCategory,
          frequency: item.frequency,
          devices: item.answers[item.reportCategory === 'Alarm' ? 'device' : 'sensitivityReadings'].filter(devices => devices.devicesType.ans === 'Smoke Detector'),
          By: item.updateHistory[0] && item.updateHistory[0].name,
          createdAt: item.createdAt,
          _id: item._id,
          isChecked: false
        }))
      };
    case actionConstants.GET_VIEW_REPORT_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.messsage
      };
    case actionConstants.SET_IS_FROM_DEVICES:
      return {
        ...state,
        isDevice: action.payload,
      };
    case actionConstants.ON_SELECT_REPORT:
      return {
        ...state,
        reports: [...state.reports.slice(0, action.payload), {
          ...state.reports[action.payload],
          isChecked: !state.reports[action.payload].isChecked
        }, ...state.reports.slice(action.payload + 1)]
      };
    default:
      return state;
  }
}
