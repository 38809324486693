import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import SearchBar from '../SearchBar/SearchBar';
import CustomMultiSelectDropDown from '../CustomMultiSelectDropdown';
import JobListRow from './JobListRow';
import DnDTable from '../DnDTable';
import {
  fetchJobDetails, checkPermission
} from '../../_helpers/commonFunctions';
import getJobListAction, {
  getMissedJobListAction,
  getNextMonthJobListAction,
} from '../../redux/actions/jobLinkActions';
import { subPermissions } from '../../libs/constants/permissions';
import { runCoachMarks, toggleCoachmarksVisibility } from '../../redux/actions/signUp';
import coachMarks from '../../libs/constants/coachMarks';

const headerDataComingJobs = [
  {
    title: 'Due this Month',
    isSortable: false,
  },
];

const headerDataMissedJobs = [
  {
    title: 'Missed Inspections',
    isSortable: false,
  },
];

const headerDataNextMonthJobs = [
  {
    title: 'Due next Month',
    isSortable: false,
  },
];

const timeCategory = [
  'Due this month',
  'Missed Inspections',
  'Due next month',
];

export class JobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isJobListOpen: false,
      searchValue: '',
      selectedReportCategory: this.props.reportCategories || [],
      selectedTimeCategory: [...timeCategory],
      jobs: [],
      missedJob: [],
      nextMonthJob: [],
      timeCategoriesSelected: { 'Due this month': true, 'Missed Inspections': true, 'Due next month': true },
      reportCategoriesSelected: {},
    };
    this.isViewJobs = checkPermission(subPermissions.viewJobsDueMissed);
  }

  jobListBodyRef = React.createRef();

  /**
   * sets new jobs in state
   *
   * @memberof JobList
   */
  setJobs = () => {
    const { missedJobs, nextMonthJobs } = this.props;
    const { jobList } = this.props.jobList;
    const jobs = fetchJobDetails(jobList, true);
    const missedJob = fetchJobDetails(missedJobs.jobList, true);
    const nextMonthJob = fetchJobDetails(nextMonthJobs.jobList, true);
    this.setState({ jobs, missedJob, nextMonthJob });
  }

  /**
   * Fetches due this month jobs list
   *
   * @memberof JobList
   */
  getJobList = (queryParams = {}, page) => {
    this.props.getJobListAction({ ...queryParams, endDate: moment().endOf('month').valueOf() },
      !page ? this.props.jobList.currentPage + 1 : page,
      100);
  }

  /**
   * Fetches missed jobs list
   *
   * @memberof JobList
   */
  getMissedJobList = (queryParams = {}, page) => {
    this.props.getMissedJobListAction(queryParams,
      !page ? this.props.missedJobs.currentPage + 1 : page,
      100);
  }

  /**
   * Fteches due next month jobs list
   *
   * @memberof JobList
   */
  getDueNextMonthJobList = (queryParams = {}, page) => {
    this.props.getNextMonthJobListAction(queryParams,
      !page ? this.props.nextMonthJobs.currentPage + 1 : page,
      100);
  }

  /**
   * Sets search text
   *
   * @param {object} e
   * @memberof JobList
   */
  setSearchText(e) {
    this.setState({
      searchValue: e.target ? e.target.value : '',
    }, () => {
      if (!this.state.searchValue) {
        this.handleSearch();
      }
    });
  }

  /**
   * Handles job searching
   *
   * @memberof JobList
   */
  handleSearch = () => {
    const { reportCategoriesSelected, searchValue } = this.state;
    const queryParams = {};
    if (Object.keys(reportCategoriesSelected).length) {
      queryParams.reportCategory = Object.keys(reportCategoriesSelected);
    }
    if (searchValue) {
      queryParams.search = searchValue;
    }
    this.getJobList(queryParams, 1);
    this.getMissedJobList(queryParams, 1);
    this.getDueNextMonthJobList(queryParams, 1);
  }

  /**
   * returns categories to be shown
   * @param {string} name
   * @param {string} value
   * @param {boolean} isDelete
   * @param {string} categoryType
   */
  getCategories(name, value, isDelete, categoryType) {
    let categoriesSelected = {};
    let selectedCategory = [];
    if (categoryType === 'time') {
      categoriesSelected = { ...this.state.timeCategoriesSelected };
    } else if (categoryType === 'report') {
      categoriesSelected = { ...this.state.reportCategoriesSelected };
    }
    if (isDelete) {
      delete categoriesSelected[name];
    } else {
      categoriesSelected[name] = value;
    }
    if (categoryType === 'time') {
      this.setState({ timeCategoriesSelected: categoriesSelected });
    } else if (categoryType === 'report') {
      this.setState({ reportCategoriesSelected: categoriesSelected });
    }
    selectedCategory = Object.keys(categoriesSelected);
    return selectedCategory;
  }

  /**
   * Handles category selection
   *
   * @memberof JobList
   */
  setCategories = (selectedCategory, categoryType) => {
    if (categoryType === 'time') {
      this.setState({ selectedTimeCategory: selectedCategory });
    } else if (categoryType === 'report') {
      this.setState({ selectedReportCategory: selectedCategory }, () => {
        const { reportCategoriesSelected } = this.state;
        const queryParams = {
          reportCategory: Object.keys(reportCategoriesSelected),
        };
        this.getJobList(queryParams, 1);
        this.getMissedJobList(queryParams, 1);
        this.getDueNextMonthJobList(queryParams, 1);
      });
    }
  }

  /**
   * Handles change in report category
   *
   * @memberof JobList
   */
  handleCategoryChange = (name, value, categoryType) => {
    let selectedCategory = [];
    if (!value) {
      selectedCategory = this.getCategories(name, value, true, categoryType);
    } else {
      selectedCategory = this.getCategories(name, value, false, categoryType);
    }
    this.setCategories(selectedCategory, categoryType);
  }

  componentDidUpdate(prevProps) {
    const prevIsFetching = prevProps.jobList.isSuccess;
    const newIsFetching = this.props.jobList.isSuccess;
    const prevIsFetchingMissed = prevProps.missedJobs.isSuccess;
    const newIsFetchingMissed = this.props.missedJobs.isSuccess;
    const prevIsFetchingNext = prevProps.nextMonthJobs.isSuccess;
    const newIsFetchingNext = this.props.nextMonthJobs.isSuccess;

    const prevIsError = prevProps.jobList.error;
    const newIsError = this.props.jobList.error;
    const prevIsErrorMissed = prevProps.missedJobs.error;
    const newIsErrorMissed = this.props.missedJobs.error;
    const prevIsErrorNext = prevProps.nextMonthJobs.error;
    const newIsErrorNext = this.props.nextMonthJobs.error;

    if ((prevIsFetching !== newIsFetching && newIsFetching)
      || (prevIsFetchingMissed !== newIsFetchingMissed && newIsFetchingMissed)
      || (prevIsFetchingNext !== newIsFetchingNext && newIsFetchingNext)
      || (prevIsError !== newIsError && newIsError)
      || (prevIsErrorMissed !== newIsErrorMissed && newIsErrorMissed)
      || (prevIsErrorNext !== newIsErrorNext && newIsErrorNext)
    ) {
      this.setJobs();
    }
    if (checkPermission(subPermissions.viewJobsDueMissed) !== this.isViewJobs) {
      this.isViewJobs = checkPermission(subPermissions.viewJobsDueMissed);
      this.handleSearch();
    }
  }

  /**
   * adds mouse out event listener to joblist
   *
   * @memberof JobList
   */
  addJobListModalListener = () => {
    document.addEventListener(
      'mouseout',
      this.handleClick
    );
  }

  /**
   * Sets intial state for joblist
   *
   * @memberof JobList
   */
  setInitialState = () => {
    const {
      selectedReportCategory,
      selectedTimeCategory
    } = this.props;
    const initialState = {};
    if (selectedTimeCategory.length) initialState.selectedTimeCategory = selectedTimeCategory;
    if (selectedReportCategory.length) initialState.selectedReportCategory = selectedReportCategory;
    this.setState({
      ...this.state,
      ...initialState,
    });
  }

  componentDidMount() {
    this.addJobListModalListener();
    this.setJobs();
    this.setInitialState();
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mouseout',
      this.handleClick
    );
  }

  /**
   * Handles closing joblist if user points elsewhere on the screen
   *
   * @memberof JobList
   */
  handleClick = (e) => {
    if (this.jobListBodyRef.current.contains(e.target)) {
      return;
    }
    this.setState({
      isJobListOpen: false,
    });
  }

  render() {
    const {
      isJobListOpen,
      jobs,
      missedJob,
      selectedTimeCategory,
      searchValue,
      nextMonthJob,
    } = this.state;

    const {
      schedulerData,
      newEvent,
      taskDndSource,
      jobList,
      handleClickJobListItem,
      missedJobs,
      nextMonthJobs,
      currentCoachmarkTarget,
    } = this.props;

    const {
      isFetching,
      pages,
      currentPage
    } = jobList;

    return (
      <div className='emplyee-list-rt job-list' style={{ position: 'inherit' }}>
        <div className='team-card' style={{ overflow: 'visible', position: 'relative' }} ref={this.jobListBodyRef}>
          {this.props.currentCoachmarkTarget === coachMarks
            .inspectionReminders.jobHead.target
            && <div
              id='test-tooltip'
              className={`info-timecard-info-show-descr coachmark-tooltips ${coachMarks.inspectionReminders.jobHead.toolTipClass}`}
              style={coachMarks.inspectionReminders.jobHead.style}
            >
              {coachMarks.inspectionReminders.jobHead.content}
            </div>
          }
          <div
            id='test-div'
            className='team-card-head job-list-head'
            onMouseOver={() => {
              this.setState({
                isJobListOpen: true
              }, () => {
                this.props.runCoachMarks(true);
              });
              if (this.props.currentCoachmarkTarget === coachMarks
                .inspectionReminders.jobHead.target) {
                if (!missedJob.length
                  && !nextMonthJob.length
                  && !jobs.length) {
                  this.props.toggleCoachmarksVisibility(null, null, coachMarks
                    .inspectionReminders.jobs.target);
                } else {
                  this.props.toggleCoachmarksVisibility(null, null, coachMarks
                    .inspectionReminders.jobHead.nextTarget);
                }
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <p
              className={`job-list-icon sprite-icon-before1 ${!this.state.isJobListOpen && 'lower-arrow'}`}
            >
              <FormattedMessage
                id='JOB LIST'
                defaultMessage='JOB LIST'
              />
            </p>
          </div>
          {isJobListOpen
            && <div className="team-card-body" style={{ position: 'absolute', zIndex: 9 }}>
              <div className="team-card-inn-body">
                {this.props.currentCoachmarkTarget === coachMarks
                  .inspectionReminders.jobList.target
                  && <div
                    id='test-tooltip'
                    className={`info-timecard-info-show-descr coachmark-tooltips ${coachMarks.inspectionReminders.jobList.toolTipClass}`}
                    style={coachMarks.inspectionReminders.jobList.style}
                  >
                    {coachMarks.inspectionReminders.jobList.content}
                  </div>
                }
                <SearchBar
                  searchContainerClass='search-container d-flex sm-search-fld mr-bt-10'
                  inputClassName='mw-150'
                  onSearchPress={this.handleSearch}
                  onTextChange={e => this.setSearchText(e)}
                  searchValue={searchValue}
                  isCrossVisible={searchValue}
                />
                <div className="dropdown-container mr-bt-10 ">
                  <CustomMultiSelectDropDown
                    className='dropdown custom-drop-option'
                    dropDownList={this.props.reportCategories}
                    name='reportCategory'
                    handleOnChange={(name, value) => this.handleCategoryChange(name, value, 'report')}
                    placeholder='Select Here'
                    selectedValues={Object.keys(this.state.reportCategoriesSelected)}
                    dropItemSelected={this.state.reportCategoriesSelected}
                    dataTip='Filter jobs by category.'
                    dataFor='joblink-category-dropdown-tooltip'
                  />
                  <CustomMultiSelectDropDown
                    className='dropdown custom-drop-option'
                    dropDownList={timeCategory}
                    name='timeCategory'
                    handleOnChange={(name, value) => this.handleCategoryChange(name, value, 'time')}
                    placeholder='Select Here'
                    selectedValues={selectedTimeCategory}
                    dropItemSelected={this.state.timeCategoriesSelected}
                    dataTip='Filter jobs by due date.'
                    dataFor='joblink-time-dropdown-tooltip'
                  />
                </div>
                {missedJob.length > 0 && selectedTimeCategory.includes('Missed Inspections') && <DnDTable
                  headerData={headerDataMissedJobs}
                  bodyData={missedJob}
                  RowToRender={JobListRow}
                  loadMore={this.getMissedJobList}
                  isFetching={missedJobs.isFetching}
                  hasMoreItems={missedJobs.currentPage < missedJobs.pages}
                  className='missedJobTables'
                  isLoaderVisible={missedJobs.isFetching}
                  isEndMessageVisible={!missedJob.length && !missedJobs.isFetching}
                  tableClassName='table-responsive global-table global-sm-table mr-bt-10 job-list-item-coachmark'
                  schedulerData={schedulerData}
                  newEvent={newEvent}
                  taskDndSource={taskDndSource}
                  rowToRenderProps={{
                    handleClickJobListItem,
                    toggleCoachmarksVisibility: this.props.toggleCoachmarksVisibility,
                    currentCoachmarkTarget
                  }}
                  componentMaxHeight= '128px'
                />}
                {selectedTimeCategory.includes('Due this month') && <DnDTable
                  headerData={headerDataComingJobs}
                  bodyData={jobs}
                  RowToRender={JobListRow}
                  loadMore={this.getJobList}
                  isFetching={isFetching}
                  hasMoreItems={currentPage < pages}
                  className='missedJobTables'
                  isLoaderVisible={isFetching}
                  isEndMessageVisible={!jobs.length && !isFetching}
                  tableClassName='table-responsive global-table global-sm-table mr-bt-10 job-list-item-coachmark'
                  schedulerData={schedulerData}
                  newEvent={newEvent}
                  taskDndSource={taskDndSource}
                  rowToRenderProps={{
                    handleClickJobListItem,
                    toggleCoachmarksVisibility: this.props.toggleCoachmarksVisibility,
                    currentCoachmarkTarget
                  }}
                  componentMaxHeight= '128px'
                />}
                {nextMonthJob.length > 0 && selectedTimeCategory.includes('Due next month') && <DnDTable
                  headerData={headerDataNextMonthJobs}
                  bodyData={nextMonthJob}
                  RowToRender={JobListRow}
                  loadMore={this.getDueNextMonthJobList}
                  isFetching={nextMonthJobs.isFetching}
                  hasMoreItems={nextMonthJobs.currentPage < nextMonthJobs.pages}
                  className='missedJobTables'
                  isLoaderVisible={nextMonthJobs.isFetching}
                  isEndMessageVisible={!nextMonthJob.length && !isFetching}
                  tableClassName='table-responsive global-table global-sm-table mr-bt-10'
                  schedulerData={schedulerData}
                  newEvent={newEvent}
                  taskDndSource={taskDndSource}
                  rowToRenderProps={{
                    handleClickJobListItem,
                  }}
                  componentMaxHeight= '128px'
                />}
              </div>
            </div>}
        </div>
      </div>
    );
  }
}

JobList.propTypes = {
  selectedReportCategory: PropTypes.string, // report category selected
  selectedTimeCategory: PropTypes.string, // selected time category
  reportCategoryList: PropTypes.array, // all report categories
  timeCategoryList: PropTypes.array, // all time categories
  onChangeReportCategory: PropTypes.func, // handles report category selection
  onChangeTimeCategory: PropTypes.func, // handles time category selection
  onSearch: PropTypes.func, // handles search
  schedulerData: PropTypes.object, // job board data
  newEvent: PropTypes.func, // current selected event
  taskDndSource: PropTypes.object,
  jobList: PropTypes.object, // jobs list
  getJobListAction: PropTypes.func, // fetches due this month jobs
  reportCategories: PropTypes.array, // all report categories
  handleClickJobListItem: PropTypes.func, // handles click event for job list item
  getMissedJobListAction: PropTypes.func, // fetches missed jobs
  missedJobs: PropTypes.object, // missed jobs
  getNextMonthJobListAction: PropTypes.func, // fetches next month jobs
  nextMonthJobs: PropTypes.object, // next month jobs
  runCoachMarks: PropTypes.func,
  toggleCoachmarksVisibility: PropTypes.func,
  currentCoachmarkTarget: PropTypes.string,
};

JobList.defaultProps = {
  selectedReportCategory: '',
  selectedTimeCategory: '',
  reportCategoryList: [],
  timeCategoryList: [],
  onChangeReportCategory: () => { },
  onChangeTimeCategory: () => { },
  onSearch: () => { },
  reportCategories: [],
};

const mapStateToProps = ({
  jobListDetails, reportCategory, auth, signUpStatus
}) => ({
  jobList: jobListDetails.jobList,
  missedJobs: jobListDetails.missedJobList,
  nextMonthJobs: jobListDetails.nextMonthJobList,
  reportCategories: Object.keys(reportCategory.reportData),
  auth,
  currentCoachmarkTarget: signUpStatus.currentTarget,
});

export default connect(mapStateToProps, {
  getJobListAction,
  getMissedJobListAction,
  getNextMonthJobListAction,
  runCoachMarks,
  toggleCoachmarksVisibility,
})(JobList);
