import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { convertFrequency } from '../../utils/Constants';
import './MissedInspection.css';

const MissedInspectionRow = (props) => {
  const {
    jobId, createdFor, reportCategory, recurringInspectionDetails, type, startDateTime, price,
  } = props.row;
  const { country } = props;
  const idToExpand = Object.keys(props.isClassVisible);
  const isIdToExpand = props.isClassVisible[idToExpand[0]];
  const frequency = (recurringInspectionDetails
    && recurringInspectionDetails.frequency) || '';
  const startJobPayload = {
    jobId: props.row._id,
    createdFor,
    reportCategory,
    type,
    price,
  };
  if (frequency) {
    startJobPayload.frequency = frequency;
  }
  const isJobCompletedManually = props.jobsDoneManually.includes(props.row._id);
  const isReportNotNeeded = props.isReportNeeded === false;

  return (
    <tr className={
      jobId.toString() === idToExpand[0] && isIdToExpand ? 'accordion-opened' : ''
    }
      style={
        jobId.toString() === idToExpand[0] && isIdToExpand
          ? { maxHeight: '1000px', cursor: 'pointer' }
          : { maxHeight: '60px', cursor: 'pointer' }
      }>
      <td style={{ width: '5%' }}><h3><FormattedMessage id='Job Id' defaultMessage='Job Id' /></h3><span >{jobId}</span></td>
      <td style={{ width: '12%' }}><h3><FormattedMessage id='Name' defaultMessage='Name' /></h3>{createdFor.businessName}</td>
      <td style={{ width: '17%' }}><h3><FormattedMessage id='Report Category' defaultMessage='Report Category' /></h3>
        <FormattedMessage
          id={reportCategory}
          defaultMessage={reportCategory}
        />
      </td>
      <td style={{ width: '14%' }}><h3><FormattedMessage id='Frequency' defaultMessage='Frequency' /></h3>
        <FormattedMessage
          id={recurringInspectionDetails
            && recurringInspectionDetails.frequency ? convertFrequency[recurringInspectionDetails.frequency] : 'NA'}
          defaultMessage={recurringInspectionDetails
            && recurringInspectionDetails.frequency ? convertFrequency[recurringInspectionDetails.frequency] : 'NA'}
        /></td>
      <td style={{ width: '14%' }}><h3><FormattedMessage id='Date' defaultMessage='Date' /></h3>{moment(startDateTime).format(country ==="united states"? 'MM-DD-YYYY': 'DD-MM-YYYY')}</td>
      <td style={{ width: '10%' }}><h3><FormattedMessage id='Type' defaultMessage='Type' /></h3>
        <FormattedMessage
          id={type}
          defaultMessage={type}
        /></td>
      <td style={{ position: 'relative', width: '14%' }}>
        {isReportNotNeeded
          ? <label className='cust-checkbox' style={{ color: '#6aa14e' }}>
            <FormattedMessage
              id='Done'
              defaultMessage='Done' />
            <span
              className="material-icons material-icon-checkmark"
              onClick={isJobCompletedManually
                ? () => { }
                : () => props.completeJobManually({
                  reportCategory,
                  createdFor: createdFor._id,
                  createdForName: createdFor.businessName,
                  reportType: type,
                  jobId: props.row._id
                })
              }
            >
              {isJobCompletedManually ? 'check_box' : 'check_box_outline_blank'}
            </span>
          </label>
          : <button style={{ width: '88px', marginLeft: '30px' }}
            onClick={() => props.handleStartJob(startJobPayload)}
            className="inspectbutton">
            <FormattedMessage id='Start Job'
              defaultMessage='Start Job' />
          </button>}
        {recurringInspectionDetails && recurringInspectionDetails.tasks.length > 0
          && <span onClick={() => props.showTaskModdal(recurringInspectionDetails.tasks)} style={{
            backgroundColor: ' #74A8F8',
            width: '30px',
            height: '30px',
            color: 'white',
            borderRadius: '50%',
            position: 'absolute',
            right: '75%',
            top: '13px',
            textAlign: 'center',
            paddingTop: 'inherit',
          }}>
            {recurringInspectionDetails.tasks.length}
          </span>}
      </td>
      <span className="icon-accordion" onClick={() => props.setClassVisibility(jobId)}>
        {
          jobId.toString() === idToExpand[0] && isIdToExpand ? '-' : '+'
        }
      </span>
    </tr>
  );
};
MissedInspectionRow.propTypes = {
  row: PropTypes.object,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func,
  handleStartJob: PropTypes.func,
  showTaskModdal: PropTypes.func,
  jobsDoneManually: PropTypes.array,
  completeJobManually: PropTypes.func,
  isReportNeeded: PropTypes.any,
  country: PropTypes.string,
};

const mapStateToProps = ({ auth })=> {
return {
    country: auth.login.user.businessAddress.country.toLowerCase()
  }
}

export default connect(mapStateToProps)(MissedInspectionRow);

