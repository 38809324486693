import isEmpty from 'lodash';
import actionConstants from '../constants/ActionConstants';

const user = JSON.parse(localStorage.getItem('user'));

const login = {
  error: '',
  isFetching: false,
  isSuccessForLogin: false,
  user: {},
  loggedIn: false,
};

const offline = {
  isOffline: false,
  isFetchApi: true,
  isFetchInventory: false,
};

const changePassword = {
  error: '',
  success: '',
  isFetching: false,
  isSuccess: false,
};

const editProfile = {
  error: '',
  success: '',
  isFetching: false,
  isSuccess: false,
};

const updatedPermissions = {
  error: '',
  success: '',
  isFetching: false,
  isSuccess: false,
};

const updatedSubscriptions = {
  error: '',
  success: '',
  isFetching: false,
  isSuccess: false,
};

const initialState = {
  login,
  changePassword,
  editProfile,
  error: '',
  isTermsAndConditionFetching: false,
  isTermsAndCondition: 0,
  isPrivacyPolicy: false,
  errors: '',
  isfetching: false,
  loginData: {},
  user: user || {},
  addEmployee: {},
  loggedIn: !isEmpty(user),
  offline,
  updatedPermissions,
  updatedSubscriptions,
  isCleared: false
};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case actionConstants.LOGIN_REQUEST:
      return {
        ...state,
        login: {
          ...state.login,
          error: '',
          isFetching: true,
          loggedIn: false,
        }
      };
    case actionConstants.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          isFetching: false,
          loggedIn: true,
          isSuccessForLogin: true,
          user: {}
        },
        loginData: {
          ...action.payload.user,
          token: action.headers.authorization,
          adminEmail: action.payload.adminEmail,
          stripeUserId: action.payload.stripeUserId,
          timeZone: action.payload.timeZone,
          ownerId: action.payload.customerId,
          customerId: action.payload._id,
          businessName: action.payload.businessName,
          profilePicture: action.payload.profilePicture,
          homeAddress: action.payload.homeAddress,
          role: action.payload.role,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          city: action.payload.city,
          formattedAddress: action.payload.formattedAddress,
          contactNo: action.payload.contactNo,
          state: action.payload.state,
          zipCode: action.payload.zipCode,
          SourceOfGetIn: action.payload.SourceOfGetIn,
          userName: `${action.payload.firstName} ${action.payload.lastName}`,
          userPlans: action.payload.userPlans,
          email: action.payload.email,
          isRedirectedFromAdmin: action.isRedirectedFromAdmin,
          businessAddress: action.payload.businessAddress,
          userSubscriptionId: action.payload.subscriptionData
            ? action.payload.subscriptionData.subscriptions.map(item => item.planId)
            : action.payload.userSubscriptionId
            && action.payload.userSubscriptionId.subscriptions.map(item => item.planId),
          permissions: [...action.payload.permissions],
          subscriptionPermissions: action.payload.subscriptionPermissions
            ? [...action.payload.subscriptionPermissions]
            : [],
          status: action.payload.subscriptionData
            ? action.payload.subscriptionData.type
            : action.payload.status,
          endDate: action.payload.subscriptionData
            ? action.payload.subscriptionData.expirationDate
            : action.payload.endDate,
          userSubscriptionDetails: action.payload.subscriptionData
            ? { ...action.payload.subscriptionData }
            : { ...action.payload.userSubscriptionId },
          _id: action.payload._id,
          balanceAmount: action.payload.subscriptionData
            && action.payload.subscriptionData.balanceAmount,
          isReportNeeded: action.payload.isReportNeeded,
          inspectorSignature: action.payload.inspectorSignature,
          inspectorName: action.payload.inspectorName,
          licenceNo: action.payload.licenceNo,
          chatDisabled: action.payload.chatDisabled,
        },
        isTermsAndCondition: action.payload.termsAndCondition,
      };
    case actionConstants.SET_LOGIN_USER_DETAILS:
      return {
        ...state,
        login: {
          ...state.login,
          isFetching: false,
          loggedIn: true,
          isSuccessForLogin: true,
          user: {
            ...state.loginData,
          }
        },
      };
    case actionConstants.LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          isFetching: false,
          loggedIn: false,
          user: {},
          error: action.message
        }
      };
    case actionConstants.SET_TERMS_AND_CONDITION_REQUEST:
      return {
        ...state,
        isTermsAndConditionFetching: true,
      };
    case actionConstants.SET_TERMS_AND_CONDITION_SUCCESS:
      return {
        ...state,
        isTermsAndConditionFetching: false,
        isTermsAndCondition: true,
      };
    case actionConstants.SET_TERMS_AND_CONDITION_FAILURE:
      return {
        ...state,
        isTermsAndConditionFetching: false,
        isTermsAndCondition: 0,
      };
    case actionConstants.SET_PRIVACY_POLICY:
      return {
        ...state,
        isPrivacyPolicy: action.isPrivacy,
      };
    case actionConstants.LOGOUT:
      return {
        ...state,
        login: {
          error: '',
          isFetching: false,
          user: {},
          loggedIn: false,
        }
      };

    case actionConstants.CHANGE_PASSWORD_RESET:
      return {
        ...state,
        changePassword: initialState.changePassword,
      };

    case actionConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isFetching: true,
          isSuccess: false,
          error: '',
          success: '',
        },
      };

    case actionConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isFetching: false,
          error: '',
          isSuccess: true,
          success: action.payload.message,
        },
      };

    case actionConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          error: action.message,
          isFetching: false,
        },
      };

    case actionConstants.EDIT_PROFILE_REQUEST:
      return {
        ...state,
        editProfile: {
          ...state.editProfile,
          isFetching: true,
        }
      };
    case actionConstants.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        editProfile: {
          isFetching: false,
          isSuccess: true,
          success: action.message,
        },
        login: {
          ...state.login,
          isFetching: false,
          loggedIn: true,
          user: {
            ...state.login.user,
            customerId: action.payload._id,
            businessName: action.payload.businessName,
            role: action.payload.role,
            profilePicture: action.payload.profilePicture,
            homeAddress: action.payload.homeAddress,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            formattedAddress: action.payload.formattedAddress,
            city: action.payload.city,
            contactNo: action.payload.contactNo,
            state: action.payload.state,
            zipCode: action.payload.zipCode,
            SourceOfGetIn: action.payload.SourceOfGetIn,
            userName: `${action.payload.firstName} ${action.payload.lastName}`,
            userPlans: action.payload.userPlans,
            email: action.payload.email,
            timeZone: action.payload.timeZone,
            inspectorSignature: action.payload.inspectorSignature,
            inspectorName: action.payload.inspectorName,
            licenceNo: action.payload.licenceNo,
          }
        },
        isTermsAndCondition: action.payload.termsAndCondition,
      };
    case actionConstants.EDIT_PROFILE_FAILURE:
      return {
        ...state,
        editProfile: {
          ...state.editProfile,
          isFetching: false,
          error: action.message,
        }
      };

    case actionConstants.OFFLINE_MODE_ON:
      return {
        ...state,
        offline: {
          ...state.offline,
          isOffline: action.payload.isOffline,
          isFetchApi: action.payload.isFetchAPI,
          isFetchInventory: action.payload.isFetchInventory,
        }
      };

    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        changePassword: initialState.changePassword,
        editProfile: {
          ...state,
          isSuccess: false,
          success: '',
          error: '',
        },
        offline: {
          ...state.offline,
          isFetchInventory: false,
        }
      };

    case actionConstants.CLEAR_LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          isSuccessForLogin: false,
          isFetching: false
        }
      };

    case actionConstants.UPDATE_EMPLOYEE_PERMISSIONS:
      return {
        ...state,
        login: {
          ...state.login,
          user: {
            ...state.login.user,
            permissions: action.payload.newPermissions,
          }
        }
      };

    case actionConstants.UPDATE_EMPLOYEE_SUBSCRIPTIONS:
      return {
        ...state,
        login: {
          ...state.login,
          user: {
            ...state.login.user,
            status: action.payload.newSubscriptions.type || action.payload.newSubscriptions.status,
            endDate: action.payload.newSubscriptions.expirationDate
              || action.payload.newSubscriptions.endDate,
            userSubscriptionId: action.payload.newSubscriptions.subscriptions
              ? {
                subscriptions: action.payload.newSubscriptions.subscriptions,
                _id: action.payload.newSubscriptions._id
              }
              : action.payload.newSubscriptions.userSubscriptions,
            stripeUserId: action.payload.newSubscriptions.stripeUserId,
            subscriptionPermissions: action.payload.newSubscriptions.subscriptions
              ? action.payload.newSubscriptions.subscriptions
                .map(plan => plan.permissions || plan.planValue)
              : action.payload.newSubscriptions.userSubscriptions.subscriptions
                .map(plan => plan.permissions || plan.planValue),
            userSubscriptionDetails: action.payload.newSubscriptions.subscriptions
              ? {
                subscriptions: action.payload.newSubscriptions.subscriptions,
                _id: action.payload.newSubscriptions._id
              }
              : action.payload.newSubscriptions.userSubscriptions,
            balanceAmount: action.payload.newSubscriptions.balanceAmount,
          }
        }
      };

    case actionConstants.UPDATE_PERMISSIONS_REQUEST:
      return {
        ...state,
        updatedPermissions: {
          error: '',
          success: '',
          isFetching: true,
          isSuccess: false,
        }
      };
    case actionConstants.UPDATE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        updatedPermissions: {
          error: '',
          success: action.message,
          isFetching: false,
          isSuccess: true,
        },
        login: {
          ...state.login,
          user: {
            ...state.login.user,
            permissions: action.payload.permissions,
            subscriptionPermissions: action.payload.subscriptionPermissions,
          }
        }
      };
    case actionConstants.UPDATE_PERMISSIONS_FAILURE:
      return {
        ...state,
        updatedPermissions: {
          error: action.message,
          success: '',
          isFetching: false,
          isSuccess: false,
        }
      };

    case actionConstants.UPDATE_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        updatedSubscriptions: {
          error: '',
          success: '',
          isFetching: true,
          isSuccess: false,
        }
      };
    case actionConstants.UPDATE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        updatedSubscriptions: {
          error: '',
          success: action.message,
          isFetching: false,
          isSuccess: true,
        },
        login: {
          ...state.login,
          user: {
            ...state.login.user,
            status: action.payload.type || action.payload.status,
            endDate: action.payload.expirationDate || action.payload.endDate,
            userSubscriptionId: action.payload.subscriptions
              ? { subscriptions: action.payload.subscriptions, _id: action.payload._id }
              : action.payload.userSubscription,
            subscriptionPermissions: action.payload.subscriptions
              ? action.payload.subscriptions
                .map(plan => plan.permissions || plan.planValue)
              : action.payload.userSubscription
              && action.payload.userSubscription.subscriptions
                .map(plan => plan.permissions || plan.planValue),
            stripeUserId: action.payload.stripeUserId,
            userSubscriptionDetails: action.payload.subscriptions
              ? { subscriptions: action.payload.subscriptions, _id: action.payload._id }
              : action.payload.userSubscription,
            balanceAmount: action.payload.balanceAmount,
          }
        }
      };
    case actionConstants.UPDATE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        updatedSubscriptions: {
          error: action.message,
          success: '',
          isFetching: false,
          isSuccess: false,
        }
      };

    case actionConstants.RESET_ALL_REDUCERS:
      return { ...initialState };

    default:
      return state;
  }
}
