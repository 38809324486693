import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import endpoints from '../../routes';
import Table from '../../components/ReusableTable';
import Loader from '../../components/Loader';
import Input from '../../components/Input';
import { getTransactionDetail } from '../../redux/actions/getTransactionList';
import {
  TransactionDetailsPartsTableHeader,
  TransactionDetailsToolsTableHeader,
} from '../../views/constant';
import { TransactionDetailsPartsRow, TransactionDetailsToolsRow } from './TransactionPartsToolsRow';


export const TransactionDetail = (props) => {
  const { country } = props;
  useEffect(() => {
    if (props.location && props.location.state && props.location.state._id) {
      props.getTransactionDetail(props.location.state._id);
    } else {
      props.history.push({
        pathname: `${endpoints.transactions}`,
      });
    }
  }, []);
  return (
    <div>
      {props.isfetching ? (<Loader />) : null}
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center top-search-field'>
        <h1 className='head-back'>
          <Link to={endpoints.transactions}>
            <span className='back-arrow sprite-icon' />{' '}
          </Link>
          {props.transactionDetail.transactionId}
        </h1>
      </div>

      <div className='card'>
        <div className='card-header'>
          <span className='card-header-text'>
            <FormattedMessage
              id='Transaction details'
              defaultMessage='Transaction details'
            />
          </span>

          {/** Delete Vehicle */}
          {/* <span className="delete-icon sprite-icon" onClick= { () => {}} /> */}
        </div>
        <div className='card-body'>
          <div className='row'>
            {
              props.transactionDetail.transactionId && <div className='col-md-4'>
                <div className='form-group dis-flex f-dir-column'>
                  <Input
                    type='text'
                    name='transactionId'
                    disabled={true}
                    value={props.transactionDetail.transactionId}
                    className='form-control'
                    onChange={() => { }}
                  />
                  <label>
                    <FormattedMessage
                      id='Transaction Id'
                      defaultMessage='Transaction Id'
                    />
                  </label>
                </div>
              </div>
            }
            {
              props.transactionDetail.technicianName && <div className='col-md-4'>
                <div className='form-group dis-flex f-dir-column'>
                  <Input
                    type='text'
                    name='technicianName'
                    disabled={true}
                    value={props.transactionDetail.technicianName}
                    className='form-control'
                    onChange={() => { }}
                  />
                  <label>
                    <FormattedMessage
                      id='Technician Name'
                      defaultMessage='Techinician Name'
                    />
                  </label>
                </div>
              </div>
            }
            {
              props.transactionDetail.asset && <div className='col-md-4'>
                <div className='form-group dis-flex f-dir-column'>
                  <Input
                    type='text'
                    name='AssetType'
                    disabled={true}
                    value={props.transactionDetail.asset}
                    className='form-control'
                    onChange={() => { }}
                  />
                  <label>
                    <FormattedMessage
                      id='Asset Type'
                      defaultMessage='Asset Type'
                    />
                  </label>
                </div>
              </div>
            }
            {
              props.transactionDetail.transactionType && <div className='col-md-4'>
                <div className='form-group dis-flex f-dir-column'>
                  <Input
                    type='text'
                    name='transactionType'
                    disabled={true}
                    value={props.transactionDetail.transactionType}
                    className='form-control'
                    onChange={() => { }}
                  />
                  <label>
                    <FormattedMessage
                      id='Transaction Type'
                      defaultMessage='Transaction Type'
                    />
                  </label>
                </div>
              </div>
            }
            {
              props.transactionDetail.userName && <div className='col-md-4'>
                <div className='form-group dis-flex f-dir-column'>
                  <Input
                    type='text'
                    name='userName'
                    disabled={true}
                    value={props.transactionDetail.userName}
                    className='form-control'
                    onChange={() => { }}
                  />
                  <label>
                    <FormattedMessage
                      id='User Name'
                      defaultMessage='User Name'
                    />
                  </label>
                </div>
              </div>
            }
            {
              props.transactionDetail.createdAt && <div className='col-md-4'>
                <div className='form-group dis-flex f-dir-column'>
                  <Input
                    type='text'
                    name='createdAt'
                    disabled={true}
                    value={moment(props.transactionDetail.createdAt).format(country ==="united states"? 'MM-DD-YYYY': 'DD-MM-YYYY')}
                    className='form-control'
                    onChange={() => { }}
                  />
                  <label>
                    <FormattedMessage
                      id='Created At'
                      defaultMessage='Created At'
                    />
                  </label>
                </div>
              </div>
            }
            {
              props.transactionDetail.vehicleDetails && <div className='col-md-4'>
                <div className='form-group dis-flex f-dir-column'>
                  <Input
                    type='text'
                    name='vehicleNumber'
                    disabled={true}
                    value={props.transactionDetail.vehicleDetails.vehicleNumber}
                    className='form-control'
                    onChange={() => { }}
                  />
                  <label>
                    <FormattedMessage
                      id='Vehicle Number'
                      defaultMessage='Vehicle Number'
                    />
                  </label>
                </div>
              </div>
            }
            {
              props.transactionDetail.supplierDetails && <div className='col-md-4'>
                <div className='form-group dis-flex f-dir-column'>
                  <Input
                    type='text'
                    name='supplierName'
                    value={props.transactionDetail.supplierDetails.supplierName}
                    className='form-control'
                    onChange={() => { }}
                  />
                  <label>
                    <FormattedMessage
                      id='Supplier Name'
                      defaultMessage='Supplier Name'
                    />
                  </label>
                </div>
              </div>
            }
          </div>
          <div className='row marginTable'>

            {/** Table for assign parts.  */}
            {
              props.transactionDetail.parts && props.transactionDetail.parts.length > 0 && <div className="col-md-12">
                <div className='table-responsive global-sm-table mr-bt-30 content-box removeMarginTop'>
                  <Table
                    headerData={TransactionDetailsPartsTableHeader}
                    bodyData={props.transactionDetail.parts}
                    RowToRender={TransactionDetailsPartsRow}
                    isLoaderVisible={false}
                    endMessage={'No part assigned'}
                    height={200}
                    isEndMessageVisible={false}
                    RowToRenderProps={{
                      unassignBtn: () => { }
                    }}
                    mainTableClass={'table table-striped table-sm'}
                  />
                </div>
              </div>
            }

            {/** Table for assign tool */}
            {
              props.transactionDetail.tools && props.transactionDetail.tools.length > 0 && <div className="col-md-12">
                <div className='table-responsive global-table global-sm-table mr-bt-30 content-box removeMarginTop'>

                  <Table
                    headerData={TransactionDetailsToolsTableHeader}
                    bodyData={props.transactionDetail.tools}
                    RowToRender={TransactionDetailsToolsRow}
                    isLoaderVisible={false}
                    endMessage={'No tools assigned'}
                    isEndMessageVisible={false}
                    RowToRenderProps={{
                      unassignBtn: () => { }
                    }} s
                    height={200}
                    mainTableClass={'table table-striped table-sm'}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isfetching: state.getTransactionsList.isfetching,
    transactionDetail: state.getTransactionsList.transactionDetail,
    country: state.auth.login.user.businessAddress.country.toLowerCase()
  };
}

TransactionDetail.propTypes = {
  isfetching: PropTypes.bool,
  getTransactionDetail: PropTypes.func,
  location: PropTypes.object,
  transactionDetail: PropTypes.object,
  history: PropTypes.object,
  country: PropTypes.string,
};

export default connect(mapStateToProps, { getTransactionDetail })(TransactionDetail);
