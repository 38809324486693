import moment from 'moment';

export const BuglistHeaderFields = [
  {
    title: 'Bug Title',
    sorting: false,
  },
  {
    title: 'Description',
    sorting: false,
  },
  {
    title: 'Date of Reporting',
    sorting: false,
  },
  {
    title: 'Status',
    sorting: false,
  },
];

export const BugStatusClass = {
  pending: { className: 'out-stock', label: 'Pending' },
  inProgress: { className: 'in-stock', label: 'In Progress' },
  resolved: { className: 'resolved', label: 'Resolved' },
};

export const AutoRouteErrorMessage = 'Address for one or more jobs is invalid';

export const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
export const reportfilters = {
  pump: {
    label: 'Pump',
    status: false,
  },
  backflow: {
    label: 'Backflow',
    status: false,
  },

  Alarm: {
    label: 'Alarm',
    status: false,
  },
  Security: {
    label: 'Security',
    status: false,
  },
};

export const currentYear = moment().year();
export const previousYear = moment().year() - 1;
export const months = moment.months();

export const areaChartProperties = {
  legends: [
    { value: currentYear, legendClass: 'red-legend-box' },
  ],
  areas: [
    {
      type: 'linear',
      dataKey: previousYear,
      stroke: '#D7DCE0',
      fillOpacity: 0.2,
      fill: '#D7DCE0',
      activeDot: true,
      dot: { strokeWidth: 1 }
    },
    {
      type: 'linear',
      dataKey: currentYear,
      stroke: '#A51C1C',
      fillOpacity: 0.2,
      fill: '#A51C1C',
      activeDot: true,
      dot: { strokeWidth: 1 }
    }
  ]
};

export const reportsColor = {
  Alarm: '#22bec5',
  Backflow: '#c776b1',
  'Emergency Exit/Light': '#008595',
  Extinguisher: '#734fa0',
  'Fire Door': '#f79868',
  'Grease Cleaning': '#ccccff',
  'Hood System': '#ffcc66',
  Hydrant: '#6699cc',
  Monitoring: '#75cac2',
  Pump: '#3769b3',
  Security: '#947f2f',
  Sensitivity: '#773217',
  'Special Hazard': '#f85b12',
  Sprinkler: '#333399',
  'Standpipe/Hose': '#f5dd42',
  Suppression: '#429ef5',
  'Water Tank': '#42f5b0',
  Other: '#e942f5',
};

export const dueGraphProperties = {
  innerRadius: '45%',
  outerRadius: '90%',
  showLegend: true
};

export const jobsGraphProperties = {
  innerRadius: '62%',
  outerRadius: '70%',
  showLegend: false
};

export const PartHeaders = [
  {
    title: 'PartName',
    sorting: false,
  },
  {
    title: 'Quantity',
    sorting: false,
  },
  {
    title: 'Price',
    sorting: false,
  },
  {
    title: ' ',
    sorting: false,
  },
];

export const LabourHeaders = [
  {
    title: 'Name',
    sorting: false,
  },
  {
    title: 'Labor Hours',
    sorting: false,
  },
  {
    title: 'Price($)',
    sorting: false,
  },
  {
    title: ' ',
    sorting: false,
  },
];

export const InventoryVehicleRowHeader = [
  {
    title: 'Vehicle Number',
    sorting: false,
  },
  {
    title: 'Assigned Technician',
    sorting: false,
  },
  {
    title: 'Parts Quantity',
    sorting: false,
  },
  {
    title: 'Tools Quantity',
    sorting: false,
  },
];

export const SupplierListHeader = [
  {
    title: ' ',
    sorting: false,
    width: '9%',
  },
  {
    title: 'Suppliers Name',
    sorting: false,
    width: '19%'
  },
  {
    title: 'Email Id',
    sorting: false,
    width: '20%'
  },
  {
    title: 'Contact Number',
    sorting: false,
    width: '20%'
  },
  {
    title: 'Status',
    sorting: false,
    width: '12%'
  },
];

export const PartListHeader = [
  {
    title: 'Part Image',
    sorting: false
  },
  {
    title: 'Part Name',
    sorting: false
  },
  {
    title: 'Report Category',
    sorting: false
  },
  {
    title: 'SKU',
    sorting: false
  },
  {
    title: 'Qty/Warehouse',
    sorting: false
  },
  {
    title: 'Qty/Vehicle',
    sorting: false
  },
  {
    title: 'Status',
    sorting: false
  }
];

export const AssignPartsHeader = [
  {
    title: 'Part',
    sorting: false
  },
  {
    title: 'SKU',
    sorting: false
  },
  {
    title: 'Quantity',
    sorting: false
  },
  {
    title: 'Price($)',
    sorting: false
  },
  {
    title: ' ',
    sorting: false
  }
];

export const InventoryDetailsPartsTableHeader = [
  {
    title: 'Parts',
    sorting: false
  },
  {
    title: 'SKU',
    sorting: false
  },
  {
    title: 'Quantity',
    sorting: false
  },
  {
    title: ' ',
    sorting: false
  }
];

export const TransactionDetailsPartsTableHeader = [
  {
    title: 'Parts',
    sorting: false
  },
  {
    title: 'SKU',
    sorting: false
  },
  {
    title: 'Transact Quantity',
    sorting: false
  },
  {
    title: 'Warehouse Quantity',
    sorting: false
  },
  {
    title: 'Report Type',
    sorting: false
  }
];

export const InventoryDetailsToolsTableHeader = [
  {
    title: 'Tools',
    sorting: false
  },
  {
    title: 'ID.',
    sorting: false
  },
  {
    title: 'Quantity',
    sorting: false
  },
  {
    title: ' ',
    sorting: false
  }
];

export const TransactionDetailsToolsTableHeader = [
  {
    title: 'Tools',
    sorting: false
  },
  {
    title: 'ID.',
    sorting: false
  },
  {
    title: 'Transact Quantity',
    sorting: false
  },
  {
    title: 'WareHouse Quantity',
    sorting: false
  },
];

export const AssignToolsHeader = [{
  title: 'Tool',
  sorting: false
}, {
  title: 'Id Number',
  sorting: false
}, {
  title: 'Quantity',
  sorting: false
}, {
  title: 'Description',
  sorting: false
}, {
  title: ' ',
  sorting: false
}];

export const BUTTON_SUCCESS_COLOR = '#6aa14e';

export const TOOL_STATUS = [
  { name: 'Archive' },
  { name: 'In Stock' },
  { name: 'Out of Stock' }
];

export const VEHICLE_STATUS = [
  { name: 'Archived' },
  { name: 'UnArchived' },
];

export const AutoRouting = 'AUTOROUTING';

export const SUPPLIER_STATUS = [
  { name: 'Active' },
  { name: 'Archive' },
];
