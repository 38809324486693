import { customerDetail, fetchSubs } from '../../api/apiCallSubscription';
import actionConstants from '../constants/ActionConstants';
import actionDispatcher from '../../api/ActionDispatcher';

const fetchEmployeesDetail = id => (
  actionDispatcher(customerDetail.bind(null, id),
    actionConstants.FETCH_EMPLOYESS_DETAIL_SUCCESS,
    actionConstants.FETCH_EMPLOYESS_DETAIL_FAILURE,
    actionConstants.FETCH_EMPLOYESS_DETAIL_REQUEST)
);

const fetchActiveSubscriptions = () => (
  actionDispatcher(fetchSubs.bind(null),
    actionConstants.FETCH_SUBSCRIPTIONS,
    actionConstants.FETCH_EMPLOYESS_DETAIL_FAILURE,
    actionConstants.FETCH_EMPLOYESS_DETAIL_REQUEST
  )
);

const clearEmployeesDetails = () => (dispatch) => {
  dispatch({ type: actionConstants.FETCH_EMPLOYESS_DETAIL_RESET });
};

export { fetchEmployeesDetail, clearEmployeesDetails, fetchActiveSubscriptions };
