import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import CustomMultiSelectDropDown from '../../../../components/CustomMultiSelectDropdown';
import JobListRow from './JobListRow';
import Table from '../../../../components/ReusableTable';
import {
  fetchJobDetails
} from '../../../../_helpers/commonFunctions';
import {
  headerDataComingJobs,
  headerDataMissedJobs,
  timeCategory
} from '../../../../libs/constants/offlineEnhanced';

const jobStatus = ['Created', 'Assigned', 'Inprogress'];

class SelectJobsModalBody extends React.Component {
  state = {
    searchValue: '',
    selectedReportCategory: this.props.reportCategories || [],
    selectedTimeCategory: [...timeCategory],
    jobs: [],
    missedJob: [],
    timeCategoriesSelected: { 'Jobs Due': true, 'Missed Inspections': true },
    reportCategoriesSelected: {},
  };

  setJobs = () => {
    const { missedJobs } = this.props;
    const { jobList } = this.props.jobList;
    const jobs = fetchJobDetails(jobList, true);
    const missedJob = fetchJobDetails(missedJobs.jobList, true);
    this.setState({ jobs, missedJob });
  }

  getJobList = (queryParams = {}, page) => {
    this.props.getJobListAction(queryParams,
      !page ? this.props.jobList.currentPage + 1 : page,
      100, jobStatus);
  }

  getMissedJobList = (queryParams = {}, page) => {
    this.props.getMissedJobListAction(queryParams,
      !page ? this.props.missedJobs.currentPage + 1 : page,
      100, jobStatus);
  }

  handleSearch = () => {
    const { reportCategoriesSelected, searchValue } = this.state;
    const queryParams = {};
    if (Object.keys(reportCategoriesSelected).length) {
      queryParams.reportCategory = Object.keys(reportCategoriesSelected);
    }
    if (searchValue) {
      queryParams.search = searchValue;
    }
    this.getJobList(queryParams, 1);
    this.getMissedJobList(queryParams, 1);
  }

  setSearchText(e) {
    this.setState({
      searchValue: e.target ? e.target.value : '',
    }, () => {
      if (!this.state.searchValue) {
        this.handleSearch();
      }
    });
  }

  getCategories(name, value, isDelete, categoryType) {
    let categoriesSelected = {};
    let selectedCategory = [];
    if (categoryType === 'time') {
      categoriesSelected = { ...this.state.timeCategoriesSelected };
    } else if (categoryType === 'report') {
      categoriesSelected = { ...this.state.reportCategoriesSelected };
    }
    if (isDelete) {
      delete categoriesSelected[name];
    } else {
      categoriesSelected[name] = value;
    }
    if (categoryType === 'time') {
      this.setState({ timeCategoriesSelected: categoriesSelected });
    } else if (categoryType === 'report') {
      this.setState({ reportCategoriesSelected: categoriesSelected });
    }
    selectedCategory = Object.keys(categoriesSelected);
    return selectedCategory;
  }

  setCategories = (selectedCategory, categoryType) => {
    if (categoryType === 'time') {
      this.setState({ selectedTimeCategory: selectedCategory });
    } else if (categoryType === 'report') {
      this.setState({ selectedReportCategory: selectedCategory }, () => {
        const { reportCategoriesSelected } = this.state;
        const queryParams = {
          reportCategory: Object.keys(reportCategoriesSelected),
        };
        this.getJobList(queryParams, 1);
        this.getMissedJobList(queryParams, 1);
      });
    }
  }

  handleCategoryChange = (name, value, categoryType) => {
    let selectedCategory = [];
    if (!value) {
      selectedCategory = this.getCategories(name, value, true, categoryType);
    } else {
      selectedCategory = this.getCategories(name, value, false, categoryType);
    }
    this.setCategories(selectedCategory, categoryType);
  }

  setInitialState = () => {
    const {
      selectedReportCategory,
      selectedTimeCategory
    } = this.props;
    const initialState = {};
    if (selectedTimeCategory.length) initialState.selectedTimeCategory = selectedTimeCategory;
    if (selectedReportCategory.length) initialState.selectedReportCategory = selectedReportCategory;
    this.setState({
      ...this.state,
      ...initialState,
    });
  }

  componentDidUpdate(prevProps) {
    const prevIsFetching = prevProps.jobList.isSuccess;
    const newIsFetching = this.props.jobList.isSuccess;
    const prevIsFetchingMissed = prevProps.missedJobs.isSuccess;
    const newIsFetchingMissed = this.props.missedJobs.isSuccess;
    if ((prevIsFetching !== newIsFetching && newIsFetching)
      || (prevIsFetchingMissed !== newIsFetchingMissed && newIsFetchingMissed)) {
      this.setJobs();
    }
  }

  componentDidMount() {
    this.setJobs();
    this.getJobList({}, 1, 10);
    this.getMissedJobList({}, 1, 10);
  }

  getSelectJobModalBody = () => {
    const {
      jobs,
      missedJob,
      selectedTimeCategory,
      searchValue,
    } = this.state;

    const {
      jobList,
      missedJobs,
      setSelectedJobsClients,
      offlineMode,
      resetOfflineJobsList,
    } = this.props;

    const {
      isFetching,
      pages,
      currentPage
    } = jobList;

    return (
      <div className='select-job-modal-table'>
        <SearchBar
          searchContainerClass='search-container d-flex mr-bt-20'
          inputClassName='mw-325'
          onSearchPress={this.handleSearch}
          onTextChange={e => this.setSearchText(e)}
          searchValue={searchValue}
          isCrossVisible={searchValue}
        />
        <div className="dropdown-container mr-bt-10 ">
          <CustomMultiSelectDropDown
            className='user-confirmation-modal-buttons'
            dropDownList={this.props.reportCategories}
            name='reportCategory'
            handleOnChange={(name, value) => this.handleCategoryChange(name, value, 'report')}
            placeholder='Select Here'
            selectedValues={Object.keys(this.state.reportCategoriesSelected)}
            dropItemSelected={this.state.reportCategoriesSelected}
          />
          <CustomMultiSelectDropDown
            className='user-confirmation-modal-buttons'
            dropDownList={timeCategory}
            name='timeCategory'
            handleOnChange={(name, value) => this.handleCategoryChange(name, value, 'time')}
            placeholder='Select Here'
            selectedValues={selectedTimeCategory}
            dropItemSelected={this.state.timeCategoriesSelected}
          />
        </div>
        {missedJob.length > 0 && selectedTimeCategory.includes('Missed Inspections') && <Table
          headerData={headerDataMissedJobs}
          bodyData={missedJob}
          RowToRender={JobListRow}
          loadMore={this.getMissedJobList}
          isFetching={missedJobs.isFetching}
          hasMoreItems={missedJobs.currentPage < missedJobs.pages}
          className='missedJobTables'
          isLoaderVisible={missedJobs.isFetching}
          isEndMessageVisible={!missedJob.length && !missedJobs.isFetching}
          tableClassName='table-responsive global-table global-sm-table mr-bt-10'
          mainTableClass='job-list-table-special'
          RowToRenderProps={{
            setSelectedJobsClients,
            offlineMode,
            resetOfflineJobsList,
          }}
        />}
        {selectedTimeCategory.includes('Jobs Due') && <Table
          headerData={headerDataComingJobs}
          bodyData={jobs}
          RowToRender={JobListRow}
          loadMore={this.getJobList}
          isFetching={isFetching}
          hasMoreItems={currentPage < pages}
          className='missedJobTables'
          isLoaderVisible={isFetching}
          isEndMessageVisible={!jobs.length && !isFetching}
          tableClassName='table-responsive global-table global-sm-table mr-bt-10'
          mainTableClass='job-list-table-special'
          RowToRenderProps={{
            setSelectedJobsClients,
            offlineMode,
            resetOfflineJobsList,
          }}
        />}
      </div>
    );
  }

  render() {
    const {
      handleClickSave,
      handleClickPrevious,
    } = this.props;

    return (
      <div>
        <div>
          {this.getSelectJobModalBody()}
        </div>
        <div className='select-customer-modal-buttons-container mr-top-24 mr-bt-24'>
          <Button
            text='Previous'
            color='#6AA14E'
            className='user-confirmation-modal-buttons'
            onBtnClick={handleClickPrevious}
          />
          <Button
            text='Save'
            color='#6AA14E'
            className='user-confirmation-modal-buttons'
            onBtnClick={handleClickSave}
          />
        </div>
      </div>
    );
  }
}

SelectJobsModalBody.propTypes = {
  handleClickPrevious: PropTypes.func,
  handleClickSave: PropTypes.func,
  selectedReportCategory: PropTypes.string,
  selectedTimeCategory: PropTypes.string,
  jobList: PropTypes.object,
  getJobListAction: PropTypes.func,
  reportCategories: PropTypes.array,
  getMissedJobListAction: PropTypes.func,
  missedJobs: PropTypes.object,
  setSelectedJobsClients: PropTypes.func,
  offlineMode: PropTypes.object,
  resetOfflineJobsList: PropTypes.func,
};

export default SelectJobsModalBody;
