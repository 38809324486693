import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  offlineModeOptions, OfflineModeContext, fetch,
  offlineModeOptionsToolTips
} from '../../../../libs/constants/offlineEnhanced';
import '../../offline.css';
import Button from '../../../../components/Button';
import ToolTip from '../../../../components/ToolTip';
import { checkPermission, displayToast } from '../../../../_helpers/commonFunctions';
import { permissions } from '../../../../libs/constants/permissions';

const OfflineModeOptionsModalBody = (props) => {
  useEffect(() => {
    props.setInitialModalOption();
  }, []);
  return (
    <OfflineModeContext.Consumer>
      {value => (
        <div className='offline-mode-option-modal-body-container'>
          <div className='offline-mode-option-modal-body-wrapper'>
            {offlineModeOptions.map((option, index) => (
              <div key={option} className='offline-mode-options-list mr-bt-10'>
                <label className='cust-radio'>
                  <span
                  data-tip={props.intl.formatMessage({ id: offlineModeOptionsToolTips[index] })}
                  data-for={`offline-${offlineModeOptionsToolTips[index]}`}
                  ><FormattedMessage
                      id={option}
                      defaultMessage={option}
                    />
                    <ToolTip
                      id={`offline-${offlineModeOptionsToolTips[index]}`}
                      place='right'
                    />
                  </span>
                  <input
                    type='radio'
                    name={option}
                    checked={value.offlineMode.selectedOfflineModeOption === option}
                    onChange={(e) => {
                      if ((e.target.checked && e.target.name === offlineModeOptions[1])
                        && !checkPermission(permissions.jobLink)) {
                        displayToast('Operation not allowed', 'error');
                        return;
                      }
                      if (e.target.checked) {
                        value.selectOfflineModalOption(e.target.name);
                      }
                    }}
                  />
                  <span className='checkmark' />
                </label>
              </div>
            ))}
            <div className='mr-top-14'>
              <Button
                text={value.offlineMode.selectedOfflineModeOption === offlineModeOptions[1] ? 'Save' : 'Next'}
                onBtnClick={() => {
                  if (value.offlineMode.selectedOfflineModeOption === offlineModeOptions[0]) {
                    value.toggleCustomerModalVisibility(true);
                  }
                  if (value.offlineMode.selectedOfflineModeOption === offlineModeOptions[2]) {
                    value.toggleJobsModalVisibility(true);
                  }
                  if ((value.offlineMode.selectedOfflineModeOption === offlineModeOptions[1])
                    && checkPermission(permissions.jobLink)) {
                    value.setStep(fetch.JOB_LIST);
                    value.toggleOffline(true);
                  } else if (!checkPermission(permissions.jobLink)
                    && (value.offlineMode.selectedOfflineModeOption === offlineModeOptions[1])) {
                    displayToast('Operation not allowed', 'error');
                  }
                  value.toggleModalVisibility(false);
                }}
                color='#6AA14E'
              />
            </div>
          </div>
        </div>
      )}
    </OfflineModeContext.Consumer>
  );
};

OfflineModeOptionsModalBody.propTypes = {
  setInitialModalOption: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(OfflineModeOptionsModalBody);
