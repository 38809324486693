import Socket from './chatSocket';
import EVENT from './chatSocketEvents';

function init() {
  new Socket().getInstance();
  // socket.emit(EVENT.TO.CONNECT);
}

function onConnected(handleOnConnected) {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.CONNECT, () => {
    handleOnConnected();
    socket.emit(EVENT.TO.CONNECT);
  });
}

function onReceive(handleMessageReceived) {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.MESSAGE_RECEIVED, handleMessageReceived);
}

function onMsgDelivered(handleOnMessageDelivered) {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.MESSAGE_DELIVERED, handleOnMessageDelivered);
}

function onMsgSent(handleOnMessageSent) {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.MESSAGE_SENT, handleOnMessageSent);
}

function onMsgSeen(handleOnMessageSeen) {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.MESSAGE_SEEN, handleOnMessageSeen);
}

function onUpdateGroupDetails(handleGroupUpdate) {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.UPDATE_GROUP, handleGroupUpdate);
}

function sendMessage(message) {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.TO.SEND_MESSAGE, message);
}

function emitAllMsgsAsDelivered({ userId }) {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.TO.MARK_ALL_DELIVERED, { userId });
}

function emitReceivedMsgAsDelivered({ chatId, currentUserId }) {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.TO.MARK_MESSAGE_AS_DELIVERED, { channelId: chatId, senderId: currentUserId });
}

function emitChatIdAsSeen({ chatId, currentUserId }) {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.TO.MARK_CHAT_AS_SEEN, { channelId: chatId, senderId: currentUserId });
}

function emitGPSLocation(payload) {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.TO.EMPLOYEE_GPS_LOCATION, payload);
}

function destroy() {
  const { getInstance, removeInstance } = new Socket();
  const socket = getInstance();
  socket.off();
  socket.disconnect();
  removeInstance();
}

export {
  init,
  sendMessage,
  onReceive,
  destroy,
  onMsgSent,
  emitChatIdAsSeen,
  emitGPSLocation,
  onMsgSeen,
  onMsgDelivered,
  emitReceivedMsgAsDelivered,
  emitAllMsgsAsDelivered,
  onConnected,
  onUpdateGroupDetails,
};
