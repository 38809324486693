import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const DefaultDropDownContainer = (props) => {
  const {
    selectedValue,
    children,
    onToggle,
    isDropDownOpen,
    className,
    isDisabled,
  } = props;
  return (
    <div
      className={`${className} ${isDropDownOpen ? 'label-color' : ''}`}
      onClick={() => {
        if (!isDisabled) {
          onToggle(!isDropDownOpen);
        }
      }}>
      <div className={`select-selected ${isDropDownOpen ? 'select-arrow-active' : ''}`}>
        {
          selectedValue
            ? <FormattedMessage
              id={selectedValue}
              defaultMessage={selectedValue}
            /> : ''
        }
      </div>
      {isDropDownOpen ? children : null}
    </div>
  );
};

DefaultDropDownContainer.propTypes = {
  children: PropTypes.object,
  selectedValue: PropTypes.string,
  className: PropTypes.string,
  onToggle: PropTypes.func,
  isDropDownOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
};


DefaultDropDownContainer.defaultProps = {
  className: 'custom-select min-176'
};

export default DefaultDropDownContainer;
