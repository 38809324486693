import call from './apiRequest';
import apiEndpoints from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';
import { store } from '../redux/store/initStore';

/**
 * Api call for creating report
 * @param {object} payload
 */
const reportCreate = payload => call({
  method: 'post',
  payload,
  endpoint: apiEndpoints.reportCreate,
  authKey: getToken()
});

/**
 * Api call for getting report details
 * @param {string} id
 */
const reportDetail = id => call({
  method: 'get',
  endpoint: `${apiEndpoints.reportCreate}/${id}`,
  authKey: getToken()
});

/**
 * Api call for getting report templates list
 * @param {number} page
 * @param {number} limit
 */
const reportTemplateListApiCall = (page, limit) => call({
  method: 'post',
  endpoint: apiEndpoints.reportTemplateList,
  query: {
    page,
    limit
  },
  authKey: getToken()
});

/**
 * Api call for creating report inspection
 * @param {object} payload
 */
const reportCreateInspection = payload => call({
  method: 'post',
  endpoint: apiEndpoints.reportCreateInspection,
  payload: {
    ...payload,
    lang: ((store.getState() || {}).locale || {}).lang || 'en',
  },
  authKey: getToken()
});

export { reportDetail, reportTemplateListApiCall, reportCreateInspection };

export default reportCreate;
