import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  ChatBody,
  PeopleList,
  StartChatModal,
  ChatRoomDetails,
  ChatScreenHeader
} from './components';
import './ChatView.scss';

const LIMIT = 100;

function ChatView(props) {
  const {
    // data props
    chats,
    allPeople,
    peopleList,
    currentUser,
    activeChatId,
    chatRoomDetails,
    page,
    totalAllPeopleCount,
    totalChatMessagesCount,
    // action props
    getGroupDetails,
    createGroupChat,
    setActiveChatId,
    sendTextMessage,
    updateGroupChat,
    getAllChatMessages,
    getAllPeopleToChat,
    createIndividualChat,
    deleteOldMessage,
    // flag props
    isChatLoading,
    isGetChatByIdSuccess,
    isAllPeopleApiLoading,
    isAllPeopleApiError,
    allPeopleApiError,
    isUpdateChatLoading,
    isUpdateChatSuccess,
    isUpdateChatError,
    updateChatError,
    isChatRoomDetailsLoading
  } = props;

  const [isChatModalVisible, setChatModalVisible] = useState(false);
  const [isGroupChatEnabled, setGroupChatEnabled] = useState(false);
  const [isChatRoomDetailsVisible, setChatRoomDetailsVisible] = useState(false);

  const showChatModal = () => {
    setChatModalVisible(true);
  };

  const hideChatModal = () => {
    setChatModalVisible(false);
  };

  const showGroupChats = (enabled) => {
    setGroupChatEnabled(enabled);
    setActiveChatId('');
  };

  const showChatRoomDetails = () => {
    setChatRoomDetailsVisible(true);
    getAllPeopleToChat(false, 0, LIMIT);
    getGroupDetails({ chatId: activeChatId });
  };

  const hideChatRoomDetails = () => {
    setChatRoomDetailsVisible(false);
  };

  const commonModalProps = {
    allPeople,
    page,
    totalAllPeopleCount,
    isGroupChatEnabled,
    getAllPeopleToChat,
    isAllPeopleApiLoading,
    isAllPeopleApiError,
    allPeopleApiError,
    createIndividualChat,
    isUpdateChatLoading,
    isUpdateChatSuccess,
    currentUser,
    createGroupChat,
    updateChatError,
    isUpdateChatError
  };

  return (
    <Fragment>
      <ChatScreenHeader />
      <div className="chat-wrap">
        {/* Modal */}
        {<StartChatModal
          isVisible={isChatModalVisible}
          hideChatModal={hideChatModal}
          {...commonModalProps}
        />}
        {/* Modal */}
        {<ChatRoomDetails
          isVisible={isChatRoomDetailsVisible}
          hideChatModal={hideChatRoomDetails}
          chatRoomDetails={chatRoomDetails}
          getGroupDetails={getGroupDetails}
          activeChatId={activeChatId}
          currentUser={currentUser}
          updateGroupChat={updateGroupChat}
          {...commonModalProps}
        />}
        {/* Left Pane */}
        <PeopleList
          peopleList={peopleList}
          activeChatId={activeChatId}
          setActiveChatId={setActiveChatId}
          showChatModal={showChatModal}
          showGroupChats={showGroupChats}
          isGroupChatEnabled={isGroupChatEnabled}
        />
        {/* Right Pane */}
        <ChatBody
          chats={chats}
          activeChatId={activeChatId}
          peopleList={peopleList}
          sendTextMessage={sendTextMessage}
          isChatLoading={isChatLoading}
          totalChatMessagesCount={totalChatMessagesCount}
          isUpdateChatLoading={isUpdateChatLoading}
          getAllChatMessages={getAllChatMessages}
          currentUser={currentUser}
          isGetChatByIdSuccess={isGetChatByIdSuccess}
          getGroupDetails={getGroupDetails}
          showChatRoomDetails={showChatRoomDetails}
          isChatRoomDetailsLoading={isChatRoomDetailsLoading}
          setActiveChatId={setActiveChatId}
          deleteOldMessage={deleteOldMessage}
          isGroupChatEnabled={isGroupChatEnabled}
        />
      </div>
    </Fragment>
  );
}

ChatView.propTypes = {
  activeChatId: PropTypes.string.isRequired,
  allPeople: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalAllPeopleCount: PropTypes.number.isRequired,
  allPeopleApiError: PropTypes.string.isRequired,
  chatRoomDetails: PropTypes.object.isRequired,
  chats: PropTypes.array.isRequired,
  createGroupChat: PropTypes.func.isRequired,
  createIndividualChat: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  getAllChatMessages: PropTypes.func.isRequired,
  getAllPeopleToChat: PropTypes.func.isRequired,
  getGroupDetails: PropTypes.func.isRequired,
  isAllPeopleApiError: PropTypes.bool.isRequired,
  isAllPeopleApiLoading: PropTypes.bool.isRequired,
  isChatLoading: PropTypes.bool.isRequired,
  totalChatMessagesCount: PropTypes.number.isRequired,
  isChatRoomDetailsLoading: PropTypes.bool.isRequired,
  isGetChatByIdSuccess: PropTypes.bool.isRequired,
  isUpdateChatError: PropTypes.bool.isRequired,
  isUpdateChatLoading: PropTypes.bool.isRequired,
  isUpdateChatSuccess: PropTypes.bool.isRequired,
  peopleList: PropTypes.array.isRequired,
  sendTextMessage: PropTypes.func.isRequired,
  setActiveChatId: PropTypes.func.isRequired,
  updateChatError: PropTypes.func.isRequired,
  updateGroupChat: PropTypes.func.isRequired,
  deleteOldMessage: PropTypes.func.isRequired,
};

export default ChatView;
