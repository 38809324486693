import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './DnDTable.scss';

const DnDTable = ({
  headerData,
  bodyData,
  loadMore,
  hasMoreItems,
  Footer,
  sortData,
  isEndMessageVisible,
  isLoaderVisible,
  endMessage,
  columnSpan,
  rowToRenderProps,
  firstChildStyle,
  tableClassName,
  schedulerData,
  newEvent,
  taskDndSource,
  componentMaxHeight
}) => {
  const DnDTaskItem = taskDndSource && taskDndSource.getDragSource();
  const taskList = bodyData && bodyData.map(item => <DnDTaskItem
    className='job-details-hover'
    key={item.id}
    task={item}
    newEvent={newEvent}
    schedulerData={schedulerData}
    {...rowToRenderProps}
  />);

  return (
    <div className={`${tableClassName} job-list-table-special job-list-large-screen`}>
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th style={{ padding: '0 20px 0 20px', background: 'transparent' }}>
              <table width="100%">
                <tbody>
                  <tr>
                    {headerData.map((header, index) => (
                      <th className={header.isSortable ? 'arrow' : null} key={header.title + index} style={index === 0 ? firstChildStyle : null}> {/* key can be made unique */}
                        {header.isSortable
                          ? <span id = 'test-span' onClick={() => sortData(index)} style={{ cursor: 'pointer' }}>
                            <FormattedMessage
                              id={header.title}
                              defaultMessage={header.title}
                            />
                          </span>
                          : <FormattedMessage
                            id={header.title}
                            defaultMessage={header.title}
                          />
                        }
                      </th>
                    ))}
                  </tr>
                </tbody>
              </table>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columnSpan} style={{ padding: '20px', background: '#ffffff' }}>
              <InfiniteScroll
                dataLength={bodyData.length}
                next={loadMore}
                hasMore={hasMoreItems}
                height={componentMaxHeight}
                endMessage={isEndMessageVisible
                  ? <div className="end-message">
                    <FormattedMessage
                      id={endMessage}
                      defaultMessage={endMessage}
                    />
                  </div>
                  : ''
                }
              >
                <table width="100%">
                  <tbody>
                    {taskList}
                  </tbody>
                </table>
                {
                  isLoaderVisible ? <div className='loader-container'><div className='loader'></div></div> : null
                }
              </InfiniteScroll>
              {Footer ? <Footer /> : null}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

DnDTable.propTypes = {
  headerData: PropTypes.array,
  bodyData: PropTypes.array,
  RowToRender: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  loadMore: PropTypes.func,
  hasMoreItems: PropTypes.bool,
  Footer: PropTypes.func,
  sortData: PropTypes.func,
  height: PropTypes.number,
  isEndMessageVisible: PropTypes.bool,
  isLoaderVisible: PropTypes.bool,
  endMessage: PropTypes.string,
  columnSpan: PropTypes.number,
  rowToRenderProps: PropTypes.object,
  firstChildStyle: PropTypes.object,
  tableClassName: PropTypes.string,
  schedulerData: PropTypes.object,
  newEvent: PropTypes.func,
  taskDndSource: PropTypes.object,
  className: PropTypes.string
};

DnDTable.defaultProps = {
  headerData: [],
  bodyData: [],
  RowToRender: undefined,
  loadMore: () => { },
  hasMoreItems: false,
  Footer: undefined,
  sortData: () => { },
  isEndMessageVisible: true,
  isLoaderVisible: true,
  endMessage: 'NO_MORE_DATA',
  columnSpan: 7,
  rowToRenderProps: {},
  firstChildStyle: {},
  tableClassName: 'table-responsive customer-table global-table',
  schedulerData: {},
  newEvent: () => { },
  taskDndSource: {},
};

export default DnDTable;
