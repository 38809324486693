import ActionConstants from '../constants/ActionConstants';

const initialState = {
  permissions: [],
};

export default function setSelectedCustomer(state = initialState, action) {
  if (action.type === ActionConstants.SET_PERMISSIONS) {
    return {
      permissions: action.data.permissions,
    };
  }
  if (action.type === ActionConstants.CLEAR_PERMISSIONS) {
    return {
      permissions: [],
    };
  }
  return state;
}
