import call from './apiRequest';
import { getToken } from '../_helpers/commonFunctions';
import apiEndpoints from './apiEndpoints';

/**
 * Api call for getting labour list
 * @param {number} page
 * @param {number} limit
 * @param {string} searchText
 */
const labourListDropDown = (page, limit = 10, searchText) => {
  const payload = {};

  if (searchText) {
    payload.search = searchText;
  }

  return call({
    method: 'post',
    endpoint: `${apiEndpoints.employee}?pageNo=${page}&limit=${limit}`,
    payload: {
      ...payload,
      sortOrder: 1
    },
    authKey: getToken(),
  });
};

/**
 * Api call getting part list with search text
 * @param {number} page
 * @param {string} searchText
 */
const partDropDown = (page, searchText) => {
  const payload = {};
  return call({
    method: 'post',
    endpoint: `${apiEndpoints.partListDropDown}?page=${page}&search=${searchText}`,
    payload,
    authKey: getToken(),
  });
};

export {
  labourListDropDown, partDropDown
};
