import { isEmpty } from 'lodash';
import actionConstants from '../constants/ActionConstants';
import actionDispatcher from '../../api/ActionDispatcher';
import { auth } from '../../api';
import logoutHandler from '../../_helpers/LogoutHandler';

const request = user => ({
  type: actionConstants.LOGIN_REQUEST,
  user
});
const success = user => ({
  type: actionConstants.LOGIN_SUCCESS,
  user
});
const failure = errorpassword => ({
  type: actionConstants.LOGIN_FAILURE,
  errorpassword
});

export const loginauth = (email, password, response, isRedirectedFromAdmin = false) => {
  if (!email && !password && response && !isEmpty(response) && isRedirectedFromAdmin) {
    return (dispatch => dispatch({
      type: actionConstants.LOGIN_SUCCESS,
      payload: { ...response.body.data },
      message: { ...response.body.message },
      headers: { ...response.headers },
      isRedirectedFromAdmin

    }));
  }
  return (
    actionDispatcher(auth.login.bind(null, email, password),
      actionConstants.LOGIN_SUCCESS,
      actionConstants.LOGIN_FAILURE,
      actionConstants.LOGIN_REQUEST)
  );
};


export const updateTermsAndCondition = isTermsAndCondition => (
  actionDispatcher(auth.setTermsAndCondition.bind(null, isTermsAndCondition),
    actionConstants.SET_TERMS_AND_CONDITION_SUCCESS,
    actionConstants.SET_TERMS_AND_CONDITION_FAILURE,
    actionConstants.SET_TERMS_AND_CONDITION_REQUEST)
);


export const updatePrivacyPolicy = isPrivayPolicy => dispatch => (
  dispatch({
    type: actionConstants.SET_PRIVACY_POLICY,
    payload: { isPrivacy: isPrivayPolicy }
  })
);

export function logout() {
  logoutHandler();
  return { type: actionConstants.RESET_ALL_REDUCERS };
}

export const logoutAction = () => (
  actionDispatcher(auth.logout.bind(null),
    actionConstants.LOGOUT_SUCCESS,
    actionConstants.LOGOUT_FAILURE,
    actionConstants.LOGOUT_REQUEST
  )
);

export const clearLogoutAction = () => ({
  type: actionConstants.CLEAR_LOGOUT,
});

export const handleFetchUser = user => (dispatch) => {
  const { email } = user;
  dispatch(request({ email }));
  auth.fetchUserApi(user).then(
    (res) => {
      dispatch(success(res));
    },
    (error) => {
      dispatch(failure(error));
      dispatch(logout());
    }
  );
};

export const clearAllReducers = () => (dispatch) => {
  dispatch({ type: actionConstants.RESET_ALL_REDUCERS });
};

export function getAuthUser(args) {
  return (dispatch, getState) => {
    const { user } = getState();
    // do nothing when user is available
    if (!isEmpty(user)) {
      return;
    }

    // redirect if token is invalid or expired
    if (!user || !isEmpty(user)) {
      dispatch(failure());
      dispatch(logout());
    } else {
      dispatch(handleFetchUser(args));
    }
  };
}

export const changePasswordAction = (oldPassword, newPassword) => actionDispatcher(
  auth.changePasswordApi.bind(null, oldPassword, newPassword),
  actionConstants.CHANGE_PASSWORD_SUCCESS,
  actionConstants.CHANGE_PASSWORD_FAILURE,
  actionConstants.CHANGE_PASSWORD_REQUEST
);

export function resetPasswordState() {
  return { type: actionConstants.RESET_PASSWORD_INITIAL };
}

export function clearLoginSuccessState() {
  return { type: actionConstants.CLEAR_LOGIN_SUCCESS };
}

export function setLoginUserDetails() {
  return { type: actionConstants.SET_LOGIN_USER_DETAILS };
}

export const updatePermissions = () => actionDispatcher(
  auth.permissionsUpdate.bind(null),
  actionConstants.UPDATE_PERMISSIONS_SUCCESS,
  actionConstants.UPDATE_PERMISSIONS_FAILURE,
  actionConstants.UPDATE_PERMISSIONS_REQUEST
);

export const updateSubscriptions = () => actionDispatcher(
  auth.subscriptionsUpdate.bind(null),
  actionConstants.UPDATE_SUBSCRIPTIONS_SUCCESS,
  actionConstants.UPDATE_SUBSCRIPTIONS_FAILURE,
  actionConstants.UPDATE_SUBSCRIPTIONS_REQUEST
);
