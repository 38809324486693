import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../components/ReusableTable';
import IncompleteReportLayoutRows from './IncompleteReportLayoutRow';

const incompleteHeaderFields = [
  {
    title: 'Category',
    isSortable: false,
  },
  {
    title: 'Start Date',
    isSortable: false,
  },
  {
    title: 'Modified Date',
    isSortable: false,
  },
];

const IncompleteReportLayout = ({
  sortData, reportList, loadMore, isfetching, hasMoreItems, endMessage,
  isEndMessageVisible, reportHeading,
  setSelectedReportInfoId, setSelectedReportEditId, isClassVisible,
  setClassVisibility
}) => (
    <React.Fragment>
      <Table
        sortData={sortData}
        headerData={incompleteHeaderFields}
        bodyData={reportList}
        RowToRender={IncompleteReportLayoutRows}
        RowToRenderProps={{
          reportHeading,
          setSelectedReportInfoId,
          setSelectedReportEditId,
          isClassVisible,
          setClassVisibility,
        }}
        loadMore={loadMore}
        endMessage={endMessage}
        isLoaderVisible={isfetching}
        mainTableClass={'customer-table report-listing-table'}
        hasMoreItems={hasMoreItems}
        isEndMessageVisible={isEndMessageVisible}
      />
    </React.Fragment>

);
IncompleteReportLayout.propTypes = {
  setSearchText: PropTypes.func,
  setFilters: PropTypes.func,
  isCrossVisible: PropTypes.bool,
  handleSearch: PropTypes.func,
  status: PropTypes.array,
  reportType: PropTypes.string,
  filter: PropTypes.array,
  setDatePeriod: PropTypes.func,
  setReportType: PropTypes.func,
  sortData: PropTypes.func,
  reportList: PropTypes.array,
  loadMore: PropTypes.func,
  isfetching: PropTypes.boolean,
  hasMoreItems: PropTypes.boolean,
  endMessage: PropTypes.string,
  isEndMessageVisible: PropTypes.func,
  searchText: PropTypes.string,
  reportHeading: PropTypes.string,
  setSelectedReportInfoId: PropTypes.func,
  setSelectedReportEditId: PropTypes.func,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func
};

export default IncompleteReportLayout;
