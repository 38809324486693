import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { handleUserConfirmationPopupForModals } from '../../_helpers/commonFunctions';

const Modal = (props) => {
  const {
    modalHeading,
    ModalBody,
    ModalFooter,
    handleClickCross,
    modalId,
    ModalFooterProps,
    ModalBodyProps,
    isDetailFetching,
    modalClass,
    customStyle,
    preview,
    isCrossVisible,
    modalContentClass,
    modalStyle,
  } = props;

  return (
    <div id='overlayModal'>
      <div className="modal fade show" id={modalId} style={{ display: 'block', overflow: 'auto' }}>
        <div className={modalClass} style={modalStyle}>
          <div className={`modal-content ${modalContentClass || ''}`} style={preview ? customStyle : { cursor: 'default' }}>
            <div className="modal-header">
              <h4 className="modal-title">
                <FormattedMessage
                  id={modalHeading}
                  defaultMessage={modalHeading}
                />
              </h4>
              {isCrossVisible && <button
                type="button"
                className="close sprite-icon"
                data-dismiss="modal"
                onClick={() => handleUserConfirmationPopupForModals(handleClickCross)}
              ></button>}
            </div>
            <div className="modal-body">
              {isDetailFetching
                ? <div className='loader-container'>
                  <div className='loader'></div>
                </div>
                : (<div>
                  <ModalBody {...ModalBodyProps} />
                  {ModalFooter && <ModalFooter {...ModalFooterProps} />}
                </div>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  modalHeading: PropTypes.string, // Modal Heading
  ModalBody: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]), // Modal body component
  ModalFooter: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]), // Modal footer
  handleClickCross: PropTypes.func, // Handles close modal
  modalId: PropTypes.string,
  ModalBodyProps: PropTypes.object, // Object for modal body component
  ModalFooterProps: PropTypes.object, // Object for Modal Footer component
  isDetailFetching: PropTypes.bool,
  modalClass: PropTypes.string,
  modalStyle: PropTypes.object,
  preview: PropTypes.bool,
  customStyle: PropTypes.object, // style object for modal
  isCrossVisible: PropTypes.bool,
  modalContentClass: PropTypes.string,
};

Modal.defaultProps = {
  modalHeading: <div>Modal Heading</div>,
  ModalBody: () => 'Modal Body',
  ModalFooter: null,
  handleClickCross: () => { },
  modalClass: 'modal-dialog modal-dialog-centered',
  modalStyle: null,
  modalId: 'myModal',
  ModalBodyProps: {},
  ModalFooterProps: {},
  isDetailFetching: false,
  preview: false,
  isCrossVisible: true,
};

export default Modal;
