import actionConstants from '../constants/ActionConstants';

const initialState = {
  isPrompt: false,
  extraData: undefined
};

const IsPromptReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.SET_IS_PROMPT:
      return {
        ...state,
        isPrompt: action.payload,
        extraData: action.extradata
      };
    case actionConstants.GET_XLXS_COUNT_SUCCESS:
    case actionConstants.GET_REPORT_LIST_REQUEST:
    case actionConstants.GET_REPORT_LIST_SUCCESS:
    case actionConstants.GET_XLXS_COUNT_REQUEST:
    case actionConstants.CHAT_MARK_AS_UNDELIVERED:
    case actionConstants.UPDATE_REPORT_REQUEST:
    case actionConstants.SET_JOB_DETAILS:
    case actionConstants.INVENTORY_GET_SUPPLIER_LIST_RESET:
    case actionConstants.UPLOAD_IMAGE_SUCCESS:
    case actionConstants.UPLOAD_IMAGE_RESET:
    case actionConstants.INVENTORY_GET_VEHICLE_LIST_RESET:
    case actionConstants.FETCH_ASSIGNED_TECHNICIAN_RESET:
    case actionConstants.GET_TOOLS_LIST_RESET:
    case actionConstants.UPLOAD_IMAGE_REQUEST:
    case actionConstants.GET_REPORT_PART_LIST_REQUEST:
    case actionConstants.GET_REPORT_PART_LIST_SUCCESS:
    case actionConstants.CLEAR_STATE:
      return state;
    default:
      return initialState;
  }
};

export default IsPromptReducer;
