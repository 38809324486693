import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  isSuccess: false,
  success: ''
};

export default function addSystemOrDevice(state = initialState, action) {
  switch (action.type) {
    case actionConstants.UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isFetching: true
      };
    case actionConstants.UPDATE_CLIENT_SUCCESS:
      return {
        isFetching: false,
        isSuccess: true,
        success: action.message,
        error: '',
      };
    case actionConstants.UPDATE_CLIENT_FAILURE:
      return {
        error: action.message,
        isSuccess: false,
        isFetching: false,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    case actionConstants.CLEAR_STATE:
      return {
        error: '',
        isSuccess: false,
        isFetching: false,
        success: ''
      };
    default:
      return state;
  }
}
