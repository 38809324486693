import call from './apiRequest';
import apiEndpoint from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call to get report categories
 */
const reportCategory = () => call({
  method: 'get',
  endpoint: apiEndpoint.genericReportType,
  authKey: getToken(),
  query: {
    type: 'all'
  }
});

/**
 * Api call to sync quick book
 */
const syncQuickBookAuthApiCall = () => call({
  method: 'get',
  endpoint: apiEndpoint.quickbook,
  authKey: getToken(),
});

/**
 * Api call to get quickbook details
 */
const getQuickBooksDetails = () => call({
  method: 'get',
  endpoint: apiEndpoint.quickbookDetail,
  authKey: getToken(),
});

/**
 * Api call to unlink quickbook
 */
const unlinkQuickBook = () => call({
  method: 'delete',
  endpoint: apiEndpoint.quickbookDelete,
  authKey: getToken(),
});

/**
 * Api call to sync with quickbook
 */
const syncWithQuickBooksApi = () => call({
  method: 'post',
  authKey: getToken(),
  endpoint: apiEndpoint.quickBooksSync
});

/**
 * Api call to get working report categories
 */
const getWorkingReportCategory = () => call({
  method: 'get',
  endpoint: apiEndpoint.customer.concat('/', apiEndpoint.setting),
  authKey: getToken(),

});

/**
 * Api call to set working report category
 * @param {string} workingReportCategory
 * @param {string} invoice
 */
const setWorkingReportCategory = (workingReportCategory, invoice) => call({
  method: 'put',
  endpoint: apiEndpoint.customer.concat('/', apiEndpoint.setting),
  payload: {
    serviceReportCategories: workingReportCategory,
    invoiceNumberType: invoice
  },
  authKey: getToken()
});

export {
  reportCategory,
  getWorkingReportCategory,
  setWorkingReportCategory,
  syncQuickBookAuthApiCall,
  getQuickBooksDetails,
  unlinkQuickBook,
  syncWithQuickBooksApi
};
