import call from './apiRequest';
import { getToken } from '../_helpers/commonFunctions';
import apiEndpoints from './apiEndpoints';

/**
 * Api call for registering device
 * @param {string} deviceId
 */
const registerDeviceApi = deviceId => call({
  method: 'post',
  endpoint: `${apiEndpoints.registerDeviceId}`,
  authKey: getToken(),
  payload: {
    deviceId,
  }
});

export default registerDeviceApi;
