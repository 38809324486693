import React from 'react';
import PropTypes from 'prop-types';

const getInitialChars = (str = '', maxLen = 2) => str
  .split(' ').map(item => item[0] && item[0].toUpperCase()).slice(0, maxLen).join('');

const initialCharsCache = {}; // maintained to memoize getInitialChars fn calls

function _getInitialChars(label, maxLen) {
  const key = label + maxLen;
  if (initialCharsCache[key]) {
    return initialCharsCache[key];
  }
  return getInitialChars(label, maxLen);
}

const ProfilePhoto = ({ label, size }) => (
  <div className="profile-photo" style={{ width: size, height: size }}>
    <div className="profile-photo-text">
      {_getInitialChars(label, 2)}
    </div>
  </div>
);

ProfilePhoto.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired
};

export default ProfilePhoto;
