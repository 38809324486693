import 'babel-polyfill';
import React, { useEffect, useState, Suspense } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import LoginRouteProtector from '../components/RouterProtectors/LoginRouteProtector';
import LogoutRouteProtector from '../components/RouterProtectors/LogoutRouteProtector';
import messages from '../i18n/messages';
import endPoints from '../routes/endpoints';
import TermsAndCondition from '../views/TermsAndCondition/TermsAndCondition';
import resetAllReducersAction from '../redux/actions/resetState';
import Loader from '../components/Loader';
import { loginauth } from '../redux/actions/auth';

const Header = React.lazy(() => import('../components/Header'));
const ForgetPassword = React.lazy(() => import('../views/ForgetPassword'));
const ChangePassword = React.lazy(() => import('../views/ChangePassword'));
const SignUp = React.lazy(() => import('../views/SignUp'));
const Login = React.lazy(() => import('../views/Login'));
const PrivacyPolicy = React.lazy(() => import('../views/PrivacyPolicy'));
const Dashboard = React.lazy(() => import('../views/Dashboard'));
const Home = React.lazy(() => import('../layouts/Home'));
const ResetPassword = React.lazy(() => import('../views/ResetPassword'));

function App(props) {
  toast.configure({
    autoClose: 3000,
    draggable: false,
    hideProgressBar: true,
    pauseOnHover: false,
  });

  const { lang } = props;
  const [renderRedirect, setRenderRedirect] = useState(false);

  const handleRedirectFromAdmin = (customerDetails) => {
    if (customerDetails) {
      const { data } = customerDetails;
      const body = !!data && typeof (data) === 'string' && JSON.parse(data);
      if (body && !isEmpty(body) && body.statusCode === 200) {
        props.loginauth(undefined, undefined, body, true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleRedirectFromAdmin, false);
    if (window.location.search.includes('isAdmin=true')) { // reset all reducers if viewing customer details from admin
      if (window.opener && window.opener.parent) {
        window.opener.parent.postMessage('firelab-ready-for-login', '*');
      }
      props.resetAllReducersAction();
    }
    const path = window.location.pathname.split('/');
    if (!props.isCustomerChild && path.length > 2 && path.includes('customer')) { // redirects to customer if user is trying to access any incorrect route
      setRenderRedirect(true);
    }
  }, []);

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang]}
    >
      <Suspense fallback={<Loader />}>
        <div>
          {renderRedirect && <Redirect to={endPoints.customer} />}
          <ToastContainer hideProgressBar />
          <Header />
          <Switch>
            <Route
              path={endPoints.login}
              component={
                LogoutRouteProtector(Login)
              }
            />
            <Route
              path={endPoints.resetPassword}
              component={ForgetPassword}
            />
            <Route
              path={endPoints.tokenExpired}
              component={LogoutRouteProtector(
                ForgetPassword
              )}
            />
            <Route
              path={endPoints.forgetPassword}
              component={
                LogoutRouteProtector(ResetPassword)
              }
            />
            <Route
              path={endPoints.signUp}
              component={LogoutRouteProtector(
                SignUp
              )}
            />
            <Route
              path={endPoints.termsAndConditions}
              component={
                TermsAndCondition
              }
            />
            <Route
              path={endPoints.privacyPolicy}
              component={
                PrivacyPolicy
              }
            />
            <Route
              path={endPoints.changePassword}
              component={LoginRouteProtector(
                ChangePassword
              )}
            />
            <Route
              path={endPoints.default}
              exact
              component={LoginRouteProtector(Dashboard)}
            />
            <Route
              path={`${endPoints.default}:id`}
              component={LoginRouteProtector(
                Home
              )}
            />
          </Switch>
        </div>
      </Suspense>
    </IntlProvider>
  );
}

App.propTypes = {
  lang: PropTypes.string.isRequired, // language selected for app
  isCustomerChild: PropTypes.bool, // is true if the route accessed is a submenu of customer
  history: PropTypes.object, // history object
  resetAllReducersAction: PropTypes.func, // resets all reducers
  loginauth: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    lang: state.locale.lang,
  };
}

export default connect(
  mapStateToProps,
  { resetAllReducersAction, loginauth }
)(App);
