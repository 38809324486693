
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call to upload report files
 * @param {object} payload
 */
const ReportsFileUpload = payload => call({
  method: 'post',
  endpoint: `${apiEndpoints.reportsFileUpload}`,
  payload,
  type: 'multipart/form-data',
  authKey: getToken()
});

/**
 * Api call to add a part
 * @param {string} partName
 * @param {string} quantity
 * @param {string} price
 * @param {string} reportType
 */
const savePartApi = (partName, quantity, price, reportType) => call({
  method: 'post',
  endpoint: apiEndpoints.addParts,
  authKey: getToken(),
  payload: {
    partName,
    reportType,
    price: parseInt(price / quantity, 10),
    quantityWareHouse: quantity
  }
});

/**
 * Api call to add new report
 * @param {object} payload
 */
const ReportAddNew = payload => call({
  method: 'put',
  endpoint: `${apiEndpoints.reportUpdate}`,
  payload,
  authKey: getToken()
});
export {
  ReportsFileUpload,
  ReportAddNew,
  savePartApi
};
