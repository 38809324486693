import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../components/Button';
import { displayToast } from '../../../../_helpers/commonFunctions';
import { RECURRING_INSPECTION_VALID_FREQUENCIES as FREQUENCIES } from '../../../../utils/Constants';
import {
  checkIfLocalImage, getBlobImagesFromDB, checkLocalImagesInInspections, deleteReportDetailsFromDB
} from '../../../../_helpers/offlineEnhanced';

let isReportUploaded = false;

export const CustomerListTableRow = (props) => {
  const {
    row, history, clearInspectionState, formReportDetails,
    updateReport, setReportSelectedToUpload, clearAnswers, setAnswer,
    setUpdatedAnswersReportData, offlineMode, createInspection,
  } = props;

  const {
    type,
    reportCategory,
    createdFor,
    createdForName,
    reportType,
    _id,
    jobId,
    frequency,
    reportJson,
    answers,
    createdAt,
    recurringInspectionDetails,
    price,
  } = row;

  const isReport = Object.prototype.hasOwnProperty.call(row, 'quickBookDetails');
  const isIncompleteReportByJob = Object.prototype.hasOwnProperty.call(row, 'jobId');
  const isInspectionReport = (reportType === 'Inspection' || type === 'Inspection');

  let reportEntryId = _id;
  isReportUploaded = false;
  if (isReport && isIncompleteReportByJob && jobId) {
    reportEntryId = jobId;
  }
  const templateType = props.offlineMode.reportEntries[reportEntryId]
    && props.offlineMode.reportEntries[reportEntryId].templateType;

  const isDefaultReport = templateType === 'Static';

  const uploadReport = (reportId) => {
    isReportUploaded = true;
    const report = {
      ...props.offlineMode.reportEntries[reportId || offlineMode.reportSelectedToUpload]
    };
    if (report.isIdTemporary) {
      if (report.reportId < 100 || !report.reportId) delete report.reportId;
      delete report.isIdTemporary;
      delete report._id;
    }
    if (isInspectionReport) {
      delete report.finishReport;
    }
    if (report.templateType) {
      delete report.templateType;
    }
    delete report.createdAt;
    if (isDefaultReport) createInspection(report);
    else updateReport(report);
  };

  const handleSignatureUploadForNonInspections = (reportId, report, localSignatures) => {
    const ownerSignatures = report.finishReport && report.finishReport.ownerSignature;
    const inspectorSignatures = report.finishReport && report.finishReport.inspectorSignature;

    const isOwnerSignLocal = ownerSignatures && checkIfLocalImage(ownerSignatures.url);
    const isInspectorSignLocal = inspectorSignatures && checkIfLocalImage(inspectorSignatures.url);

    if (isOwnerSignLocal) {
      props.setSignatureType('ownerSignature');
      const formData = new FormData();
      formData.append('image', localSignatures.ownerSignature);
      if (!offlineMode.uploadSignature.isFetching) props.uploadSignatures(formData);
    } else if (isInspectorSignLocal) {
      props.setSignatureType('inspectorSignature');
      const formData = new FormData();
      formData.append('image', localSignatures.inspectorSignature);
      if (!offlineMode.uploadSignature.isFetching) props.uploadSignatures(formData);
    } else {
      uploadReport(reportId);
    }
  };

  const handleUploadSignatures = async (reportId, report) => {
    const localSignatures = await getBlobImagesFromDB(reportId);

    if (isInspectionReport) {
      const localSignDivId = checkLocalImagesInInspections(report, isDefaultReport);
      if (!localSignDivId) {
        uploadReport(reportId);
      } else {
        props.setSignatureType(localSignDivId);
        const formData = new FormData();
        formData.append('image', localSignatures[localSignDivId]);
        props.uploadSignatures(formData);
      }
    } else {
      handleSignatureUploadForNonInspections(reportId, report, localSignatures);
    }
  };

  const checkForLocalSignatures = () => {
    const { reportSelectedToUpload, reportEntries } = props.offlineMode;
    const report = { ...reportEntries[reportSelectedToUpload] };

    const ownerSignatures = report.finishReport && report.finishReport.ownerSignature;
    const inspectorSignatures = report.finishReport && report.finishReport.inspectorSignature;

    const isOwnerSignLocal = ownerSignatures && checkIfLocalImage(ownerSignatures.url);
    const isInspectorSignLocal = inspectorSignatures && checkIfLocalImage(inspectorSignatures.url);

    const isInspectionSignLocal = checkLocalImagesInInspections(report, isDefaultReport);

    if (isOwnerSignLocal || isInspectorSignLocal || isInspectionSignLocal) {
      handleUploadSignatures(reportSelectedToUpload, report);
    } else {
      uploadReport();
    }
  };

  useEffect(() => {
    if (offlineMode.uploadSignature && offlineMode.uploadSignature.isSuccess
      && !isReportUploaded
      && !props.updateReportState.isfetching
      && offlineMode.reportSelectedToUpload
      && reportEntryId === offlineMode.reportSelectedToUpload
    ) {
      props.uploadSignaturesReset();
      checkForLocalSignatures();
    }
    if (offlineMode.uploadSignature && offlineMode.uploadSignature.error) {
      props.uploadSignaturesReset();
      displayToast(offlineMode.uploadSignature.error, 'error');
    }
  }, [offlineMode.uploadSignature && offlineMode.uploadSignature.isSuccess]);

  const handleSetReportData = (reportEntry, reportEntryName) => {
    const isReportStarted = props.offlineMode.reportEntries[reportEntry]
      && Object.prototype.hasOwnProperty.call(props.offlineMode.reportEntries[reportEntry], 'frequency');
    const state = {
      reportEntry
    };
    if (reportEntryName) state.reportEntryName = reportEntryName;
    if (templateType) state.selectedReport = isDefaultReport ? 'Default' : 'Dynamic';
    if (!templateType && !isReport && isReportStarted) {
      state.selectedReport = props.offlineMode.reportEntries[reportEntry]
        && (props.offlineMode.reportEntries[reportEntry].templateType === 'Static'
          ? 'Default'
          : 'Dynamic');
    }
    if (isInspectionReport && isReportStarted) {
      setUpdatedAnswersReportData({
        ...props.offlineMode.reportEntries[reportEntry], _id: reportEntry
      });
    }
    history.push({
      pathname: (isInspectionReport && !isReportStarted) ? 'reportClientDetail' : `reportDetail/${reportEntry}`,
      state
    });
  };

  const handleStartReport = () => {
    if (props.offlineMode.isOffline) {
      clearInspectionState();
      const formData = {
        typeValue: isReport ? reportType : (type || reportType),
        catValue: reportCategory,
        customerValue: isReport ? createdFor : (createdFor._id || createdFor)
      };
      if (reportType === 'Inspection' || type === 'Inspection') {
        if (recurringInspectionDetails && recurringInspectionDetails.frequency) {
          formData.frequency = recurringInspectionDetails.frequency;
        }
        if (frequency) {
          const frequencyObjects = FREQUENCIES.find(freq => freq.value === frequency);
          formData.frequency = frequencyObjects
            && frequencyObjects.label ? frequencyObjects.label : frequency;
        }
        if (reportJson) formData.reportTemplate = { reportJson };
        clearAnswers();
        if (answers) {
          setAnswer({ answers });
        }
        if (price !== undefined || price !== null) {
          formData.price = price;
        }
      }
      formReportDetails(formData);
      if (!isReport) {
        handleSetReportData(_id);
      } else if (Object.prototype.hasOwnProperty.call(row, 'jobId')) {
        handleSetReportData(jobId);
      } else {
        handleSetReportData(_id, _id);
      }
    } else {
      const report = { ...props.offlineMode.reportEntries[reportEntryId] };
      if (isInspectionReport
        && !templateType
      ) {
        displayToast("This inspection isn't started yet. Kindly delete the entry.", 'error');
      } else {
        setReportSelectedToUpload(reportEntryId);
        handleUploadSignatures(reportEntryId, report);
      }
    }
  };

  const idToExpand = Object.keys(props.isClassVisible);
  const isIdToExpand = props.isClassVisible[idToExpand[0]];

  const jobIndexOfReport = props.entries.findIndex(entry => entry._id === jobId);

  if (isReport && jobIndexOfReport > -1) {
    return null;
  }

  return (
    <tr className={
      jobId && jobId.toString() === idToExpand[0] && isIdToExpand ? 'accordion-opened' : ''
    }
      style={
        jobId && jobId.toString() === idToExpand[0] && isIdToExpand
          ? { maxHeight: '1000px', cursor: 'pointer' }
          : { maxHeight: '60px', cursor: 'pointer' }
      }>
      <td style={{ width: '13%' }}>
        <span>
          {reportCategory}
        </span>
      </td>
      <td style={{ width: '16%' }}>
        <h3><FormattedMessage id='Report Type' defaultMessage='Report Type' /></h3>
        <span>{isReport ? reportType : (type || reportType)}</span>
      </td>
      <td style={{ width: '16%' }}>
        <h3><FormattedMessage id='Customer' defaultMessage='Customer' /></h3>
        <span>{isReport ? createdForName : (createdFor.businessName || createdForName)}</span>
      </td>
      <td style={{ width: '16%' }}>
        <h3><FormattedMessage id='Type' defaultMessage='Type' /></h3>
        <span>{isReport ? 'Report' : 'Job'}</span>
      </td>
      <td style={{ width: '16%' }}>
        <h3><FormattedMessage id='Created At' defaultMessage='Created At' /></h3>
        <span>{createdAt && moment(createdAt).format('LLL')}</span>
      </td>
      <td style={{ width: '19%' }}>
        <div className='posi-relative'>
          {!props.offlineMode.isOffline
            && <Button
              text='Sync'
              className='offline-customer-list-table-start-btn mr-rt-30'
              color='#B72E28'
              onBtnClick={props.offlineMode.isOffline
                ? () => { displayToast('Kindly switch to online mode to sync the report', 'error'); }
                : handleStartReport}
            />
          }
          {props.offlineMode.isOffline
            && <>
              {((props.offlineMode.reportEntries[reportEntryId]
                && props.offlineMode.reportEntries[reportEntryId].isFinished)
                || !props.offlineMode.isOffline)
                && <Button
                  text='Sync'
                  className='offline-customer-list-table-start-btn mr-rt-30'
                  color='#B72E28'
                  onBtnClick={props.offlineMode.isOffline
                    ? () => { displayToast('Kindly switch to online mode to sync the report', 'error'); }
                    : handleStartReport}
                />
              }
              {!(props.offlineMode.reportEntryIdsEditedOffline.includes(reportEntryId.toString()))
                ? <Button
                  text='Start'
                  className='offline-customer-list-table-start-btn mr-rt-30'
                  color='#6AA14E'
                  onBtnClick={handleStartReport}
                />
                : <span
                  style={{ right: '2.5vw' }}
                  className="edit-icon sprite-icon offline-customer-list-table-delete-icon"
                  onClick={handleStartReport}
                />
              }
            </>
          }
          <span
            className="delete-icon sprite-icon offline-customer-list-table-delete-icon"
            onClick={() => {
              deleteReportDetailsFromDB(reportEntryId);
              props.deleteSelectedJob(_id, isReport);
            }}
          ></span>
        </div>
      </td>
      <span className="icon-accordion" onClick={() => props.setClassVisibility(jobId)}>
        {
          jobId && jobId.toString() === idToExpand[0] && isIdToExpand ? '-' : '+'
        }
      </span>
    </tr>
  );
};

CustomerListTableRow.propTypes = {
  row: PropTypes.object,
  offlineMode: PropTypes.object,
  deleteSelectedJob: PropTypes.func,
  history: PropTypes.object,
  clearInspectionState: PropTypes.func,
  formReportDetails: PropTypes.func,
  updateReport: PropTypes.func,
  setReportSelectedToUpload: PropTypes.func,
  clearAnswers: PropTypes.func,
  setAnswer: PropTypes.func,
  setUpdatedAnswersReportData: PropTypes.func,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func,
  uploadSignatures: PropTypes.func,
  setSignatureType: PropTypes.func,
  uploadSignaturesReset: PropTypes.func,
  updateReportState: PropTypes.object,
  createInspection: PropTypes.func,
  entries: PropTypes.array,
};

export default withRouter(CustomerListTableRow);
