import React from 'react';

export default [
  {
    title: 'Report Category',
    isSortable: false,
    width: '16%'
  },
  {
    title: 'Report Type',
    isSortable: false,
    width: '16%'
  },
  {
    title: 'Customer',
    isSortable: false,
    width: '16%'
  },
  {
    title: 'Type',
    isSortable: false,
    width: '16%',
  },
  {
    title: 'Created At',
    isSortable: false,
    width: '16%',
  },
  {
    title: ' ',
    isSortable: false,
    width: '20%'
  },
];

export const OfflineModeContext = React.createContext('hi');

export const offlineModeOptions = [
  'Customers',
  'JobLink',
  'Jobs',
];

export const offlineModeOptionsToolTips = [
  'Manually create your jobs.',
  'Jobs that have been created and scheduled.',
  'Jobs that have already been created but not scheduled.',
];

export const reportTypes = [
  'Inspection',
  'Work Order',
  'Quote',
];

export const headerDataComingJobs = [
  {
    title: 'Jobs Due',
    isSortable: false,
  },
];

export const headerDataMissedJobs = [
  {
    title: 'Missed Inspections',
    isSortable: false,
  },
];

export const timeCategory = [
  'Jobs Due',
  'Missed Inspections',
];

export const onlineToastMessage = 'Please sync your data to the server';

export const toggleOfflineErrorMessage = 'Please upload your data to server before going offline';

export const fetch = {
  CUSTOMER_LIST: 1,
  JOB_LIST: 2,
  REPORT_LIST_BY_JOB_ID: 3,
  REPORT_LIST_BY_CUSTOMER: 4,
  TEAM_LIST: 5,
  REPORT_TEMPLATE_LIST: 6,
  DEVICE_LIST: 7,
  INNER_DEVICE_LIST: 8,
  PART_LIST: 9,
  QUESTION_JSON_DEFAULT_EPORT: 10,
  CREATE_REPORT_ENTRIES: 11,
  CREATE_NEW_REPORT: 12,
};
