import actionConstants from '../constants/ActionConstants';
import { teamListing } from '../../api';
import ActionDispatcher from '../../api/ActionDispatcher';

const showTeamListAction = (isInitial = false, page, limit, payload) => {
  const REQUEST = isInitial
    ? actionConstants.SHOW_INITIAL_TEAM_REQUEST
    : actionConstants.SHOW_TEAM_REQUEST;
  return (
    ActionDispatcher(teamListing.bind(null, page, limit, payload),
      actionConstants.SHOW_TEAM_SUCCESS,
      actionConstants.SHOW_TEAM_FAILURE,
      REQUEST)
  );
};

export default showTeamListAction;
