import actionConstants from '../constants/ActionConstants';

const formReportDetails = payload => (dispatch) => {
  dispatch({
    type: actionConstants.SET_REPORT_CAT,
    payload
  });
};

export default formReportDetails;
