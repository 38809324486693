import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  success: '',
  isSuccess: false,
};

export default function deleteClient(state = initialState, action) {
  switch (action.type) {
    case actionConstants.DELETE_CLIENT_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isFetching: true,
        error: '',
      };
    case actionConstants.DELETE_CLIENT_SUCCESS:
      return {
        isFetching: false,
        isSuccess: true,
        success: action.message,
        error: '',
      };
    case actionConstants.DELETE_CLIENT_FAILURE:
      return {
        error: action.message,
        isSuccess: false,
        isFetching: false,
        success: '',
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isSuccess: false,
        isFetching: false,
      };
    default:
      return state;
  }
}
