import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  isSuccess: false,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.INVENTORY_DELETE_SUPPLIER_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false
      };
    case actionConstants.INVENTORY_DELETE_SUPPLIER_LIST_SUCCESS:
      return {
        ...state,
        isfetching: false,
        success: action.message,
        isSuccess: true,
        data: action.payload
      };
    case actionConstants.INVENTORY_DELETE_SUPPLIER_LIST_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.INVENTORY_DELETE_SUPPLIER_LIST_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
