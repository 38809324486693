import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  isSuccess: false,
  resetPassword: {
    error: '',
    isFetching: false,
    success: '',
    isSuccess: false,
  }
};

export default function sendEmail(state = initialState, action) {
  let resetPassword = { ...state.resetPassword };
  switch (action.type) {
    case actionConstants.SEND_EMAIL_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false
      };
    case actionConstants.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isfetching: false,
        success: action.payload.message,
        isSuccess: true,
      };
    case actionConstants.SEND_EMAIL_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.RESET_PASSWORD_REQUEST:
      resetPassword = {
        ...resetPassword,
        isfetching: true,
        isSuccess: false
      };
      return {
        ...state,
        resetPassword,
      };
    case actionConstants.RESET_PASSWORD_SUCCESS:
      resetPassword = {
        ...resetPassword,
        isfetching: false,
        success: action.message,
        isSuccess: true,
      };
      return {
        ...state,
        resetPassword,
      };
    case actionConstants.RESET_PASSWORD_FAILURE:
      resetPassword = {
        ...resetPassword,
        error: action.message,
        isfetching: false,
      };
      return {
        ...state,
        resetPassword,
      };
    case actionConstants.CLEAR_STATE:
      resetPassword = {
        error: '',
        success: '',
        isSuccess: false,
        isfetching: false,
      };
      return {
        error: '',
        success: '',
        isSuccess: false,
        isfetching: false,
        resetPassword,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
