const EVENT = {
  TO: {
    CONNECT: 'connected',
    SEND_MESSAGE: 'message',
    MARK_CHAT_AS_SEEN: 'messageRead',
    MARK_ALL_DELIVERED: 'messageDelivered', // on reconnect
    MARK_MESSAGE_AS_DELIVERED: 'messageDeliveredByChannelId', // on receive
    EMPLOYEE_GPS_LOCATION: 'updateEmployeeLocation'
  },
  ON: {
    CONNECT: 'connect',
    MESSAGE_RECEIVED: 'message',
    MESSAGE_DELIVERED: 'messageDeliveredByChannelId',
    MESSAGE_SENT: 'messageAcknowledged',
    MESSAGE_SEEN: 'messageRead',
    UPDATE_GROUP: 'updateGroup',
  }
};

export default EVENT;
