import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import DropDown from '../DropDown';

const AddNewReportModalBody = (props) => {
  const {
    validFrequencies, frequency, handleOnChange, reportTemplateList, reportTemplate, selectedReport
  } = props;

  return (
    <div>
      <div className="dis-flex f-dir-column mr-top-24">

        {selectedReport === 'Dynamic'
          ? <div className='report-inner-part'>
            <div className='dis-flex f-dir-column'>
              <DropDown
                dropDownList={reportTemplateList}
                value={(reportTemplate && reportTemplate.ReportName) || 'Select here'}
                showDropDownItemObjectKey={'ReportName'}
                name={'reportTemplate'}
                height='250px'
                handleOnChange={(name, value) => {
                  handleOnChange(name, value);
                }}
                className='dis-flex f-dir-column'
              />
              <label htmlFor="usr">
                <FormattedMessage
                  id='Report Templates'
                  defaultMessage='Report Templates'
                />
              </label>
            </div>
          </div>
          : null}

        <div className='report-inner-part'>
          <div className='dis-flex f-dir-column'>
            <DropDown
              dropDownList={validFrequencies}
              value={frequency || 'Select here'}
              height='250px'
              name={'frequency'}
              handleOnChange={(name, value) => {
                handleOnChange(name, value);
              }}
              className='dis-flex f-dir-column'
            />
            <label htmlFor="usr">
              <FormattedMessage
                id='Frequency'
                defaultMessage='Frequency'
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};


const AddNewReportModalFooter = ({ handleClickSave, isSaveVisible }) => (
  <div className='dis-flex' id='auto-routing-buttons'>
    {isSaveVisible
      ? <Button
        text='Save'
        color='#6AA14E'
        onBtnClick={handleClickSave}
      /> : null}
  </div>

);

AddNewReportModalBody.propTypes = {
  validFrequencies: PropTypes.array, // array containing valid frequencies
  frequency: PropTypes.string, // frequency
  handleOnChange: PropTypes.func, // handles change event
  reportTemplateList: PropTypes.array, // array of report templates
  reportTemplate: PropTypes.string, // report template
  selectedReport: PropTypes.string,
};

AddNewReportModalFooter.propTypes = {
  handleClickSave: PropTypes.func, // handles save button click
  isSaveVisible: PropTypes.bool // checks if save button is visible
};

export { AddNewReportModalBody, AddNewReportModalFooter };
