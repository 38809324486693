import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { updateJob } from '../../redux/actions/jobLinkActions';
import NotesBodys from '../../components/EditJobModal/NotesBody';
import Loader from '../../components/Loader';
import { displayToast } from '../../_helpers/commonFunctions';
import setIsPromptAction from '../../redux/actions/isPromptAction';
import coachMarks from '../../libs/constants/coachMarks';

export const ChooseModalBody = (props) => {
  useEffect(() => {
    props.runCoachMarks(true);
  }, []);
  return (
    <div className=''>
      {props.currentCoachmarkTarget === coachMarks
        .inspectionReminders.choose.target
        && <div
          id='test-tooltip'
          className={`info-timecard-info-show-descr coachmark-tooltips ${coachMarks.inspectionReminders.choose.toolTipClass}`}
          style={coachMarks.inspectionReminders.choose.style}
        >
          {coachMarks.inspectionReminders.choose.content}
        </div>
      }
      <FormattedMessage
        id='Choose one of the following'
        defaultMessage='Choose one of the following'
      />:-
      <div className='mr-top-14' style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button
          text='Schedule job'
          color='#b71b1b'
          onBtnClick={() => {
            props.handleClickScheduleJob();
            if (props.currentCoachmarkTarget === coachMarks.inspectionReminders.choose.target) {
              props.toggleCoachmarksVisibility(null, null,
                coachMarks.inspectionReminders.choose.nextTarget);
            }
          }}
          className='mr-rt-20 choose-modal-btns'
        />
        <Button
          text='Make Notes'
          onBtnClick={() => {
            props.handleClickMakeNotes();
            if (props.currentCoachmarkTarget === coachMarks.inspectionReminders.choose.target) {
              props.toggleCoachmarksVisibility(null, null,
                coachMarks.inspectionReminders.jobs.target);
            }
          }}
          className='choose-modal-btns'
        />
      </div>
    </div>
  );
};

const ChooseModal = props => (
  <Modal
    modalHeading='Choose'
    modalContentClass='choose-one-coachmark'
    ModalBody={ChooseModalBody}
    handleClickCross={props.handleClickCross}
    ModalBodyProps={{
      handleClickScheduleJob: props.handleClickScheduleJob,
      handleClickMakeNotes: props.handleClickMakeNotes,
      runCoachMarks: props.runCoachMarks,
      toggleCoachmarksVisibility: props.toggleCoachmarksVisibility,
      currentCoachmarkTarget: props.currentCoachmarkTarget,
    }}
  />
);

export const MakeNotesBody = (props) => {
  const { note, handleAddNotes, handleClickSave } = props;
  const { inspectorNotes, customerNotes } = note;
  return (
    <div>
      <NotesBodys
        iNotes={inspectorNotes}
        cNotes={customerNotes}
        handleModalNotes={handleAddNotes}
        setIsPrompt={props.setIsPrompt}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          text='Save'
          color='#6aa14e'
          className='mr-top-24'
          onBtnClick={handleClickSave}
        />
      </div>
    </div>
  );
};

export class MakeNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: {
        inspectorNotes: [],
        customerNotes: [],
      },
    };
  }

  handleAddNotes = (type, notes) => {
    if (type === 'inspector') {
      this.setState({ note: { ...this.state.note, inspectorNotes: notes } });
    } else if (type === 'customer') {
      this.setState({ note: { ...this.state.note, customerNotes: notes } });
    }
  }

  getInitialNoteDetails = () => {
    const { jobDetail } = this.props.jobDetail;
    let noteId = '';
    const note = {
      customerNotes: [],
      inspectorNotes: [],
    };
    if (jobDetail.noteId) {
      noteId = jobDetail.noteId._id;
      note.inspectorNotes = [...jobDetail.noteId.inspectorNotes];
      note.customerNotes = [...jobDetail.noteId.customerNotes];
    }
    return {
      noteId,
      note
    };
  }

  setInitialNotes = () => {
    if (this.props.jobDetail) {
      const initialNotes = this.getInitialNoteDetails();
      this.setState({ note: { ...initialNotes.note } });
    }
  }

  componentDidUpdate(prevProps) {
    const { jobDetail, updateJobDetails, handleClickCross } = this.props;
    if (jobDetail.isSuccess && prevProps.jobDetail.isSuccess !== jobDetail.isSuccess) {
      this.setInitialNotes();
    }
    if (updateJobDetails.isSuccess
      && updateJobDetails.isSuccess !== prevProps.updateJobDetails.isSuccess) {
      displayToast(updateJobDetails.success, 'success');
      handleClickCross();
    } else if (updateJobDetails.error
      && updateJobDetails.error !== prevProps.updateJobDetails.error) {
      displayToast(updateJobDetails.error, 'error');
    }
  }

  componentDidMount() {
    this.setInitialNotes();
  }

  render() {
    const { handleClickCross, jobDetail } = this.props;
    return (
      jobDetail.isFetching ? <Loader /> : <Modal
        modalHeading='Make Notes'
        modalId='make-notes'
        ModalBody={MakeNotesBody}
        handleClickCross={handleClickCross}
        ModalBodyProps={{
          handleAddNotes: this.handleAddNotes,
          note: { ...this.state.note },
          handleClickSave: () => (
            this.props.updateJob(jobDetail.jobDetail._id, {
              note: {
                inspectorNotes: this.state.note.inspectorNotes.filter(note => note !== ''),
                customerNotes: this.state.note.customerNotes.filter(note => note !== ''),
              }
            })
          ),
          setIsPrompt: this.props.setIsPromptAction
        }}
      />
    );
  }
}

MakeNotesBody.propTypes = {
  note: PropTypes.object,
  handleAddNotes: PropTypes.func,
  handleClickSave: PropTypes.func,
  setIsPrompt: PropTypes.func,
};

MakeNotes.propTypes = {
  handleClickCross: PropTypes.func,
  jobDetail: PropTypes.object,
  updateJobDetails: PropTypes.object,
  updateJob: PropTypes.func,
  setIsPromptAction: PropTypes.func,
};

ChooseModalBody.propTypes = {
  handleClickScheduleJob: PropTypes.func,
  handleClickMakeNotes: PropTypes.func,
  runCoachMarks: PropTypes.func,
  toggleCoachmarksVisibility: PropTypes.func,
  currentCoachmarkTarget: PropTypes.string,
};

ChooseModal.propTypes = {
  handleClickCross: PropTypes.func,
  runCoachMarks: PropTypes.func,
  handleClickScheduleJob: PropTypes.func,
  handleClickMakeNotes: PropTypes.func,
  toggleCoachmarksVisibility: PropTypes.func,
  currentCoachmarkTarget: PropTypes.string,
};

const mapStateToProps = store => ({
  jobDetail: store.jobListDetails.jobDetails,
  updateJobDetails: store.jobListDetails.updateJob,
});

export { ChooseModal };
export default connect(mapStateToProps, {
  updateJob,
  setIsPromptAction,
})(MakeNotes);
