import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

/**
 * This component shows the searching text field.
 * @param {*func} onTextChange function for handling change in text field
 * @param {*func} onSearchPress function for handling searching on enter
 * @param {*string} searchValue value that is typed in text box.
 */
const SearchBar = ({
  onTextChange, onSearchPress, searchContainerClass, style, id,
  intl, isCrossVisible, searchValue, inputClassName, placeHolder
}) => {
  const placeholder = intl.formatMessage({ id: placeHolder });
  return (
    <div id={id} className={searchContainerClass || 'search-container'} style={{ ...style }}>
      <input
        type="search"
        value={searchValue}
        spellCheck="true"
        placeholder={placeholder}
        autoComplete='off'
        name='search-bar'
        className={inputClassName || 'search'}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onSearchPress();
          }
        }}
        onChange={onTextChange}
      />
      {
        isCrossVisible
          ? <div
            className="cross-button"
            data-testid="crossButton"
            onClick={
              () => onTextChange('')
            }>x</div>
          : null
      }
      <button type="button" onClick={onSearchPress} >
        {/* <span className="search-icon sprite-icon"></span> */}
        <i className="search-icon material-icons">search</i>
      </button>
    </div>
  );
};

SearchBar.propTypes = {
  /**
   * Onchange function
   */
  onTextChange: PropTypes.func,
  /**
   * @function called on enter press or button click.
   */
  onSearchPress: PropTypes.func,
  intl: intlShape.isRequired,
  inputClassName: PropTypes.string,
  searchContainerClass: PropTypes.string,
  isCrossVisible: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  searchValue: PropTypes.string,
  placeHolder: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
};

SearchBar.defaultProps = {
  placeHolder: 'Search',
  style: {},
  id: '',
};

export default injectIntl(SearchBar);
