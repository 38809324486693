import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  isSuccess: false,
  success: '',
  isUploadedFile: false,
  isListCreated: false,
};

export default function importDevice(state = initialState, action) {
  switch (action.type) {
    case actionConstants.UPLOAD_XLXS_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
        isUploadedFile: false
      };
    case actionConstants.UPLOAD_XLXS_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccess: true,
        success: action.message,
        isUploadedFile: true,
      };
    case actionConstants.UPLOAD_XLXS_FAILURE:
      return {
        ...state,
        error: action.message,
        isSuccess: false,
        isfetching: false,
        isUploadedFile: false,
      };
    case actionConstants.CREATE_DEVICE_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
        success: action.message,
        isListCreated: false,
      };
    case actionConstants.CREATE_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        success: action.message,
        isfetching: false,
        isSuccess: true,
        isListCreated: true,
      };
    case actionConstants.CREATE_DEVICE_LIST_FAILURE:
      return {
        ...state,
        isfetching: false,
        isSuccess: false,
        error: action.message,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isListCreated: false,
        isUploadedFile: false,
        isSuccess: false,
        success: '',
        error: ''
      };
    default:
      return state;
  }
}
