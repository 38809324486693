import call from './apiRequest';
import { getToken } from '../_helpers/commonFunctions';
import apiEndpoints from './apiEndpoints';

/**
 * Api call for getting job list
 * @param {object} params
 * @param {number} page
 * @param {number} limit
 */
const getJobList = (params, page, limit = 10) => {
  const queryParams = { ...params };
  if (!queryParams.startDate) {
    delete queryParams.startDate;
  }
  return call({
    method: 'get',
    endpoint: `${apiEndpoints.getJobList}?page=${page}&limit=${limit}`,
    query: { ...queryParams, businessNameSort: 1 },
    authKey: getToken(),
  });
};

/**
 * Api call for getting job details
 * @param {string} jobId
 */
export const getJobDetails = jobId => call({
  method: 'get',
  endpoint: apiEndpoints.getJobDetails,
  query: {
    jobid: jobId,
  },
  authKey: getToken(),
});

/**
 * Api call for getting team list
 * @param {object} queryParams
 * @param {number} page
 * @param {number} limit
 */
export const getTeamListWithFilters = (queryParams, page, limit = 5) => call({
  method: 'get',
  endpoint: `${apiEndpoints.teamListWithFilters}?page=${page}&limit=${limit}`,
  query: queryParams,
  authKey: getToken(),
});

/**
 * Api call for assigning job
 * @param {string} jobId
 * @param {object} body
 */
export const assignJob = (jobId, body) => call({
  method: 'put',
  endpoint: `${apiEndpoints.teamListWithFilters}/${jobId}`,
  payload: body,
  authKey: getToken(),
});

/**
 * Api call for unassigning job
 * @param {string} jobId
 */
export const unassignJob = jobId => call({
  method: 'delete',
  endpoint: `${apiEndpoints.teamListWithFilters}/${jobId}`,
  authKey: getToken(),
});

/**
 * Api call for deleting job
 * @param {string} jobId
 */
export const deleteJob = jobId => call({
  method: 'delete',
  endpoint: apiEndpoints.getJobDetails,
  query: {
    jobid: jobId,
  },
  authKey: getToken(),
});

/**
 * Api call for updating job details
 * @param {string} jobid
 * @param {object} body
 */
export const updateJobApiCall = (jobid, body) => call({
  method: 'put',
  endpoint: apiEndpoints.jobs,
  query: {
    jobid,
  },
  payload: body,
  authKey: getToken(),
});

export default getJobList;
