import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  successMsg: '',
  isSuccessDelete: false,
};

export default function deleteReport(state = initialState, action) {
  switch (action.type) {
    case actionConstants.DELETE_REPORT_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccessDelete: false,
        successMsg: ''
      };
    case actionConstants.DELETE_REPORT_LIST_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccessDelete: true,
        successMsg: action.message
      };
    case actionConstants.DELETE_REPORT_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isfetching: false,
      };
    case actionConstants.CLEAR_REPORT_DELETE_STATE:
      return {
        ...state,
        isSuccessDelete: false,
        successMsg: '',
        error: ''
      };
    default:
      return state;
  }
}
