import call from './apiRequest';
import apiEndpoints from './apiEndpoints';
import { getToken } from '../_helpers/commonFunctions';

/**
 * Api call to get generic permissions
 */
const genericPermissions = () => call({
  method: 'get',
  endpoint: apiEndpoints.genericPermissions,
  authKey: getToken()
});

export default genericPermissions;
